import React, {FC} from 'react';

interface IShareIcon {
  active: boolean
}

const ShareIcon: FC<IShareIcon> = ({active}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_396_169)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.4945 3.74315C16.4944 2.8633 16.8037 2.01142 17.3682 1.33656C17.9328 0.661706 18.7166 0.206842 19.5827 0.0515525C20.4487 -0.103737 21.3418 0.0504369 22.1056 0.4871C22.8695 0.923764 23.4555 1.61511 23.7611 2.44018C24.0667 3.26526 24.0724 4.17152 23.7773 5.00041C23.4822 5.82929 22.9051 6.52803 22.1468 6.97436C21.3886 7.42069 20.4976 7.5862 19.6296 7.44192C18.7617 7.29765 17.9721 6.85278 17.399 6.18515L7.32204 10.8651C7.5537 11.5993 7.5537 12.387 7.32204 13.1211L17.399 17.8011C18.0048 17.0966 18.8505 16.6421 19.7723 16.5256C20.6941 16.4091 21.6263 16.639 22.3883 17.1707C23.1503 17.7024 23.6877 18.498 23.8965 19.4033C24.1053 20.3087 23.9706 21.2593 23.5184 22.071C23.0663 22.8827 22.3289 23.4976 21.4492 23.7966C20.5695 24.0956 19.6101 24.0573 18.757 23.6893C17.9038 23.3212 17.2177 22.6495 16.8316 21.8045C16.4455 20.9594 16.3868 20.001 16.667 19.1151L6.59004 14.4351C6.09119 15.0165 5.42626 15.4311 4.68471 15.6233C3.94315 15.8154 3.16054 15.7758 2.44215 15.5099C1.72376 15.2439 1.10405 14.7643 0.666399 14.1356C0.228747 13.5069 -0.00585938 12.7592 -0.00585938 11.9931C-0.00585938 11.2271 0.228747 10.4794 0.666399 9.85072C1.10405 9.222 1.72376 8.7424 2.44215 8.47643C3.16054 8.21046 3.94315 8.17088 4.68471 8.36302C5.42626 8.55516 6.09119 8.96979 6.59004 9.55115L16.667 4.87115C16.5523 4.50615 16.4942 4.12575 16.4945 3.74315Z"
              fill={active ? "#3399FF" : "#343A40"}/>
      </g>
      <defs>
        <clipPath id="clip0_396_169">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShareIcon;
import {IAssignmentsList} from "../../../interfaces/IAssignments";
import {SyntheticEvent} from "react";
import {createSearchParams} from "react-router-dom";
import makeQuery from "../../../functions/makeQuery";

export const openModalsAndAddTaskHandler = (
    obj: IAssignmentsList,
    setState: any,
    setActiveIdTask:any,
    setActiveTask:any
) => {
    setState(true);
    setActiveIdTask(obj.id);
    setActiveTask(obj);
};

export const chooseStatusHandler = (
    status: SyntheticEvent<HTMLSelectElement, Event>,
    activeTask: any,
    setPermission: any,
    setNewStatus: any,
    me: any
) => {
    if(activeTask && activeTask.responsible_executor === null) {
        setPermission(true);
    }
    if(activeTask && activeTask.responsible_executor.id !== me.id) {
        setPermission(true);
    }
    if(activeTask && activeTask.responsible_executor?.id === me.id) {
        setPermission(false);
        if(status.currentTarget.value !== 'completed') {
            setNewStatus(status.currentTarget.value);
        }
        if (status.currentTarget.value === 'completed') {
            setPermission(true);
        }
    }
    if(activeTask && activeTask.author?.id === me.id) {
        setNewStatus(status.currentTarget.value);
        setPermission(false);
    }
};

export const navigateToPathAndIdHandler = (path: string, id: string, status:string, navigate:any) => {
    if(id !== '') {
        navigate({
            pathname: path,
            search: createSearchParams(makeQuery.stringify({id: id, status: status})).toString()
        });
    }
};
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IGeoIcon, IGeoIcons} from "../../interfaces/IGeoIcons";

export const geoIconsApi = createApi({
    reducerPath: 'geoIcons/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['GeoIcons'],
    endpoints: build => ({
        getGeoIcons: build.query<IGeoIcons, string | void>({
            query: (query) => {
                return {
                    url: `/geoicon/list/${query || ''}`
                }
            },
            providesTags: ['GeoIcons'],
        }),
        // getUsersById: build.query<IRegion, string>({
        //     query: (id: string) => ({
        //         url: `/detail/users/${id}`
        //     })
        // }),
        createGeoIcon: build.mutation<IGeoIcon, void>({
            query: (body) => ({
                url: '/geoicon/create/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['GeoIcons'],
        }),
        updateGeoIcon: build.mutation<IGeoIcon, Partial<any> & Pick<any, 'id'>>({
            query: ({id, body}) => {
                return {
                    url: `/geoicon/update/${id}/`,
                    method: 'PATCH',
                    body,
                }
            },
            invalidatesTags: ['GeoIcons'],
        }),
        removeGeoIcon: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/geoicon/delete/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['GeoIcons'],
        })
    })
})

export const {
    useLazyGetGeoIconsQuery,
    useGetGeoIconsQuery,
    useCreateGeoIconMutation,
    useUpdateGeoIconMutation,
    useRemoveGeoIconMutation
} = geoIconsApi;
import React, {FC} from 'react';
import {CChart} from "@coreui/react-chartjs";
import {getStyle} from "@coreui/utils";
import {useTranslation} from "react-i18next";

interface IBarChartProps {
  labels: string[]
  data: string[];
  field: string;
}

const BarChart: FC<IBarChartProps> = ({labels, data, field}) => {
  const {t} = useTranslation();
  return (
    <>
      <CChart
        labels="sdaf"
        type="bar"
        data={{
          labels: labels,
          datasets: [
            {
              label: t(`analytics.${field}`),
              backgroundColor: 'rgba(255,99,132,0.75)',
              // @ts-ignore
              data: data,
            },
          ],
        }}

        options={{
          plugins: {
            legend: {
              labels: {
                color: getStyle('--cui-body-color'),
              }
            }
          },
          scales: {
            x: {
              grid: {
                color: getStyle('--cui-border-color-translucent'),
              },
              ticks: {
                color: getStyle('--cui-body-color'),
              },
            },
            y: {
              grid: {
                color: getStyle('--cui-border-color-translucent'),
              },
              ticks: {
                color: getStyle('--cui-body-color'),
              },
            },
          },
        }}
      />
    </>
  );
};

export default BarChart;
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IGeneralInfoEmergencies, IGeneralInfoEmergency} from "../../interfaces/IGeneralInfoEmergency";

export const generalInfoEmergenciesApi = createApi({
    reducerPath: 'generalInfoEmergencies/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['GeneralInfoEmergencies'],
    endpoints: build => ({
        getGeneralInfoEmergencies: build.query<IGeneralInfoEmergency[], void>({
            query: () => {
                return {
                    url: `/gen/info/emergency/list/`
                }
            },
            providesTags: ['GeneralInfoEmergencies'],
        }),
        getGeneralInfoEmergency: build.query<IGeneralInfoEmergency, any>({
            query: (id: string) => ({
                url: `/gen/info/emergency/detail/${id}/`
            })
        }),
        createGeneralInfoEmergency: build.mutation<any, any>({
            query: (body) => ({
                url: '/gen/info/emergency/create/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['GeneralInfoEmergencies'],
        }),
    })
})

export const {
    useLazyGetGeneralInfoEmergenciesQuery,
    useLazyGetGeneralInfoEmergencyQuery,
    useCreateGeneralInfoEmergencyMutation,
} = generalInfoEmergenciesApi;
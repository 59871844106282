import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {ICounty, ICountyResponse} from "../../interfaces/ICounties";

export const countiesApi = createApi({
  reducerPath: 'counties/api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers) => headerConfig(headers),
  }),
  tagTypes: ['Counties'],
  endpoints: build => ({
    getCounties: build.query<ICountyResponse[], string | void>({
      query: (query) => {
        return {
          url: `/list/county/${query || ''}`
        }
      },
      providesTags: ['Counties'],
    }),
    createCounty: build.mutation<any, ICounty>({
      query: (body) => ({
        url: '/create/county/',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Counties'],
    }),
    updateCounty: build.mutation<any, Partial<any> & Pick<any, 'id'>>({
      query: ({id, body}) => {
        return {
          url: `/update/county/${id}/`,
          method: 'PUT',
          body
        }
      },
      invalidatesTags: ['Counties'],
    }),
    removeCounty: build.mutation<{ success: boolean; id: number }, number>({
      query: (id) => ({
        url: `/delete/county/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Counties'],
    })
  })
});

export const {useGetCountiesQuery, useLazyGetCountiesQuery, useCreateCountyMutation, useUpdateCountyMutation, useRemoveCountyMutation} = countiesApi;

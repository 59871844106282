import React, {useEffect, useMemo, useRef, useState} from 'react';
import FormGroup from "../../../components/UI/Forms/FormGroup";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CFormCheck,
    CFormInput,
    CFormSelect,
    CFormTextarea, CSpinner

} from "@coreui/react";
import {useGetGeoTypesQuery} from "../../../store/geoTypes/geoTypes.api";
import {useTranslation} from "react-i18next";
import {
    useCreateGeoObjectMutation, useLazyGetGeoObjectsGeomQuery,
    useLazyGetUsersByIdQuery,
    useUpdateGeoObjectMutation
} from "../../../store/geoObjects/geoObjects.api";
import {useLazyGetRegionsQuery} from "../../../store/regions/regions.api";
import {useLazyGetDistrictsQuery} from "../../../store/districts/districts.api";
import {useLocation, useNavigate} from "react-router-dom";
import {getFormData} from "../../../functions";
import {
    FeatureGroup,
    GeoJSON,
    LayersControl,
    MapContainer, Marker,
    Popup,
    ScaleControl,
    TileLayer, useMapEvents,
    ZoomControl
} from "react-leaflet";
import L from "leaflet";
import Control from "react-leaflet-custom-control";
import {EditControl} from "react-leaflet-draw";
import {getTiles} from "../../../components/maps/tiles";
import MapLoader from "../../../components/preloaders/MapLoader/MapLoader";
import CIcon from "@coreui/icons-react";
import {cilX} from "@coreui/icons";
import {useRemoveGeoObjImageMutation, useUploadGeoObjImageMutation} from "../../../store/geoObjImages/geoObjImages.api";
import Leaflet from "leaflet";
import geoIcons from "../../Settings/GeoIcons/GeoIcons";
import {useGetGeoIconsQuery, useLazyGetGeoIconsQuery} from "../../../store/geoIcons/geoIcons.api";
import makeQuery from "../../../functions/makeQuery";

interface MyComponent {
    handlerZoom: (zoom: number) => void;
    getPosition?: (event: any) => void;
}

interface NewGeoObject {
    geo_type_field: any[]
    value: string,
    geo_type_field_name: string,
    id: number,
    type: string
}

const MyComponent: React.FC<MyComponent> = ({
                                                handlerZoom,
                                                getPosition
                                            }) => {
    const mapEvents = useMapEvents({
        zoomend: () => {
            mapEvents.getZoom();
        },
        click: (e) => {

            //@ts-ignore
            if (getPosition && e.type === 'click') {
                getPosition(e.latlng)

            }
        },

    });
    return null;
};

const NewGeoObject = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const location = useLocation();
    const isId = /\d/.test(location.pathname);
    let geoObjId: string;

    if (isId) {
        geoObjId = location.pathname.split('/')[2];
    } else {
        geoObjId = ''
    }

    let layersControl = true;
    let zoomControl = true;
    let scaleControl = true;
    let loading = false;

    const [updateGeoObject] = useUpdateGeoObjectMutation();
    const [createGeoObject] = useCreateGeoObjectMutation();
    const {data: geoTypes} = useGetGeoTypesQuery();
    const [fetchGeoIcons, {data: geoIcons}] = useLazyGetGeoIconsQuery();
    const [fetchGeoObject, {
        isError: geoObjectError,
        isLoading: geoObjectLoading,
        data: geoObject
    }] = useLazyGetUsersByIdQuery();
    const [fetchGeoObjectsGeom, {
        isError: geoObjectsGeomError,
        isLoading: geoObjectsGeomLoading,
        data: geoObjectsGeom
    }] = useLazyGetGeoObjectsGeomQuery();
    const [fetchRegions, {isError: regionsError, isLoading: regionsLoading, data: regions}] = useLazyGetRegionsQuery();
    const [fetchDistricts, {
        isError: districtsError,
        isLoading: districtsLoading,
        data: districts
    }] = useLazyGetDistrictsQuery();
    const [uploadGeoObjectImage] = useUploadGeoObjImageMutation();
    const [deleteGeoObjectImage] = useRemoveGeoObjImageMutation();
    const [selectedId, setSelectedId] = useState('');
    const [typeLeaflet, setTypeLeaflet] = useState({
        type: '',
    });

    const [state, setState]: any = useState(
        {
            name: '',
            description: '',
            geo_type: '',
            region: '',
            district: '',
            latitude: 41.4635,
            longitude: 75.9895,
            geo_json: '',
        }
    );
    const [deletedImages, setDeletedImages]: any = useState([]);
    // const [btnRender, setBtnRender] = useState(true);
    const [popUp, setPopUp] = useState(false);
    const [visible, setVisible]: any = useState(false)
    const [opacity, setOpacity] = useState(1);
    const [opacityVisible, setOpacityVisible] = useState(false);
    const [coordinatesVisible, setCoordinatesVisible] = useState(false);
    const [coordinates, setCoordinates]: any = useState({lat: '', lng: ''});
    const [mapKey, setMapKey] = useState(0);
    const [center, setCenter] = useState(false);
    const [geoJsonState, setGeoJsonState]: any = useState({})
    const [fields, setFields]: any = useState([]);
    const [error, setError] = useState('');
    const [mapActive, setMapActive] = useState('block');
    // const [arrowActive, setArrowActive] = useState('first');
    const [filesState, setFilesState] = useState<any[]>([]);
    const [geoObjectFilesState, setGeoObjectFilesState] = useState<any[]>([]);
    const [filesForDelete, setFilesForDelete] = useState<number[]>([]);
    const [updateMap, setUpdateMap] = useState(true);
    const containerRef = useRef(null);
    const [width, setWidth] = useState(0);
    const fileInputRef = useRef(null);

    let position: any = [state.latitude, state.longitude], icon;
    // let array: NewGeoObject[] | undefined = [];
    let array: any = [];

    const result = useMemo(() => {
        if (!geoObjectsGeom || !geoObjectsGeom.features) {
            return null;
        }

        return geoObjectsGeom.features.find((g: any) => {
            // console.log(g.properties.id);
            return String(g?.properties?.id) === String(geoObjId);
        });

    }, [geoObjectsGeom, geoObjId]);

    useEffect(() => {
        if (containerRef.current) {
            //@ts-ignore
            setWidth(containerRef.current.offsetWidth);
        }
    }, []);

    useEffect(() => {
        fetchGeoObjectsGeom()
        fetchRegions();
        fetchDistricts();
        fetchGeoIcons(makeQuery.stringify({page_size: 10000}));

        if (isId) {
            setMapActive('none');
            fetchGeoObject(geoObjId).then((res: any) => {
                try {
                    array = res?.data?.geo_object_field.map((g: any) => {
                        return {
                            geo_type_field: g.geo_type_field,
                            geo_type_field_name: g.geo_type_field_name,
                            id: g.id,
                            type: g.type,
                            value: g.value || ''
                        }
                    });
                    // if (res?.data?.geo_json !== 'object') {
                    if (result && result.geometry.type === "Polygon") {
                        setState({
                            name: res?.data?.name,
                            description: res?.data?.description,
                            geo_type: res?.data?.geo_type,
                            region: res?.data?.region,
                            district: res?.data?.district,
                            latitude: Number(res?.data?.latitude),
                            longitude: Number(res?.data?.longitude),
                            geo_json: '',
                            images: res?.data?.images,
                            geometry: {
                                type: "Polygon",
                                coordinates: result?.geometry?.coordinates,
                            },
                        });
                        setTypeLeaflet({
                            type: 'Polygon',
                        });
                    } else if (result && result.geometry.type === "Point") {
                        setState({
                            name: res?.data?.name,
                            description: res?.data?.description,
                            geo_type: res?.data?.geo_type,
                            region: res?.data?.region,
                            district: res?.data?.district,
                            latitude: Number(res?.data?.latitude),
                            longitude: Number(res?.data?.longitude),
                            geo_json: '',
                            images: res?.data?.images,
                            location: {
                                type: "Point",
                                coordinates: position,
                            }
                        });
                        setTypeLeaflet({
                            type: 'Point',
                        });
                    } else {
                        return null
                    }

                    if(!array?.length && !res?.data?.geo_object_field.length) {
                        const geoType = geoTypes?.results?.find(type => type.id === res?.data?.geo_type);
                        array = geoType?.geo_type_field.map((g: any) => {
                            return {
                                geo_type_field: g.id,
                                value: g.value || ''
                            }
                        });
                        setFields(array);
                    } else {
                        setFields(array);
                    }
                    // }

                    // if (res?.data?.geo_json) {
                    //     let json;
                    //     if (res?.data?.geo_json !== null) {
                    //         json = JSON.parse(res?.data?.geo_json);
                    //     }
                    //
                    //     if (typeof json === "string" && res?.data?.geo_json !== null) {
                    //         json = Function(`return ${json}`)();
                    //     }
                    //
                    //     setState({
                    //         name: res?.data?.name,
                    //         description: res?.data?.description,
                    //         geo_type: res?.data?.geo_type,
                    //         region: res?.data?.region,
                    //         district: res?.data?.district,
                    //         latitude: Number(res?.data?.latitude),
                    //         longitude: Number(res?.data?.longitude),
                    //         geo_json: json,
                    //     })
                    //     setFields(array)
                    // }

                } catch (e: any) {
                    console.log(e);
                    setError(e);
                }
                setSelectedId(geoObjId);

                let images: any[] = [];
                if (res?.data?.images?.length) {
                    images = res?.data?.images?.map((el: any) => {
                        return {
                            id: el.id,
                            geo_object: el.geo_object,
                            image: el.image
                        }
                    })
                    setGeoObjectFilesState(images);
                }
            });
        } else {
            setState({
                name: '',
                description: '',
                geo_type: '',
                region: '',
                district: '',
                latitude: 41.1262532,
                longitude: 73.79516602,
                geo_json: '',
            });
            setGeoObjectFilesState([]);
        }
    }, [result, isId, geoTypes]);

    useEffect(() => {
        if (isId && geoJsonState.geometry) {
            if (geoJsonState.geometry.type === "Polygon") {
                delete state["location"];
                setState((prev: any) => ({
                    ...prev,
                    // geo_json: {
                    //     type: "FeatureCollection",
                    //     features: [geoJsonState],
                    // },
                    geometry: {
                        type: "Polygon",
                        coordinates: geoJsonState?.geometry?.coordinates,
                    }
                }));
            } else {
                delete state["geometry"];
                setState((prev: any) => ({
                    ...prev,
                    // geo_json: '',
                    location: {
                        type: "Point",
                        coordinates: position,
                    }
                }));
            }
        } else if (isId && typeLeaflet.type === "Point") {
            delete state["geometry"];
            setState((prev: any) => ({
                ...prev,
                location: {
                    type: "Point",
                    coordinates: position,
                }
            }));
        } else if (!isId && typeLeaflet.type === "Point") {
            delete state["geometry"];
            setState((prev: any) => ({
                ...prev,
                location: {
                    type: "Point",
                    coordinates: position,
                }
            }));
        } else if (!isId && typeLeaflet.type === "Polygon") {
            delete state["location"];
            // !isId && !geoJsonState.geometry && typeLeaflet.type === "Polygon"
            if (geoJsonState?.geometry?.type === "Polygon") {
                setState((prev: any) => ({
                    ...prev,
                    geometry: {
                        type: "Polygon",
                        coordinates: geoJsonState?.geometry?.coordinates,
                    }
                }));
            }
        }

    }, [geoJsonState, typeLeaflet, state.latitude, state.longitude]);
    // useEffect(() => {
    //     if (state.geo_json.features && state.geo_json.features.length && state.geo_type) {
    //         setState((prev: any) => ({
    //             ...prev,
    //             geo_json: {
    //                 type: "FeatureCollection",
    //                 features: [...prev.geo_json.features, geoJsonState],
    //             },
    //             geometry: geoJsonState.geometry
    //         }));
    //     }
    // }, [geoJsonState, state.geo_type]);

    useEffect(() => {
        if(state.geo_type === 'null') {
            setState((prev: any) => ({
                ...prev,
                geo_type: ''
            }))
        }
        if(state.region === 'null') {
            setState((prev: any) => ({
                ...prev,
                region: ''
            }))
        }
        if(state.district === 'null') {
            setState((prev: any) => ({
                ...prev,
                district: ''
            }))
        }
    }, [state.geo_type, state.region, state.district]);

    const handleChange = (event: any) => {
        const value = event.target.value;

        if (event.target.name === 'latitude' || event.target.name === 'longitude') {
            const regex = /^$|^[0-9]+(\.[0-9]*)?$/;

            // Если значение не соответствует регулярному выражению, просто возвращаемся, не обновляя state
            if (!regex.test(value)) {
                return;
            }
        }

        if (event.target.name === 'typeLeaflet') {
            if (event.target.value === 'Point') {
                setTypeLeaflet({
                    type: 'Point',
                });
                setMapActive('none');
            } else if (event.target.value === 'Polygon') {
                setTypeLeaflet({
                    type: 'Polygon',
                });
                setMapActive('none');
            } else {
                setTypeLeaflet({
                    type: '',
                });
                setMapActive('block');
            }
        }

        if (event.target.name === 'geo_type') {
            geoTypes?.results?.map((g: any) => {
                if (String(event.target.value) === String(g.id)) {
                    array = g.geo_type_field.map((field: any) => {
                        return {
                            geo_type_field: field.id,
                            value: '',
                            geo_type_field_name: field.field_name,
                        }
                    });
                    setFields(array);
                }
            });
        }

        if (event.target.name !== "typeLeaflet") {
            setState((prevState: any) => {
                return {...prevState, [event.target.name]: event.target.value}
            });
        }
    }

    const handleChangeValue = ((e: any, id: any) => {
        const updatedFields = [...fields];
        let index = updatedFields.findIndex((elem: any) => elem?.geo_type_field === id);
        if (index >= 0) {
            const copyField = {...updatedFields[index]};
            copyField.value = e.target.value;
            updatedFields[index] = copyField;
            setFields(updatedFields);
        }
    });

    const handleCreate = () => {
        // data: any, preData,  dataArray: any[] = [], linksArray: any[] = [], linksObj: any = {}, deletedFiles: any = {},
        let geo_object_field: any[] = [], dataForSend: any, filesData: any = {}, filesArray: any[] = [], formData: any;

        const removeFields = fields.filter((el: any) => {
            const geoTypeFieldIds = geoTypes?.results?.find((el: any) => el.id === state.geo_type)?.geo_type_field.map((el: any) => el.id);
            return !geoTypeFieldIds?.includes(el.geo_type_field);
        }).map((el: any) => el.id);

        geo_object_field = fields.map((el: any) => {
            if (el.id) {
                return {
                    geo_field_id: Number(el.id),
                    geo_type_field: Number(el.geo_type_field),
                    value: el.value || '',
                }
            } else {
                return {
                    geo_type_field: Number(el.geo_type_field),
                    value: el.value || '',
                }
            }
        });

        dataForSend = {...state};
        if (removeFields.length) {
            // dataForSend.removeFields = removeFields;
        }
        const new_geo_object_field: any[] = [];

        geo_object_field.map((g: any) => {
            if(g.value && g.value !== '') {
                return new_geo_object_field.push(g);
            }
        })

        if(new_geo_object_field.length) dataForSend.geo_object_field = new_geo_object_field

        if (state.geo_json) {
            dataForSend.geo_json = JSON.stringify(state.geo_json);
        }

        dataForSend.geo_type = Number(dataForSend.geo_type);
        dataForSend.district = Number(dataForSend.district);
        dataForSend.region = Number(dataForSend.region);

        filesData = filesArray.reduce((a, b, idx) => ({...a, ['file_' + (idx + 1) + "_" + b.id]: b.file}), {});

        createGeoObject(dataForSend).then((response: any) => {
                const promises = [];

                if (filesArray.length) {
                    if (response.data.id) {
                        filesData.geo_object_id = Number(response.data.id);
                        formData = getFormData(filesData);
                    }
                    promises.push(uploadGeoObjectImage(formData))
                }
                if (response?.data) {
                    filesState.forEach(el => {
                        const formDataImg = new FormData();
                        formDataImg.append('geo_object', response.data.id);
                        formDataImg.append('image', el);
                        promises.push(uploadGeoObjectImage(formDataImg))
                    })
                }
                return Promise.all(promises)
            }
        ).then(() => {
            navigate('/gis-geo-objects/list');
            // TODO: Сделать процесс загрузки файлов
        }).catch((e: any) => {
            console.log("Something went wrong...");
            console.log(e);
            setError(e);
        })
    }

    const handleEdit = () => {
        let data: any, preData, formData: any, dataArray: any[] = [], filesArray: any[] = [], linksArray: any[] = [],
            geo_object_field: any[] = [], dataForSend: any, filesData: any = {}, deletedFiles: any = {},
            linksObj: any = {};

        const removeFields = fields.filter((el: any) => {
            const geoTypeFieldIds = geoTypes?.results?.find((el: any) => el.id === state.geo_type)?.geo_type_field.map((el: any) => el.id);
            return !geoTypeFieldIds?.includes(el.geo_type_field);
        }).map((el: any) => el.id);

        geo_object_field = fields.map((el: any) => {
            if (el.id) {
                return {
                    geo_field_id: Number(el.id),
                    geo_type_field: Number(el.geo_type_field),
                    value: el.value || '',
                }
            } else {
                return {
                    geo_type_field: Number(el.geo_type_field),
                    value: el.value || '',
                }
            }
        });

        dataForSend = {...state};
        if (removeFields.length) {
            // dataForSend.removeFields = removeFields;
        }
        const new_geo_object_field: any[] = [];

        geo_object_field.map((g: any) => {
            if(g.value && g.value !== '') {
                return new_geo_object_field.push(g);
            }
        })

        if(new_geo_object_field.length) dataForSend.geo_object_field = new_geo_object_field

        if (state.geo_json) {
            dataForSend.geo_json = JSON.stringify(state.geo_json);
        }

        dataForSend.geo_type = Number(dataForSend.geo_type);
        dataForSend.district = Number(dataForSend.district);
        dataForSend.region = Number(dataForSend.region);
        dataForSend.latitude = Number(dataForSend.latitude);
        dataForSend.longitude = Number(dataForSend.longitude);
        filesData = filesArray.reduce((a, b, idx) => ({...a, ['file_' + (idx + 1) + "_" + b.id]: b.file}), {});

        if (selectedId) {
            deletedImages.forEach((el: any) => {
                if (deletedFiles.hasOwnProperty(el.fieldId)) {
                    deletedFiles[el.fieldId].push(el.images)
                } else {
                    deletedFiles[el.fieldId] = [el.images]
                }
            })

            let deletedData = {
                geo_object_id: selectedId,
                deleting_files: deletedFiles
            }

            updateGeoObject({id: selectedId, body: dataForSend}).then((res: any) => {
                const promises: any = [];

                if (res?.data) {
                    filesState.forEach(el => {
                        const formDataImg = new FormData();
                        // @ts-ignore
                        formDataImg.append('geo_object', res.data.id);
                        formDataImg.append('image', el);
                        promises.push(uploadGeoObjectImage(formDataImg))
                    })
                }

                if (filesForDelete.length) {
                    filesForDelete.forEach(el => {
                        promises.push(deleteGeoObjectImage(el))
                    })
                }
                return Promise.all(promises)
            }).then(() => {
                navigate('/gis-geo-objects/list');
            }).catch((e: any) => {
                console.log("Something went wrong...");
                console.log(e);
                setError(e);
            });
        } else {
            createGeoObject(dataForSend).then(
                (response: any) => {
                    if (filesArray.length) {
                        if (response.data.id) {
                            filesData.geo_object_id = Number(response.data.id);
                            formData = getFormData(filesData);
                        }
                        // uploadGeoObjectFiles(formData).then()
                    }
                }
            ).then(() => {
                navigate('/gis-geo-objects/list');
            }).catch((e: any) => {
                console.log("Something went wrong...");
                console.log(e);
                setError(e);
            })
        }
    }

    let geoTypesOptions, regionsOptions, districtsOptions, geoObjectFields, geo, typeLeafletOptions;

    const arrayTypesLeaflet = [
        {
            name: t('gis.geoObjects.Point'),
            value: "Point"
        },
        {
            name: t('gis.geoObjects.Polygon'),
            value: "Polygon"
        }
    ]

    if (geoTypes) {
        geoTypesOptions = geoTypes?.results?.map((el: any) => {
            return {label: el.type_name, value: el.id}
        })
        geoTypesOptions?.unshift({label: t('gis.geoObjects.selectType'), value: 'null'});

        typeLeafletOptions = arrayTypesLeaflet?.map((el: any) => {
            return {label: el.name, value: el.value}
        })
        typeLeafletOptions.unshift({label: t('gis.geoObjects.selectType'), value: 'null'});
    }

    if (regions) {
        regionsOptions = regions.map((el: any) => {
            return {label: el.name, value: el.id}
        })
        regionsOptions.unshift({label: t('gis.geoObjects.selectArea'), value: 'null'});
    }

    districtsOptions = useMemo(() => {
        const res = [{label: t('gis.geoObjects.selectDistrict'), value: ''}];

        districts && districts.forEach(el => {
            if (state.region && Number(state.region) === el.region) {
                res.push({label: el.name, value: String(el.id)})
            }
        });

        if (state.region) {
            setState((prev: any) => ({
                ...prev,
                district: geoObject?.district,
            }))
        } else {
            setState((prev: any) => ({
                ...prev,
                district: '',
            }))

        }
        return res;
    }, [state.region]);

    const handleGetPosition = (e: any) => {
        setState((prevState: any) => {
            return {...prevState, latitude: e.lat, longitude: e.lng}
        });
        //TODO MyComponent туда засунуть onclick который будет всегда в true ставить для PopUp
    }

    const _onDrawStart = (event: any) => {
        // console.log(event);
    };

    const _onEdited = (e: any) => {
        const layers = e.layers;
        layers.eachLayer((layer: any) => {
            const geoJson = layer.toGeoJSON();
            setGeoJsonState(geoJson);
        });
    };

    const _onDeleted = (e: any) => {
        setGeoJsonState({});
        setTypeLeaflet({
            type: '',
        });
        setMapActive('block');
        // setArrowActive('first');
    };

    const _onCreated = (event: any) => {
        const {layerType, layer} = event;
        const geoJson = layer.toGeoJSON();
        setGeoJsonState(geoJson);

        if (layerType === 'circle') {
            const center = layer.getLatLng();
            const radius = layer.getRadius();
            const numSegments = 360;
            const coordinates = [];

            for (let i = 0; i <= numSegments; i++) {
                const angle = (i / numSegments) * Math.PI * 2;
                const lat = center.lat + radius * Math.sin(angle) / 110540;
                const lng = center.lng + radius * Math.cos(angle) / (111320 * Math.cos(center.lat * Math.PI / 180));
                coordinates.push([lat, lng]);
            }
            coordinates.push(coordinates[0]);

            const circle = L.polygon(coordinates);
            const circleLatLngs = circle.getLatLngs()[0];
            //@ts-ignore
            const coordinatesArray = circleLatLngs.map((point: any) => [
                Number(point.lng.toFixed(6)),
                Number(point.lat.toFixed(6))
            ]);

            const circleGeoJson = {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [coordinatesArray]
                },
                properties: {}
            };
            setGeoJsonState(circleGeoJson);
        }
    };

    const clearJson = () => {
        delete state["geometry"];
        delete state["location"];
        // setArrowActive('second');

        if (state.geo_json && state.geometry) {
            setState((prev: any) => ({
                ...prev,
                geo_json: '',
                geometry: ''
            }));
        }
        // setArrowActive('second');
        setTimeout(() => {
            setUpdateMap(prevState => !prevState);
        }, 0)
        setTimeout(() => {
            setUpdateMap(prevState => !prevState);
        }, 1000)
    }

    const handleChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {files} = e.target;
        let tempFiles = [...filesState]

        if (e.target.type === 'file') {
            const chosenFiles = Array.prototype.slice.call(files);
            chosenFiles.forEach(el => {
                if (!tempFiles.find(item => item.name === el.name)) {
                    tempFiles.push(el)
                }
            })

            setFilesState(tempFiles);
            updateFileInput(tempFiles);
        }
    }

    const updateFileInput = (files: any) => {
        const dataTransfer = new DataTransfer();
        files.forEach((file: any) => dataTransfer.items.add(file));
        // @ts-ignore
        fileInputRef.current.files = dataTransfer.files;
    };

    const handleDeleteFile = (name: string) => {
        let arr = [...filesState];
        let index = arr.findIndex(el => el.name === name);
        arr.splice(index, 1);
        setFilesState(arr);

        updateFileInput(arr);
    }

    const handleDeleteEmergencyFile = (id: number) => {
        let arr = [...geoObjectFilesState];
        let idsArr = [...filesForDelete];
        let idx = arr.findIndex(el => el.id === id);
        arr.splice(idx, 1);

        if (!idsArr.includes(id)) {
            idsArr.push(id);
        }

        setGeoObjectFilesState(arr);
        setFilesForDelete(idsArr);
    }

    const handlePopUp = (e: any) => {
        const event = e.target
        if (typeLeaflet.type === "Point" && event.tagName === "SPAN") setPopUp(false);
        if (typeLeaflet.type === "Point" && event.tagName === "IMG") setPopUp(true);
    }

    const handleVisible = (e: any, name: string) => {
        e.preventDefault();
        if (name === 'tree') {
            setVisible(!visible);
        } else if (name === 'opacity') {
            setOpacityVisible(!opacityVisible);
        } else if (name === 'coordinates') {
            setCoordinatesVisible(!coordinatesVisible);
        } else {
            return;
        }
    };

    const handleOpacityChange = (e: any) => {
        const value = e.target.value / 100;
        setOpacity(value);
        const mapContainer: any = document.querySelector('#MapContainer');

        const images = mapContainer.querySelector('div');
        images.style.opacity = opacity;
    };

    let iconUrl = '';

    if (state.geo_type && geoTypes) {
        geoTypes?.results?.forEach((g: any) => {
            if (String(g.id) === String(state.geo_type)) {
                const foundIcon = geoIcons?.results?.find((el: any) => el.id === g.icon);
                if (foundIcon) {
                    iconUrl = foundIcon.icon;
                }
            }
        });

        if (iconUrl) {
            icon = new Leaflet.Icon({
                iconUrl: iconUrl,
                iconSize: [32, 37],
                iconAnchor: [16, 32],
                popupAnchor: [0, -32],
            })
        }
    }

    const renderOpacity = (() => {
        return (
            <div className="map-opacity-block">
                <p className="map-opacity-title">{t('gis.geoObjects.titleOpacity')}</p>
                <input
                    className="map-opacity-input"
                    type="range"
                    min="0"
                    max="100"
                    value={opacity * 100}
                    onChange={handleOpacityChange}
                />
            </div>
        )
    });

    const handleInputChange = (event: any) => {
        const {name, value} = event.target;
        setCoordinates((prevCoordinates: any) => ({
            ...prevCoordinates,
            [name]: parseFloat(value) || ''
        }));
    };

    const searchCoordinates = (e: any) => {
        e.preventDefault();
        setMapKey(prevKey => prevKey + 1);
        setCenter(true);
    };

    const renderCoordinate = (
        <div className="map-opacity-block">
            <p className="map-opacity-title">{t('gis.geoObjects.coordinates')}:</p>
            <div style={{marginBottom: '10px'}}>
                <label>
                    {t("gis.geoObjects.latitude")}
                    <input
                        type="number"
                        name="lat"
                        value={coordinates.lat}
                        onChange={handleInputChange}
                    />
                </label>
                <label>
                    {t("gis.geoObjects.longitude")}
                    <input
                        type="number"
                        name="lng"
                        value={coordinates.lng}
                        onChange={handleInputChange}
                    />
                </label>
            </div>
            <CButton
                type="button"
                size="sm"
                className="bg-info"
                color="black"
                onClick={e => searchCoordinates(e)}
                disabled={!(coordinates.lat && coordinates.lng)}
            >
                {t("pagination.search")}
            </CButton>
        </div>
    );

    if (fields?.length) {
        geo = geoTypes?.results?.find((type: any) => String(type.id) === String(state.geo_type))?.geo_type_field.map((elem: any) => {
            let value: any = fields?.find((el: any) => el.geo_type_field === elem.id)?.value || '';
            // console.log(value);
            switch (elem.field_type) {
                case 'boolean':
                    return (
                        <FormGroup key={elem.id + elem.field_name} htmlFor={elem.field_name + elem.id}
                                   label={elem.field_name}
                                   className="mb-1">
                            <CFormCheck id="flexCheckDefault"
                                        label=""
                                        name={elem.field_name}
                                        checked={!!value}
                                        onChange={e => handleChangeValue(e, elem.id)}
                            />
                        </FormGroup>
                    )
                case 'number':
                    return (
                        <FormGroup key={elem.id + elem.field_name} htmlFor={elem.field_name + elem.id}
                                   label={elem.field_name}
                                   className="mb-1">
                            <CFormInput id={elem.type + elem.id}
                                        type="number"
                                        value={value}
                                        name={elem.field_name}
                                        onChange={e => handleChangeValue(e, elem.id)}
                                        size="sm"
                            />
                        </FormGroup>
                    )
                case 'string':
                    return (
                        <FormGroup key={elem.id + elem.field_name} htmlFor={elem.field_name + elem.id}
                                   label={elem.field_name}
                                   className="mb-1">
                            <CFormInput id={elem.type + elem.id}
                                        type="text"
                                        value={value}
                                        name={elem.field_name}
                                        onChange={e => handleChangeValue(e, elem.id)}
                                        size="sm"
                            />
                        </FormGroup>
                    )
                case 'date':
                    return (
                        <FormGroup key={elem.id + elem.field_name} htmlFor={elem.field_name + elem.id}
                                   label={elem.field_name}
                                   className="mb-1">
                            <input type='date'
                                   value={value}
                                   name={elem.field_name}
                                   onChange={e => handleChangeValue(e, elem.id)}
                                   className="date-input"
                            />
                        </FormGroup>
                    )
                case 'time':
                    return (
                        <FormGroup key={elem.id + elem.field_name} htmlFor={elem.field_name + elem.id}
                                   label={elem.field_name}
                                   className="mb-1">
                            <input onChange={e => handleChangeValue(e, elem.id)}
                                   type='time'
                                   value={value}
                                   name={elem.field_name}
                                   className="date-input"
                            />
                        </FormGroup>
                    )
                case 'datetime':
                    return (
                        <FormGroup key={elem.id + elem.field_name} htmlFor={elem.field_name + elem.id}
                                   label={elem.field_name}
                                   className="mb-1">
                            <input onChange={e => handleChangeValue(e, elem.id)}
                                   type='datetime-local'
                                   value={value}
                                   name={elem.field_name}
                                   className="date-input"
                            />
                        </FormGroup>
                    )
                case 'textarea':
                    return (
                        <FormGroup key={elem.id + elem.field_name} htmlFor={elem.field_name + elem.id}
                                   label={elem.field_name}
                                   className="mb-1">
                            <CFormTextarea id={elem.field_name + elem.id}
                                           value={value}
                                           name={elem.field_name}
                                           onChange={e => handleChangeValue(e, elem.id)}
                                //@ts-ignore
                                           size="sm"
                            />
                        </FormGroup>
                    )
                default:
                    return null
            }
        });
    }

    return (
        <>
            <div className="pb-3 mb-4 title-divider">
                {
                    isId ? <h1>{t('gis.geoObjects.geoObjectUpdate')}</h1>
                        : <h1>{t('gis.geoObjects.geoObjectCreate')}</h1>
                }
            </div>

            <FormGroup className="main-label" htmlFor="name">
                <CFormInput id="name"
                            type="text"
                            label={<>{t("gis.geoObjects.naming")}<span style={{color: 'red'}}>&nbsp;*</span></>}
                            value={state.name}
                            name="name"
                            onChange={handleChange}
                            size="sm"
                            ref={containerRef}
                />
            </FormGroup>

            <FormGroup className="main-label" htmlFor="description">
                <CFormTextarea id="description"
                               label={<>{t("gis.geoObjects.description")}<span style={{color: 'red'}}>&nbsp;*</span></>}
                               value={state.description}
                               name="description"
                               onChange={handleChange}
                    //@ts-ignore
                               size="sm"
                />
            </FormGroup>

            <FormGroup className="main-label" htmlFor="geo_type">
                <CFormSelect size="sm"
                             id="geo_type"
                             label={<>{t("gis.geoObjects.type")}<span style={{color: 'red'}}>&nbsp;*</span></>}
                             value={state.geo_type}
                             name="geo_type"
                             onChange={handleChange}
                             options={geoTypesOptions}
                />
            </FormGroup>

            <FormGroup className="main-label" htmlFor="region">
                <CFormSelect size="sm"
                             id="region"
                             label={<>{t("gis.geoObjects.area")}
                                 <span style={{color: 'red'}}>&nbsp;*</span>
                             </>}
                             value={state.region}
                             name="region"
                             onChange={handleChange}
                             options={regionsOptions}
                />
            </FormGroup>
            {state.region === 'null' || state.region === '' ?
                <FormGroup className="main-label" htmlFor="district">
                    <CFormInput id="selectRegion"
                                type="text"
                                label={<>{t("gis.geoObjects.district")}
                                    <span style={{color: 'red'}}>&nbsp;*</span>
                                </>}
                                value={t('settings.regions.selectRegion')}
                                name="selectRegion"
                                size="sm"
                                disabled={true}
                    />
                </FormGroup>
                :
                <FormGroup className="main-label" htmlFor="district">
                    <CFormSelect size="sm"
                                 id="district"
                                 label={<>{t("gis.geoObjects.district")}
                                     <span style={{color: 'red'}}>&nbsp;*</span>
                                 </>}
                                 value={state.district}
                                 name="district"
                                 onChange={handleChange}
                                 options={districtsOptions}
                    />
                </FormGroup>
            }

            {/*<FormGroup className="main-label" htmlFor="region" label={t('gis.geoObjects.area')}>*/}
            <FormGroup className="main-label" htmlFor="region">
                <CFormSelect size="sm"
                             id="typeLeaflet"
                             label={<>{t("gis.geoObjects.infoType")}<span style={{color: 'red'}}>&nbsp;*</span></>}
                             value={typeLeaflet.type}
                             name="typeLeaflet"
                             onChange={handleChange}
                             options={typeLeafletOptions}
                             disabled={state.geo_type === 'null' || state.geo_type === ''}
                />
            </FormGroup>

            {typeLeaflet.type === 'Point' ?
                <FormGroup className="main-label" htmlFor="latitude" label={t('gis.geoObjects.latitude')}>
                    <CFormInput id="latitude"
                                type="text"
                                value={state.latitude}
                                name="latitude"
                                onChange={handleChange}
                                size="sm"
                    />
                </FormGroup> : null
            }

            {typeLeaflet.type === 'Point' ?

                <FormGroup className="main-label" htmlFor="longitude" label={t('gis.geoObjects.longitude')}>
                    <CFormInput id="longitude"
                                type="text"
                                value={state.longitude}
                                name="longitude"
                                onChange={handleChange}
                                size="sm"
                    />
                </FormGroup> : null
            }

            <h5 className="title-of-map">{t('gis.geoObjects.MapTitle')}<span style={{color: 'red'}}>&nbsp;*</span></h5>

            <div style={{position: 'relative'}}>
                <div className="diActive-map" style={{width: width, display: mapActive}}>
                </div>
                {/*{*/}
                {/*    arrowActive === 'second' ?*/}
                {/*        <div className="arrow-container-second-delete">*/}
                {/*            <div className="arrow-second-delete"></div>*/}
                {/*            <div className="arrow-text">*/}
                {/*                Press "Clear All" for delete*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        : null*/}
                {/*}*/}
            </div>


            <CCard className="mb-3 emergency-map-wrapper">
                <div className="whole-place-wrapper" onClick={e => handlePopUp(e)}>
                    {coordinatesVisible ?
                        <div className="map-coordinate">
                            {renderCoordinate}
                        </div>
                        : null
                    }

                    {updateMap ? <MapContainer
                            key={mapKey}
                            center={center ? [coordinates.lat, coordinates.lng] : [41.1262532, 73.79516602]}
                            zoom={7}
                            zoomControl={false}
                            attributionControl={false}
                            style={{height: '100%', position: 'relative'}}
                            id="MapContainer"
                        >
                            {
                                coordinates.lat && coordinates.lat ?
                                    <Marker position={[coordinates.lat, coordinates.lng]}
                                            icon={
                                                L.divIcon({
                                                    className: 'coordinates-icon',
                                                    html: '<i class="fa fa-dot-circle-o" aria-hidden="true"></i>',
                                                    iconSize: [32, 32]
                                                })
                                            }>
                                        <Popup>
                                            <div>
                                                <p>Широта:{coordinates.lat}</p>
                                                <p>Долгота: {coordinates.lng}</p>
                                            </div>
                                        </Popup>
                                    </Marker>
                                    : null
                            }

                            {state.geometry && icon ? <GeoJSON
                                //@ts-ignore
                                data={state.geometry}
                            /> : null}
                            {/*@ts-ignore*/}
                            {state.geo_type && icon && typeLeaflet.type === "Point" ?
                                <Marker position={position} icon={icon}/> : null}

                            <FeatureGroup>
                                {/*{arrowActive === 'second' ?*/}
                                {/*    <EditControl position="topleft"*/}
                                {/*                 onDrawStart={_onDrawStart}*/}
                                {/*                 onEdited={_onEdited}*/}
                                {/*                 onCreated={_onCreated}*/}
                                {/*                 onDeleted={_onDeleted}*/}
                                {/*                 draw={*/}
                                {/*                     {*/}
                                {/*                         polyline: true,*/}
                                {/*                         circlemarker: false,*/}
                                {/*                         marker: false,*/}
                                {/*                         polygon: false,*/}
                                {/*                         rectangle: false,*/}
                                {/*                         circle: false,*/}
                                {/*                     }*/}
                                {/*                 }*/}
                                {/*    /> : null}*/}
                                {/*TODO нужно поправить баг - когда нарисовал геометрию к примеру квадрат и потом удалил его и после хочешь нарисовать многогранник то многограник уже нельзя нарисовать*/}
                                {typeLeaflet.type === 'Point' ?
                                    <EditControl position="topleft"
                                                 onDrawStart={_onDrawStart}
                                                 onEdited={_onEdited}
                                                 onCreated={_onCreated}
                                                 onDeleted={_onDeleted}
                                                 draw={
                                                     {
                                                         polyline: true,
                                                         circlemarker: false,
                                                         marker: false,
                                                         polygon: false,
                                                         rectangle: false,
                                                         circle: false,
                                                     }
                                                 }
                                    /> : null}
                                {
                                    state.geometry ?
                                        <EditControl position="topleft"
                                                     onDrawStart={_onDrawStart}
                                                     onEdited={_onEdited}
                                                     onCreated={_onCreated}
                                                     onDeleted={_onDeleted}
                                                     draw={{
                                                         polyline: true,
                                                         circlemarker: false,
                                                         marker: false,
                                                         polygon: false,
                                                         rectangle: false,
                                                         circle: false,
                                                     }}
                                        />
                                        :
                                        typeLeaflet.type === 'Polygon' ?
                                            <EditControl position="topleft"
                                                         onDrawStart={_onDrawStart}
                                                         onEdited={_onEdited}
                                                         onCreated={_onCreated}
                                                         onDeleted={_onDeleted}
                                                         draw={{
                                                             polyline: true,
                                                             circlemarker: false,
                                                             marker: false,
                                                             polygon: true,
                                                             rectangle: true,
                                                             circle: true,
                                                         }}
                                            />
                                            : null
                                }
                            </FeatureGroup>

                            {layersControl ?
                                // @ts-ignore
                                <LayersControl position="topright">
                                    <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                                        <TileLayer url={getTiles('OpenStreetMap.Mapnik')}/>
                                    </LayersControl.BaseLayer>
                                    <LayersControl.BaseLayer name="OpenStreetMap">
                                        <TileLayer url={getTiles('OpenStreetMap')}/>
                                    </LayersControl.BaseLayer>
                                    <LayersControl.BaseLayer name="Google.Hybrid">
                                        <TileLayer url={getTiles('Google.Hybrid')}/>
                                    </LayersControl.BaseLayer>
                                    <LayersControl.BaseLayer name="Esri.WorldStreetMap">
                                        <TileLayer url={getTiles('Esri.WorldStreetMap')}/>
                                    </LayersControl.BaseLayer>
                                    <LayersControl.BaseLayer name="Esri.WorldGrayCanvas">
                                        <TileLayer url={getTiles('Esri.WorldGrayCanvas')}/>
                                    </LayersControl.BaseLayer>
                                    <LayersControl.BaseLayer name="Esri.WorldImagery">
                                        <TileLayer url={getTiles('Esri.WorldImagery')}/>
                                    </LayersControl.BaseLayer>
                                    <LayersControl.BaseLayer name="Esri.WorldShadedRelief">
                                        <TileLayer url={getTiles('Esri.WorldShadedRelief')}/>
                                    </LayersControl.BaseLayer>
                                    <LayersControl.BaseLayer name="Stamen.Terrain">
                                        <TileLayer url={getTiles('Stamen.Terrain')}/>
                                    </LayersControl.BaseLayer>
                                </LayersControl>
                                :
                                <TileLayer url={getTiles('OpenStreetMap.Mapnik')}/>
                            }

                            {zoomControl ? <ZoomControl position="topright"/> : null}

                            <Control position="topright">
                                <a className="leaflet-control-crosshairs leaflet-control-button"
                                   href="/"
                                   title="Crosshairs"
                                   role="button"
                                   aria-label="Crosshairs"
                                   aria-disabled="false"
                                   onClick={e => handleVisible(e, 'coordinates')}
                                >
                                    <i className="fa fa-crosshairs" aria-hidden="true"/>
                                </a>
                            </Control>

                            <Control position="topright">
                                {opacityVisible ? renderOpacity() : null}
                                <a className="leaflet-control-layers-satellite leaflet-control-button"
                                   href="/"
                                   title="Layers satellite"
                                   role="button"
                                   aria-label="Layers satellite"
                                   aria-disabled="false"
                                   onClick={e => handleVisible(e, 'opacity')}
                                >
                                    <i className="fa fa-adjust" aria-hidden="true"/>
                                </a>
                            </Control>

                            {scaleControl ? <ScaleControl position="bottomleft"/> : null}

                            {loading ? <MapLoader/> : null}

                            {position && typeLeaflet.type === 'Point' && popUp && (
                                // @ts-ignore
                                <Popup position={position}>
                                    lat: {Math.round(position[0] * 1000000) / 1000000}<br/>
                                    lng: {Math.round(position[1] * 1000000) / 1000000}
                                </Popup>
                            )}

                            <MyComponent handlerZoom={() => {
                            }} getPosition={handleGetPosition}/>

                        </MapContainer>
                        : <div className="preLoader" style={{
                            position: "absolute",
                            top: "50%",
                            right: "50%",
                        }}>
                            <CSpinner color="primary"/>
                        </div>
                    }

                    {state.geometry ?
                        <>
                            <CButton size="sm" color='info' style={{
                                position: 'absolute',
                                bottom: "10px",
                                right: "10px",
                                zIndex: 99999
                            }}
                                     onClick={() => clearJson()}
                            >
                                Очистить ГеоОбъект
                            </CButton>
                            <div className="arrow-container">
                                <div className="arrow-text">
                                    Нажмите чтобы удалить ГеоОбъект
                                </div>
                                <div className="arrow"></div>
                            </div>
                        </>
                        : null}

                </div>

            </CCard>

            <CCard>
                <CCardHeader className="p-0">
                <div className="p-2 mb-0 card-header-title">
                        {t('gis.geoObjects.additionalInformation')}
                    </div>
                </CCardHeader>
                <CCardBody>
                    {geo}
                </CCardBody>
            </CCard>

            <FormGroup className="main-label" htmlFor="description" label={t('gis.geoObjects.image')}> <CFormInput
                type="file"
                // label={t("gis.geoObjects.image")}
                name={'material_damage'}
                onChange={handleChangeFiles}
                className='emergency-custom-input mb-3'
                multiple
                ref={fileInputRef}
            />
                <div style={{display: "flex", justifyContent: 'flex-start', flexWrap: 'wrap', gap: '20px'}}>
                    {filesState.map((item: any, i: number) => {
                        return (
                            <div key={i + '_pic'} className="image-wrapper">
                                <div className="image-delete-icon-wrapper"
                                     onClick={() => handleDeleteFile(item.name)}
                                >
                                    <CIcon icon={cilX} className="image-delete-icon"/>
                                </div>
                                {!item.type.includes("image") ?
                                    <div className="file-item">
                                        {item.name}
                                    </div> :
                                    <img alt={""}
                                         src={URL.createObjectURL(item)}
                                         style={{height: '110px', width: 'auto'}}
                                    />}
                            </div>
                        )
                    })}
                    {geoObjectFilesState.map((item: any, i: number) => {
                        return (
                            <div key={i + '_pic'} className="image-wrapper">
                                <div className="image-delete-icon-wrapper"
                                     onClick={() => handleDeleteEmergencyFile(item.id)}
                                >
                                    <CIcon icon={cilX} className="image-delete-icon"/>
                                </div>
                                {
                                    <img alt={""}
                                         src={item.image}
                                         style={{height: '110px', width: 'auto'}}
                                    />
                                }
                            </div>
                        )
                    })}
                </div>
            </FormGroup>

            <>
                {error && <span style={{color: 'red'}}>{error}</span>}
                <div className="mb-3" style={{textAlign: 'right'}}>
                    <span style={{color: 'red'}}>*&nbsp;</span><span>{t('createAssignment.field star')}</span>
                </div>
                {
                    !isId ?
                        <CButton size="sm" color="info"
                                 className="default-table__action-btn color-white mt-3 mb-4 d-block ms-auto"
                                 onClick={() => handleCreate()}
                                 disabled={!(state.name && state.description && state.geo_type && state.region && state.district && typeLeaflet.type && state.latitude && state.longitude)}
                        >
                            {t("gis.geoObjects.buttonCreate")}
                        </CButton>
                        :
                        <CButton size="sm" color="info"
                                 className="default-table__action-btn color-white mt-3 mb-4 d-block ms-auto"
                                 onClick={() => handleEdit()}
                                 disabled={!(state.name && state.description && state.geo_type && state.region && state.district && typeLeaflet.type && state.latitude && state.longitude)}
                        >
                            {t("gis.geoObjects.buttonUpdate")}
                        </CButton>
                }
            </>

        </>
    );
};

export default NewGeoObject;

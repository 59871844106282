import React from 'react';

const EsDailyReportNew = () => {
  return (
    <div>
      ES Daily Report New
    </div>
  );
};

export default EsDailyReportNew;

import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IListRoles, IRole} from "./roles.models";

export const rolesApi = createApi({
  reducerPath: 'roles/api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers) => headerConfig(headers),
  }),
  endpoints: build => ({
    listRoles: build.query<IRole[], void>({
      query: () => ({
        url: `/roles/`,
        params: {
          per_page: 1000000
        }
      }),
      transformResponse: (response: IListRoles) => response.data,
    })
  })
});

export const { useListRolesQuery } = rolesApi;

import React from 'react';

const PublicMobileAdmin = () => {
  return (
    <div className="whole-place-wrapper">
      <iframe title="public-mobile-admin"
              className="third-party-app-iframe"
              src='https://new.mes.yorc.org?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhbGxvdyI6dHJ1ZSwiaWF0IjoxNjQ5NjUwMTU0LCJleHAiOjE2NDk3MzY1NTR9.O5wiO4HJvlB3WV14Ih3Ater-ebijFYL7VIbjeRYgZi4'
      />
    </div>
  );
};

export default PublicMobileAdmin;

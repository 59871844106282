import { useState } from 'react';
import { useTranslation } from 'react-i18next';
type Prop = {
    priority: string
};

const PriorityComponent = ({priority}:Prop) => {
    const {t} = useTranslation();
    const [toolTip, setTooltip] = useState(false);

    const toolTipHandler = (pri: string) => {
        return (
            <>
                {toolTip ? <span style={{
                    position: 'absolute', 
                    top: '0',
                    right: '0',
                    background: 'rgba(255, 255, 255, 0.9)', 
                    width: '120px', 
                    bottom: '0',
                    padding: '5px',
                    borderRadius: '10px',
                    textAlign: 'center',
                    color: 'grey',
                    fontSize: '13px',
                    textTransform: 'capitalize'
                }}>{t(`createAssignment.${pri}`)}</span> : null}
               
            </>
        )
    };

    if (priority === 'lowest') {
        return <div 
            style={{color: 'blue', fontSize: '23px', fontWeight: '700', right: '0', top: '-8px', position: 'relative'}}
            onMouseLeave={() => setTooltip(false)} 
            onMouseMove={() => setTooltip(true)}
        >
            <i className="fa fa-angle-double-down" aria-hidden="true"></i>
            {toolTipHandler(priority)}
         </div>
     }
     if (priority === 'low') {
        return <div 
            style={{color: 'blue', fontSize: '23px', fontWeight: '700', right: '0', top: '-8px', position: 'relative'}}
            onMouseLeave={() => setTooltip(false)} 
            onMouseMove={() => setTooltip(true)}
        >   
            <i className="fa fa-angle-down" aria-hidden="true"></i>
            {toolTipHandler(priority)}
        </div>
     }
     if (priority === 'medium') {
        return <div 
            style={{color: 'rgb(235, 125, 60)', fontSize: '23px', fontWeight: '700', right: '0', top: '-8px', position: 'relative'}}
            onMouseLeave={() => setTooltip(false)} 
            onMouseMove={() => setTooltip(true)}
        >
            <i>=</i>
            {toolTipHandler(priority)}
        </div>
     }
     if (priority === 'high') {
        return <div 
            style={{color: 'red', fontSize: '23px', fontWeight: '700', right: '0', top: '-8px', position: 'relative'}}
            onMouseLeave={() => setTooltip(false)} 
            onMouseMove={() => setTooltip(true)}
        >
            <i className="fa fa-angle-up" aria-hidden="true"></i>
            {toolTipHandler(priority)}
        </div>
     }
     if (priority === 'highest') {
        return <div 
            style={{color: 'red', fontSize: '23px', fontWeight: '700', right: '0', top: '-8px', position: 'relative'}}
            onMouseLeave={() => setTooltip(false)} 
            onMouseMove={() => setTooltip(true)}
        >
             <i className="fa fa-angle-double-up" aria-hidden="true"></i>
             {toolTipHandler(priority)}
        </div>
     }

  return (
    <></>
  );
};

export default PriorityComponent;
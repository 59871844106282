import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import ky from './ky.json';
import ru from './ru.json';
import en from './en.json';

const resources = {
  ky: {
    translation: ky
  },
  ru: {
    translation: ru
  },
  en: {
    translation: en
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ru',
    fallbackLng: 'ru',
    react: {
      useSuspense: true
    }
  }).then(r => r);

export default i18n;
import React, {FC, Fragment, MouseEvent, useEffect, useMemo, useState} from 'react';
import {CCollapse} from "@coreui/react";
import {useTranslation} from "react-i18next";
import './GeoCategoriesTree.css';

import {useGetGeoCategoriesQuery} from "../../../store/geoCategories/geoCategories.api";

interface IComponentProps {
  checked?: number,
  onCheck: (id: number) => void,
  okIsHover?: boolean,
  geoCategory?: String,
}

const chevronDown = (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
    <path fill='none' stroke='#636f83' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6'/>
  </svg>
);

const GeoCategoriesTree: FC<IComponentProps> = ({checked = 0, onCheck, okIsHover, geoCategory}) => {
  const {t} = useTranslation();

  const [expanded, setExpanded] = useState<boolean>(false);
  const [expandedCategories, setExpandedCategories] = useState<number[]>([]);
  const [checkedId, setCheckedId] = useState<number>(checked);
  const [checkedName, setCheckedName] = useState<string>('');

  const {data, isLoading, isError} = useGetGeoCategoriesQuery();

  useEffect(() => {
    if (data && !isLoading && !isError && checked) {
      const findCategory = (list: any[], parentIds: number[] = []) => {
        for (let cat of list) {
          if (cat.id === checked) {
            setCheckedName(cat.category_name);
            setExpandedCategories(parentIds);
            break;
          } else if (cat.children?.length) {
            findCategory(cat.children, [...parentIds, cat.id]);
          }
        }
      }
      findCategory(data);
    }
  }, [data, isLoading, isError]);

  const toggleWrapperHandler = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setExpanded(prev => !prev);
  };

  const toggleSubListHandler = (e: MouseEvent<HTMLDivElement>, el: any) => {
    e.stopPropagation();
    const innerState = [...expandedCategories];
    if (innerState.includes(el.id)) innerState.splice(innerState.findIndex(id => id === el.id), 1);
    else if (el.children?.length) innerState.push(el.id);
    setExpandedCategories(innerState);
  };

  const onCheckHandler = (e: MouseEvent<HTMLElement>, el: any) => {
    e.stopPropagation();
    const newCheckedId = checkedId === el.id ? 0 : el.id;
    setCheckedId(newCheckedId);
    setCheckedName(newCheckedId ? el.category_name : '');
    onCheck(newCheckedId);
  };

  const collapsedListCreator = (list: any[], nestLevel: number = 1, catId: number = 0) => {
    return (
      <CCollapse visible={expandedCategories.includes(catId)}>
        {list.map((el: any) => (
          <Fragment key={'catItem' + el.id}>
            <div
              className={`geo-categories-tree__item-wrapper${checkedId === el.id ? ' geo-categories-tree__item-wrapper--selected' : ''}`}
              style={{paddingLeft: `${1.25 * nestLevel}rem`, cursor: el.children?.length ? 'pointer' : 'default'}}
              onClick={(e) => toggleSubListHandler(e, el)}
            >
              <div className="geo-categories-tree__caret-wrapper">
                {expandedCategories.includes(el.id) ?
                  <i className="fa fa-caret-down" aria-hidden="true"/>
                  : el.children?.length ?
                    <i className="fa fa-caret-right" aria-hidden="true"/>
                    : null
                }
              </div>
              <div className="geo-categories-tree__check-wrapper">
                {/*<i className={`fa fa-${checkedId === el.id ? 'check-' : ''}square-o`} aria-hidden="true"*/}
                {/*   onClick={(e) => onCheckHandler(e, el)}*/}
                {/*/>*/}
                <i className={`fa fa-${checkedId === el.id ? 'dot-' : ''}circle-o`} aria-hidden="true"
                   style={{padding: '5px!important'}}
                   onClick={(e) => onCheckHandler(e, el)}
                />
              </div>
              {el.category_name}
            </div>
            {el.children?.length ? collapsedListCreator(el.children, nestLevel + 1, el.id) : null}
          </Fragment>
        ))}
      </CCollapse>
    )
  };

  if (!data || isLoading || isError) return null;

  return (
    <div
        className={`geo-categories-tree__wrapper${expanded ? ' geo-categories-tree__wrapper--expanded' : ''} ${okIsHover && (!geoCategory || geoCategory === '0') ? ' form-control--alert' : ''}`}
         onClick={toggleWrapperHandler}
    >
      <div className="geo-categories-tree__header">
        {checkedName || t('Nothing is selected')}
      </div>
      <CCollapse visible={expanded}>
        <div className="geo-categories-tree__list">
          {data.map((el: any) => (
            <Fragment key={'catItem' + el.id}>
              <div
                className={`geo-categories-tree__item-wrapper${checkedId === el.id ? ' geo-categories-tree__item-wrapper--selected' : ''}`}
                style={{cursor: el.children?.length ? 'pointer' : 'default'}}
                onClick={(e) => toggleSubListHandler(e, el)}
              >
                <div className="geo-categories-tree__caret-wrapper">
                  {expandedCategories.includes(el.id) ?
                    <i className="fa fa-caret-down" aria-hidden="true"/>
                    : el.children?.length ?
                      <i className="fa fa-caret-right" aria-hidden="true"/>
                      : null
                  }
                </div>
                <div className="geo-categories-tree__check-wrapper">
                  <i className={`fa fa-${checkedId === el.id ? 'dot-' : ''}circle-o`} aria-hidden="true"
                     style={{padding: '5px!important'}}
                     onClick={(e) => onCheckHandler(e, el)}
                  />
                </div>
                {el.category_name}
              </div>
              {el.children?.length ? collapsedListCreator(el.children, 1, el.id) : null}
            </Fragment>
          ))}
        </div>
      </CCollapse>
      {chevronDown}
    </div>
  );
};

export default GeoCategoriesTree;

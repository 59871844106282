import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CSpinner} from "@coreui/react";
import {useGetServicesQuery, useLazyGetServicesQuery} from "../../../store/services/services.api";

// import {fetchServices} from "../../../store/actions/services";

const SingleServiceInfo = () => {
  const location: any = useParams();
  const dispatch = useDispatch();

  // const services = useSelector(state => state.services.services);
  // const loading = useSelector(state => state.services.loading);
  // const [fetchServices, {isError: error,  isLoading: loading, data: services}] = useLazyGetServicesQuery();
  const {isError: error,  isLoading: loading, data: services} = useGetServicesQuery();


  const [service, setService] = useState({name: '', url: ''});

  // useEffect(() => {
  //   // if (!services.length) dispatch(fetchServices(location.id));
  //   // @ts-ignore
  //   if (!services?.length) fetchServices();
  //   // eslint-disable-next-line
  // },[]);

  useEffect(() => {
    // @ts-ignore
    const idx = services?.length ? services.findIndex((el: any) => el.id.toString() === location.id.toString()) : -1;
    if (idx < 0) setService({name: '', url: ''});
    // @ts-ignore
    else setService({name: services[idx].service_name, url: services[idx].url});
  },[location.id, services]);

  if (loading) return <div className="d-flex justify-content-md-center mb-3"><CSpinner/></div>;
  if (!service.url) return <div>404 Not Found</div>;

  return (
    <div className="whole-place-wrapper">
      <iframe className="third-party-app-iframe" src={service.url} title={service.name}/>
    </div>
  );
};

export default SingleServiceInfo;

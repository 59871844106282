import React, {ChangeEventHandler, useEffect, useMemo, useState} from 'react';
import {useGetInfoUserQuery, useLazyGetInfoUserQuery, useUpdateInfoUserMutation} from "../../store/account/account.api";
import {
    CCol,
    CForm,
    CFormInput, CFormLabel,
    CImage, CRow,
} from "@coreui/react";

import avatarPage from "../../assets/images/avatars/avatar-page.svg";
import {useTranslation} from "react-i18next";
import DefaultModal from "../../components/UI/Modals/DefaultModal";
import FormGroup from "../../components/UI/Forms/FormGroup";
import {useLocation, useNavigate} from "react-router-dom";

const Profile = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    // const [getMe, {isError: errorGetMe, isLoading: loadingGetMe, data: me}] = useLazyGetInfoUserQuery();
    const {data: me} = useGetInfoUserQuery();
    const [updateUser] = useUpdateInfoUserMutation();

    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editingUser, setEditingUser]: any = useState({});
    const [avatarPreview, setAvatarPreview]: any = useState('');
    const [error, setError]: any = useState(null);

    // useEffect(() => {
    //     getMe();
    // }, []);

    useEffect(() => {
        if (me) {
            setEditingUser({
                first_name: me.first_name,
                last_name: me.last_name,
                patronymic: me.patronymic,
                phone_number: me.phone_number,
                email: me.email,
                avatar: me.avatar
            });

            setAvatarPreview(me.avatar || '');
        }
    }, [me]);

    const isChanged = useMemo(() => {
        for (let [key, value] of Object.entries(editingUser)) {
            if (me[key] !== value) return true;
        }
        return false;
    }, [editingUser]);

    const goBackHandler = () => {
        navigate(-1);
    };

    const toggleEditModalHandler = (visible?: boolean) => {
        setError(null);
        setEditModalVisible(!!visible);
        if (visible && me) {
            setEditingUser({
                first_name: me.first_name,
                last_name: me.last_name,
                patronymic: me.patronymic,
                phone_number: me.phone_number,
                email: me.email,
                avatar: me.avatar
            });
            if(typeof me.avatar !== "string") setAvatarPreview(me.avatar || '');
        }
    };

    const onEditUserHandler = () => {
        if (!isChanged) {
            toggleEditModalHandler();
            return null;
        }

        const formData: any = new FormData();

        Object.entries(editingUser).forEach((key: any)=> {
            if(key[0] !== "avatar") {
                formData.append(key[0], key[1]);
            } else {
                if(key[1] && typeof key[1] !== "string") formData.append(key[0], key[1]);
            }
        });

        updateUser(formData).then(
            () => {
                toggleEditModalHandler();
            },
            () => {
                setError('Something went wrong');
            }
        );
    };

    const onChangeHandler = (event: any) => {
        setEditingUser((prev: any) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
    };

    const onChangeAvatarHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {files} = e?.target;
        const file = files?.length ? files[0] : '';

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const result = reader.result;
                setAvatarPreview(result);
                setEditingUser((prev: any) => ({
                    ...prev,
                    avatar: file
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            {
                me ?
                    <div className="container-md">
                        <div className="profile-page">
                            <h2 className="profile-page__title">{t('profile.title')}</h2>
                            <div className="profile-page__content">
                                <div className="profile-page__block-img">
                                    <img src={me.avatar ? me.avatar : avatarPage} alt="avatar-page"/>
                                </div>

                                <div className="profile-page__block-info">
                                    <div className="profile-page__block-text">
                                        <p className="profile-page__text">{t("profile.full_name")}:</p>
                                        <p className="profile-page__text">{me.last_name} {me.first_name} {me.patronymic}</p>
                                    </div>

                                    {me.email ? <div className="profile-page__block-text">
                                        <p className="profile-page__text">{t("profile.email")}:</p>
                                        <p className="profile-page__text">{me.email}</p>
                                    </div> : null}

                                    <div className="profile-page__block-text">
                                        <p className="profile-page__text">{t("profile.phone")}:</p>
                                        <p className="profile-page__text">{me.phone_number ? me.phone_number : t('profile.phone_number')}</p>
                                    </div>
                                </div>
                                <button className="btn btn-info btn-sm color-white" type="button"
                                        onClick={() => toggleEditModalHandler(true)}>
                                    {t("profile.edit")}
                                </button>
                                <button className="btn btn-secondary btn-sm color-black ms-4" type="button"
                                        onClick={goBackHandler}
                                >
                                    {t("profile.back")}
                                </button>
                            </div>
                        </div>
                    </div>
                    : null

            }
            <DefaultModal visible={editModalVisible}
                          setVisible={toggleEditModalHandler}
                          title={t(`profile.titleModal`)}
                          cancelButton={t('cancel')}
                          okButton={t('save')}
                          onOk={onEditUserHandler}
                          error={error}
                          size="xl"
            >
                <CForm>
                    <FormGroup htmlFor='username'>
                        <div className="profile-page__img-edit-block">
                            <CImage
                                className="profile-page__img-edit"
                                src={avatarPreview || editingUser.avatar}
                            />
                            <i className="fa fa-camera nav-icon" aria-hidden="true"></i>
                            <CFormInput type="file"
                                        id="file"
                                        name="file"
                                        className="profile-page__input-upload"
                                        onChange={onChangeAvatarHandler}
                            />
                        </div>

                        <div className="profile-page-modal-block-inputs">
                            <CRow>
                                <CFormLabel htmlFor="last_name" className="col-sm-4 col-form-label">{t('profile.last_name')}</CFormLabel>
                                <CCol>
                                    <CFormInput id="last_name"
                                                name="last_name"
                                                type="text"
                                                className="profile-page__input-modal"
                                                value={editingUser.last_name}
                                                onChange={onChangeHandler}
                                    />
                                </CCol>
                            </CRow>

                            <CRow>
                                <CFormLabel htmlFor="first_name" className="col-sm-4 col-form-label">{t('profile.first_name')}</CFormLabel>
                                <CCol>
                                    <CFormInput id="first_name"
                                                name="first_name"
                                                type="text"
                                                className="profile-page__input-modal"
                                                value={editingUser.first_name}
                                                onChange={onChangeHandler}
                                    />
                                </CCol>
                            </CRow>

                            <CRow>
                                <CFormLabel htmlFor="patronymic" className="col-sm-4 col-form-label">{t('profile.patronymic')}</CFormLabel>
                                <CCol>
                                    <CFormInput id="patronymic"
                                                name="patronymic"
                                                type="text"
                                                className="profile-page__input-modal"
                                                value={editingUser.patronymic}
                                                onChange={onChangeHandler}
                                    />
                                </CCol>
                            </CRow>

                            <CRow>
                                <CFormLabel htmlFor="email" className="col-sm-4 col-form-label">{t('profile.email')}</CFormLabel>
                                <CCol>
                                    <CFormInput id="email"
                                                name="email"
                                                type="text"
                                                className="profile-page__input-modal"
                                                value={editingUser.email}
                                                onChange={onChangeHandler}
                                    />
                                </CCol>
                            </CRow>

                            <CRow>
                                <CFormLabel htmlFor="phone_number" className="col-sm-4 col-form-label">{t('profile.phone')}</CFormLabel>
                                <CCol>
                                    <CFormInput id="phone_number"
                                                name="phone_number"
                                                type="phone"
                                                className="profile-page__input-modal"
                                                value={editingUser.phone_number}
                                                onChange={onChangeHandler}
                                    />
                                </CCol>
                            </CRow>
                        </div>
                    </FormGroup>
                </CForm>
            </DefaultModal>
        </>
    )
}

export default Profile;

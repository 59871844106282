import React, {FC} from 'react';

import {IEmergency} from "../../../interfaces/IEmergency";
import EmergencyCard from "./EmergencyCard";
import {esFeed} from "./EsFeed";
import {IPaginationResponse} from "../../../store/models";

interface IEmergencyList {
  data: esFeed[]
  onClick: (e: React.MouseEvent<HTMLDivElement>, id: number) => void
  isMenuOpen: any
  menuPosition: string
  users: any
  openModal: (id: number) => void
  emergencyTypes: IPaginationResponse<any[]> | undefined
}

const EmergencyList: FC<IEmergencyList> = ({data, onClick, isMenuOpen, menuPosition, users, openModal, emergencyTypes}) => {

  let emergencyList;

  if (data.length) {
    emergencyList = data.map(el => {
      return (
        <div key={el.id}>
          <EmergencyCard item={el}
                         onClick={onClick}
                         isMenuOpen={isMenuOpen}
                         menuPosition={menuPosition}
                         users={users}
                         openModal={openModal}
                         icon={emergencyTypes?.results?.find(item => item.id === el.type_emergency)?.geo_icon?.icon}
          />
        </div>
      )
    })
  }

  return (
    <div id="es-scrollable-block" className="es-scrollable-block emergency-block">
      {emergencyList}
      <div id='feed_bottom'/>
    </div>
  );
};

export default EmergencyList;
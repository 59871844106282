import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IEmergencyAnalyticFields} from "../../interfaces/IEmergency";


export const esFieldsApi = createApi({
  reducerPath: 'esFields/api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers) => headerConfig(headers),
  }),
  tagTypes: ['esFields'],
  endpoints: build => ({
    getEsFields: build.query<IEmergencyAnalyticFields, string | void>({
      query: () => {
        return {
          url: `/get/verbose/name/`
        }
      },
      // @ts-ignore
      transformResponse: (response: any) => {
        return {labels: response.labels, field_types: response.field_types}
      },
      providesTags: ['esFields']
    })
  })
})

export const {useGetEsFieldsQuery} = esFieldsApi
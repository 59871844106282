import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import { IAssigmentNew, IAssignment, IAssignmentsList, INewComment } from "../../interfaces/IAssignments";

export const assignmentsApi = createApi({
  reducerPath: 'assignments/api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers) => headerConfig(headers),
  }),
  tagTypes: ['assignments'],
  endpoints: build => ({
    getAssigmentsList: build.query<IAssignmentsList[], string | void>({
      query: (query) => {
        return {
          url: `/assignment/list/${query || ''}`
        }
      },
      providesTags: ['assignments'],
    }),

    getAssigmentById: build.query<IAssignment, string | number>({
        query: (id: string | number) => ({
            url: `/assignment/detail/${id}/`
        })
    }),

    createAssigment: build.mutation<IAssigmentNew, IAssigmentNew>({
      query: (body) => ({
        url: '/assignment/create/',
        method: 'POST',
        body
      }),
      invalidatesTags: ['assignments'],
    }),

    changeAssignmentStatus: build.mutation<any, Partial<any> & Pick<any, 'id'>>({
      query: (body) => {
        return {
          url: `/assignment/change/status/${body.id}/`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: ['assignments'],
    }),
    
    createAssigmentFiles: build.mutation<any, any>({
      query: (body) => {
        return {
          url: '/file/assignment/create/',
          method: 'POST',
          body
        }
      }
    }),

    uploadAssignment: build.mutation<IAssigmentNew, IAssigmentNew>({
      query: (body) => {
        return {
          url: `/assignment/update/${body.id}/`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: ['assignments'],
    }),

    removeAssigment: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `/assignment/delete/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['assignments'],
    }),

    removeAssigmentFile: build.mutation<{ success: boolean; id: number }, number>({
      query: (id) => ({
        url: `/file/assignment/delete/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['assignments'],
    }),

    createComment: build.mutation<INewComment, INewComment>({
      query: (body) => ({
        url: '/assignment/comment/create/',
        method: 'POST',
        body
      }),
      invalidatesTags: ['assignments'],
    }),

    uploadComment: build.mutation<INewComment, INewComment>({
      query: (body) => {
        return {
          url: `/assignment/comment/update/${body.id}/`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: ['assignments'],
    }),

    createCommentFiles: build.mutation<any, any>({
      query: (body) => {
        return {
          url: '/file/comment/create/',
          method: 'POST',
          body
        }
      }
    }),
    
    removeComment: build.mutation<{ success: boolean; id: number }, number>({
      query: (id) => ({
        url: `/assignment/comment/delete/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['assignments'],
    }),
  })
})

export const {
  useGetAssigmentsListQuery,
  useLazyGetAssigmentsListQuery,
  useGetAssigmentByIdQuery,
  useLazyGetAssigmentByIdQuery,
  useCreateAssigmentMutation,
  useChangeAssignmentStatusMutation,
  useCreateAssigmentFilesMutation,
  useUploadAssignmentMutation,
  useRemoveAssigmentMutation,
  useRemoveAssigmentFileMutation,
  useCreateCommentMutation,
  useUploadCommentMutation,
  useCreateCommentFilesMutation,
  useRemoveCommentMutation,
} = assignmentsApi;
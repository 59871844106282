import React, {FC} from 'react';
import {CChart} from "@coreui/react-chartjs";
import {getStyle} from "@coreui/utils";

import {colors} from "../../../../colorsForCharts/colorsForCharts";

interface IPolarChartProps {
  labels: string[];
  data: number[];
}

const PolarChart: FC<IPolarChartProps> = ({labels, data}) => {
  return (
    <>
      <CChart
        type="polarArea"
        data={{
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: colors,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              labels: {
                color: getStyle('--cui-body-color'),
              }
            }
          },
          scales: {
            r: {
              grid: {
                color: getStyle('--cui-border-color'),
              },
            }
          }
        }}
      />
    </>
  );
};

export default PolarChart;
import {useTranslation} from "react-i18next";

interface SelectFilterProps {
    options: any[],
    onclick: () => void,
    visible: boolean,
    onMouse: (event: boolean) => void
}
const SelectForFilter = ({options, onclick, visible, onMouse}:SelectFilterProps) => {
    const {t} = useTranslation();
    return (
        <>
            <div
                onClick={onclick}
                style={{padding: '0.500rem 0.5rem',
                        border: '1px solid #d8dbe0',
                        borderRadius: '5px 0 0 5px',
                        background: '#fff',
                        width: '12rem',
                        cursor: 'default',
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        fontSize: '1rem',
                        color: 'rgba(44, 56, 74, 0.95)',
                }}
            >
                {t('assignmentsPage.fast_filters')}
                <img src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23636f83%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e"
                     alt="" width={13} height={13}
                />

            </div>
            <div onMouseDown={() => onMouse(true)}
                 onMouseLeave={() => onMouse(false)}
                 style={{display: 'flex',
                     flexDirection: 'column',
                     gap: '20px',
                     padding: '10px',
                     background: visible ? '#fff' : 'transparent',
                     position: 'absolute',
                     top: '123px',
                     right: '74px',
                 }}>
                {visible && options.map((item: any, i:number) => (
                    <div key={item.value + i}>
                        {item.label}
                    </div>
                ))}
            </div>
        </>
    );
}


export default SelectForFilter;
interface esFeed {
  id: string,
  value: string
}

interface esFeedState {
  esFeeds: esFeed[],
  isLoading: boolean,
  error: string
}

const initialState: any = {
  esFeed: [],
  isLoading: false,
  error: ''
}


const esFeedSlice = (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_ES_FEED_SUCCESS':
      return {...state, esFeed: action.payload}
    default:
      return {...state}
  }
}

export default esFeedSlice;
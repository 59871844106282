import React from 'react';
import './SpinnerDotted.css';

interface SpinnerDottedProps {
  size?: any;
  color?: string;
  visible?: any;
  style?: any;
}
const SpinnerDotted: React.FC<SpinnerDottedProps> = ({size, color, visible}) => {
  const styles: any = {};
  if (size || !isNaN(size)) {
    styles.width = `${size}px`;
    styles.height = `${size}px`;
    styles.fontSize = `${size}px`;
    styles.margin = `${size / 2}px`;
  }
  if (color) styles.color = color;

  return (
    <div className="spinner-dotted" style={styles}>
      Loading...
    </div>
  );
};

export default SpinnerDotted;

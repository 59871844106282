import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";

export const emergencyFilesApi = createApi({
  reducerPath: 'emergencyFiles/api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers) => headerConfig(headers),
  }),
  tagTypes: ['emergencyFiles'],
  endpoints: build => ({
    getEmergencyFiles: build.query<any, any>({
      query: (id) => {
        return {
          url: `/detail/file/emergency/${id}/`
        }
      },
      providesTags: ['emergencyFiles']
    }),
    createEmergencyFile: build.mutation<any, any>({
      query: (formData) => ({
        url: '/create/file/emergency/',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: ['emergencyFiles'],
    }),
    deleteEmergencyFile: build.mutation({
      query: (id) => ({
        url: `/delete/file/emergency/${id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ['emergencyFiles'],
    })
  })
})

export const {useCreateEmergencyFileMutation, useLazyGetEmergencyFilesQuery, useDeleteEmergencyFileMutation} = emergencyFilesApi
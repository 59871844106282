import en from './en.json';
import ky from './ky.json';
import ru from './ru.json';

const dict = {en, ky, ru};

function locales (lng, str) {
  return dict[lng][str];
}

export default locales;

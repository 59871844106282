import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import DefaultPlayer from "./DefaultPlayer";

import fullscreenOpenIcon from "../../assets/icons/fullscreen-open.png";
import fullscreenCloseIcon from "../../assets/icons/fullscreen-close.png";

function VideoWall (props) {
  const [wallWidth, setWallWidth] = useState(0);
  const [wallHeight, setWallHeight] = useState(0);
  const [cellSize, setCellSize] = useState({width: 0, height: 0});
  const [expCellSize, setExpCellSize] = useState({width: 0, height: 0});
  const [coordinates, setCoordinates] = useState([]);
  const [positions, setPositions] = useState([]);
  const [prevCoords, setPrevCoords] = useState({top: 0, left: 0});
  const [startCoords, setStartCoords] = useState({top: 0, left: 0});
  const [dragCoords, setDragCoords] = useState({top: 0, left: 0});
  const [dragOf, setDragOf] = useState(null);
  const [dragTo, setDragTo] = useState(null);
  const [moveNotAllowed, setMoveNotAllowed] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    const wall = document.querySelector('.video-wall');
    if (wall) {
      const width = wall.clientWidth / props.cols;
      const height = width / 16 * 9;
      setWallWidth(wall.clientWidth);
      setWallHeight(props.cols * height);
      setCellSize({width: width, height: height});
      if (props.layout === 6 || props.layout === 10) setExpCellSize({width: width * 2, height: height * 2});
    }
    setPositions(props.sources.map((el, idx) => idx));
  }, [props.cols, props.layout, props.sources]);

  const calculateSizes = isFullscreen => {
    const width = (isFullscreen ? window.innerWidth : wallWidth) / props.cols;
    const height = width / 16 * 9;
    setWallHeight(props.cols * height);
    setCellSize({width: width, height: height});
    if (props.layout === 6 || props.layout === 10) setExpCellSize({width: width * 2, height: height * 2});
  };

  const onFullscreenToggle = isResize => {
    if (fullscreen && isResize) return null;
    if (isResize) window.onresize = null;
    const wall = document.querySelector('.video-wall');
    if (wall) {
      if (fullscreen) {
        if (document.cancelFullScreen) try { document.cancelFullScreen() } catch (e) {}
        else if (document.exitFullscreen) try { document.exitFullscreen() } catch (e) {}
        else if (document.mozCancelFullScreen) try { document.mozCancelFullScreen() } catch (e) {}
        else if (document.webkitCancelFullScreen) try { document.webkitCancelFullScreen() } catch (e) {}
        window.onresize = null;
      } else if (!isResize) {
        if (wall.requestFullScreen) try { wall.requestFullScreen() } catch (e) {}
        else if (wall.mozRequestFullScreen) try { wall.mozRequestFullScreen() } catch (e) {}
        else if (wall.webkitRequestFullScreen) try { wall.webkitRequestFullScreen() } catch (e) {}
        setTimeout(() => {
          window.onresize = e => {
            onFullscreenToggle(true);
          }
        }, 500);
      }
    }
    calculateSizes(isResize ? false : !fullscreen);
    setFullscreen(prevState => !prevState);
  };

  const onMouseDown = (event, idx, coords) => {
    if (moveNotAllowed) return null;
    const currentCoordinates = [];
    document.querySelectorAll('.video-wall__cell').forEach(el => {
      const rect = el.getBoundingClientRect();
      currentCoordinates.push(rect);
    });
    setCoordinates(currentCoordinates);
    setPrevCoords({top: coords.top, left: coords.left});
    setStartCoords({top: event.clientY, left: event.clientX});
    setDragCoords({top: coords.top, left: coords.left});
    setDragOf(idx);
    setDragTo(idx);
  };

  const onMouseUp = event => {
    if (event) event.stopPropagation();
    if (!dragOf && dragOf !== 0) return null;
    setMoveNotAllowed(true);
    const movableEl = document.querySelector(`.video-wall__cell:nth-child(${dragOf + 1})`);
    let replaceableEl = null;
    if (dragTo < 0 || dragTo === dragOf) {
      movableEl.style.top = prevCoords.top + 'px';
      movableEl.style.left = prevCoords.left + 'px';
      movableEl.style.transition = 'top .3s ease-out, left .3s ease-out';
    } else {
      replaceableEl = document.querySelector(`.video-wall__cell:nth-child(${dragTo + 1})`);
      movableEl.style.top = replaceableEl.style.top;
      movableEl.style.left = replaceableEl.style.left;
      replaceableEl.style.top = prevCoords.top + 'px';
      replaceableEl.style.left = prevCoords.left + 'px';
      replaceableEl.style['z-index'] = 99;
      const ofIdx = positions.findIndex(el => el === dragOf);
      const toIdx = positions.findIndex(el => el === dragTo);
      if ((props.layout === 6 && (toIdx === 0 || ofIdx === 0)) || (props.layout === 10 && (toIdx === 0 || ofIdx === 0 || toIdx === 1 || ofIdx === 1))) {
        const movableWidth = movableEl.style.width;
        const movableHeight = movableEl.style.height;
        movableEl.style.width = replaceableEl.style.width;
        movableEl.style.height = replaceableEl.style.height;
        replaceableEl.style.width = movableWidth;
        replaceableEl.style.height = movableHeight;
        movableEl.style.transition = 'top .3s ease-out, left .3s ease-out, width .3s ease-out, height .3s ease-out';
        replaceableEl.style.transition = 'top .3s ease-out, left .3s ease-out, width .3s ease-out, height .3s ease-out';
      } else {
        movableEl.style.transition = 'top .3s ease-out, left .3s ease-out';
        replaceableEl.style.transition = 'top .3s ease-out, left .3s ease-out';
      }
    }
    setTimeout(() => {
      movableEl.style.transition = 'none';
      if (replaceableEl) {
        replaceableEl.style['z-index'] = 10;
        replaceableEl.style.transition = 'none';
      }
      if (dragTo >= 0 && dragTo !== dragOf) {
        const newPositions = [...positions];
        const ofIdx = positions.findIndex(el => el === dragOf);
        const toIdx = positions.findIndex(el => el === dragTo);
        newPositions[ofIdx] = dragTo;
        newPositions[toIdx] = dragOf;
        setPositions(newPositions);
      }
      setDragOf(null);
      setDragTo(null);
    }, 310);
    setTimeout(() => {
      setMoveNotAllowed(false);
    }, 500);
  };

  const onMouseMove = event => {
    if (event) event.stopPropagation();
    if (moveNotAllowed) return null;
    if (dragOf || dragOf === 0) {
      setDragCoords({top: event.clientY - startCoords.top + prevCoords.top, left: event.clientX - startCoords.left + prevCoords.left});
      const destIdx = coordinates.findIndex(el => (
        event.clientY > el.top && event.clientY < el.bottom && event.clientX > el.left && event.clientX < el.right
      ));
      if (destIdx >= 0) setDragTo(destIdx);
    }
  };

  const onMouseLeave = () => {
    onMouseUp();
  }

  const defineCellStyles = idx => {
    const style = {width: cellSize.width, height: cellSize.height};
    const posIdx = positions.findIndex(p => p === idx);
    if ((props.layout === 6 && posIdx === 0) || (props.layout === 10 && (posIdx === 0 || posIdx === 1))) {
      style.width = expCellSize.width;
      style.height = expCellSize.height;
    }
    if (dragOf === idx) {
      style.top = dragCoords.top;
      style.left = dragCoords.left;
      style.zIndex = 100;
    } else {
      if (props.layout === 6) {
        if (posIdx === 0) {
          style.top = 0;
          style.left = 0;
        }
        if (posIdx === 1) {
          style.top = 0;
          style.left = expCellSize.width;
        }
        if (posIdx === 2) {
          style.top = cellSize.height;
          style.left = expCellSize.width;
        }
        if (posIdx > 2) {
          style.top = Math.floor(posIdx / props.cols) * expCellSize.height;
          style.left = (posIdx % props.cols) * cellSize.width;
        }
      } else if (props.layout === 10) {
        if (posIdx === 0) {
          style.top = 0;
          style.left = 0;
        }
        if (posIdx === 1) {
          style.top = 0;
          style.left = expCellSize.width;
        }
        if (posIdx > 1) {
          style.top = Math.floor((posIdx + 2) / props.cols) * cellSize.height + cellSize.height;
          style.left = (posIdx + 2) % props.cols * cellSize.width;
        }
      } else {
        style.top = Math.floor(posIdx / props.cols) * cellSize.height;
        style.left = (posIdx % props.cols) * cellSize.width;
      }
    }
    return style;
  };

  return (
    <div className="video-wall"
         style={{height: wallHeight, background: fullscreen ? 'whitesmoke' : 'transparent'}}
         onMouseLeave={onMouseLeave}
    >
      {props.sources.map((source, idx) => {
        const style = defineCellStyles(idx);
        return (
          <div key={'video-wall__cell' + idx}
               className="video-wall__cell"
               style={style}
               onMouseDown={e => onMouseDown(e, idx, {top: style.top, left: style.left})}
               onMouseUp={onMouseUp}
               onMouseMove={onMouseMove}
          >
            <DefaultPlayer id={'video-wall__player' + idx}
                           source={source.value}
                           controls
                           autoPlay
                           allowManagement={props.allowManagement}
                           onMouseDown={e => onMouseDown(e, idx, {top: style.top, left: style.left})}
                           onMouseUp={onMouseUp}
                           onMouseMove={onMouseMove}
            />
          </div>
        );
      })}
      <div className="video-wall__fullscreen-icon"
           style={{backgroundImage: `url(${fullscreen ? fullscreenCloseIcon : fullscreenOpenIcon})`}}
           onClick={() => onFullscreenToggle(false)}
      />
    </div>
  );
}

VideoWall.propTypes = {
  cols: PropTypes.number.isRequired,
  sources: PropTypes.arrayOf(PropTypes.object).isRequired,
  layout: PropTypes.number,
  allowManagement: PropTypes.bool,
  loading: PropTypes.bool
};

export default VideoWall;

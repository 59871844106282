import React, {useEffect, useState} from 'react';
import {
    CButton, CButtonGroup, CCard, CCardBody, CCardHeader, CCol, CCollapse, CForm, CFormInput,
    CFormLabel, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow
} from "@coreui/react";
import moment from "moment";

import DefaultPaginationWrapper from "../../../components/UI/Pagination/DefaultPaginationWrapper";
import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import FormGroup from "../../../components/UI/Forms/FormGroup";

import {Query} from "../../../functions";
// import {createUser, deleteUser, fetchUsers, updateUser} from "../../../store/actions/users";
// import {fetchUserGroups} from "../../../store/actions/userGroups";
// import {fetchRegions} from "../../../store/actions/regions";
// import {fetchDistricts} from "../../../store/actions/districts";
import {useTranslation} from "react-i18next";
import {
    useCreateUsersMutation,
    useLazyGetUsersQuery,
    useRemoveUsersMutation,
    useUpdateUsersMutation
} from "../../../store/users/users.api";
import makeQuery from "../../../functions/makeQuery";
import {useLazyGetGroupQuery} from "../../../store/group/group.api";
import {IGroups} from "../../../interfaces/IGroup";
import {useLazyGetRegionsQuery} from "../../../store/regions/regions.api";
import {useLazyGetDistrictsQuery} from "../../../store/districts/districts.api";

interface PaginationState {
    page_size: string; // Replace 'string' with the actual type of page_size
    search: string;    // Replace 'string' with the actual type of search
    page: number;       // Replace 'number' with the actual type of page
}

const Users = () => {
    const {t} = useTranslation();

    // const users = useSelector(state => state.users.users);
    const [fetchUsers, {isLoading, isError, data: users}] = useLazyGetUsersQuery();
    const [updateUsers] = useUpdateUsersMutation();
    const [createUser] = useCreateUsersMutation();
    const [deleteUser] = useRemoveUsersMutation();

    // const userGroups = useSelector(state => state.userGroups.userGroups);
    const [fetchUserGroups, {data: userGroups}] = useLazyGetGroupQuery();
    const [fetchRegions, {data: regions}] = useLazyGetRegionsQuery();
    const [fetchDistricts, {data: districts}] = useLazyGetDistrictsQuery();

    // const regions = useSelector(state => state.regions.regions);
    // const districts = useSelector(state => state.districts.districts);

    const [pagination, setPagination] = useState<PaginationState>({page_size: '100', search: '', page: 1});
    const [filter, setFilter] = useState({sort_by: '', direction: 'asc'});
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [editingUser, setEditingUser]: any = useState({});

    const [multiSelection, setMultiSelection]: any = useState({available: [], selected: []});
    const [selectedUserGroups, setSelectedUserGroups] = useState<IGroups[]>([]);
    const [regionsCollapsed, setRegionsCollapsed]: any = useState([]);
    const [checkedDistricts, setCheckedDistricts]: any = useState([]);
    const [error, setError]: any = useState(null);
    const countUsers = users?.results.length;

    useEffect(() => {
        let query = makeQuery.stringify({...pagination, ...filter});

        fetchUsers(query);
        fetchUserGroups(query);
        fetchRegions(query);
        fetchDistricts(query);
        // dispatch(fetchRegions());
        // dispatch(fetchDistricts());
        // eslint-disable-next-line
    }, []);

    const onChangeHandler = (event: any) => {
        const state: any = {...editingUser};
        state[event.target.name] = event.target.value;
        setEditingUser(state);
    };

    const openPasswordFormHandler = (event: any) => {
        event.preventDefault();
        const state = {...editingUser, password: '', confirmPassword: ''};
        setEditingUser(state);
    };

    const onChangeMultiselectHandler = (name: string, id: number) => {
        if (!name || !id) return null;
        const selected = Array.isArray(multiSelection[name]) ? [...multiSelection[name]] : [];
        if (selected.includes(id)) selected.splice(selected.findIndex(el => el === id), 1);
        else selected.push(id);
        setMultiSelection({...multiSelection, [name]: selected});
    }

    const onSelectUserGroups = (action: string) => {
        let state: any = [...selectedUserGroups];
        switch (action) {
            case 'add-all':
                // @ts-ignore
                state = userGroups?.map((el: any) => el.id);
                break;
            case 'remove-all':
                state = [];
                break;
            case 'add':
                multiSelection.available.forEach((id: number) => {
                    state.push(id)
                });
                break;
            case 'remove':
                multiSelection.selected.forEach((id: number) => {
                    state.splice(state.findIndex((el: any) => el === id), 1)
                });
                break;
            default:
                return null;
        }
        setMultiSelection({available: [], selected: []})
        setSelectedUserGroups(state);
    };

    const onCheckRegionHandler = (ids: any) => {
        const state: any = [...checkedDistricts];
        if (ids.length === 1) {
            if (state.includes(ids[0])) state.splice(state.findIndex((el: any) => el === ids[0]), 1);
            else state.push(ids[0]);
            setCheckedDistricts(state);
        } else {
            if (ids.every((el: any) => state.includes(el))) {
                ids.forEach((id: any) => {
                    state.splice(state.findIndex((el: any) => el === id), 1)
                })
            } else {
                ids.forEach((id: any) => {
                    if (!state.includes(id)) state.push(id)
                })
            }
        }
        setCheckedDistricts(state);
    };

    const onCollapseRegionHandler = (id: number) => {
        const state: any = [...regionsCollapsed];
        if (state.includes(id)) state.splice(state.findIndex((el: any) => el === id), 1);
        else state.push(id);
        setRegionsCollapsed(state);
    };

    const toggleEditModalHandler = (visible?: boolean, data?: any) => {
        if (data && data.id) setEditingUser(data);
        else {
            setTimeout(() => {
                setEditingUser({password: '', confirmPassword: ''});
            }, 200);
        }
        setError(null);
        setEditModalVisible(!!visible);
    };

    const toggleDeleteModalHandler = (visible?: boolean, data?: any) => {
        if (data && data.id) setEditingUser(data);
        else {
            setTimeout(() => {
                setEditingUser({});
            }, 200);
        }
        setError(null);
        setDeleteModalVisible(!!visible);
    };

    const onPaginationHandler = (state: any) => {
        let innerState = {...pagination, ...state};

        if(countUsers && countUsers <= Number(pagination.page_size)) {
            innerState = {
                page_size: pagination.page_size,
                search: pagination.search,
                page: 1
                , ...state}
        }

        setPagination(innerState);
        if (state.search === undefined) onSearchDataHandler({...innerState, ...filter});
    };

    const onFilterHandler = (field: string) => {
        const state = {...filter};
        if (field === state.sort_by) state.direction = state.direction === 'asc' ? 'desc' : 'asc';
        else {
            state.sort_by = field;
            state.direction = 'asc';
        }
        setFilter(state);
        onSearchDataHandler({...pagination, ...state});
    };

    const onSearchCallbackHandler = (search: string) => {
        onSearchDataHandler({...pagination, ...filter, search});
    };

    const onSearchDataHandler = (data: any) => {
        const query: any = {};
        if (data.page_size) query.page_size = data.page_size;
        if (data.page) query.page = data.page;
        if (data.search && data.search !== '') query.search = data.search;
        if (data.sort_by) query.sort_by = data.sort_by;
        if (data.direction === 'desc') query.direction = 'desc';

        fetchUsers(Query.stringify(query))
    }

    const onEditUserHandler = () => {
        const data: any = {};
        if (editingUser.id) data.id = editingUser.id;
        if (editingUser.username) data.username = editingUser.username;
        else {
            setError(`"${t('settings.users.userName')}" - ${t('Should not be empty')}`);
            return null;
        }
        if (editingUser.password) {
            if (editingUser.password === editingUser.confirmPassword) data.password = editingUser.password;
            else {
                setError(t('settings.users.Passwords dont match'));
                return null;
            }
        } else {
            if (editingUser.id && editingUser.hasOwnProperty('password')) {
                setError(`"${t('settings.users.password')}" - ${t('Should not be empty')}`);
                return null;
            }
            if (!editingUser.id) {
                setError(`"${t('settings.users.password')}" - ${t('Should not be empty')}`);
                return null;
            }
        }
        if (editingUser.first_name) data.first_name = editingUser.first_name || '';
        if (editingUser.last_name) data.last_name = editingUser.last_name || '';
        // if (Array.isArray(editingUser.groups)) data.groups = editingUser.groups;
        // if (Array.isArray(editingUser.regions)) data.regions = editingUser.regions;

        if (data.id) {
            updateUsers({body: data, id: data.id}).then(
                () => {
                    onPaginationHandler({});
                    toggleEditModalHandler();
                },
                () => {
                    setError('Something went wrong')
                }
            )
        } else {
            createUser(data).then(
                () => {
                    onPaginationHandler({});
                    toggleEditModalHandler();
                },
                () => {
                    setError('Something went wrong')
                }
            )
        }
    };

    const onDeleteUserHandler = () => {
        if (!editingUser.id) return null;
        deleteUser(editingUser.id).then(
            () => {
                onPaginationHandler({});
                toggleDeleteModalHandler();
            },
            () => {
                setError('Something went wrong')
            }
        )
        // dispatch(deleteUser(
        //     editingUser.id,
        //     () => {
        //         onPaginationHandler({});
        //         toggleDeleteModalHandler();
        //     },
        //     () => {
        //         setError('Something went wrong')
        //     }
        // ));
    };

    let usersList: any[] = [];
    try {
      // @ts-ignore
        usersList = users?.results?.map((el: any, idx: number) => {
            return (
                <CTableRow key={'user' + idx}>
                    <CTableDataCell>{el.username}</CTableDataCell>
                    <CTableDataCell>{el.last_name} {el.first_name}</CTableDataCell>
                    <CTableDataCell>{moment(el.created_at).format('DD-MM-YYYY HH:mm:ss')}</CTableDataCell>
                    <CTableDataCell className="text-center">
                        <CButtonGroup size="sm">
                            <CButton color="info" className="default-table__action-btn"
                                     onClick={() => toggleEditModalHandler(true, el)}>
                                <i className="fa fa-pencil-square-o color-white" aria-hidden="true"> </i>
                            </CButton>
                            <CButton color="danger" className="default-table__action-btn"
                                     onClick={() => toggleDeleteModalHandler(true, el)}>
                                <i className="fa fa-times color-white" aria-hidden="true"> </i>
                            </CButton>
                        </CButtonGroup>
                    </CTableDataCell>
                </CTableRow>
            )
        });
    } catch (e) {
    }

    let availableUserGroupsList: any[] = [], selectedUserGroupsList: any[] = [];
    try {
      // @ts-ignore
      userGroups?.forEach((el: any) => {
            const isSelected = (multiSelection.available.includes(el.id) || multiSelection.selected.includes(el.id))
            if (selectedUserGroups.includes(el.id))
                selectedUserGroupsList.push(
                    <div key={'userGroup' + el.id}
                         className={`multi-select-item${isSelected ? ' selected' : ''}`}
                         onClick={() => onChangeMultiselectHandler('selected', el.id)}
                    >
                        {el.name}
                    </div>
                );
            else availableUserGroupsList.push(
                <div key={'userGroup' + el.id}
                     className={`multi-select-item${isSelected ? ' selected' : ''}`}
                     onClick={() => onChangeMultiselectHandler('available', el.id)}
                >
                    {el.name}
                </div>);
        })
    } catch (e) {
    }

    let regionsList: any = [];
    try {
    // @ts-ignore
      regionsList = regions?.map((el: any) => ({
            ...el,
          // @ts-ignore
          districts: districts?.filter((d: any) => d.region === el.id)
        })).map((region: any) => {
            let checked = 0;
            const districts = region.districts.map((district: any) => {
                const isChecked = checkedDistricts.includes(district.id);
                if (isChecked) checked++;
                return (
                    <div key={'district' + district.id}>
                        <div className="district-wrapper" onClick={() => onCheckRegionHandler([district.id])}>
                            <i className={`fa check fa-${isChecked ? 'check-' : ''}square-o folder-icon`}/>
                            {district.coat_code} - {district.name}
                        </div>
                    </div>
                )
            });

            let faClassName = 'fa-square-o';
            if (checked) faClassName = 'fa-square';
            if (checked === region.districts.length) faClassName = 'fa-check-square-o';
            const visible = regionsCollapsed.includes(region.id);
            return (
                <div key={'region' + region.id} className="regions-collapse-wrapper">
                    <div className="regions-collapse">
                        <i className={`fa fa-caret-${visible ? 'down' : 'right'} caret-icon`}
                           onClick={() => onCollapseRegionHandler(region.id)}/>
                        <div className="region-wrapper"
                             onClick={() => onCheckRegionHandler(region.districts.map((el: any) => el.id))}>
                            <i className={'fa check ' + faClassName + ' folder-icon'}/>
                            {region.coat_code} - {region.name}
                        </div>
                    </div>
                    <CCollapse visible={visible}>
                        {districts}
                    </CCollapse>
                </div>
            )
        })
    } catch (e) {
    }

    return (
        <>
            <div className="pb-3 mb-4 title-divider">
                <h1>{t('settings.users.usersList')}</h1>
            </div>

            <div className="d-flex justify-content-md-end mb-3">
                <CButton size="sm"
                         color="success"
                         className="color-white"
                         onClick={() => toggleEditModalHandler(true)}
                >
                    {t('settings.users.addUser')}
                </CButton>
            </div>

            <DefaultPaginationWrapper data={users}
                                      state={pagination}
                                      onChange={onPaginationHandler}
                                      onSearchDelay={2000}
                                      onSearchCallback={onSearchCallbackHandler}
            >
                <CTable bordered striped className="default-table">
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell style={{width: '20%'}}
                                              scope="col"
                                              className="sorted-table-cell"
                                              onClick={() => onFilterHandler('username')}
                            >
                                <div className="d-flex justify-content-between">
                                    <span>{t('settings.users.userName')}</span>
                                    {filter.sort_by === 'username' ?
                                        <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                                        <i className="fa fa-sort" aria-hidden="true"/>
                                    }
                                </div>
                            </CTableHeaderCell>

                            <CTableHeaderCell style={{width: '50%'}}
                                              scope="col"
                                              className="sorted-table-cell"
                                              onClick={() => onFilterHandler('last_name')}
                            >
                                <div className="d-flex justify-content-between">
                                    <span>{t('settings.users.fullName')}</span>
                                    {filter.sort_by === 'last_name' ?
                                        <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                                        <i className="fa fa-sort" aria-hidden="true"/>
                                    }
                                </div>
                            </CTableHeaderCell>

                            <CTableHeaderCell style={{width: '17%'}}
                                              scope="col"
                                              className="sorted-table-cell"
                                              onClick={() => onFilterHandler('created_at')}
                            >
                                <div className="d-flex justify-content-between">
                                    <span>{t('settings.users.createdDate')}</span>
                                    {filter.sort_by === 'created_at' ?
                                        <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                                        <i className="fa fa-sort" aria-hidden="true"/>
                                    }
                                </div>
                            </CTableHeaderCell>

                            <CTableHeaderCell style={{width: '13%'}} scope="col" className="default-table__actions">
                                {t('settings.users.actions')}
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>

                    <CTableBody>
                        {usersList}
                    </CTableBody>
                </CTable>
            </DefaultPaginationWrapper>

            <DefaultModal visible={editModalVisible}
                          setVisible={toggleEditModalHandler}
                          title={t(`settings.users.${editingUser.id ? 'editUser' : 'addUser'}`)}
                          cancelButton={t('cancel')}
                          okButton={t('save')}
                          onOk={onEditUserHandler}
                          error={error}
                          size="xl"
            >
                <CForm>
                    <FormGroup htmlFor='username' label={t('settings.users.userName')}>
                        <CFormInput id="username"
                                    name="username"
                                    type="text"
                                    value={editingUser.username || ''}
                                    onChange={onChangeHandler}
                        />
                    </FormGroup>

                    {editingUser.hasOwnProperty('password') ?
                        <>
                            <FormGroup htmlFor='password' label={t('settings.users.password')}>
                                <CFormInput id="password"
                                            name="password"
                                            type="password"
                                            value={editingUser.password || ''}
                                            onChange={onChangeHandler}
                                />
                            </FormGroup>
                            <FormGroup htmlFor='confirmPassword' label={t('settings.users.confirmPassword')}
                                       className="mb-4">
                                <CFormInput id="confirmPassword"
                                            name="confirmPassword"
                                            type="password"
                                            value={editingUser.confirmPassword || ''}
                                            onChange={onChangeHandler}
                                />
                            </FormGroup>
                        </>
                        :
                        <CRow className="mb-3">
                            <CCol sm={12}>
                                <CFormLabel className="col-form-label">
                                    {t('settings.users.password')}:
                                    {/*eslint-disable-next-line*/}
                                    <a href="#" style={{marginLeft: '10px', fontStyle: 'italic'}}
                                       onClick={openPasswordFormHandler}>
                                        {t('settings.users.changePassword')}
                                    </a>
                                </CFormLabel>
                            </CCol>
                        </CRow>
                    }

                    <CCard className="mb-3">
                        <CCardHeader>
                            <div className="card-header-title">{t('settings.users.profile')}</div>
                        </CCardHeader>
                        <CCardBody>
                            <CCard>
                                <CCardBody style={{padding: '0.5rem 1rem'}}>
                                    <FormGroup htmlFor='first_name' label={t('settings.users.firstName')}
                                               className="mb-2">
                                        <CFormInput id="first_name"
                                                    name="first_name"
                                                    type="text"
                                                    value={editingUser.first_name || ''}
                                                    onChange={onChangeHandler}
                                        />
                                    </FormGroup>

                                    <FormGroup htmlFor='last_name' label={t('settings.users.lastName')}
                                               className="mb-2">
                                        <CFormInput id="last_name"
                                                    name="last_name"
                                                    type="text"
                                                    value={editingUser.last_name || ''}
                                                    onChange={onChangeHandler}
                                        />
                                    </FormGroup>
                                </CCardBody>
                            </CCard>
                        </CCardBody>
                    </CCard>

                    <CCard className="mb-3">
                        <CCardHeader>
                            <div className="card-header-title">{t('settings.users.groups')}</div>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol sm={5}>
                                    <CFormLabel className="col-form-label">
                                        {t('settings.users.available')}
                                    </CFormLabel>
                                </CCol>
                                <CCol sm={2}/>
                                <CCol sm={5}>
                                    <CFormLabel className="col-form-label">
                                        {t('settings.users.selected')}
                                    </CFormLabel>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm={5}>
                                    <div className="multi-select-list">
                                        {availableUserGroupsList}
                                    </div>
                                </CCol>
                                <CCol sm={2} className="button-group-vertical button-group-vertical--navigation">
                                    <CButton size="sm"
                                             color="info"
                                             className="color-white non-outline"
                                             onClick={() => onSelectUserGroups('add-all')}
                                    >
                                        <i className="fa fa-forward" aria-hidden="true"/>
                                    </CButton>
                                    <CButton size="sm"
                                             color="info"
                                             className="color-white non-outline"
                                             onClick={() => onSelectUserGroups('add')}
                                    >
                                        <i className="fa fa-chevron-right" aria-hidden="true"/>
                                    </CButton>
                                    <CButton size="sm"
                                             color="info"
                                             className="color-white non-outline"
                                             onClick={() => onSelectUserGroups('remove')}
                                    >
                                        <i className="fa fa-chevron-left" aria-hidden="true"/>
                                    </CButton>
                                    <CButton size="sm"
                                             color="info"
                                             className="color-white non-outline"
                                             onClick={() => onSelectUserGroups('remove-all')}
                                    >
                                        <i className="fa fa-backward" aria-hidden="true"/>
                                    </CButton>
                                </CCol>
                                <CCol sm={5}>
                                    <div className="multi-select-list">
                                        {selectedUserGroupsList}
                                    </div>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>

                    <CCard className="mb-3">
                        <CCardHeader>
                            <div className="card-header-title">{t('settings.users.regions')}</div>
                        </CCardHeader>
                        <CCardBody>
                            {regionsList}
                        </CCardBody>
                    </CCard>
                </CForm>
            </DefaultModal>

            <DefaultModal visible={deleteModalVisible}
                          setVisible={toggleDeleteModalHandler}
                          type="danger"
                          title={t('settings.users.deleteUser')}
                          cancelButton={t('cancel')}
                          okButton={t('delete')}
                          onOk={onDeleteUserHandler}
            >
                <p>{t('settings.users.Do you really want to delete a user?')}</p>
                <p><strong>{editingUser.username}</strong></p>
            </DefaultModal>
        </>
    );
};

export default Users;

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {ILocality, ILocalityResponse} from "../../interfaces/ILocalities";

export const localitiesApi = createApi({
  reducerPath: 'localities/api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers) => headerConfig(headers),
  }),
  tagTypes: ['Localities'],
  endpoints: build => ({
    getLocalities: build.query<ILocalityResponse[], string | void>({
      query: (query) => {
        return {
          url: `/list/locality/${query || ''}`
        }
      },
      providesTags: ['Localities'],
    }),
    createLocality: build.mutation<any, ILocality>({
      query: (body) => ({
        url: '/create/locality/',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Localities'],
    }),
    updateLocality: build.mutation<any, Partial<any> & Pick<any, 'id'>>({
      query: ({id, body}) => {
        return {
          url: `/update/locality/${id}/`,
          method: 'PUT',
          body
        }
      },
      invalidatesTags: ['Localities'],
    }),
    removeLocality: build.mutation<{ success: boolean; id: number }, number>({
      query: (id) => ({
        url: `/delete/locality/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Localities'],
    })
  })
});

export const {useGetLocalitiesQuery, useLazyGetLocalitiesQuery, useCreateLocalityMutation, useUpdateLocalityMutation, useRemoveLocalityMutation} = localitiesApi;

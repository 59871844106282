import React, {FC} from 'react';
import {CFormLabel} from "@coreui/react";
import Select, {StylesConfig} from "react-select";

import {IState} from "./EsFeed";
import {IRegionResponse} from "../../../interfaces/IRegions";

interface IFilterBlock {
  regions: IRegionResponse[]
  onSelect: (e: any, name: string) => void
  state: IState
  t: (value: string) => string
}

const FilterBlock: FC<IFilterBlock> = ({regions, onSelect, state, t}) => {

  let regionsOptions, reportTypeOptions, statusOptions, reportStatusOptions

  if (regions?.length) {
    regionsOptions = regions.map(el => {
      return {label: el.coat_code + ' - ' + el.name, value: String(el.id)}
    })
  }

  reportTypeOptions = [
    {label: t('emergency.incident'), value: 'incident'},
    {label: t('emergency.emergency'), value: 'emergency'},
    {label: t('emergency.other'), value: 'other'},
  ]

  statusOptions = [
    {label: t('esFeed.inProcess'), value: 'in processing'},
    {label: t('esFeed.completed'), value: 'completed'},
  ]

  reportStatusOptions = [
    {label: t('esFeed.informationWithDelay'), value: 'true'},
    {label: t('esFeed.informationWithoutDelay'), value: 'false'},
  ]

  const selectStyles: StylesConfig<any> = {
    control: (styles) => (
      {
        ...styles,
        backgroundColor: '#ffffff',
        border: 0,
        boxShadow: 'none',
        borderRadius: '6px',
        maxHeight: 31,
        fontSize: '14px'
      }
    )
  };

  return (
    <div className="filter-block-wrapper">
      <div style={{width: '25%'}}>
        <CFormLabel>{t("settings.regions.region")}</CFormLabel>
        <Select options={regionsOptions}
                value={regionsOptions?.find(el => el.value === state.region)}
                styles={selectStyles}
                placeholder={t('All')}
                onChange={e => onSelect(e, 'region')}
                isClearable
        />
      </div>
      <div style={{width: '25%', marginLeft: '20px'}}>
        <CFormLabel>{t("emergency.typeOfReport")}</CFormLabel>
        <Select options={reportTypeOptions}
                value={reportTypeOptions?.find(el => el.value === state.reportType)}
                styles={selectStyles}
                placeholder={t('All')}
                onChange={e => onSelect(e, 'reportType')}
                isClearable
        />
      </div>
      <div style={{width: '25%', marginLeft: '20px'}}>
        <CFormLabel>{t("emergency.status")}</CFormLabel>
        <Select options={statusOptions}
                value={statusOptions?.find(el => el.value === state.status)}
                styles={selectStyles}
                placeholder={t('All')}
                onChange={e => onSelect(e, 'status')}
                isClearable
        />
      </div>
      <div style={{width: '25%', marginLeft: '20px'}}>
        <CFormLabel>{t("emergency.reportStatus")}</CFormLabel>
        <Select options={reportStatusOptions}
                value={reportStatusOptions?.find(el => el.value === state.reportStatus)}
                styles={selectStyles}
                placeholder={t('All')}
                onChange={e => onSelect(e, 'reportStatus')}
                isClearable
        />
      </div>
    </div>
  );
};

export default FilterBlock;
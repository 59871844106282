import React from 'react'

// const Home = React.lazy(() => import('./views/home/Home'));
const EsFeed = React.lazy(() => import('./views/EsManagement/EsFeed/EsFeed'));
const EsBase = React.lazy(() => import('./views/EsManagement/EsBase/EsBase'));
const EsDailyReports = React.lazy(() => import('./views/EsManagement/EsDailyReports/EsDailyReports'));
const EsDailyReportsNew = React.lazy(() => import('./views/EsManagement/EsDailyReports/EsDailyReportNew'));
const NewEsReport = React.lazy(() => import('./views/EsManagement/NewEsReport/NewEsReport'));
const EsMap = React.lazy(() => import('./views/EsManagement/EsMap/EsMap'));
const PublicEsReports = React.lazy(() => import('./views/EsManagement/PublicEsReports/PublicEsReports'));
const RejectedPublicEsReports = React.lazy(() => import('./views/EsManagement/PublicEsReports/RejectedPublicEsReports'));
const CardsFrom112 = React.lazy(() => import('./views/EsManagement/CardsFrom112/CardsFrom112'));
const EsGeneralInformation = React.lazy(() => import('./views/EsManagement/EsGeneralInformation/EsGeneralInformation'));
const Es1Form = React.lazy(() => import('./views/EsManagement/Es1Form/Es1Form'));
const GeoBase = React.lazy(() => import('./views/GIS/GeoBase/GeoBase'));
const GeoObjects = React.lazy(() => import('./views/GIS/GeoObjectsManagement/GeoObjects'));
const GeoCategories = React.lazy(() => import('./views/GIS/GeoObjectsManagement/GeoCategories'));
const GeoTypes = React.lazy(() => import('./views/GIS/GeoObjectsManagement/GeoTypes'));
const ObjectsImport = React.lazy(() => import('./views/GIS/ObjectsImport/ObjectsImport'));
const ObjectsExport = React.lazy(() => import('./views/GIS/ObjectExport/ObjectExport'));
const DocumentTypes = React.lazy(() => import('./views/Registry/Documents/DocumentTypes'));
const Documents = React.lazy(() => import('./views/Registry/Documents/Documents'));
const Document = React.lazy(() => import('./views/Registry/Documents/Document'));
const Dictionaries = React.lazy(() => import('./views/Registry/Dictionaries/Dictionaries'));
const NewDictionary = React.lazy(() => import('./views/Registry/Dictionaries/NewDictionary'));
const Reports = React.lazy(() => import('./views/Registry/Reports/Reports'));
const NewReport = React.lazy(() => import('./views/Registry/Reports/NewReport'));
const Analytics = React.lazy(() => import('./views/Analytics/Analytics'));
const NewAnalytic = React.lazy(() => import('./views/Analytics/AnalyticsTemplate'));
const VideoStreams = React.lazy(() => import('./views/VideoStreams/VideoStreams/VideoStreams'));
const ChannelsWall = React.lazy(() => import('./views/VideoStreams/ChannelsWall/ChannelsWall'));
const ChannelsWallSettings = React.lazy(() => import('./views/VideoStreams/ChannelsWallSettings/ChannelsWallSettings'));
const ListOfServices = React.lazy(() => import('./views/Services/ListOfServices/ListOfServices'));
const SingleServiceInfo = React.lazy(() => import('./views/Services/ListOfServices/SingleServiceInfo'));
const Users = React.lazy(() => import('./views/Settings/Users/Users'));
const GroupsList = React.lazy(() => import('./views/Settings/Users/GroupsList'));
const AuditLog = React.lazy(() => import('./views/Settings/AuditLog/AuditLog'));
const EsTypes = React.lazy(() => import('./views/Settings/EsTypes/EsTypes'));
const UserManual = React.lazy(() => import('./views/Settings/UserManual/UserManual'));
const GeoIcons = React.lazy(() => import('./views/Settings/GeoIcons/GeoIcons'));
const BrowserPolicy = React.lazy(() => import('./views/Settings/BrowserPolicy/BrowserPolicy'));
const GeneralSettings = React.lazy(() => import('./views/Settings/GeneralSettings/GeneralSettings'));
const Regions = React.lazy(() => import('./views/Settings/Regions/Regions'));
const VersionHistory = React.lazy(() => import('./views/Settings/VersionHistory/VersionHistory'));
const PublicMobileAdmin = React.lazy(() => import('./views/PublicMobileAdmin/PublicMobileAdmin'));
const EarthMap = React.lazy(() => import('./views/EarthMap/EarthMap'));
const RIS = React.lazy(() => import('./views/RIS/RIS'));
const Profile = React.lazy(() => import('./views/Profile/Profile'));

const routes = [
    // { path: '/', exact: true, name: 'Home', element: Home },
    { path: '/es-feed', exact: true, name: 'ES feed', element: EsFeed },
    { path: '/es-base', exact: true, name: 'ES base', element: EsBase },
    { path: '/es-daily-reports/list', exact: true, name: 'List of ES daily reports', element: EsDailyReports },
    { path: '/es-daily-reports/new', exact: true, name: 'New ES daily report', element: EsDailyReportsNew },
    { path: '/es-report-new', exact: true, name: 'New ES report', element: NewEsReport },
    { path: '/es-map', exact: true, name: 'ES map', element: EsMap },
    { path: '/es-public-reports/reports', exact: true, name: 'Public ES reports', element: PublicEsReports },
    { path: '/es-public-reports/rejected', exact: true, name: 'Rejected public ES reports', element: RejectedPublicEsReports },
    { path: '/es-cards-from112', exact: true, name: 'Cards from 112', element: CardsFrom112 },
    { path: '/es-general', exact: true, name: 'ES general information', element: EsGeneralInformation },
    { path: '/es-es1-report-form', exact: true, name: 'ES1 form', element: Es1Form },
    { path: '/gis-geo-base', exact: true, name: 'Geo base', element: GeoBase },
    { path: '/gis-geo-objects/list', exact: true, name: 'List of all geo objects', element: GeoObjects },
    { path: '/gis-geo-categories/list', exact: true, name: 'List of all geo categories', element: GeoCategories },
    { path: '/gis-geo-types/list', exact: true, name: 'List of all types of geo objects', element: GeoTypes },
    { path: '/gis-objects-import', exact: true, name: 'Import', element: ObjectsImport },
    { path: '/gis-objects-export', exact: true, name: 'Export', element: ObjectsExport },
    { path: '/registry-document-types', exact: true, name: 'List of document types', element: DocumentTypes },
    { path: '/registry-document-types/:id', exact: true, name: 'List of documents', element: Documents },
    { path: '/registry-document/:id', exact: true, name: 'Document', element: Document },
    { path: '/registry-dictionaries/list', exact: true, name: 'List of dictionaries', element: Dictionaries },
    { path: '/registry-dictionaries/new', exact: true, name: 'New dictionary', element: NewDictionary },
    { path: '/registry-reports/list', exact: true, name: 'List of reports', element: Reports },
    { path: '/registry-reports/new', exact: true, name: 'New report', element: NewReport },
    { path: '/analytic-templates', exact: true, name: 'List of analytic templates', element: Analytics },
    { path: '/analytic-templates-new', exact: true, name: 'New analytic template', element: NewAnalytic },
    { path: '/services', exact: true, name: 'List of services', element: ListOfServices },
    { path: '/services/:id', exact: true, name: 'Service info', element: SingleServiceInfo },
    { path: '/video-streams', exact: true, name: 'Video streams', element: VideoStreams },
    { path: '/channels-wall', exact: true, name: 'Channels wall', element: ChannelsWall },
    { path: '/channels-wall-settings', exact: true, name: 'Channels wall settings', element: ChannelsWallSettings },
    { path: '/users/list', exact: true, name: 'Users list', element: Users },
    { path: '/users/groups-list', exact: true, name: 'Groups list', element: GroupsList },
    { path: '/audit-log', exact: true, name: 'Audit log', element: AuditLog },
    { path: '/es-types', exact: true, name: 'ES types', element: EsTypes },
    { path: '/user-manual', exact: true, name: 'User manual', element: UserManual },
    { path: '/geo-icons', exact: true, name: 'Geo icons', element: GeoIcons },
    { path: '/browser-policy', exact: true, name: 'Browser policy', element: BrowserPolicy },
    { path: '/general-settings', exact: true, name: 'General settings', element: GeneralSettings },
    { path: '/regions', exact: true, name: 'Regions', element: Regions },
    { path: '/version-history', exact: true, name: 'Version history', element: VersionHistory },
    { path: '/public-mobile-admin', exact: true, name: 'Public app', element: PublicMobileAdmin },
    { path: '/earth-map', exact: true, name: 'Earth map', element: EarthMap },
    { path: '/ris', exact: true, name: 'RIS', element: RIS },
    { path: '/profile', exact: true, name: 'Profile', element: Profile },
];

export const getPathByName = (name: string) => {
    const idx = routes.findIndex(el => el.name === name);
    if (idx < 0) throw Error(`The route named as "${name}" was not found. Please check requested route name or check it in routes config file.`);
    return routes[idx].path;
};

export const getRouteByName = (name: string) => {
    const idx = routes.findIndex(el => el.name === name);
    if (idx < 0) throw Error(`The route named as "${name}" was not found. Please check requested route name or check it in routes config file.`);
    return routes[idx];
};

export default routes;

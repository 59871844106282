import React, { useState, useEffect } from 'react';
import {CContainer, CNavItem} from "@coreui/react";
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { AppSidebar, AppHeader } from '../components/index';
import { nav } from '../_nav';
import { getPathByName } from "../routes";
import {useTranslation} from "react-i18next";
import {useRefreshAuthMutation} from "../store/auth/auth.api";
import {useGetServicesQuery} from "../store/services/services.api";
import {useGetInfoUserQuery, useLazyGetInfoUserQuery} from "../store/account/account.api";

// import { authRefresh } from "../store/actions/auth";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"> </div>
    </div>
);

const DefaultLayout = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [authRefresh, {isError,  isLoading, data: auth}] = useRefreshAuthMutation();
    const {data: services} = useGetServicesQuery();
    const {data: me, isLoading: getMeLoading, isError: getMeError} = useGetInfoUserQuery();
    const [getMe] = useLazyGetInfoUserQuery();
    const [refreshAuth, {isError: refreshError, isLoading: refreshLoading, data: refreshData}] = useRefreshAuthMutation();

    // const auth = useSelector(state => state.auth);
    // const services = useSelector(state => state.services.services);

    const [index, setIndex]: any = useState(0);
    const [sidebarIsCollapsed, setSidebarIsCollapsed]: any = useState(false);
    const [dynamicNav, setDynamicNav]: any = useState(nav);

    const customProps = {
        hasPermission: (perm: any) => {
            return true;
            try { if (!auth.permissions || !auth.permissions.length) return false }
            catch (e) { return false }
            return !perm || auth.permissions.includes(perm);
        },
        hasOneOfPermissions: (perms: any) => {
            return true;
            try { if (!auth.permissions || !auth.permissions.length) return false }
            catch (e) { return false }
            if (!perms || !Array.isArray(perms) || !perms.length) return true;
            for (let i = 0; i < perms.length; i++) {
                if (auth.permissions.includes(perms[i])) return true;
            }
            return false;
        }
    };

    const meIsPresent = (!getMeLoading && !getMeError && me);
    // const meIsPresent = (!getMeLoading && !getMeError && me && !refreshError && !refreshLoading && refreshData);

    useEffect(() => {
        let lsData: any = '';
        try {
            lsData = JSON.parse(localStorage.getItem('getMe') || '');
        } catch (e) {}
        if (!me && lsData?.refresh) {
            refreshAuth({refresh: lsData.refresh}).then((res: any) => {
                if (res?.data?.access) {
                    lsData.access = res.data.access;
                    localStorage.setItem('getMe', JSON.stringify(lsData));
                    getMe();
                } else {
                    localStorage.setItem('getMe', '');
                    navigate('/');
                }
            });
        }
    }, []);

    useEffect(() => {
        if (!getMeLoading && (getMeError || !me)) navigate('/');
    }, [getMeLoading, getMeError, me]);

    // useEffect(() => {
    //   if (auth.access && auth.refresh) {
    //     dispatch(authRefresh(
    //       auth.refresh,
    //       token => {
    //         if (!token) navigate('/login');
    //       },
    //       () => navigate('/login')
    //     ))
    //   } else {
    //     navigate('/login');
    //   }
    //   // eslint-disable-next-line
    // }, []);

    useEffect(() => {
        let currentIndex = 0, pathname = location.pathname;
        const currentNav: any[] = [...nav];

        if (location.pathname !== '/') {
            const path = '/services';
            if (pathname.includes(path)) {
                try {
                    // @ts-ignore
                    const items = services?.map((el: any) => ({
                        component: CNavItem,
                        name: el.service_name + 'withoutTranslate',
                        to: path + '/' + el.id,
                        icon: <i className="fa fa-globe nav-icon" aria-hidden="true"> </i>,
                        permissions: [],
                    }));
                    const idx = currentNav.findIndex(el => el.name === 'Services');
                    const itemsIdx = currentNav[idx].items.findIndex((el: any) => el.name === 'Available services');
                    currentNav[idx].items[itemsIdx].items = items;
                } catch (e) {}
            }
            setDynamicNav(currentNav);

            currentNav.forEach((el, idx) => {
                const findPath = (items: any) => {
                    items.forEach((item: any) => {
                        if (item.to === pathname) currentIndex = idx;
                        if (item.items) findPath(item.items);
                    })
                }
                if (el.items) findPath(el.items);
                else if (el.to === pathname) currentIndex = idx;
            });
            if (pathname.includes(getPathByName('List of services') + '/')) currentIndex = currentNav.findIndex(el => el.name === 'Services');
            if (pathname.includes(getPathByName('Video streams') + '/')) currentIndex = currentNav.findIndex(el => el.name === 'Video streams');

            // Исключения для query params id
            if (pathname.includes('/registry-document-types/')) currentIndex = currentNav.findIndex(el => el.name === 'Registry');
            if (pathname.includes('/registry-document/')) currentIndex = currentNav.findIndex(el => el.name === 'Registry');
            if (pathname.includes('/gis-geo-object/')) currentIndex = currentNav.findIndex(el => el.name === 'GIS');
            if (pathname.includes('/es-report/update/')) currentIndex = currentNav.findIndex(el => el.name === 'ES management');
            if (pathname.includes('/assignments-main')) currentIndex = currentNav.findIndex(el => el.name === 'Assignments');
            if (pathname.includes('/es-base/detail/')) currentIndex = currentNav.findIndex(el => el.name === 'ES management');
            if (pathname.includes('/analytic-templates/update/')) currentIndex = currentNav.findIndex(el => el.name === 'Analytics');
            if (pathname.includes('/analytic/report/')) currentIndex = currentNav.findIndex(el => el.name === 'Analytics');

        }
        setIndex(currentIndex);
    }, [location.pathname, services]);

    const sidebarCollapseHandler = (state: any) => {
        if (state !== sidebarIsCollapsed) setSidebarIsCollapsed(state);
    };

    const handlePickMenuItem = (index: number) => {
        setIndex(index);
    };

    const headerMenu: any[] = [];
    dynamicNav.forEach((el: any) => {
        if (customProps.hasOneOfPermissions(el.permissions))
            headerMenu.push({name: t('nav.' + el.name), path: el.to});
    });

    const sidebarItemTranslate = (item: any) => {
        const name = item.name.substr(0, item.name.indexOf('withoutTranslate'));
        const result = {...item, name: name || t('nav.' + item.name)};
        if (result.items) result.items = result.items.map((el: any) => sidebarItemTranslate(el));
        return result;
    };

    const sidebarMenu: any[] = [];
    if (dynamicNav[index].items) {
        dynamicNav[index].items.forEach((el: any) => {
            if (customProps.hasOneOfPermissions(el.permissions))
                sidebarMenu.push(sidebarItemTranslate(el));
        });
    }

    if (!meIsPresent) return <div>{loading}</div>;

    return (
        <div>
            {!!sidebarMenu.length &&
                <AppSidebar
                            sidebarMenu={sidebarMenu}
                            sidebarIsCollapsed={sidebarIsCollapsed}
                            sidebarCollapseHandler={sidebarCollapseHandler}
                />
            }
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader
                           crumbs={!!sidebarMenu.length}
                           headerMenu={headerMenu}
                           currentIndex={index}
                           click={handlePickMenuItem}
                           sidebarIsCollapsed={sidebarIsCollapsed}
                />
                <div className="body flex-grow-1 px-3 pt-4" style={{position: 'relative'}}>
                    <CContainer fluid>
                        <React.Suspense fallback={loading}>
                            <Outlet/>
                        </React.Suspense>
                    </CContainer>
                </div>
            </div>
        </div>
    )
}

export default DefaultLayout;

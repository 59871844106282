export const hours: string[] = [
  '00','01','02','03','04','05','06','07','08','09','10','11',
  '12','13','14','15','16','17','18','19','20','21','22','23'
];

export const minutes: string[] = [
  '00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19',
  '20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39',
  '40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59',
];

export const presetRanges: string[] = [
  'Today',
  'Yesterday',
  'Last 7 days',
  'Last 30 days',
  'Current month',
  'Previous month',
  'Another range'
];

export function stringTale (str: any, factor: number = 2) {
  return str.toString().substring(str.toString().length - factor);
}

export function getFormattedDate (date: any) {
  try {
    return date.getFullYear() + stringTale(`0${date.getMonth()}`) + stringTale(`0${date.getDate()}`);
  } catch (e) {
    return '';
  }
}

export function getInputFormattedDateTime (date: any, hours: any, minutes: any) {
  try {
    return stringTale(`0${date.getDate()}`) + '.' + stringTale(`0${date.getMonth() + 1}`) + '.' + date.getFullYear() +
      ' ' + stringTale(`0${hours}`) + ':' + stringTale(`0${minutes}`);
  } catch (e) {
    return '';
  }
}

export function inputDateTimeValidate (data: any) {
  try {
    let date: string = '', time: string = '';
    data.split(' ').forEach((el: any) => {
      if (el) {
        if (!date) date = el;
        else if (!time) time = el;
      }
    });
    const d = new Date(date.split('.').reverse().join('.') + ' ' + time);
    const result = stringTale(`0${d.getDate()}`) + '.' + stringTale(`0${d.getMonth() + 1}`) + '.' + d.getFullYear() +
      ' ' + stringTale(`0${d.getHours()}`) + ':' + stringTale(`0${d.getMinutes()}`);
    if (result.indexOf('N') >= 0) return '';
    return result;
  } catch (e) {
    return '';
  }
}

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";

export const geoObjImagesApi = createApi({
    reducerPath: 'geoObjImages/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['GeoObjImages'],
    endpoints: build => ({
        uploadGeoObjImage: build.mutation<any, FormData>({
            query: (body) => ({
                url: '/geoobject/images/create/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['GeoObjImages'],
        }),
        removeGeoObjImage: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/geoobject/images/delete/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['GeoObjImages'],
        })
    })
})

export const {
    useUploadGeoObjImageMutation,
    useRemoveGeoObjImageMutation
} = geoObjImagesApi;
import React, {FC, useTransition} from 'react';
import {useTranslation} from "react-i18next";

interface iPaginationStatus {
  from: string | number;
  to: string | number;
  of: string | number;
}

const PaginationStatus: FC<iPaginationStatus> = ({from = '', to = '', of = ''}) => {
  const { t } = useTranslation();

  return (
    <div>
      {t("pagination.show")} {from} - {to} {t("pagination.from")} {of} {t("pagination.entries")}
    </div>
  );
};

export default PaginationStatus;
import { useDroppable } from '@dnd-kit/core';
import {useTranslation} from "react-i18next";
import {
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';

import TaskItem from './TaskItem';
import SortableTaskItem from './SortableTaskItem';
import { IAssignmentsList } from '../../../../interfaces/IAssignments';
import '../../../../scss/_drag_n_drop.scss';

type BoardSectionProps = {
  id: string;
  title: string;
  tasks: IAssignmentsList[];
  onclick: (id: number | string) => void
};

const BoardSection = ({ id, title, tasks, onclick }: BoardSectionProps) => {
  const {t} = useTranslation();
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <div className='board-block_column'>
        <div className='board-block_title'>
          <h3>{t(`dragNdrop.${title}`)}</h3>
        </div>
        <div>
          <SortableContext
            id={id}
            items={tasks}
            strategy={horizontalListSortingStrategy}
          >
            <div ref={setNodeRef} className='board-block_column_content'>
              {tasks && tasks.map((task) => task && (
                <div key={task.id} onClick={() => onclick(task.id)}>
                  <SortableTaskItem id={task.id}>
                    <TaskItem task={task} />
                  </SortableTaskItem>
                </div>
              ))}
            </div>
          </SortableContext>
      </div>
  </div>
  );
};

export default BoardSection;

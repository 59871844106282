// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-line {
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
  border-radius: 3px;
  background: transparent;
  box-shadow: none;
}
.loader-line--visible {
  /*background: #0c549c linear-gradient(#82add1, #0c609c);*/
  /*box-shadow: inset #2666de 0 -1px 1px, inset 0 1px 1px #98ccff, #3c80aa 0 0 0 1px;*/
  background: #0c9c0d linear-gradient(#82d18d, #0c9c0d);
  box-shadow: inset #72de26 0 -1px 1px, inset 0 1px 1px #98ff98, #3caa3c 0 0 0 1px;
}
.loader-line__flash {
  position: absolute;
  width: 100px;
  height: 100%;
  background: transparent;
  animation: flash-move 3s ease-in-out infinite;
}
.loader-line--visible .loader-line__flash {
  background: linear-gradient(90deg, rgba(12,156,13,0) 0%, rgba(255,255,255,0.9) 45%, rgba(255,255,255,0.9) 55%, rgba(12,156,13,0) 100%);
}

@keyframes flash-move {
  from {
    left: -100px
  }
  to {
    left: 100%
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/preloaders/LoaderLine/LoaderLine.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,yBAAiB;UAAjB,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,yDAAyD;EACzD,oFAAoF;EACpF,qDAAqD;EACrD,gFAAgF;AAClF;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,uBAAuB;EACvB,6CAA6C;AAC/C;AACA;EACE,sIAAsI;AACxI;;AAEA;EACE;IACE;EACF;EACA;IACE;EACF;AACF","sourcesContent":[".loader-line {\n  position: relative;\n  overflow: hidden;\n  user-select: none;\n  border-radius: 3px;\n  background: transparent;\n  box-shadow: none;\n}\n.loader-line--visible {\n  /*background: #0c549c linear-gradient(#82add1, #0c609c);*/\n  /*box-shadow: inset #2666de 0 -1px 1px, inset 0 1px 1px #98ccff, #3c80aa 0 0 0 1px;*/\n  background: #0c9c0d linear-gradient(#82d18d, #0c9c0d);\n  box-shadow: inset #72de26 0 -1px 1px, inset 0 1px 1px #98ff98, #3caa3c 0 0 0 1px;\n}\n.loader-line__flash {\n  position: absolute;\n  width: 100px;\n  height: 100%;\n  background: transparent;\n  animation: flash-move 3s ease-in-out infinite;\n}\n.loader-line--visible .loader-line__flash {\n  background: linear-gradient(90deg, rgba(12,156,13,0) 0%, rgba(255,255,255,0.9) 45%, rgba(255,255,255,0.9) 55%, rgba(12,156,13,0) 100%);\n}\n\n@keyframes flash-move {\n  from {\n    left: -100px\n  }\n  to {\n    left: 100%\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

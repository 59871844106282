import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {CButton, CFormLabel} from "@coreui/react";
import Select, {StylesConfig} from "react-select";
import {useGetGeoTypesQuery} from "../../../store/geoTypes/geoTypes.api";

const ObjectExport = () => {
    const {t} = useTranslation();
    const {data: geoTypes} = useGetGeoTypesQuery();

    const [state, setState]: any = useState(null);

    const colourStyles: StylesConfig<any> = {
        control: (styles) => (
            {
                ...styles,
                backgroundColor: '#ffffff',
                border: 0,
                boxShadow: 'none',
                borderRadius: '6px',
                maxHeight: 31,
                fontSize: '14px'
            }
        )
    };

    const handleSelect = (e: any) => {
        if (e === null) {
            setState(null);
            return;
        }

        const selectedType = geoTypes?.results?.find((type: any) => String(type.id) === e.value);
        setState(selectedType);
    };

    const download = (e: any) => {
        e.preventDefault();
        if (!state) return;

        const jsonData = JSON.stringify(state);
        const blob = new Blob([jsonData], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        link.download = `${state?.type_name}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const geoTypesOptions = geoTypes?.results?.map((type: any) => {
        return {label: type.type_name, value: String(type.id)};
    });

    return (
        <>
            <p className="import-export-title">{t('nav.Export')}</p>
            <div className="mb-3">
                <CFormLabel className="main-label">{t('nav.typeName')}</CFormLabel>
                <Select
                    options={geoTypesOptions}
                    styles={colourStyles}
                    placeholder={t('nav.typeSelected')}
                    onChange={handleSelect}
                    isClearable
                />
            </div>
            <CButton className="btn-info btn-exp-imp" size="sm" disabled={!state}  onClick={download}>{t('nav.typeBtnDownload')}</CButton>
        </>
    );
};

export default ObjectExport;

import React from 'react';

const RejectedPublicEsReports = () => {
  return (
    <div>
      Rejected public ES reports
    </div>
  );
};

export default RejectedPublicEsReports;

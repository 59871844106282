import config from './config';
import htmlToDraft from "html-to-draftjs";
import draftToHtml from 'draftjs-to-html';
import {ContentState, convertToRaw, EditorState} from "draft-js";

export function EditorStateToHtml (state: any) {
  if (!state) return '';
  return draftToHtml(convertToRaw(state.getCurrentContent()));
}

export function htmlToEditorState (html: any) {
  if (!html) return '';
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return EditorState.createWithContent(contentState);
}

// export function getTextFromHtmlString (string: string) {
//   return htmlToEditorState(string).getCurrentContent().getPlainText();
// }

export const Query = {
  parse: function (query: string) {
    const str = query[0] === '?' ? query.substr(1) : query;
    const res: any = {};
    str.split('&').forEach(el => {
      const tmp = el.split('=');
      if (tmp.length === 2) res[tmp[0]] = tmp[1];
    });
    return res;
  },
  stringify: function (data: any) {
    return Object.keys(data).length ? ('?' + Object.keys(data).map(key => typeof data[key] === 'object' ?
      Object.keys(data[key]).map(k => key + '[' + k + ']=' + data[key][k]).join('&') :
      key + '=' + data[key]).join('&')) : '';
  }
};

export const Numb = {
  merge: function (number: any) {
    return number.replace(/\D+/g, '');
  },
  split: function (number: any, factor: number = 3, symbol: string = ' ') {
    let str = '', numberStr = number.toString();
    if (!factor || factor <= 0) return numberStr;
    while (numberStr.length) {
      if (numberStr.length > factor) {
        str = symbol.toString() + numberStr.substr(numberStr.length - factor, numberStr.length) + str;
        numberStr = numberStr.substr(0, numberStr.length - factor);
      } else {
        str = numberStr + str;
        numberStr = '';
      }
    }
    return str;
  }
};

export function sortArrayOfObjectsByKey (array: any[], key: string, reverse: boolean = false) {
  const arr = [...array];
  const result = array.map(el => el[key]).sort()
    .map(value => arr.splice(arr.findIndex(el => el[key] === value), 1)[0]);
  return reverse ? result.reverse() : result;
}

export function fileNameSeparation (data: any) {
  const res = { name: '', link: '' };
  if (!data || !data.link) return res;
  res.name = data.name || data.link.substr(data.link.indexOf('/') + 1);
  if (data.link.indexOf('http') >= 0) res.link = data.link;
  else res.link = config.storageUrl + data.link;
  return res;
}

export function prepareFormData (data: any, name: string, acc: any) {
  const res: any = acc || [];
  if (typeof data === 'object')
    if (Object.keys(data).length === 0) res.push({key: name || '', value: data});
    else Object.keys(data).forEach(key => prepareFormData(data[key], name ? `${name}[${key}]` : key, res));
  else res.push({key: name || '', value: data});
  return res;
}

export function getFormData (object: any) {
  const formData = new FormData();
  Object.keys(object).forEach(key => formData.append(key, object[key]));
  return formData;
}

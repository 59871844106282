import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {CButton, CFormCheck, CFormInput, CFormLabel} from "@coreui/react";
import makeQuery from "../../../functions/makeQuery";
import {
    useGetEmergencyTypesQuery,
    useLazyGetEmergencyTypesQuery
} from "../../../store/emergencyTypes/emergencyTypes.api";
import {
    useLazyGetEsFeedGeomQuery,
} from "../../../store/emergencies/emergencies.api";
import {useGetRegionsQuery} from "../../../store/regions/regions.api";
import {useDebounce} from "../../../hooks/debounce";
import FilterBlock from "../EsFeed/FilterBlock";
import EmergencyTypesList from "../EsFeed/EmergencyTypesList";
import EmergencyListMap from "./EmergencyListMap";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";
import {Query} from "../../../functions";
import dayjs from "dayjs";
import DateTimeRangePicker from "../../../components/UI/DateTimeRangePicker/DateTimeRangePicker";

export interface IState {
    region: string
    reportType: string
    status: string
    reportStatus: string
}

export interface User {
    id: number
    name: string
}

export interface EmergencyAuthor {
    created_at: string
    first_name: string
    id: number
    last_name: string
    user_groups: User[]
    username: string
}

export interface EmergencyExecutor {
    created_at: string
    first_name: string
    id: number
    last_name: string
    user_groups: User[]
    username: string
}

export interface Emergency {
    author?: EmergencyAuthor | undefined
    county: number
    created_at: string
    date_emergency: string
    description: string
    district: number
    executor: EmergencyExecutor
    field_emergency: any[]
    human_casualties: number
    id: number
    late_information: boolean
    latitude: string
    locality: any
    longitude: string
    material_damage: number
    region: number
    status: string
    title: string
    type_emergency: number
    type_emergency_name: string
    type_report: string
    updated_at: string
}

export interface esFeed {
    action_created?: boolean | null
    action_updated?: boolean | null
    action_deleted?: boolean | null
    created_at: string
    date_emergency: Date
    description: string
    executor: string
    id: number
    late_information: boolean
    region: number
    status: string
    title: string
    type_emergency: number
    type_report: string
    updated_at: Date
}

const EsMap = () => {
    const {t, i18n} = useTranslation();

    const {data: responseEmergencyTypes} = useGetEmergencyTypesQuery(makeQuery.stringify({page_size: 100000}));
    const {data: regions} = useGetRegionsQuery();
    const [fetchEsTypes, {data: esTypes }] = useLazyGetEmergencyTypesQuery();
    const [fetchEmergenciesGeom, {data: emergenciesGeom, isFetching}] = useLazyGetEsFeedGeomQuery();
    const [esTypesChecked, setEsTypesChecked] = useState<any[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [state, setState] = useState<IState>({
        region: '',
        reportType: '',
        status: '',
        reportStatus: ''
    });
    const [visibleLegend, setVisibleLegend] = useState(true);
    const [queryState, setQueryState] = useState<string>('');
    const [esResults, setEsResults]: any = useState([]);
    const [filterArray, setFilterArray] = useState([]);
    const [dateRange, setDateRange] = useState({start: '', end: ''});
    const [dateRangeVisible, setDateRangeVisible] = useState(false);
    const [switcher, setSwitcher] = useState<string>('region');
    const filterDebounce = useDebounce(queryState, 3000);

    const isLoading = useMemo(() => {
        return (isFetching);
    }, [isFetching]);

    useEffect(() => {
        // console.log(filterDebounce);
        if (filterDebounce) {
            fetchEmergenciesGeom(filterDebounce);
        }
    }, [filterDebounce]);

    useEffect(() => {
        const query: { [key: string]: string } = {};

        if (esTypesChecked && esTypesChecked.length > 0) {
            query.type_emergency = esTypesChecked.join(',');
        }

        if (!selectAll) query.type_emergency = esTypesChecked.join(',');
        // if (state.region) query.region = state.region;
        // if (state.reportType) query.type_report = state.reportType;
        // if (state.status) query.status = state.status;
        // if (state.reportStatus) query.late_information = state.reportStatus;

        if (dateRange.start && dateRange.end) {
            query.created_at__gte = JSON.parse(JSON.stringify(dateRange.start));
            query.created_at__lte = JSON.parse(JSON.stringify(dateRange.end));
        }

        setQueryState(makeQuery.stringify(query));
    }, [state, esTypesChecked, responseEmergencyTypes, dateRange]);

    useEffect(() => {
        fetchEsTypes(makeQuery.stringify({page_size: 100000000}));
        let esArray: any[] = [];

        if(emergenciesGeom) {
            emergenciesGeom?.features?.forEach((es: any) => {
                responseEmergencyTypes?.results?.forEach((type: any) => {
                    if (es.properties.type_emergency === type.id && esTypesChecked.includes(type.id)) {
                        const {geo_icon: {icon}} = type;
                        // if (!esArray.map(el => el.id).includes(es.properties.id)) {
                        if(es?.geometry?.type === 'Point') {
                            esArray.push({
                                id: es.properties.id,
                                title: es.properties.title,
                                description: es.properties.description,
                                executor: es.properties.executor,
                                status: es.properties.status,
                                type_report: es.properties.type_report,
                                date_emergency: es.properties.date_emergency,
                                type_emergency: es.properties.type_emergency,
                                type_emergency_name: es.type_emergency_name,
                                icon,
                                county: es.properties.county,
                                human_casualties: es.properties.human_casualties,
                                material_damage: es.properties.material_damage,
                                field_emergency: es.properties.field_emergency,
                                late_information: es.properties.late_information,
                                region: es.properties.region,
                                position: [es?.geometry?.coordinates[1] !== null && es?.geometry?.coordinates[1] ? es?.geometry?.coordinates[1] : 0, es?.geometry?.coordinates[0] !== null && es?.geometry?.coordinates[0] ? es?.geometry?.coordinates[0] : 0]
                            });
                        } else {
                            return
                        }
                        // }
                    }
                });
            });
        }
        setEsResults(esArray);
    }, [emergenciesGeom]);

    useEffect(() => {
        if (!esTypesChecked) return;

        const query: { [key: string]: string } = {}
        if (esTypesChecked && esTypesChecked.length > 0) {
            query.type_emergency = esTypesChecked.join(',');
        }
        if (!selectAll) query.type_emergency = esTypesChecked.join(',');
        if (state.region) query.region = state.region;
        if (state.reportType) query.type_report = state.reportType;
        if (state.status) query.status = state.status;
        if (state.reportStatus) query.late_information = state.reportStatus;

        // setQueryState(makeQuery.stringify(query));
        const filteredResults = filterResults(esResults, state);
        // allStatePropertiesEmpty = Object.values(state).every(value => value === '');
        setFilterArray(filteredResults);
    }, [esResults, state]);

    const filterResults = (esResults: any, state: any) => {
        return esResults.filter((es: any) => (
            (!state.region || String(state.region) === String(es.region)) &&
            (!state.reportType || state.reportType === es.type_report) &&
            (!state.status || state.status === es.status) &&
            (!state.reportStatus || String(state.reportStatus) === String(es.late_information))
        ));
    };

    const handleSelectDates = (value: { start: Date; end: Date }) => {
        // @ts-ignore
        setDateRange(value)
        setDateRangeVisible(false)
    }

    const handleGetValue = (value: { start: Date; end: Date }) => {
        // @ts-ignore
        setDateRange(value);
    }

    const handleChangeChecked = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        let arr = [...esTypesChecked];
        let index;
        if (!arr.includes(id)) {
            arr.push(id);
        } else {
            index = arr.findIndex(el => el === id);
            arr.splice(index, 1);
        }
        if (responseEmergencyTypes?.results?.length !== arr.length) {
            setSelectAll(false)
        } else {
            setSelectAll(true)
        }
        setEsTypesChecked(arr);
    }

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {checked} = e.target;
        setSelectAll(!selectAll)
        let arr: any[] = []
        if (checked) {
            responseEmergencyTypes?.results.forEach(el => {
                arr.push(el.id)
            })
            setEsTypesChecked(arr);
        } else {
            setEsTypesChecked([]);
        }
    }

    const handleSelect = (e: any, name: string) => {
        if (e?.value) {
            setState(prevState => {
                return {...prevState, [name]: e.value}
            })
        } else {
            setState(prevState => {
                return {...prevState, [name]: ''}
            })
        }
    }

    const showLegend = () => {
        setVisibleLegend(!visibleLegend);
    }

    let obj: any = {
        count: 0
    };
    // let username = users?.results?.length ? users?.results?.find(el => el.id === userId)?.first_name + " " + users?.results?.find(el => el.id === userId)?.last_name : ''
    filterArray.forEach((es: any) => {
        obj.count++;
        if (obj[es.type_emergency_name] === undefined) {
            obj[es.type_emergency_name] = 0;
        }
        obj[es.type_emergency_name]++;
    });

    const legendData = useMemo(() => {
        const data: {[key: string | number]: any} = { total: filterArray.length, results: [] };
        const byRegions: {[key: number]: any} = {};

        filterArray.forEach((es: any) => {
            if (byRegions[es.region || 0]) byRegions[es.region || 0]++;
            else byRegions[es.region || 0] = 1;
        });
        Object.entries(byRegions).forEach(pair => {
            data.results.push({ name: regions?.find(r => String(r.id) === String(pair[0]))?.name || 'n/a', count: pair[1] });
        })
        return data;
    }, [filterArray]);

    const legendTypes = useMemo(() => {
        const data: {[key: string | number]: any} = { total: filterArray.length, results: [] };
        const byRegions: {[key: number]: any} = {};

        filterArray.forEach((es: any) => {
            if (byRegions[es.type_emergency || 0]) byRegions[es.type_emergency || 0]++;
            else byRegions[es.type_emergency || 0] = 1;
        });
        Object.entries(byRegions).forEach(pair => {
            data.results.push({ name: esTypes?.results?.find(r => String(r.id) === String(pair[0]))?.name || 'n/a', count: pair[1] });
        })
        return data;
    }, [filterArray]);

    const entries = Object.entries(obj);
    const getColor = (colorValue: number | string) => {
        if (colorValue >= 0 && colorValue < 40) return 'black';
        if (colorValue >= 40 && colorValue < 60) return 'yellow';
        if (colorValue >= 60 && colorValue < 80) return 'orange';
        if (colorValue >= 80 && colorValue <= 100) return 'red';
    };

    const esLegends = entries.map(([key, value], i) => {
        if (key !== 'count') {
            const colorValue = (Number(value) * 100) / Number(entries[0][1]);
            const color = getColor(colorValue);

            return (
                <div key={i} className="map-legend-info-inner-block">
                    <div className='map-legend-circle' style={{ background: color }}></div>
                    <p className="map-legend-type">{key} - {Math.ceil((Number(entries[0][1])*colorValue)/100)}</p>
                </div>
            );
        }
        return null;
    });

    const legend = (
        visibleLegend
            ?
            <>
                {switcher === "region"
                    ?
                    <div className="map-legend">
                        <div className="map-legend-inner-info">
                            <p className="map-legend-title">{t('Total')}: {legendData.total}</p>
                            <div className="map-legend-info-block">
                                {legendData.results.map((el: any, idx: number) => (
                                    <div key={'legendItem' + idx} className="map-legend-info-inner-block">
                                        <div className='map-legend-circle' style={{background: 'grey'}}></div>
                                        <p
                                            className="map-legend-type map-legend-es-type"><span
                                            className="long-text">{el.name}</span><span className="number-text"> - {el.count}</span></p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="map-legend-btns">
                            {switcher === 'region'
                                ?
                                <CButton size="sm" color="info" className="map-legend-switcher"
                                         onClick={() => setSwitcher('types')}>
                                    {t('ESMap.types')}
                                </CButton>
                                :
                                <CButton size="sm" color="info" className="map-legend-switcher"
                                         onClick={() => setSwitcher('region')}>
                                    {t('ESMap.regions')}
                                </CButton>
                            }
                            <i
                                className="fa fa-arrow-right map-legend-arrow-hide map-legend-arrow-click"
                                aria-hidden="true"
                                onClick={() => showLegend()}
                            />
                        </div>

                    </div>
                    :
                    <div className="map-legend">
                        <div className="map-legend-inner-info">
                            <p className="map-legend-title">Total: {legendTypes.total}</p>
                            <div className="map-legend-info-block">
                                {legendTypes.results.map((el: any, idx: number) => (
                                    <div key={'legendItem' + idx} className="map-legend-info-inner-block">
                                        <div className='map-legend-circle' style={{background: 'grey'}}></div>
                                        <p
                                            className="map-legend-type map-legend-es-type"><span
                                            className="long-text">{el.name}</span><span className="number-text"> - {el.count}</span></p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="map-legend-btns">
                            {switcher === 'region'
                                ?
                                <CButton size="sm" color="info" className="map-legend-switcher"
                                         onClick={() => setSwitcher('types')}>
                                    {t('ESMap.types')}
                                </CButton>
                                :
                                <CButton size="sm" color="info" className="map-legend-switcher"
                                         onClick={() => setSwitcher('region')}>
                                    {t('ESMap.regions')}
                                </CButton>
                            }
                            <i
                                className="fa fa-arrow-right map-legend-arrow-hide map-legend-arrow-click"
                                aria-hidden="true"
                                onClick={() => showLegend()}
                            />
                        </div>
                    </div>
                }
            </>
            :
            <div className="map-legend-arrow-block">
                <i
                    className="fa fa-arrow-left map-legend-arrow map-legend-arrow-click"
                    aria-hidden="true"
                    onClick={() => showLegend()}
                />
            </div>
    );

    return (
        <>
            <div className="pb-3 mb-4 title-divider">
                <h1>{t('nav.ES map')}</h1>
                <LoaderLine visible={isLoading}/>
            </div>
            <div className="d-flex justify-content-md-end mb-3" style={{position: 'relative'}}>
                <div style={{width: '305px'}}>
                    <CFormLabel>{t('Select dates')}</CFormLabel>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        {/*@ts-ignore*/}
                        <CFormInput style={{borderRadius: '6px', width: '305px'}}
                                    type="text"
                                    value={(dateRange.start ? dayjs(dateRange.start).format("DD/MM/YYYY") + " - " : "") + (dateRange.end ? dayjs(dateRange.end).format("DD/MM/YYYY") : "")}
                                    placeholder={dateRange.start || dateRange.end ? "" : t('Date is not selected')}
                                    onClick={() => setDateRangeVisible(true)}
                        />
                    </div>

                </div>
                {dateRangeVisible && <div className="language-select-overlay" onClick={() => {
                    setDateRangeVisible(false);
                }}/>}
                {dateRangeVisible ? <div style={{position: 'absolute', zIndex: 1031, top: '75px'}}>
                    <DateTimeRangePicker locale={i18n.language} onChange={handleSelectDates} getValue={handleGetValue}/>
                </div> : null}
            </div>
            <div style={{display: 'flex'}}>
                <div className="es-columns es-type-column ">
                    <div className="es-type-column__header">
                        <div className="es-type-column__title">{t("esFeed.filterBy")}</div>
                        <CFormCheck id={'select-all'}
                                    style={{marginRight: '8px'}}
                                    name={'select-all'}
                                    onChange={handleSelectAll}
                                    checked={selectAll}
                        />
                        <label htmlFor={'select-all'}>{t("esFeed.markAll")}</label>
                    </div>
                    <div className="es-scrollable-block">
                        {
                            responseEmergencyTypes?.results?.length ?
                                <EmergencyTypesList data={responseEmergencyTypes?.results} state={esTypesChecked}
                                                    onChange={handleChangeChecked}/> :
                                null
                        }
                    </div>
                </div>
                {/*<div className="es-columns es-column" onClick={handleCloseMenu}>*/}
                <div className="es-columns es-column">
                    <FilterBlock regions={regions!} onSelect={handleSelect} state={state} t={t}/>
                    {
                        <EmergencyListMap
                            data={filterArray}
                            legend={legend}
                        />
                    }
                </div>
            </div>

            {/*<CModal*/}
            {/*    alignment="center"*/}
            {/*    visible={visible}*/}
            {/*    onClose={() => setVisible(false)}*/}
            {/*    aria-labelledby="VerticallyCenteredExample"*/}
            {/*>*/}
            {/*    <CModalHeader style={{padding: '12px'}}>*/}
            {/*        <CModalTitle id="VerticallyCenteredExample">{t("esFeed.shareAnEmergency")}</CModalTitle>*/}
            {/*    </CModalHeader>*/}
            {/*    <CModalBody style={{padding: '18px 12px', fontSize: '14px'}}>*/}
            {/*        <div>{t("esFeed.areYouSureYouWantToShareTheEmergencyWithTheUser")} <strong>{username}</strong>?*/}
            {/*        </div>*/}
            {/*    </CModalBody>*/}
            {/*    <CModalFooter style={{padding: '8px 12px 8px 12px'}}>*/}
            {/*        <CButton size={'sm'} color="secondary" onClick={() => setVisible(false)}>*/}
            {/*            {t("esFeed.cancel")}*/}
            {/*        </CButton>*/}
            {/*        <CButton size={'sm'} color="primary">{t("esFeed.toShare")}</CButton>*/}
            {/*    </CModalFooter>*/}
            {/*</CModal>*/}
        </>
    );
};

export default EsMap;

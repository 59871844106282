import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";

export const affectedPopulationApi = createApi({
  reducerPath: 'affectedPopulation/api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers) => headerConfig(headers),
  }),
  tagTypes: ['AffectedPopulation'],
  endpoints: build => ({
    createAffectedPopulation: build.mutation<any, any>({
      query: (body) => ({
        url: '/info/ab/th/affected/population/create/',
        method: 'POST',
        body
      }),
      invalidatesTags: ['AffectedPopulation'],
    }),
    getAffectedPopulationById: build.query<any, string | void>({
      query: id => {
        return {
          url: `/info/ab/th/affected/population/detail/${id}/`
        }
      }
    }),
    updateAffectedPopulation: build.mutation<any, any>({
      query: ({body, id}) => {
        return {
          url: `/info/ab/th/affected/population/update/${id}/`,
          method: 'PATCH',
          body
        }
      }
    })
  })
})

export const {useCreateAffectedPopulationMutation, useLazyGetAffectedPopulationByIdQuery, useUpdateAffectedPopulationMutation} = affectedPopulationApi
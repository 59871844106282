import React from 'react';
import {RouterProvider} from "react-router-dom";
import router from "./router";

import './scss/style.scss';
import 'font-awesome/css/font-awesome.css';

const App = () => {
    return (
        <>
            <RouterProvider router={router}/>
        </>
    );
};

export default App;

import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from "react-redux";
import {
    CButton, CButtonGroup, CCard, CCardBody, CCardHeader, CCollapse,
    CFormInput, CFormLabel,
    CFormSelect,
    CTable,
    CTableBody, CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow
} from "@coreui/react";

import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import FormGroup from "../../../components/UI/Forms/FormGroup";
import {Query} from "../../../functions";
import {useTranslation} from "react-i18next";
import {
    useCreateGeoTypeMutation,
    useLazyGetGeoTypesQuery, useRemoveGeoTypeMutation,
    useUpdateGeoTypeMutation
} from "../../../store/geoTypes/geoTypes.api";
import {useLazyGetGeoIconsQuery} from "../../../store/geoIcons/geoIcons.api";
import {useLazyGetGeoCategoriesQuery} from "../../../store/geoCategories/geoCategories.api";
import PaginationSearch from "../../../components/UI/Pagination/PaginationSearch";
import makeQuery from "../../../functions/makeQuery";
import {IGeoIcon} from "../../../interfaces/IGeoIcons";
import GeoCategoriesTree from "../../../components/UI/GeoCategoriesTree/GeoCategoriesTree";
import {IGeoType} from "../../../interfaces/IGeoTypes";
import DefaultPaginationWrapper from "../../../components/UI/Pagination/DefaultPaginationWrapper";

const fieldTypeOptions = [
    {label: 'Boolean', value: 'boolean'},
    {label: 'Number', value: 'number'},
    {label: 'String', value: 'string'},
    {label: 'Textarea', value: 'textarea'},
    // {label: 'Image', value: 'image'},
    {label: 'Date', value: 'date'},
    {label: 'Time', value: 'time'},
    {label: 'DateTime', value: 'datetime'},
];
// TODO нужно добавить loading как из Esdetail.tsx
const GeoTypes = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [pagination, setPagination] = useState({page_size: '100', search: '', page: 1});
    const [filter, setFilter] = useState({sort_by: 'type_name', direction: 'asc'});
    const [searchString, setSearchString] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [collapseVisible, setCollapseVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [state, setState]: any = useState({typeOfGeoObject: '', icon: null, geoCategory: ''});
    const [fields, setFields] = useState([
        {_id: 1, options: fieldTypeOptions, textInput: '', select: ''},
    ]);

    const [visible, setVisible] = useState<number[]>([]);
    const [okIsHover, setOkIsHover] = useState<boolean>(false);
    const [activeError, setActiveError] = useState<any>('');

    const [fetchGeoIcons, {data: geoIcons}] = useLazyGetGeoIconsQuery();

    const [fetchGeoCategories, {data: geoCategories}] = useLazyGetGeoCategoriesQuery();

    const [fetchGeoTypes, {isError: error, isLoading, data: geoTypes}] = useLazyGetGeoTypesQuery();
    const [createGeoType] = useCreateGeoTypeMutation();
    const [updateGeoType] = useUpdateGeoTypeMutation();
    const [deleteGeoType, {isError: errorGeoType}] = useRemoveGeoTypeMutation();

    useEffect(() => {
        fetchGeoIcons(makeQuery.stringify({page_size: 10000}));
        fetchGeoCategories();
    }, []);

    useEffect(() => {
        fetchGeoTypes({page_size: pagination.page_size, page: pagination.page});
    }, [pagination]);

    useEffect(() => {
        if (geoTypes) setSortedGeoTypes(geoTypes?.results);
    }, [geoTypes]);

    const [sortedGeoTypes, setSortedGeoTypes] = useState<IGeoType[]>([]);
    const [sortOrder, setSortOrder] = useState<String>('asc');
    const countGeoTypes: number | undefined = sortedGeoTypes?.length;

    const sortGeoTypes = () => {
        const sorted = sortedGeoTypes && [...sortedGeoTypes].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a > b ? 1 : -1;
            } else {
                return a < b ? 1 : -1;
            }
        });
        setSortedGeoTypes(sorted);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const getCategoryById = (id: number) => {
        const findCategory = (list: any[]): any => {
            for (let cat of list) {
                if (cat.id === id) return cat;
                else if (cat.children?.length) {
                    const res = findCategory(cat.children);
                    if (res) return res;
                }
            }
            return null;
        }
        if (geoCategories?.length) return findCategory(geoCategories);
        return null;
    };

    const handleChange = (event: any) => {
        setState((prevState: any) => {
            return {...prevState, [event.target.name]: event.target.value}
        });
    };

    const handleChangeGeoCategory = (id: number | string) => {
        setState((prevState: any) => {
            return {...prevState, geoCategory: String(id)}
        });
    };

    const handleChangeField = (event: any, id: number) => {
        const tempFields: any = [...fields];
        tempFields[tempFields.findIndex((el: any) => el._id === id)][event.target.name] = event.target.value;
        setFields(tempFields)
    };

    const handleToggleModal = (visible: boolean) => {
        let arr: any[] = [];
        setTimeout(() => {
            if (visible === false) {
                arr.push({_id: 1, options: fieldTypeOptions, textInput: '', select: ''});
                setFields(arr);
                setSelectedId(null);
                setState({typeOfGeoObject: '', icon: null, geoCategory: ''});
            }
        }, 200)
        setModalVisible(visible);
    };

    const handleToggleDeleteModal = (visible: boolean) => {
        if (visible === false) setSelectedId(null);
        setDeleteModal(visible);
    };

    const okHoverHandler = (isHover: boolean = false) => {
        setOkIsHover(isHover);
    };

    const handleEdit = async () => {
        let data, geoFields: any[] = [];

        if (fields?.length) {
            geoFields = fields.map((el: any) => {
                const data: any = {
                    field_type: el.select,
                    field_name: el.textInput
                }
                if (el.id) data.id = el.id
                return data;
            })
        }

        data = {
            type_name: state.typeOfGeoObject,
            category: Number(state.geoCategory),
            icon: Number(state.icon),
            geo_type_field: geoFields
        }

        if (selectedId) {
            updateGeoType({id: selectedId, body: data});
        } else {
            createGeoType(data);
        }
        handleToggleModal(false);
    }

    const handlePickIcon = (id: any) => {
        setState({...state, icon: id});
        setCollapseVisible(false);
    };

    const handleManageFields = (action: any, index: any) => {
        let idx: number, arrayOfFields: any[] = [...fields], object: any;
        if (action === 'add') {
            if (arrayOfFields.length === 0) {
                arrayOfFields.push({_id: 1, options: fieldTypeOptions, textInput: '', select: ''});
                setFields(arrayOfFields);
            } else {
                object = {_id: arrayOfFields[arrayOfFields.length - 1]._id + 1, options: fieldTypeOptions};
                object.textInput = '';
                object.select = '';
                arrayOfFields.push(object);
                setFields(arrayOfFields);
            }
        }
        if (action === 'remove') {
            idx = fields.findIndex(el => el._id === index + 1);
            arrayOfFields.splice(idx, 1);
            setFields(arrayOfFields);
        }
    };

    const handleOpenEditModal = (visible: boolean, item: any) => {
        let geoTypesFields = [];
        setState({typeOfGeoObject: item.type_name, icon: item.icon, geoCategory: item.category})
        setSelectedId(item.id)
        if (Object.keys(item)?.length && item.geo_type_field.length) {
            geoTypesFields = item.geo_type_field.map((el: any, idx: number) => {
                return {
                    _id: idx + 1,
                    id: el.id,
                    options: fieldTypeOptions,
                    textInput: el.field_name,
                    select: el.field_type
                }
            });

            const sortedFieldsCopy = [...geoTypesFields];
            const sortedFields = sortedFieldsCopy.sort((a: any, b: any) => a.id - b.id);

            setFields(sortedFields);
        }
        setModalVisible(visible);
    };

    const handleOpenDeleteModal = (id: any) => {
        setDeleteModal(true);
        setSelectedId(id);
    };

    const handleDelete = async () => {
        deleteGeoType(Number(selectedId)).then(
            (res: any) => {
                if (res?.error) {
                    setActiveError(res?.error?.data?.error);
                    return null
                }
                setDeleteModal(false);
            },
        );
    };

    const onPaginationHandler = (state: any) => {
        // const innerState = {...pagination, ...state};
        // // console.log(innerState);
        // // console.log(filter);
        // setPagination(innerState);
        // if (state.search === undefined) onSearchDataHandler({...innerState, ...filter});
        let innerState = {...pagination, ...state};

        if(countGeoTypes && countGeoTypes <= Number(pagination.page_size)) {
            innerState = {
                page_size: pagination.page_size,
                search: pagination.search,
                page: 1
                , ...state}
        }
        setPagination(innerState);
        onSearchDataHandler({...innerState, ...filter});
    };

    const onFilterHandler = (field: string) => {
        const state = {...filter};
        if (field === state.sort_by) state.direction = state.direction === 'asc' ? 'desc' : 'asc';
        else {
            state.sort_by = field;
            state.direction = 'asc';
        }
        setFilter(state);
        onSearchDataHandler({...pagination, ...state});
    };

    const onSearchCallbackHandler = (search: string) => {
        onSearchDataHandler({...pagination, ...filter, search});
    };

    const onSearchDataHandler = (data: any) => {
        const query: any = {};
        if (data.page_size) query.page_size = data.page_size;
        if (data.page) query.page = data.page;
        if (data.search && data.search !== '') query.search = data.search;
        if (data.sort_by) query.sort_by = data.sort_by;
        if (data.direction === 'desc') query.direction = 'desc';
        fetchGeoTypes(Query.stringify(query))
    };

    const handleClickToAdditionalInformation = (id: number) => {
        const innerState = [...visible];
        if (innerState.includes(id)) innerState.splice(innerState.findIndex(el => el === id), 1);
        else innerState.push(id);
        setVisible(innerState);
    }

    let geoCategoriesOptions, selectedIcon: any;

    if (geoCategories) {
        geoCategoriesOptions = geoCategories?.map((el: any) => {
            return {label: el.category_name, value: el.id}
        })
        geoCategoriesOptions.unshift({label: t('gis.typesOfGeoObjects.selectCategory'), value: 'null'})
    }

    if (state.icon) {
        selectedIcon = geoIcons?.results?.findIndex((el: IGeoIcon) => el.id === Number(state.icon));
    }

    const geoTypesForTable = useMemo(() => {
        if (sortedGeoTypes?.length) {
            return sortedGeoTypes?.map((el: any, idx: number) => {
                const sortedFieldsCopy = [...el.geo_type_field];
                const sortedFields = sortedFieldsCopy.sort((a: any, b: any) => a.id - b.id);
                const category = getCategoryById(el.category);
                if (!searchString || el.type_name.toLowerCase().includes(searchString.toLowerCase()) || (category && category.category_name.toLowerCase().includes(searchString.toLowerCase()))) {
                    const icon = geoIcons?.results?.find((ic: any) => ic.id === el.icon);
                    return (
                        <CTableRow key={el.id + 'geoType'}>
                            <CTableDataCell className="text-center">
                                {icon && <img alt='' src={icon.icon} style={{height: '37px', width: 'auto'}}/>}
                            </CTableDataCell>
                            <CTableDataCell>
                                {el.type_name}
                            </CTableDataCell>
                            <CTableDataCell>
                                {category && <span>{category.category_name}</span>}
                            </CTableDataCell>
                            <CTableDataCell>
                                {sortedFields?.length ?
                                    <div style={{background: 'transparent', padding: '8px'}}>
                                        <div className={visible.includes(el.id) ? "visible-item" : "collapsable-item"}
                                             onClick={() => handleClickToAdditionalInformation(el.id)}>
                                            {
                                                visible.includes(el.id) ?
                                                    <i className="fa fa-chevron-down" aria-hidden="true"
                                                       style={{marginRight: '10px'}}></i> :
                                                    <i className="fa fa-chevron-right" aria-hidden="true"
                                                       style={{marginRight: '10px'}}></i>
                                            }
                                            <div style={{cursor: 'pointer', fontWeight: 'bold'}}>
                                                {t("gis.geoObjects.additionalInformation")}
                                            </div>
                                        </div>
                                        <CCollapse visible={visible.includes(el.id)}>
                                            <ul style={{marginTop: '20px', paddingLeft: '5px'}}>
                                                {sortedFields.map((elem: any) => {
                                                    return (
                                                        <li key={el.id + 'geoTypeField' + elem.id} className="li-dot">
                                                            <strong>{elem.field_name}:</strong> {" "} {elem.field_type || " - "}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </CCollapse>
                                    </div>
                                    : null
                                }
                            </CTableDataCell>

                            <CTableDataCell className="text-center">
                                <CButtonGroup size="sm">

                                    <CButton size="sm" color="info" className="default-table__action-btn"
                                             onClick={() => handleOpenEditModal(true, el)}>
                                        <i className="fa fa-pencil-square-o color-white"/>
                                    </CButton>
                                    <CButton size="sm" color="danger" className="default-table__action-btn"
                                             onClick={() => handleOpenDeleteModal(el.id)}>
                                        <i className="fa fa-times color-white" aria-hidden="true"> </i>
                                    </CButton>
                                </CButtonGroup>
                            </CTableDataCell>
                        </CTableRow>
                    )
                }
                return null;
            })
        }
        return [];
    }, [sortedGeoTypes, geoIcons, geoCategories, visible, searchString]);

    const formValidate = () => {
        let isValid = !!(state.icon && state.typeOfGeoObject);
        fields.forEach(item => {
            isValid = !!(isValid && item.select && item.select !== 'null' && item.textInput)
        })
        return !isValid;
    };

    return (
        <>
            <div className="pb-3 mb-4 title-divider">
                <h1>{t('gis.typesOfGeoObjects.typesOfGeoObjects')}</h1>
            </div>

            <div className="d-flex justify-content-md-end mb-3">
                <CButton size="sm" color="info" className="color-white" onClick={() => handleToggleModal(true)}>
                    {t('gis.typesOfGeoObjects.addTypeOfGeoObject')}
                </CButton>
            </div>

            {/*<PaginationSearch name={'geo-search'}*/}
            {/*                  value={pagination.search || ''}*/}
            {/*    // onChange={(value: string) => onPaginationHandler({*/}
            {/*    //     name: 'search',*/}
            {/*    //     value: value*/}
            {/*    // })}*/}
            {/*                  onChange={(value: string) => setSearchString(value)}*/}
            {/*    // onSearchDelay={10}*/}
            {/*    // onSearchCallback={onSearchCallbackHandler}*/}
            {/*/>*/}

            <DefaultPaginationWrapper data={geoTypes}
                                      state={pagination}
                                      onChange={onPaginationHandler}
                                      onSearchDelay={2000}
                                      onSearchCallback={onSearchCallbackHandler}
                                      showSearch={false}
            >
                <CTable bordered striped className="default-table">
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell style={{width: '50px'}}
                                              scope="col"
                                              className="sorted-table-cell"
                                // onClick={() => onFilterHandler('icon')}
                            >
                                <div className="d-flex justify-content-between">
                                    {/*<span>{t('gis.typesOfGeoObjects.icon')}</span>*/}
                                </div>
                            </CTableHeaderCell>

                            <CTableHeaderCell scope="col"
                                              className="sorted-table-cell"
                                // onClick={() => onFilterHandler('type_name')}
                                              onClick={() => sortGeoTypes()}

                            >
                                <div className="d-flex justify-content-between">
                                    <span>{t('gis.typesOfGeoObjects.title')}</span>
                                    {sortOrder === 'asc' ?
                                        <i className={`fa fa-sort-amount-asc`} aria-hidden="true"/> :
                                        <i className="fa fa-sort-amount-desc" aria-hidden="true"/>
                                    }
                                </div>
                            </CTableHeaderCell>

                            <CTableHeaderCell style={{width: '25%'}}
                                              scope="col"
                                              className="sorted-table-cell"
                                // onClick={() => onFilterHandler('category')}
                                //               onClick={() => sortGeoTypes()}
                            >
                                <div className="d-flex justify-content-between">
                                    <span>{t('gis.typesOfGeoObjects.category')}</span>
                                    {/*{filter.sort_by === 'category' ?*/}
                                    {/*    <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :*/}
                                    {/*    <i className="fa fa-sort" aria-hidden="true"/>*/}
                                    {/*}*/}
                                </div>
                            </CTableHeaderCell>


                            <CTableHeaderCell style={{width: '25%'}}
                                              scope="col"
                                              className="sorted-table-cell"
                            >
                                <div className="d-flex justify-content-between">
                                    <span>{t('gis.typesOfGeoObjects.fields')}</span>
                                </div>
                            </CTableHeaderCell>

                            <CTableHeaderCell style={{width: '100px'}} scope="col" className="default-table__actions">
                                {t('actions')}
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        {geoTypesForTable}
                    </CTableBody>
                </CTable>

            </DefaultPaginationWrapper>

            <DefaultModal visible={modalVisible}
                          setVisible={handleToggleModal}
                          title={selectedId ? t('gis.typesOfGeoObjects.editTypeOfGeoObject') : t('gis.typesOfGeoObjects.addTypeOfGeoObject')}
                          type={'info'}
                          cancelButton={t('cancel')}
                          onOk={handleEdit}
                          onOkHover={okHoverHandler}
                          okDisabled={formValidate()}
                          size="lg"
            >

                <FormGroup className="main-label" htmlFor="typeOfGeoObject"
                           label={t('gis.typesOfGeoObjects.typeOfGeoObject')}>
                    <CFormInput id="typeOfGeoObject"
                                className={`${(okIsHover && !state.typeOfGeoObject) ? 'form-control--alert' : ''}`}
                                type="text"
                                value={state.typeOfGeoObject}
                                name="typeOfGeoObject"
                                onChange={handleChange}
                                size="sm"
                    />
                </FormGroup>

                <FormGroup className="main-label" htmlFor="typeOfGeoObject" label={t('gis.typesOfGeoObjects.icon')}>
                    <div>
                        <CButton color="info"
                                 className={`${(okIsHover && !selectedIcon) ? 'form-control--alert' : ''}`}
                                 size="sm"
                                 href="#"
                                 shape="rounded-0"
                                 onClick={(event) => {
                                     event.preventDefault()
                                     setCollapseVisible(!collapseVisible)

                                 }}>
                            {t('gis.typesOfGeoObjects.selectIcon')} <i
                            className={`fa fa-caret-${collapseVisible ? 'up' : 'down'}`} aria-hidden="true"></i>
                            {/*// @ts-ignore*/}
                        </CButton> <img alt=''
                                        src={geoIcons?.results?.length ? geoIcons.results[selectedIcon]?.icon : ''} // results?
                                        style={{height: '37px', width: 'auto', marginLeft: '15px'}}/>
                        <CCollapse visible={collapseVisible}>
                            <CCard className="mt-3">
                                <CCardBody>
                                    {/*// @ts-ignore*/}
                                    {geoIcons?.results?.length ? geoIcons.results.map((el: any) => {
                                        return (
                                            <img key={el.id}
                                                 alt=''
                                                 src={el.icon}
                                                 style={{
                                                     width: 'auto',
                                                     height: '37px',
                                                     marginLeft: '5px',
                                                     cursor: 'pointer'
                                                 }}
                                                 onClick={() => handlePickIcon(el.id)}
                                                // @ts-ignore
                                                 name='icon'
                                            />
                                        )
                                    }) : null}
                                </CCardBody>
                            </CCard>
                        </CCollapse>
                    </div>
                </FormGroup>

                <FormGroup
                    // className={`${(okIsHover && !state.geoCategory && state.geoCategory !== '0' && state.geoCategory !== 'null') ? 'form-control--alert' : ''}`}
                    htmlFor="geoCategory" label={t('gis.typesOfGeoObjects.category')}>
                    <GeoCategoriesTree onCheck={handleChangeGeoCategory}
                                       checked={!isNaN(Number(state.geoCategory)) ? Number(state.geoCategory) : 0}
                                       okIsHover={okIsHover}
                                       geoCategory={state.geoCategory}
                    />
                    {/*<CFormSelect size="sm"*/}
                    {/*             id="geoCategory"*/}
                    {/*             value={state.geoCategory}*/}
                    {/*             name="geoCategory"*/}
                    {/*             onChange={handleChange}*/}
                    {/*             options={geoCategoriesOptions}*/}
                    {/*/>*/}
                </FormGroup>

                <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                    <CCard style={{width: '100%'}}>
                        <CCardHeader className="p-0">
                            <div className="p-2 mb-0 card-header-title">
                                {t('gis.typesOfGeoObjects.fields')}
                            </div>
                        </CCardHeader>
                        {fields?.length ?
                            fields.map((el, idx) => {
                            return (
                                <div key={idx}>
                                    <div className="d-flex justify-content-between p-3"
                                         style={{
                                             borderBottom: '1px solid rgba(0, 0, 21, 0.125)',
                                             flexDirection: "column"
                                         }}>
                                        <CButton color="info"
                                                 className="color-white"
                                                 size="sm"
                                                 style={{height: '5%', width: '30px', marginBottom: '15px'}}
                                                 onClick={() => handleManageFields('remove', idx)}
                                        >
                                            <i className="fa fa-minus" aria-hidden="true"/>
                                        </CButton>
                                        <CCard style={{width: '95%'}}>
                                            <CCardHeader className="p-0">
                                                <div className="p-2 mb-0 card-header-title">
                                                    {t('gis.typesOfGeoObjects.field')} {idx + 1}
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <CFormLabel
                                                        className="regions-label main-inner-label align-items-start">{t('gis.typesOfGeoObjects.title')}</CFormLabel>
                                                    <CFormInput size="sm"
                                                                className={`${(okIsHover && !fields[idx].textInput) ? 'form-control--alert' : ''}`}
                                                                type="text"
                                                                value={fields[idx].textInput}
                                                                name='textInput'
                                                                style={{width: '100%'}}
                                                                onChange={e => handleChangeField(e, el._id)}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <CFormLabel
                                                        className="regions-label main-inner-label align-items-start">{t('gis.typesOfGeoObjects.type')}</CFormLabel>
                                                    <CFormSelect size="sm"
                                                                 value={fields[idx].select}
                                                                 name='select'
                                                                 onChange={e => handleChangeField(e, el._id)}
                                                                 options={[
                                                                     {
                                                                         label: t('gis.typesOfGeoObjects.selectType'),
                                                                         value: 'null'
                                                                     },
                                                                     ...el.options
                                                                 ]}
                                                                 className={`regions-modal-input ${(okIsHover && (fields[idx].select === 'null' || !fields[idx].select)) ? 'form-control--alert' : ''}`}
                                                                 style={{width: '100%'}}
                                                    />
                                                </div>
                                            </CCardBody>
                                        </CCard>
                                    </div>
                                </div>
                            )
                        }) : null}
                        <CButton color="info"
                                 className="color-white m-3"
                                 size="sm"
                                 style={{height: '5%', width: '30px'}}
                                 onClick={() => handleManageFields('add', null)}
                        >
                            <i className="fa fa-plus" aria-hidden="true"/>
                        </CButton>
                    </CCard>
                </div>

            </DefaultModal>

            <DefaultModal visible={deleteModal}
                          setVisible={handleToggleDeleteModal}
                          title={t('deletion')}
                          type="danger"
                          cancelButton={t('cancel')}
                          onOk={handleDelete}
                          error={activeError}
                          size="lg"
            >
                <div>
                    <p className="mb-0 fs-6">{t('gis.typesOfGeoObjects.geoTypeDeletion')}</p>
                </div>
            </DefaultModal>
        </>
    );
};

export default GeoTypes;

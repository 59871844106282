import React, {FC} from 'react';
import {esFeed} from "./EsMap";
import {GeoJSON, LayersControl, MapContainer, Marker, Popup, ScaleControl, TileLayer, ZoomControl} from "react-leaflet";
import L from "leaflet";
import Control from "react-leaflet-custom-control";
import {getTiles} from "../../../components/maps/tiles";
import MapLoader from "../../../components/preloaders/MapLoader/MapLoader";
import MarkerClusterGroup from "react-leaflet-cluster";
import {CImage, CModal} from "@coreui/react";
import {useTranslation} from "react-i18next";

interface IEmergencyList {
    data: esFeed[]
    legend: any
    emergenciesGeom?: any
    // onClick: (e: React.MouseEvent<HTMLDivElement>, id: number) => void
    // isMenuOpen: any
    // menuPosition: string
    // users: any
    // openModal: (id: number) => void
    // emergencyTypes: IPaginationResponse<any[]> | undefined
}

const EmergencyListMap: FC<IEmergencyList> = ({
                                                  data,
                                                  legend,
                                                  // emergenciesGeom,
                                                  // onClick,
                                                  // isMenuOpen,
                                                  // menuPosition,
                                                  // users,
                                                  // openModal,
                                                  // emergencyTypes
                                              }) => {

    const {t} = useTranslation();

    const esInfo = (marker: any) => {
        return (
            <div key={marker.region + marker.name} className="info-popup-block">
                <p><strong>Заголвок:</strong> {marker?.title}</p>
                <p><strong>Название проишествия:</strong> {marker?.type_emergency_name}</p>
                <p><strong>Описание:</strong> {marker?.description}</p>
                <p><strong>Дата проишествия:</strong> {marker?.date_emergency}</p>
                <p><strong>Репорт:</strong> {marker?.type_report}</p>
                <p><strong>Статус:</strong> {marker?.status}</p>
                <p><strong>human_casualties:</strong> {marker?.human_casualties}</p>
                <p><strong>material_damage:</strong> {marker?.material_damage}</p>

                <p><strong>{t("gis.geoObjects.infoFields")}:</strong></p>
                <ul>
                    <li>
                        <strong>Автор</strong>: {marker?.executor?.last_name} {marker?.executor?.first_name}
                        <strong>Ник</strong>: {marker?.executor?.username}
                    </li>
                    {marker?.executor?.user_groups?.map((g: any) => {
                        return (
                            <li key={g.id}>
                                <strong>Группа</strong>: {g?.name}
                            </li>
                        )
                    })}
                </ul>

                <p><strong>{t("gis.geoObjects.infoFields")}:</strong></p>
                <ul>
                    {marker?.field_emergency?.map((field: any) => {
                        return (
                            <li key={field.id}>
                                <strong>{field?.field_type_emergency?.name}</strong>: {field?.meaning}
                            </li>
                        )
                    })}
                </ul>
            </div>
        );
    };

    let loading = false;

    return (
        <MapContainer
            center={[41.1262532, 73.79516602]}
            zoom={7}
            zoomControl={false}
            attributionControl={false}
            style={{height: '100%', position: 'relative', zIndex: 0, margin: '20px'}}
        >
            {/*{*/}
            {/*    coordinates.lat && coordinates.lat ?*/}
            {/*        <Marker position={[coordinates.lat, coordinates.lng]}*/}
            {/*                icon={*/}
            {/*                    L.divIcon({*/}
            {/*                        className: 'coordinates-icon',*/}
            {/*                        html: '<i class="fa fa-dot-circle-o" aria-hidden="true"></i>',*/}
            {/*                        iconSize: [32, 32]*/}
            {/*                    })*/}
            {/*                }>*/}
            {/*            <Popup>*/}
            {/*                <div>*/}
            {/*                    <p>Широта:{coordinates.lat}</p>*/}
            {/*                    <p>Долгота: {coordinates.lng}</p>*/}
            {/*                </div>*/}
            {/*            </Popup>*/}
            {/*        </Marker>*/}
            {/*        : null*/}
            {/*}*/}
            {/*<Control position="topleft">*/}
            {/*    <a className="leaflet-control-filter leaflet-control-button"*/}
            {/*       href="/"*/}
            {/*       title="Category filter"*/}
            {/*       role="button"*/}
            {/*       aria-label="Category filter"*/}
            {/*       aria-disabled="false"*/}
            {/*       onClick={e => handleVisible(e, 'tree')}*/}
            {/*    >*/}
            {/*        <i className="fa fa-filter" aria-hidden="true"*/}
            {/*        />*/}
            {/*    </a>*/}
            {/*</Control>*/}

            <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer url={getTiles('OpenStreetMap.Mapnik')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="OpenStreetMap">
                    <TileLayer url={getTiles('OpenStreetMap')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Google.Hybrid">
                    <TileLayer url={getTiles('Google.Hybrid')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Esri.WorldStreetMap">
                    <TileLayer url={getTiles('Esri.WorldStreetMap')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Esri.WorldGrayCanvas">
                    <TileLayer url={getTiles('Esri.WorldGrayCanvas')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Esri.WorldImagery">
                    <TileLayer url={getTiles('Esri.WorldImagery')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Esri.WorldShadedRelief">
                    <TileLayer url={getTiles('Esri.WorldShadedRelief')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Stamen.Terrain">
                    <TileLayer url={getTiles('Stamen.Terrain')}/>
                </LayersControl.BaseLayer>
            </LayersControl>

            <ZoomControl position="topright"/>

            {/*<Control position="topright">*/}
            {/*    <a className="leaflet-control-crosshairs leaflet-control-button"*/}
            {/*       href="/"*/}
            {/*       title="Crosshairs"*/}
            {/*       role="button"*/}
            {/*       aria-label="Crosshairs"*/}
            {/*       aria-disabled="false"*/}
            {/*       onClick={e => handleVisible(e, 'coordinates')}*/}
            {/*    >*/}
            {/*        <i className="fa fa-crosshairs" aria-hidden="true"/>*/}
            {/*    </a>*/}
            {/*</Control>*/}

            {/*<Control position="topright">*/}
            {/*    {opacityVisible ? renderOpacity() : null}*/}
            {/*    <a className="leaflet-control-layers-satellite leaflet-control-button"*/}
            {/*       href="/"*/}
            {/*       title="Layers satellite"*/}
            {/*       role="button"*/}
            {/*       aria-label="Layers satellite"*/}
            {/*       aria-disabled="false"*/}
            {/*       onClick={e => handleVisible(e, 'opacity')}*/}
            {/*    >*/}
            {/*        <i className="fa fa-adjust" aria-hidden="true"/>*/}
            {/*    </a>*/}
            {/*</Control>*/}

            <ScaleControl position="bottomleft"/>

            {loading ? <MapLoader/> : null}

            <MarkerClusterGroup
                chunkedLoading
            >
                {data?.map((marker: any, index: any) => (
                    <Marker key={index} position={marker?.position}
                            icon={new L.Icon({
                                iconUrl: marker?.icon,
                                iconSize: [32, 42],
                                iconAnchor: [16, 32],
                                popupAnchor: [0, -32],
                            })}>
                        <Popup>
                            {esInfo(marker)}
                        </Popup>
                    </Marker>
                ))}
            </MarkerClusterGroup>
            {legend}
        </MapContainer>
    );
};

export default EmergencyListMap;
import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {
  CButton, CButtonGroup,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";

import {Query} from "../../../functions";
import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import DefaultPaginationWrapper from "../../../components/UI/Pagination/DefaultPaginationWrapper";
import FormGroup from "../../../components/UI/Forms/FormGroup";
// import {
//   createDocumentType,
//   deleteDocumentType,
//   fetchDocumentTypes,
//   updateDocumentType
// } from "../../../store/actions/documentTypes";
import {useTranslation} from "react-i18next";
import {
  useCreateDocTypeMutation,
  useLazyGetDocTypesQuery,
  useRemoveDocTypeMutation,
  useUpdateDocTypeMutation
} from "../../../store/docTypes/docTypes.api";

const DocumentTypes = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [fetchDocumentTypes, {isError: error,  isLoading, data: documentTypes}] = useLazyGetDocTypesQuery();
  const [createDocumentType] = useCreateDocTypeMutation();
  const [updateDocumentType] = useUpdateDocTypeMutation();
  const [deleteDocumentType] = useRemoveDocTypeMutation();
  // const {documentTypes} = useSelector(state => state.documentTypes);
  // const {error} = useSelector(state => state.documentTypes);

  const [pagination, setPagination] = useState({page_size: '100', search: '', page: 1});
  const [filter, setFilter] = useState({sort_by: '', direction: 'asc'});
  const [visible, setVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [validateError, setValidateError] = useState('');
  const [state, setState]: any = useState({name: ''});
  const countDocumentTypes = documentTypes?.results.length;

  useEffect(() => {
    fetchDocumentTypes('');
  }, [])

  const handleChange = (event: any) => {
    setState((prevState: any) => {
      return {...prevState, [event.target.name]: event.target.value}
    });
  }

  const handleToggleModal = (visible: boolean) => {
    setTimeout(() => {
      if (visible === false) {
        setState({name: ''});
        setSelectedId(null);
      }
    }, 200)
    setVisible(visible);
  }

  const handleToggleDeleteModal = (visible: boolean) => {
    if (visible === false) {
      setSelectedId(null);
    }
    setDeleteModal(visible);
  }

  const handleDelete = () => {
    deleteDocumentType(Number(selectedId)).then(
        () => {
          // dispatch(fetchRegions());
          handleToggleDeleteModal(false);
        },
        (error: string) => {
          console.log(error);
        }
    )
    // dispatch(deleteDocumentType(selectedId,
    //   () => {
    //     dispatch(fetchDocumentTypes());
    //     handleToggleDeleteModal(false);
    //   },
    //     (error: string) => {
    //     // console.log(error);
    //   }
    // ))
  }

  const handleOpenEditModal = (visible?: any, item?: any) => {
    setSelectedId(item.id);
    setState({name: item.name})
    setVisible(visible);
  }

  const handleOpenDeleteModal = (id: any) => {
    setDeleteModal(true);
    setSelectedId(id);
  }

  const handleEdit = () => {
    let data;

    data = state;

    for (const item of Object.keys(state)) {
      if (!state[item]) {
        setValidateError(t('allFieldsValidate'));
        return null
      }
    }

    if (selectedId) {
      updateDocumentType({id: selectedId, body: data}).then(
          () => {
            // dispatch(fetchDocumentTypes());
            handleToggleModal(false);
          },
          (error: string) => {
            console.log(error);
          }
      )
      // dispatch(updateDocumentType(selectedId, data,
      //   () => {
      //     dispatch(fetchDocumentTypes());
      //     handleToggleModal(false);
      //   },
      //   (error: string) => {
      //     // console.log(error);
      //   }
      // ))
    } else {
      createDocumentType(data).then(
          () => {
            // dispatch(fetchDocumentTypes());
            handleToggleModal(false);
          },
          (error: string) => {
            console.log(error);
          }
      )
      // dispatch(createDocumentType(data,
      //   () => {
      //     dispatch(fetchDocumentTypes());
      //     handleToggleModal(false);
      //   },
      //     (error: string) => {
      //     // console.log(error);
      //   }
      // ))
    }
  }

  const handleRedirect = (item: any) => {
    navigate(`/registry-document-types/${item.id}`);
  }

  const onPaginationHandler = (state: any) => {
    let innerState = {...pagination, ...state};

    if(countDocumentTypes && countDocumentTypes <= Number(pagination.page_size)) {
      innerState = {
        page_size: pagination.page_size,
        search: pagination.search,
        page: 1
        , ...state}
    }

    setPagination(innerState);
    if (state.search === undefined) onSearchDataHandler({...innerState, ...filter});
  };

  const onFilterHandler = (field: string) => {
    const state = {...filter};
    if (field === state.sort_by) state.direction = state.direction === 'asc' ? 'desc' : 'asc';
    else {
      state.sort_by = field;
      state.direction = 'asc';
    }
    setFilter(state);
    onSearchDataHandler({...pagination, ...state});
  };

  const onSearchCallbackHandler = (search: string) => {
    onSearchDataHandler({...pagination, ...filter, search});
  };

  const onSearchDataHandler = (data: any) => {
    const query: any = {};
    if (data.page_size) query.page_size = data.page_size;
    if (data.page) query.page = data.page;
    if (data.search && data.search !== '') query.search = data.search;
    if (data.sort_by) query.sort_by = data.sort_by;
    if (data.direction === 'desc') query.direction = 'desc';

    // dispatch(fetchDocumentTypes(Query.stringify(query)));
    fetchDocumentTypes(Query.stringify(query));
  }

  let documentTypesForTable;

  // @ts-ignore
  if (documentTypes?.results?.length) {
    // @ts-ignore
    documentTypesForTable = documentTypes?.results.map((el: any) => {
      return (
        <CTableRow key={el.id}>
          <CTableDataCell>
            <span className="document-types-link" onClick={() => handleRedirect(el)}>{el.name}</span>
          </CTableDataCell>

          <CTableDataCell className="text-center">
            <CButtonGroup size="sm">

              <CButton size="sm" color="info" className="default-table__action-btn"
                       onClick={() => handleOpenEditModal(true, el)}>
                <i className="fa fa-pencil-square-o color-white"/>
              </CButton>
              <CButton size="sm" color="danger" className="default-table__action-btn"
                       onClick={() => handleOpenDeleteModal(el.id)}>
                <i className="fa fa-times color-white" aria-hidden="true"> </i>
              </CButton>
            </CButtonGroup>
          </CTableDataCell>
        </CTableRow>
      )
    })
  }



  return (
    <>
      <div className="pb-3 mb-4 title-divider">
        <h1>{t('registry.documentTypes.documentTypes')}</h1>
      </div>

      <div className="d-flex justify-content-md-end mb-3">
        <CButton size="sm" color="success" className="color-white" onClick={() => handleToggleModal(true)}>
          {t('registry.documentTypes.addDocumentType')}
        </CButton>
      </div>

      <DefaultPaginationWrapper data={documentTypes}
                                state={pagination}
                                onChange={onPaginationHandler}
                                onSearchDelay={2000}
                                onSearchCallback={onSearchCallbackHandler}
      >
        <CTable bordered striped className="default-table">
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell style={{width: '25%'}}
                                scope="col"
                                className="sorted-table-cell"
                                onClick={() => onFilterHandler('name')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('gis.geoObjects.naming')}</span>
                  <i className="fa fa-sort" aria-hidden="true"/>
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '8%'}} scope="col" className="default-table__actions">
                {t('actions')}
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {documentTypesForTable}
          </CTableBody>
        </CTable>
      </DefaultPaginationWrapper>

      <DefaultModal visible={visible}
                    setVisible={handleToggleModal}
                    title={selectedId ?
                      t('registry.documentTypes.editDocumentType') :
                      t('registry.documentTypes.addDocumentType')}
                    type="info"
                    cancelButton={t('cancel')}
                    onOk={handleEdit}
                    size="lg"
                    error={validateError}
      >
        <FormGroup htmlFor="name" label={t('gis.geoObjects.naming')}>
          <CFormInput id="name"
                      type="text"
                      value={state.name}
                      name="name"
                      onChange={handleChange}
                      size="sm"
          />
        </FormGroup>
      </DefaultModal>

      <DefaultModal visible={deleteModal}
                    setVisible={handleToggleDeleteModal}
                    title={t('deletion')}
                    type="danger"
                    cancelButton={t('cancel')}
                    onOk={handleDelete}
          // @ts-ignore
                    error={error?.data?.error}
                    size="lg"
      >
        <div>
          <p className="mb-0 fs-6">{t('registry.documentTypes.deleteDocumentType')}</p>
        </div>
      </DefaultModal>

    </>
  );
};

export default DocumentTypes;

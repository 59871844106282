import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";

export const readCurrentUserApi = createApi({
    reducerPath: 'readCurrentUser/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['ReadCurrentUser'],
    endpoints: build => ({
        getReadCurrentUser: build.query<any, void>({
            query: () => {
                return {
                    url: `/auth/users/me/`
                }
            },
        }),
        getInfoUser: build.query<any, void>({
            query: () => {
                return {
                    url: `/get-me/`
                }
            },
            providesTags: ['ReadCurrentUser'],
        }),
        updateInfoUser: build.mutation<any, Partial<any> & Pick<any, 'id'>>({
            query: (body) => {
                return {
                    url: `/update/me/`,
                    method: 'PATCH',
                    body,
                }
            },
            invalidatesTags: ['ReadCurrentUser'],
        }),
    })
})

export const {
    useGetReadCurrentUserQuery,
    useLazyGetReadCurrentUserQuery,
    useLazyGetInfoUserQuery,
    useGetInfoUserQuery,
    useUpdateInfoUserMutation,
} = readCurrentUserApi;
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IGroup, IGroups} from "../../interfaces/IGroup";

export const groupApi = createApi({
    reducerPath: 'group/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['Group'],
    endpoints: build => ({
        getGroup: build.query<IGroups, any>({
            query: () => {
                return {
                    url: `/list/group/`
                }
            },
            providesTags: ['Group'],
        }),
        // getGroupById: build.query<IGroup, string>({
        //     query: (id: string) => ({
        //         url: `/detail/group/${id}`
        //     })
        // }),
        createGroup: build.mutation<any, any>({
            query: (body) => ({
                url: '/create/group/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Group'],
        }),
        updateGroup: build.mutation<any, Partial<any>>({
            query: ({body, id}) => ({
                url: `/update/group/${id}/`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Group'],
        }),
        removeGroup: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/delete/group/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Group'],
        })
    })
})

export const {
    useLazyGetGroupQuery,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useRemoveGroupMutation,
} = groupApi;
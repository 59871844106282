import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import SpinnerDotted from "../../../components/preloaders/SpinnerDotted/SpinnerDotted";
import {useLazyGetDocumentQuery} from "../../../store/documents/documents.api";

const Document = () => {
  const dispatch = useDispatch();
  const params = useParams();

  // const {oneDocument} = useSelector(state => state.documents);
  // const {loading} = useSelector(state => state.documents);
  const [fetchOneDocument, {isError: error,  isLoading: loading, data: oneDocument}] = useLazyGetDocumentQuery();

  useEffect(() => {
    // dispatch(fetchOneDocument(params.id));
    fetchOneDocument(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (

    <>

      {loading ?
        <div style={{position: 'relative'}}>
          <div style={{position: 'absolute', top: '40px', left: 'calc(45% - 20px)'}}>
            <SpinnerDotted style={{position: 'absolute', top: '30px'}} color={'#0a84fd'} size={'40'}/>
          </div>
        </div> :
        <div>
          {/*@ts-ignore*/}
          {Object.keys(oneDocument).length ? <div dangerouslySetInnerHTML={{__html: oneDocument.description}}/> : null}
        </div>
      }
    </>
  );
};

export default Document;

import React from 'react';

const EarthMap = () => {
  return (
    <div className="whole-place-wrapper">
      <iframe title="earth-map"
              className="third-party-app-iframe"
              src="https://collectearth.users.earthengine.app/view/emergency-compare"
      />
    </div>
  );
};

export default EarthMap;

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IRegionResponse, IRegion} from "../../interfaces/IRegions";

export const regionsApi = createApi({
    reducerPath: 'regions/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['Regions'],
    endpoints: build => ({
        getRegions: build.query<IRegionResponse[], string | void>({
            query: (query) => {
                return {
                    url: `/list/region/${query || ''}`
                }
            },
            providesTags: ['Regions'],
        }),
        // getUsersById: build.query<IRegion, string>({
        //     query: (id: string) => ({
        //         url: `/detail/users/${id}`
        //     })
        // }),
        createRegion: build.mutation<any, IRegion>({
            query: (body) => ({
                url: '/create/region/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Regions'],
        }),
        updateRegion: build.mutation<any, Partial<any> & Pick<any, 'id'>>({
            query: ({id, body}) => {
                return {
                    url: `/update/region/${id}/`,
                    method: 'PATCH',
                    body
                }
            },
            invalidatesTags: ['Regions'],
        }),
        removeRegion: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/delete/region/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Regions'],
        })
    })
})

export const {
    useGetRegionsQuery,
    useLazyGetRegionsQuery,
    useCreateRegionMutation,
    useUpdateRegionMutation,
    useRemoveRegionMutation
} = regionsApi;
import React from 'react';
import loadImg from '../../../assets/images/loading-light.gif';
import './MapLoader.sass';

const MapLoader = () => {
  return (
    <div className="map-loader">
      <img src={loadImg} alt=""/>
    </div>
  );
};

export default MapLoader;

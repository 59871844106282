import { IAssignmentsList } from "../../../../interfaces/IAssignments";
import { Status } from "../types";
import {sortArrayOfObjectsByKey} from "../../../../functions";

export interface IFilterSettings {
  sort_by?: string,
  sort_direction?: string,
  filter_by?: string
}

export const getTasksByStatus = (tasks: IAssignmentsList[], status: Status) => {
  return tasks && tasks.filter((task:IAssignmentsList) => task.status === status);
};

export const getTaskById = (tasks: IAssignmentsList[], id: string | number) => {
  return tasks.find((task:IAssignmentsList) => task.id === id);
};

export const descriptionEdit = (desc:any, amount: number) => {
  const deskArr:string[] = [];
  if(!desc) {
    return;
  }
  if (desc.length > amount) {
    desc.split('').forEach((d:string, i:number) => {
      if(i < amount) {
        deskArr.push(d);
      }
    });
    return deskArr.join('') + ' ...';
  }

  return desc;
};

export const cutBySpaceAboveSpecificLength = (text: string = '', length: number) => {
  const tmp = text.split(' ');
  let phrase = '';
  for (let word of tmp) {
    phrase += (word + ' ');
    if (phrase.length > length) {
      if (phrase.length > (length * 1.25)) phrase = phrase.substring(0, length);
      phrase += '...';
      break;
    }
  }
  return phrase;
};

export const sortingAndFilteringList = (array: IAssignmentsList[] = [], filter: IFilterSettings, username: string = '', status: string = ''): IAssignmentsList[] => {
  const usingFilter = () => {
    if (username) {
      return (status ? array.filter(t => t.status === status) : array).filter(t => {
        if (filter.filter_by === 'author') return t.author?.username === username;
        if (filter.filter_by === 'executor') return t.executors_report?.map((ex: {[key: string]: string | number}) => ex.username).includes(username);
        return true;
      });
    }
    return status ? array.filter(t => t.status === status) : array;
  };
  const filtered = usingFilter();
  const prioritySequence: string[] = ['highest', 'high', 'medium', 'low', 'lowest'];

  switch (filter.sort_by) {
    case 'created_at':
    case 'date':
      return sortArrayOfObjectsByKey(filtered, 'created_at', filter.sort_direction === 'desc');
    case 'priority':
      return (filter.sort_direction === 'desc' ? [...prioritySequence].reverse() : prioritySequence).map(ps => filtered.filter(t => t.priority === ps)).flat();
    case 'title':
      return sortArrayOfObjectsByKey(filtered, 'title', filter.sort_direction === 'desc');
    case 'description':
      return sortArrayOfObjectsByKey(filtered, 'description', filter.sort_direction === 'desc');
    case 'author':
      return sortArrayOfObjectsByKey(filtered.map(t => ({...t, author_name: t.author?.username || ''})), 'author_name', filter.sort_direction === 'desc');
    default:
      return filtered;
  }
};

import React from 'react';

const GeneralSettings = () => {
  return (
    <div>
      General settings
    </div>
  );
};

export default GeneralSettings;

import React from 'react';

const NewReport = () => {
  return (
    <div>
      New report
    </div>
  );
};

export default NewReport;

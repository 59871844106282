import React from 'react';

const Dictionaries = () => {
  return (
    <div>
      List of dictionaries
    </div>
  );
};

export default Dictionaries;

import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {
  CButton,
  CButtonGroup, CCollapse, CFormInput, CFormLabel, CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";

import {
  useLazyGetEmergenciesQuery,
  useRemoveEmergencyMutation
} from "../../../store/emergencies/emergencies.api";
import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import {Query} from "../../../functions";
import {useGetRegionsQuery} from "../../../store/regions/regions.api";
import {useGetDistrictsQuery} from "../../../store/districts/districts.api";
import {useGetCountiesQuery} from "../../../store/counties/counties.api";
import {useGetLocalitiesQuery} from "../../../store/localities/localities.api";
import {IPagination} from "../../../interfaces/IPagination";
import DefaultPaginationWrapper from "../../../components/UI/Pagination/DefaultPaginationWrapper";
import DateTimeRangePicker from "../../../components/UI/DateTimeRangePicker/DateTimeRangePicker";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";

const EsBase = () => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const {data: regions, isLoading: regionsLoading} = useGetRegionsQuery();
  const {data: districts, isLoading: districtsLoading} = useGetDistrictsQuery();
  const {data: counties, isLoading: countiesLoading} = useGetCountiesQuery();
  const {data: localities, isLoading: localitiesLoading} = useGetLocalitiesQuery();
  const [fetchEmergencies, {data: emergencies, isLoading: emergenciesLoading}] = useLazyGetEmergenciesQuery();
  const [removeEmergency] = useRemoveEmergencyMutation();

  const [pagination, setPagination] = useState<IPagination>({page_size: '100', search: '', page: 1});
  const [filter, setFilter] = useState({sort_by: 'title', sort_direction: 'asc'});
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [visible, setVisible] = useState<any[]>([]);
  const [dateRangeVisible, setDateRangeVisible] = useState(false);
  const [dateRange, setDateRange] = useState({start: '', end: ''})
  const countEmergency: number | undefined = emergencies?.results.length;

  useEffect(() => {
    const range: {[key: string]: string} = {};
    if (dateRange.start && dateRange.end) {
      range.created_at__gte = JSON.parse(JSON.stringify(dateRange.start));
      range.created_at__lte = JSON.parse(JSON.stringify(dateRange.end));
      // range.date_emergency__gte = JSON.parse(JSON.stringify(dateRange.start))
      // range.date_emergency__lte = JSON.parse(JSON.stringify(dateRange.end))
    }
    const query: {[key: string]: string | number} = {...pagination, ...filter, ...range};
    if (!query.search) delete query.search;
    fetchEmergencies(Query.stringify(query));
  }, [pagination, filter, dateRange]);

  useEffect(() => {
    let array: any[] = []
    if (emergencies?.results?.length) {
      array = emergencies.results.map(el => {
        return {id: el.id, value: false}
      })
      setVisible(array)
    }
  }, [emergencies]);

  const isLoading = useMemo(() => {
    return (emergenciesLoading || regionsLoading || districtsLoading || countiesLoading || localitiesLoading);
  }, [emergenciesLoading, regionsLoading, districtsLoading, countiesLoading, localitiesLoading]);

  const onPaginationHandler = (state: any) => {
    let innerState = {...pagination, ...state};
    
    if(countEmergency && countEmergency <= Number(pagination.page_size)) {
        innerState = {
          page_size: pagination.page_size,
          search: pagination.search,
          page: 1
          , ...state}
    }
    setPagination(innerState);
    // if (state.search === undefined) onSearchDataHandler({...innerState, ...filter});
  };

  const onFilterHandler = (field: string) => {
    const state = {...filter};
    if (field === state.sort_by) state.sort_direction = state.sort_direction === 'asc' ? 'desc' : 'asc';
    else {
      state.sort_by = field;
      state.sort_direction = 'asc';
    }
    setFilter(state);
    // onSearchDataHandler({...pagination, ...state});
  };

  // const onSearchCallbackHandler = (search: string) => {
  //   onSearchDataHandler({...pagination, ...filter, search});
  // };

  // const onSearchDataHandler = (data: any) => {
  //   const query: any = {};
  //   if (data.page_size) query.page_size = data.page_size;
  //   if (data.page) query.page = data.page;
  //   if (data.search && data.search !== '') query.search = data.search;
  //   if (data.sort_by) query.sort_by = data.sort_by;
  //   if (data.direction === 'desc') {
  //     query.direction = 'desc';
  //   } else {
  //     query.direction = 'asc'
  //   }
  //   // emergenciesRefetch();
  //   // fetchEmergencies(Query.stringify({...pagination, ...filter}));
  //
  // }

  const handleOpenDeleteModal = (id: any) => {
    setDeleteModal(true);
    setSelectedId(id);
  }

  const handleToggleDeleteModal = (visible: boolean) => {
    if (!visible) setSelectedId(null);
    setDeleteModal(visible)
  }

  const handleDelete = () => {
    removeEmergency(selectedId!).then(res => {
      // @ts-ignore
      if (res?.data === null) {
        handleToggleDeleteModal(false)
      }
    })
  }

  const handleRedirectToCreate = () => {
    navigate({pathname: '/es-report/create'})
  }

  const handleRedirectToUpdate = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>, id: number) => {
    e.preventDefault();
    navigate({pathname: `/es-report/update/${id}`})
  }

  const handleClickToAdditionalInformation = (id: number) => {
    // @ts-ignore
    setVisible(prevState => prevState.map((el: any) => {
      if (el.id === id) {
        return {...el, value: !el.value}
      }
      return {...el}
    }))
  }

  const handleSelectDates = (value: { start: Date; end: Date }) => {
    // @ts-ignore
    setDateRange(value)
    setDateRangeVisible(false)
  }

  const handleGetValue = (value: { start: Date; end: Date }) => {
    // @ts-ignore
    setDateRange(value);
  }

  let esList: any [] = [];

  if (emergencies?.results?.length) {
    esList = emergencies.results.map((el: any, idx: number) => {
      return (
        <CTableRow key={'user' + idx}>
          <CTableDataCell>
            <a href={`/es-base/detail/base/${el.id}`} target="_blank" className="es-base__title">{el.title}</a>
          </CTableDataCell>
          <CTableDataCell>
            <div style={{cursor: el.description.length > 150 ? 'pointer' : 'initial'}}
                 onClick={el.description.length > 150 ? () => handleClickToAdditionalInformation(el.id) : () => {
                 }}>
              {visible[idx]?.value ? el.description :
                <span>{el.description.substring(0, 150)}{el.description.length > 150 ? '...' : ''}</span>}
            </div>
          </CTableDataCell>
          <CTableDataCell>
            {
              el.locality ?
                localities?.find(item => el.locality === item.id)?.coat_code :
                el.county ?
                  counties?.find(item => el.county === item.id)?.coat_code :
                  el.district ?
                    districts?.find(item => el.district === item.id)?.coat_code :
                    el.region ?
                      regions?.find(item => el.region === item.id)?.coat_code : ''

            }
          </CTableDataCell>
          <CTableDataCell>{dayjs(el.created_at).format('DD.MM.YYYY HH:mm:ss')}</CTableDataCell>
          <CTableDataCell>{el.executor.first_name + " " + el.executor.last_name}</CTableDataCell>
          <CTableDataCell>{el.type_emergency_name}</CTableDataCell>
          <CTableDataCell>
            {el.field_emergency.length ?
              <div style={{background: 'transparent', padding: '8px'}}>
                <div className={visible[idx]?.value ? "visible-item" : "collapsable-item"}
                     onClick={() => handleClickToAdditionalInformation(el.id)}>
                  {
                    visible[idx]?.value ?
                      <i className="fa fa-chevron-down" aria-hidden="true" style={{marginRight: '10px'}}></i> :
                      <i className="fa fa-chevron-right" aria-hidden="true" style={{marginRight: '10px'}}></i>
                  }
                  <div style={{cursor: 'pointer', fontWeight: 'bold'}}>
                    {t("gis.geoObjects.additionalInformation")}
                  </div>
                </div>
                <CCollapse visible={visible[idx]?.value}>
                  <ul style={{marginTop: '20px', paddingLeft: '5px'}}>
                    {el.field_emergency.map((elem: any) => {
                      const meaning: string = elem.field_type_emergency?.type === 'date' ? dayjs(elem.meaning).format('DD.MM.YYYY')
                        : elem.field_type_emergency?.type === 'time' ? dayjs(elem.meaning).format('HH:mm:ss')
                          : elem.field_type_emergency?.type === 'datetime' ? dayjs(elem.meaning).format('DD.MM.YYYY HH:mm:ss')
                            : elem.field_type_emergency?.type === 'boolean' ? (elem.meaning === 'true' ? t('Yes') : t('No')) : elem.meaning || ' - ';
                      return (
                        <li key={elem.id + '_emergency-type'} className="li-dot">
                          <strong>{elem.field_type_emergency.name}:</strong> {" "} {meaning}
                        </li>
                      )
                    })}
                  </ul>
                </CCollapse>
              </div>
              : null}
          </CTableDataCell>
          <CTableDataCell className="text-center">
            <CButtonGroup size="sm">
              <CButton color="info" className="default-table__action-btn"
                       onClick={(e) => handleRedirectToUpdate(e, el.id)}
              >
                <i className="fa fa-pencil-square-o color-white" aria-hidden="true"> </i>
              </CButton>
              <CButton color="danger" className="default-table__action-btn"
                       onClick={() => handleOpenDeleteModal(el.id)}
              >
                <i className="fa fa-times color-white" aria-hidden="true"> </i>
              </CButton>
            </CButtonGroup>
          </CTableDataCell>
        </CTableRow>
      )
    })
  }

  return (
    <>
      <div className="pb-3 mb-4 title-divider">
        <h1>{t('nav.ES base')}</h1>
        <LoaderLine visible={isLoading}/>
      </div>

      <div className="d-flex justify-content-md-end mb-3">
        <CButton size="sm"
                 color="info"
                 className="color-white"
                 onClick={handleRedirectToCreate}
        >
          {t('esBase.createEs')}
        </CButton>
      </div>

      <div className="d-flex justify-content-md-end mb-3" style={{position: 'relative'}}>
        <div style={{width: '305px'}}>
          <CFormLabel>{t('Select dates')}</CFormLabel>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            {/*@ts-ignore*/}
            <CFormInput style={{borderRadius: '6px', width: '305px'}}
                        type="text"
                        value={(dateRange.start ? dayjs(dateRange.start).format("DD/MM/YYYY") + " - " : "") + (dateRange.end ? dayjs(dateRange.end).format("DD/MM/YYYY") : "")}
                        placeholder={dateRange.start || dateRange.end ? "" : t('Date is not selected')}
                        onClick={() => setDateRangeVisible(true)}
            />
          </div>

        </div>
        {dateRangeVisible && <div className="language-select-overlay" onClick={() => {
          setDateRangeVisible(false)
        }}/>}
        {dateRangeVisible ? <div style={{position: 'absolute', zIndex: 1031, top: '75px'}}>
          <DateTimeRangePicker locale={i18n.language} onChange={handleSelectDates} getValue={handleGetValue}/>
        </div> : null}
      </div>

      <DefaultPaginationWrapper data={emergencies}
                                state={pagination}
                                onChange={onPaginationHandler}
                                onSearchDelay={2000}
                                // onSearchCallback={onSearchCallbackHandler}
      >
        <CTable bordered striped className="default-table" style={{minWidth: '1038px'}}>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell style={{width: '10%'}}
                                scope="col"
                                className="sorted-table-cell"
                                onClick={() => onFilterHandler('title')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('labels.title')}</span>
                  {filter.sort_by === 'title' ?
                    <i className={`fa fa-sort-amount-${filter.sort_direction}`} aria-hidden="true"/> :
                    <i className="fa fa-sort" aria-hidden="true"/>
                  }
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '15%'}} scope="col"
                                className="sorted-table-cell"
                                onClick={() => onFilterHandler('description')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('labels.description')}</span>
                  {filter.sort_by === 'description' ?
                    <i className={`fa fa-sort-amount-${filter.sort_direction}`} aria-hidden="true"/> :
                    <i className="fa fa-sort" aria-hidden="true"/>
                  }
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '10%'}} scope="col"
                                className="sorted-table-cell"
              >
                <div className="d-flex justify-content-between">
                  <span>{t('labels.SOATE')}</span>
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '15%'}}
                                scope="col"
                                className="sorted-table-cell"
                                onClick={() => onFilterHandler('created_at')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('Date of message')}</span>
                  {filter.sort_by === 'created_at' ?
                    <i className={`fa fa-sort-amount-${filter.sort_direction}`} aria-hidden="true"/> :
                    <i className="fa fa-sort" aria-hidden="true"/>
                  }
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '10%'}}
                                scope="col"
                                className="sorted-table-cell"
                // onClick={() => onFilterHandler('last_name')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('labels.executor')}</span>
                  {/*{filter.sort_by === 'last_name' ?*/}
                  {/*  <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :*/}
                  {/*  <i className="fa fa-sort" aria-hidden="true"/>*/}
                  {/*}*/}
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '10%'}}
                                scope="col"
                                className="sorted-table-cell"
                                onClick={() => onFilterHandler('type_emergency__name')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('labels.type_emergency')}</span>
                  {filter.sort_by === 'type_emergency__name' ?
                    <i className={`fa fa-sort-amount-${filter.sort_direction}`} aria-hidden="true"/> :
                    <i className="fa fa-sort" aria-hidden="true"/>
                  }
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '25%'}}
                                scope="col"
                                className="sorted-table-cell"
              >
                <div className="d-flex justify-content-between">
                  <span>{t("gis.geoObjects.additionalInformation")}</span>
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell scope="col" className="default-table__actions">
                {t('settings.users.actions')}
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>

          <CTableBody>
            {esList}
          </CTableBody>
        </CTable>
      </DefaultPaginationWrapper>
      <DefaultModal visible={deleteModal}
                    setVisible={handleToggleDeleteModal}
                    title={t('deletion')}
                    type="danger"
                    cancelButton={t('cancel')}
                    onOk={handleDelete}
        // @ts-ignore
        //             error={error?.data?.error}
                    size="lg"
      >
        <div>
          <p className="mb-2 fs-6">{t('esBase.doYouReallyWantToDeleteAnEmergency')}</p>
          {/*@ts-ignore*/}
          <div className="fs-6 fw-bolder">{emergencies?.results?.find(el => el.id === selectedId)?.title}?</div>
        </div>
      </DefaultModal>
    </>
  );
};

export default EsBase;

const tiles = [
  {
    name: 'OpenStreetMap.Mapnik',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  },
  {
    name: 'OpenStreetMap',
    url: 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png'
  },
  {
    name: '2GIS',
    url: 'https://{s}.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1.4&r=g&ts=online_sd'
  },
  {
    name: 'Google.Hybrid',
    url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga'
  },
  {
    name: 'Yandex',
    url: 'http://vec{s}.maps.yandex.net/tiles?l=map&v=4.55.2&z={z}&x={x}&y={y}&scale=2&lang=ru_RU'
  },
  {
    name: 'Esri.WorldStreetMap',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
  },
  {
    name: 'Esri.WorldImagery',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
  },

  {
    name: 'Esri.WorldTerrain',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Terrain_Base/MapServer/tile/{z}/{y}/{x}'
  },
  {
    name: 'Esri.WorldShadedRelief',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}'
  },
  {
    name: 'Esri.WorldGrayCanvas',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}'
  },
  {
    name: 'OpenMapSurfer.Hillshade',
    url: 'https://maps.heigit.org/openmapsurfer/tiles/asterh/webmercator/{z}/{x}/{y}.png'
  },
  {
    name: 'Stamen.Terrain',
    url: 'https://stamen-tiles-{s}.a.ssl.fastly.net/terrain-background/{z}/{x}/{y}{r}.png'
  }
];

export function getTiles (name: string) {
  const idx = tiles.findIndex(el => el.name === name);
  if (idx < 0) return '';
  return tiles[idx].url;
}

export default tiles;

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IPaginationResponse} from "../models";
import {IEmergency} from "../../interfaces/IEmergency";

export const emergenciesApi = createApi({
    reducerPath: 'emergencies/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['emergency'],
    endpoints: build => ({
        getEmergencies: build.query<IPaginationResponse<IEmergency[]>, string | void>({
            query: (query) => {
                return {
                    url: `/list/emergency/${query || ''}`
                }
            },
            providesTags: ['emergency']
        }),
        getEsFeed: build.query<IEmergency[], string | void>({
            query: (query) => {
                return {
                    url: `/feed/emergency/${query || ''}`
                }
            }
        }),
        getEmergencyById: build.query<any, string | void>({
            query: id => {
                return {
                    url: `/detail/emergency/${id}/`
                }
            },
            providesTags: ['emergency']
        }),
        createEmergency: build.mutation<any, any>({
            query: (body) => ({
                url: '/create/emergency/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['emergency'],
        }),
        updateEmergency: build.mutation<any, any>({
            query: ({body, id}) => {
                return {
                    url: `/update/emergency/${id}/`,
                    method: 'PATCH',
                    body
                }
            },
            invalidatesTags: ['emergency'],
        }),
        removeEmergency: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/delete/emergency/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['emergency'],
        }),

        getEsFeedGeom: build.query<any, string | void>({
            query: (query) => {
                return {
                    url: `feed/geom/emergency/${query || ''}`
                }
            }
        }),

    })
})

export const {
    useCreateEmergencyMutation,
    useUpdateEmergencyMutation,
    useLazyGetEsFeedQuery,
    useLazyGetEmergenciesQuery,
    useRemoveEmergencyMutation,
    useGetEmergencyByIdQuery,
    useLazyGetEmergencyByIdQuery,
    useLazyGetEsFeedGeomQuery,
} = emergenciesApi
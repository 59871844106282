// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dtrp-calendar {
  padding: 0.25rem;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
}

.dtrp-calendar table {
  border-collapse: collapse;
}

.dtrp-calendar table th,
.dtrp-calendar table td {
  width: 2.25rem;
  height: 2rem;
  color: #000;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #d8dbe0;
  cursor: default;
  -webkit-user-select: none;
          user-select: none;
}

.dtrp-calendar table th.dtrp-month-pick-arrow {
  font-weight: bold;
  cursor: pointer;
}

.dtrp-calendar table td.dtrp-day {
  cursor: pointer;
  transition: background-color 0.1s ease;
}

.dtrp-calendar table td.dtrp-day.in-range {
  background-color: #dbf2ff;
}

.dtrp-calendar table td.dtrp-day:hover,
.dtrp-calendar table td.dtrp-day.in-range:hover {
  background-color: #c0defc;
}

.dtrp-calendar table td.dtrp-day.day-active {
  background-color: #4ab3ff;
}

.dtrp-calendar table td.dtrp-day.is-today {
  font-weight: bold;
}

.dtrp-calendar table td.dtrp-day.out-of-month {
  color: #999;
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/DateTimeRangePicker/Calendar.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,cAAc;EACd,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;EACtB,yBAAyB;EACzB,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".dtrp-calendar {\n  padding: 0.25rem;\n  border: 1px solid #d8dbe0;\n  border-radius: 0.25rem;\n}\n\n.dtrp-calendar table {\n  border-collapse: collapse;\n}\n\n.dtrp-calendar table th,\n.dtrp-calendar table td {\n  width: 2.25rem;\n  height: 2rem;\n  color: #000;\n  font-weight: normal;\n  text-align: center;\n  vertical-align: middle;\n  border: 1px solid #d8dbe0;\n  cursor: default;\n  user-select: none;\n}\n\n.dtrp-calendar table th.dtrp-month-pick-arrow {\n  font-weight: bold;\n  cursor: pointer;\n}\n\n.dtrp-calendar table td.dtrp-day {\n  cursor: pointer;\n  transition: background-color 0.1s ease;\n}\n\n.dtrp-calendar table td.dtrp-day.in-range {\n  background-color: #dbf2ff;\n}\n\n.dtrp-calendar table td.dtrp-day:hover,\n.dtrp-calendar table td.dtrp-day.in-range:hover {\n  background-color: #c0defc;\n}\n\n.dtrp-calendar table td.dtrp-day.day-active {\n  background-color: #4ab3ff;\n}\n\n.dtrp-calendar table td.dtrp-day.is-today {\n  font-weight: bold;\n}\n\n.dtrp-calendar table td.dtrp-day.out-of-month {\n  color: #999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, {FC} from 'react';
import {IAnalyticsTemplate} from "../../../interfaces/IAnalyticsTemplates";
import {
  CFormLabel,
  CTabContent,
  CTable, CTableBody, CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTabPane
} from "@coreui/react";
import Select, {StylesConfig} from "react-select";
import {useTranslation} from "react-i18next";

import {TSelectOption} from "./AnalyticReport";
import DonutChart from "./Charts/DonutChart";
import BarChart from "./Charts/BarChart";
import PolarChart from "./Charts/PolarChart";

interface IDataDisplayProps {
  data: IAnalyticsTemplate[];
  activeKey: number;
  setActiveKey: (key: number) => void;
  onSelect: (data: TSelectOption, name: string) => void;
  diagramState: { [key: string]: TSelectOption };
}

const DataDisplay: FC<IDataDisplayProps> = ({data, activeKey, setActiveKey, onSelect, diagramState}) => {
  const {t} = useTranslation();

  const analyticsTemplateTable = data.map(el => {
    return (
      <CTableRow key={el.id}>
        <CTableDataCell>{el.name}</CTableDataCell>
        <CTableDataCell>{el.count_emergency}</CTableDataCell>
        <CTableDataCell>{el.human_casualties}</CTableDataCell>
        <CTableDataCell>{el.material_damage}</CTableDataCell>
      </CTableRow>
    )
  })
  let totalAnalyticsData: any[] = [], totalAnalyticsTemplateTable: any[] = []


  if (data.length) {
    // @ts-ignore
    totalAnalyticsData = data?.reduce((acc: any, currentValue: any) => {
      return {
        count_emergency: acc.count_emergency + currentValue.count_emergency,
        human_casualties: acc.human_casualties + currentValue.human_casualties,
        material_damage: acc.material_damage + currentValue.material_damage
      }
    })
    totalAnalyticsTemplateTable.push(totalAnalyticsData)
    totalAnalyticsTemplateTable = totalAnalyticsTemplateTable.map((el: any, index: number) => {
      return (
        <CTableRow key={'total_analytics' + index}>
          <CTableDataCell></CTableDataCell>
          <CTableDataCell>{el.count_emergency}</CTableDataCell>
          <CTableDataCell>{el.human_casualties}</CTableDataCell>
          <CTableDataCell>{el.material_damage}</CTableDataCell>
        </CTableRow>
      )
    })
  }

  const diagramTypesOptions: TSelectOption[] = [
    {label: t("analytics.donut"), value: 'donut'},
    {label: t("analytics.polar"), value: 'polar'},
    {label: t("analytics.bar"), value: 'bar'}
  ]

  const fieldGroup: TSelectOption[] = [
    {label: t("analytics.count_emergency"), value: 'count_emergency'},
    {label: t("analytics.human_casualties"), value: 'human_casualties'},
    {label: t("analytics.material_damage"), value: 'material_damage'},
  ];

  const colorStyles: StylesConfig<any> = {
    control: (styles) => (
      {
        ...styles,
        backgroundColor: '#ffffff',
        border: '1px solid grey',
        boxShadow: 'none',
        borderRadius: '6px',
        maxHeight: 31,
        fontSize: '14px'
      }
    )
  };

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'flex-start'}}>
        <div className={activeKey === 1 ? 'active-tab' : 'no-active-tab'}
             style={{display: 'flex', justifyContent: 'center'}} onClick={() => setActiveKey(1)}>
          {t("analytics.dataTable")}
        </div>
        <div className={activeKey === 2 ? 'active-tab' : 'no-active-tab'}
             style={{display: 'flex', justifyContent: 'center'}} onClick={() => setActiveKey(2)}>
          {t("analytics.visualization")}
        </div>
      </div>
      <CTabContent>
        <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 1}
                  style={{padding: '15px', background: "white"}}>
          <CTable bordered striped className="default-table mb-4">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell style={{width: '53%'}}
                                  scope="col"
                                  className="sorted-table-cell">
                  {t("settings.esTypes.emergencyType")}
                </CTableHeaderCell>
                <CTableHeaderCell style={{width: '15%'}}
                                  scope="col"
                                  className="sorted-table-cell">
                  {t("analytics.count_emergency")}
                </CTableHeaderCell>
                <CTableHeaderCell style={{width: '17%'}}
                                  scope="col"
                                  className="sorted-table-cell">
                  {t("analytics.human_casualties")}
                </CTableHeaderCell>
                <CTableHeaderCell style={{width: '15%'}}
                                  scope="col"
                                  className="sorted-table-cell">
                  {t("analytics.material_damage")}
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {analyticsTemplateTable}
            </CTableBody>
          </CTable>

          <CTable bordered striped className="default-table mb-4">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell style={{width: '53%'}}
                                  scope="col"
                                  className="sorted-table-cell">

                </CTableHeaderCell>
                <CTableHeaderCell style={{width: '15%'}}
                                  scope="col"
                                  className="sorted-table-cell">
                  {t("analytics.count_emergency")}
                </CTableHeaderCell>
                <CTableHeaderCell style={{width: '17%'}}
                                  scope="col"
                                  className="sorted-table-cell">
                  {t("analytics.human_casualties")}
                </CTableHeaderCell>
                <CTableHeaderCell style={{width: '15%'}}
                                  scope="col"
                                  className="sorted-table-cell">
                  {t("analytics.material_damage")}
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {totalAnalyticsTemplateTable}
            </CTableBody>
          </CTable>
        </CTabPane>
        <CTabPane role="tabpanel" aria-labelledby="profile-tab" visible={activeKey === 2}
                  style={{margin: '0 1px 0 1px', padding: '15px', background: "white"}}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{width: '30%'}}>
              <div style={{width: '90%'}} className="mb-4">
                <CFormLabel>{t("analytics.typesOfDiagrams")}</CFormLabel>
                <Select options={diagramTypesOptions}
                  // value={diagramTypesOptions.find(el => el.value === diagramState?.value)}
                        styles={colorStyles}
                        placeholder={'Select one'}
                        onChange={e => onSelect(e, 'diagram_type')}
                        isClearable
                />
              </div>

              <div style={{width: '90%'}}>
                <CFormLabel>{t("analytics.specifyTheFieldToGroup")}</CFormLabel>
                <Select options={fieldGroup}
                  // value={diagramTypesOptions.find(el => el.value === diagramState?.value)}
                        styles={colorStyles}
                        placeholder={'Select one'}
                        onChange={e => onSelect(e, 'field')}
                        isClearable
                />
              </div>

            </div>
            <div style={{width: '50%'}} className="mb-5">
              {
                diagramState?.diagram_type?.value === 'donut' ?
                  // @ts-ignore
                  <DonutChart labels={data.map(el => el.name)} data={data.map(el => el[diagramState?.field?.value])}
                              field={diagramState?.field?.value}/> :
                  diagramState?.diagram_type?.value === 'polar' ?
                    // @ts-ignore
                    <PolarChart labels={data.map(el => el.name)} data={data.map(el => el[diagramState?.field?.value])}/>
                    // <LineChart labels={data.map(el => el.name)} data={data} diagramType={diagramState}/>
                    : diagramState?.diagram_type?.value === 'bar' ?
                      // @ts-ignore
                      <BarChart labels={data.map(el => el.name)} data={data.map(el => el[diagramState?.field?.value])}
                                field={diagramState?.field?.value}/> : ''}
            </div>
            <div style={{width: '10%'}}></div>
          </div>

        </CTabPane>
      </CTabContent>
    </>
  );
};

export default DataDisplay;
import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import Select, {StylesConfig} from 'react-select';
import {useTranslation} from "react-i18next";
import {
  CButton, CCard, CCardBody, CCardHeader, CCardTitle,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormTextarea
} from "@coreui/react";
import {Marker} from 'react-leaflet';
import Leaflet from 'leaflet';
import dayjs from 'dayjs';
import DatePicker, {registerLocale} from 'react-datepicker';
import {enUS, ru} from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import 'dayjs/locale/ky';

import makeQuery from "../../../functions/makeQuery";
import {
  useGetEmergencyTypesQuery,
} from "../../../store/emergencyTypes/emergencyTypes.api";
import {useGetRegionsQuery} from "../../../store/regions/regions.api";
import {useGetDistrictsQuery} from "../../../store/districts/districts.api";
import {useGetCountiesQuery} from "../../../store/counties/counties.api";
import {useGetLocalitiesQuery} from "../../../store/localities/localities.api";
import {cilX} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  useCreateEmergencyMutation,
  useLazyGetEmergencyByIdQuery, useUpdateEmergencyMutation
} from "../../../store/emergencies/emergencies.api";
import {
  useCreateAffectedPopulationMutation,
  useLazyGetAffectedPopulationByIdQuery, useUpdateAffectedPopulationMutation
} from "../../../store/affectedPopulation/affectedPopulation.api";
import {
  useCreateEmergencyFileMutation, useDeleteEmergencyFileMutation, useLazyGetEmergencyFilesQuery
} from "../../../store/emergencyFiles/emergencyFiles.api";
import {initialEmergencyState, initialMainState} from "./initialConfig";
import BaseMap from "../../../components/maps/BaseMap";
import AccordionEsReport from "./AccordionEsReport";
import {IEmergencyType} from "../../../interfaces/IEmergency";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";
import SpinnerDotted from "../../../components/preloaders/SpinnerDotted/SpinnerDotted";
import TimePicker from "../../../components/UI/TimePicker/TimePicker";

interface ICurrentEsType {
  id: number
  name: string
  type: string
  value: number | string | Date | boolean | any[] | null,
  field_id?: string | number
}

type TSelectOptions = {
  label: string,
  value: string | number
};

const reactSelectControlStyles = (baseStyles: any, state: any): any => {
  return {
    ...baseStyles,
    '&:hover': {
      borderColor: state.isFocused ? '#998fed' : '#d8dbe0'
    },
    borderColor: state.isFocused ? '#998fed' : '#d8dbe0',
    boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(50, 31, 219, 0.25)' : 'unset',
    marginBottom: '5px'
  }
};

registerLocale('en', enUS);
registerLocale('ru', ru);
registerLocale('ky', ru);

const NewEsReport = () => {
  const {t, i18n} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [mainState, setMainState] = useState(initialMainState);
  const [currentEsType, setCurrentEsType] = useState<ICurrentEsType[]>([]);
  const [title, setTitle] = useState<string>('');
  const [filesState, setFilesState] = useState<any[]>([]);
  const [emergencyFilesState, setEmergencyFilesState] = useState<any[]>([]);
  const [filesForDelete, setFilesForDelete] = useState<number[]>([]);
  const [emergencyState, setEmergencyState] = useState(initialEmergencyState);
  const [affectedPopulationId, setAffectedPopulationId] = useState(null);
  const [firstLoading, setFirstLoading] = useState(false);
  const [titleManuallyChanged, setTitleManuallyChanged] = useState(false);

  const {data: responseEmergencyTypes, isLoading: emergencyTypesLoading} = useGetEmergencyTypesQuery(makeQuery.stringify({page_size: 100000, sort_by: 'name', sort_direction: 'asc'}));
  const {data: regions, isLoading: regionsLoading} = useGetRegionsQuery();
  const {data: districts, isLoading: districtsLoading} = useGetDistrictsQuery();
  const {data: counties, isLoading: countiesLoading} = useGetCountiesQuery();
  const {data: locality, isLoading: localityLoading} = useGetLocalitiesQuery();
  const [createEmergency, {isLoading: createIsLoading}] = useCreateEmergencyMutation();
  const [updateEmergency, {isLoading: updateIsLoading}] = useUpdateEmergencyMutation();
  const [createAffectedPopulation, {isLoading: createAffectedIsLoading}] = useCreateAffectedPopulationMutation();
  const [createEmergencyFile, {isLoading: createFileIsLoading}] = useCreateEmergencyFileMutation();
  const [getEmergencyById, {data: emergencyById, isLoading: getEmergencyLoading}] = useLazyGetEmergencyByIdQuery();
  const [getAffectedPopulationById] = useLazyGetAffectedPopulationByIdQuery();
  const [updateAffectedPopulation, {isLoading: updateAffectedIsLoading}] = useUpdateAffectedPopulationMutation();
  const [fetchEmergencyFiles, {data: emergencyFiles}] = useLazyGetEmergencyFilesQuery();
  const [deleteEmergencyFile] = useDeleteEmergencyFileMutation();
  const [savingError, setSavingError] = useState<{[key: string]: string} | null>(null);

  const id = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const isLoading = useMemo(() => {
    return (emergencyTypesLoading || getEmergencyLoading || regionsLoading || districtsLoading || countiesLoading || localityLoading);
  }, [emergencyTypesLoading, getEmergencyLoading, regionsLoading, districtsLoading, countiesLoading, localityLoading]);

  const saveIsLoading = useMemo(() => {
    return (createIsLoading || updateIsLoading || createAffectedIsLoading || createFileIsLoading || updateAffectedIsLoading);
  }, [createIsLoading, updateIsLoading, createAffectedIsLoading, createFileIsLoading, updateAffectedIsLoading]);

  useEffect(() => {
    let array: any[] = []
    if (emergencyFiles?.length) {
      array = emergencyFiles.map((el: any) => {
        return {
          id: el.id,
          emergency: el.emergency,
          name: el.file.split('/')[el.file.split('/').length - 1],
          file: el.file
        }
      })
      setEmergencyFilesState(array)
    }
  }, [emergencyFiles]);

  useEffect(() => {
    if (location.pathname.includes('/es-report/create') && !titleManuallyChanged) {
      let autoTitle = '';
      if (dayjs(mainState.date_emergency).format() !== 'Invalid Date') {
        autoTitle += (dayjs(mainState.date_emergency).format("YYYY") + '-жыл.' + dayjs(mainState.date_emergency).format('MM-DD'));
      }
      if (mainState.region) {
        autoTitle += (autoTitle ? ' - ' : '') + regions?.find(el => String(el.id) === mainState.region)?.name || '';
      }
      if (mainState.type_emergency) {
        autoTitle += (autoTitle ? ' - ' : '') + responseEmergencyTypes?.results?.find(el => el.id === mainState.type_emergency)?.name;
      }
      setTitle(autoTitle);
      if (autoTitle !== mainState.title) setMainState(prevState => ({...prevState, title: autoTitle}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainState]);

  useEffect(() => {
    let array: any[] = [], value: any, newArray: any[] = [];

    if (mainState.type_emergency !== 0 && responseEmergencyTypes?.results?.length && location.pathname.includes('/es-report/update/') && !firstLoading) {
      // @ts-ignore
      responseEmergencyTypes?.results?.find(el => el.id === mainState.type_emergency)?.field_type_emergency?.forEach(el => {
        if (el.type === 'number') {
          value = 0;
        }
        if (el.type === 'textarea' || el.type === 'string') {
          value = ''
        }
        if (el.type === 'time') {
          value = null;
        }
        if (el.type === 'date' || el.type === 'datetime') {
          // value = new Date();
          value = '';
        }
        if (el.type === 'boolean') {
          value = false;
        }
        if (el.type === 'image') {
          value = []
        }
        array.push({...el, value})
      })

      console.log(emergencyById);

      emergencyById?.properties?.field_emergency?.forEach((el: any) => {
        newArray.push({
          field_id: el.id,
          id: el.field_type_emergency.id,
          name: el.field_type_emergency.name,
          type: el.field_type_emergency.type,
          value: el.meaning
        })
      })

      newArray.forEach(el => {
        array.find(elem => el.id === elem.id).value = el.value === "false" ? false : el.value === "true" ? true : el.value
        array.find(elem => el.id === elem.id).field_id = el.field_id
      })

      setCurrentEsType(array)
      setFirstLoading(true)
    }
  }, [mainState, responseEmergencyTypes, id]);

  useEffect(() => {
    if (!isNaN(Number(id)) && location.pathname.includes('/es-report/update/')) {
      getEmergencyById(id).then(res => {
        setMainState({
          type_emergency: res.data?.properties?.type_emergency?.id,
          type_report: res.data?.properties?.type_report,
          date_emergency: res.data?.properties?.date_emergency,
          region: String(res.data?.properties?.region),
          district: String(res.data?.properties?.district),
          county: String(res.data?.properties?.county),
          locality: String(res.data?.properties?.locality),
          title: res.data?.properties?.title,
          description: res.data?.properties?.description,
          material_damage: res.data?.properties?.material_damage,
          human_casualties: res.data?.properties?.human_casualties,
          latitude: Number(res.data?.properties?.latitude),
          longitude: Number(res.data?.properties?.longitude),
          status: res.data?.properties?.status,
          late_information: res.data?.properties?.late_information,
          field_emergency: []
        })

        if (res.data?.properties?.type_report === 'emergency') {
          getAffectedPopulationById(res.data.properties.id).then(result => {
            setAffectedPopulationId(result?.data?.id)
            if (result.data) {
              let data: any = {...emergencyState}
              Object.entries(result.data).forEach(elem => {
                Object.entries(emergencyState).forEach(el => {
                  if (elem[0] === el[0]) {
                    data[el[0]] = elem[1]
                  }
                })
              })
              setEmergencyState(data)
            }
          })
        }

        setTitle(res.data?.properties?.title);
        setTitleManuallyChanged(true);
      })
      fetchEmergencyFiles(id);
    } else {
      setMainState(initialMainState);
      setEmergencyState(initialEmergencyState);
      setCurrentEsType([]);
      setFilesState([]);
      setEmergencyFilesState([]);
      setFilesForDelete([]);
      setTitle('')
    }
  }, [location.pathname]);

  const emergencyTypes: IEmergencyType[] = useMemo(() => {
    return responseEmergencyTypes?.results || [];
  }, [responseEmergencyTypes]);

  const handleSelectEmergencyType = (e: any, name: string) => {
    let array: any[] = [], value: any, newArray: any[] = [];
    // @ts-ignore
    responseEmergencyTypes?.results?.find(el => el.id === e?.value)?.field_type_emergency?.forEach(el => {
      if (el.type === 'number') {
        value = 0;
      }
      if (el.type === 'textarea' || el.type === 'string') {
        value = ''
      }
      if (el.type === 'time') {
        value = null;
      }
      if (el.type === 'date' || el.type === 'datetime') {
        // value = new Date();
        value = '';
      }
      if (el.type === 'boolean') {
        value = false;
      }
      if (el.type === 'image') {
        value = []
      }
      array.push({...el, value, field_id: ''})
      setCurrentEsType(array)
    })

    if (e !== null) {
      setMainState(prevState => ({...prevState, [name]: e.value}))
    } else {
      setMainState(prevState => ({...prevState, [name]: 0}))
    }
  }

  const handleSelect = (e: any, name: string) => {
    const innerState = {...mainState}

    if (name === 'region') {
      innerState.district = '';
      innerState.county = '';
      innerState.locality = '';
    }
    if (name === 'district') {
      innerState.county = '';
      innerState.locality = '';
    }
    if (name === 'county') {
      innerState.locality = '';
    }

    // @ts-ignore
    innerState[name] = (e !== null) ? e?.value : '';
    setMainState(innerState);
  }

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;

    if (name === 'status') {
      setMainState(prevState => {
        return {...prevState, [name]: mainState.status === 'in processing' ? 'completed' : 'in processing'}
      })
    } else if (name === 'late_information') {
      setMainState(prevState => {
        return {...prevState, [name]: !mainState.late_information}
      })
    } else {
      setMainState(prevState => {
        return {...prevState, [name]: value}
      })
    }
  }

  const handleSelectDateTime = (date: any) => {
    setMainState(prevState => {
      return {...prevState, date_emergency: date}
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = e.target;
    if (name === 'title') {
      setTitle(value);
      setTitleManuallyChanged(true);
    }
    setMainState(prevState => ({...prevState, [name]: value}));
  }

  const changeCustomTimeHandler = (value: string | null, type: string, id: number) => {
    if (type === 'time') {
      setCurrentEsType(prevState => prevState.map((el) => {
        if (el.id === id) {
          return {...el, value: value}
        }
        return {...el}
      }));
    }
  };

  const handleCustom = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | Date>, type: string, id: number) => {
    if (type === 'boolean') {
      setCurrentEsType(prevState => prevState.map((el) => {
        if (el.id === id) {
          return {...el, value: !el.value}
        }
        return {...el}
      }));
    } else if (type === 'date' || type === 'datetime') {
      // @ts-ignore
      setCurrentEsType(prevState => prevState.map((el) => {
        if (el.id === id) {
          // @ts-ignore
          return {...el, value: e}
        }
        return {...el}
      }));
    } else if (type === 'image') {
      // @ts-ignore
      setCurrentEsType(prevState => prevState.map(el => {
        if (el.id === id) {
          // @ts-ignore
          let arr = [...el.value]

          // @ts-ignore
          const chosenFiles = Array.prototype.slice.call(e.target.files)
          chosenFiles.forEach(elem => {
            if (!arr.some(el => el.name === elem.name)) {
              arr.push(elem);
            }
          })
          return {...el, value: arr}
        }
        return {...el}
      }))
    } else {
      setCurrentEsType(prevState => prevState.map((el) => {
        if (el.id === id) {
          // @ts-ignore
          return {...el, value: e.target.value}
        }
        return {...el}
      }));
    }
  }

  const handleDeleteCustomPicture = (e: React.MouseEvent<HTMLDivElement>, name: string, id: number) => {
    let array = currentEsType.find(el => el.id === id)?.value!;
    // @ts-ignore
    if (array.length) {
      let index;
      // @ts-ignore
      index = array.findIndex(el => el.name === name);
      // @ts-ignore
      array.splice(index, 1)
    }
    setCurrentEsType(prevState => prevState.map((el) => {
      if (el.id === id) {
        return {...el, value: array}
      }
      return {...el}
    }))
  }

  const handleChangeEmergency = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    const state = {...emergencyState, [name]: Number(value)}

    if (name === 'zero_five_men_dead_and_missing' ||
      name === 'six_seventeen_men_dead_and_missing' ||
      name === 'eighteen_fifty_nine_men_dead_and_missing' ||
      name === 'sixty_and_older_men_dead_and_missing' ||
      name === 'zero_five_women_dead_and_missing' ||
      name === 'six_seventeen_women_dead_and_missing' ||
      name === 'eighteen_fifty_nine_women_dead_and_missing' ||
      name === 'sixty_and_older_women_dead_and_missing') {
      state.men_dead_and_missing = state.zero_five_men_dead_and_missing + state.six_seventeen_men_dead_and_missing + state.eighteen_fifty_nine_men_dead_and_missing + state.sixty_and_older_men_dead_and_missing;
      state.women_dead_and_missing = state.zero_five_women_dead_and_missing + state.six_seventeen_women_dead_and_missing + state.eighteen_fifty_nine_women_dead_and_missing + state.sixty_and_older_women_dead_and_missing;
      state.qty_dead_and_missing = state.men_dead_and_missing + state.women_dead_and_missing;
    } else if (name === 'zero_five_men_dead' ||
      name === 'six_seventeen_men_dead' ||
      name === 'eighteen_fifty_nine_men_dead' ||
      name === 'sixty_and_older_men_dead' ||
      name === 'zero_five_women_dead' ||
      name === 'six_seventeen_women_dead' ||
      name === 'eighteen_fifty_nine_women_dead' ||
      name === 'sixty_and_older_women_dead') {
      state.men_dead = state.zero_five_men_dead + state.six_seventeen_men_dead + state.eighteen_fifty_nine_men_dead + state.sixty_and_older_men_dead;
      state.women_dead = state.zero_five_women_dead + state.six_seventeen_women_dead + state.eighteen_fifty_nine_women_dead + state.sixty_and_older_women_dead;
      state.qty_dead = state.men_dead + state.women_dead;
    } else if (name === 'zero_five_men_missing' ||
      name === 'six_seventeen_men_missing' ||
      name === 'eighteen_fifty_nine_men_missing' ||
      name === 'sixty_and_older_men_missing' ||
      name === 'zero_five_women_missing' ||
      name === 'six_seventeen_women_missing' ||
      name === 'eighteen_fifty_nine_women_missing' ||
      name === 'sixty_and_older_women_missing') {
      state.men_missing = state.zero_five_men_missing + state.six_seventeen_men_missing + state.eighteen_fifty_nine_men_missing + state.sixty_and_older_men_missing;
      state.women_missing = state.zero_five_women_missing + state.six_seventeen_women_missing + state.eighteen_fifty_nine_women_missing + state.sixty_and_older_women_missing;
      state.qty_missing = state.men_missing + state.women_missing;
    } else if (name === 'zero_five_men_affected' ||
      name === 'six_seventeen_men_affected' ||
      name === 'eighteen_fifty_nine_men_affected' ||
      name === 'sixty_and_older_men_affected' ||
      name === 'zero_five_women_affected' ||
      name === 'six_seventeen_women_affected' ||
      name === 'eighteen_fifty_nine_women_affected' ||
      name === 'sixty_and_older_women_affected') {
      state.men_affected = state.zero_five_men_affected + state.six_seventeen_men_affected + state.eighteen_fifty_nine_men_affected + state.sixty_and_older_men_affected;
      state.women_affected = state.zero_five_women_affected + state.six_seventeen_women_affected + state.eighteen_fifty_nine_women_affected + state.sixty_and_older_women_affected;
      state.qty_affected = state.men_affected + state.women_affected;
    } else if (name === 'zero_five_men_injured_but_not_hospitalized' ||
      name === 'six_seventeen_men_injured_but_not_hospitalized' ||
      name === 'eighteen_fifty_nine_men_injured_but_not_hospitalized' ||
      name === 'sixty_and_older_men_injured_but_not_hospitalized' ||
      name === 'zero_five_women_injured_but_not_hospitalized' ||
      name === 'six_seventeen_women_injured_but_not_hospitalized' ||
      name === 'eighteen_fifty_nine_women_injured_but_not_hospitalized' ||
      name === 'sixty_and_older_women_injured_but_not_hospitalized') {
      state.men_injured_but_not_hospitalized = state.zero_five_men_injured_but_not_hospitalized + state.six_seventeen_men_injured_but_not_hospitalized + state.eighteen_fifty_nine_men_injured_but_not_hospitalized + state.sixty_and_older_men_injured_but_not_hospitalized;
      state.women_injured_but_not_hospitalized = state.zero_five_women_injured_but_not_hospitalized + state.six_seventeen_women_injured_but_not_hospitalized + state.eighteen_fifty_nine_women_injured_but_not_hospitalized + state.sixty_and_older_women_injured_but_not_hospitalized;
      state.qty_injured_but_not_hospitalized = state.men_injured_but_not_hospitalized + state.women_injured_but_not_hospitalized;
    } else if (name === 'zero_five_men_injured_hospitalized' ||
      name === 'six_seventeen_men_injured_hospitalized' ||
      name === 'eighteen_fifty_nine_men_injured_hospitalized' ||
      name === 'sixty_and_older_men_injured_hospitalized' ||
      name === 'zero_five_women_injured_hospitalized' ||
      name === 'six_seventeen_women_injured_hospitalized' ||
      name === 'eighteen_fifty_nine_women_injured_hospitalized' ||
      name === 'sixty_and_older_women_injured_hospitalized') {
      state.men_injured_hospitalized = state.zero_five_men_injured_hospitalized + state.six_seventeen_men_injured_hospitalized + state.eighteen_fifty_nine_men_injured_hospitalized + state.sixty_and_older_men_injured_hospitalized;
      state.women_injured_hospitalized = state.zero_five_women_injured_hospitalized + state.six_seventeen_women_injured_hospitalized + state.eighteen_fifty_nine_women_injured_hospitalized + state.sixty_and_older_women_injured_hospitalized;
      state.qty_injured_hospitalized = state.men_injured_hospitalized + state.women_injured_hospitalized;
    } else if (name === 'zero_five_men_who_left_their_place_of_residence' ||
      name === 'six_seventeen_men_who_left_their_place_of_residence' ||
      name === 'eighteen_fifty_nine_men_who_left_their_place_of_residence' ||
      name === 'sixty_and_older_men_who_left_their_place_of_residence' ||
      name === 'zero_five_women_who_left_their_place_of_residence' ||
      name === 'six_seventeen_women_who_left_their_place_of_residence' ||
      name === 'eighteen_fifty_nine_women_who_left_their_place_of_residence' ||
      name === 'sixty_and_older_women_who_left_their_place_of_residence') {
      state.men_who_left_their_place_of_residence = state.zero_five_men_who_left_their_place_of_residence + state.six_seventeen_men_who_left_their_place_of_residence + state.eighteen_fifty_nine_men_who_left_their_place_of_residence + state.sixty_and_older_men_who_left_their_place_of_residence;
      state.women_who_left_their_place_of_residence = state.zero_five_women_who_left_their_place_of_residence + state.six_seventeen_women_who_left_their_place_of_residence + state.eighteen_fifty_nine_women_who_left_their_place_of_residence + state.sixty_and_older_women_who_left_their_place_of_residence;
      state.qty_who_left_their_place_of_residence = state.men_who_left_their_place_of_residence + state.women_who_left_their_place_of_residence;
    } else if (name === 'zero_five_men_temporarily_left_the_place_of_residence' ||
      name === 'six_seventeen_men_temporarily_left_the_place_of_residence' ||
      name === 'eighteen_fifty_nine_men_temporarily_left_the_place_of_residence' ||
      name === 'sixty_and_older_men_who_temporarily_left_the_place_of_residence' ||
      name === 'zero_five_women_temporarily_left_the_place_of_residence' ||
      name === 'six_seventeen_women_temporarily_left_the_place_of_residence' ||
      name === 'eighteen_fifty_nine_women_temporarily_left_the_place_of_residence' ||
      name === 'sixty_and_older_women_who_temporarily_left_the_place_of_residence') {
      state.men_temporarily_left_the_place_of_residence = state.zero_five_men_temporarily_left_the_place_of_residence + state.six_seventeen_men_temporarily_left_the_place_of_residence + state.eighteen_fifty_nine_men_temporarily_left_the_place_of_residence + state.sixty_and_older_men_who_temporarily_left_the_place_of_residence;
      state.women_temporarily_left_the_place_of_residence = state.zero_five_women_temporarily_left_the_place_of_residence + state.six_seventeen_women_temporarily_left_the_place_of_residence + state.eighteen_fifty_nine_women_temporarily_left_the_place_of_residence + state.sixty_and_older_women_who_temporarily_left_the_place_of_residence;
      state.qty_temporarily_left_the_place_of_residence = state.men_temporarily_left_the_place_of_residence + state.women_temporarily_left_the_place_of_residence;
    } else if (name === 'zero_five_men_resettled' ||
      name === 'six_seventeen_men_resettled' ||
      name === 'eighteen_fifty_nine_men_resettled' ||
      name === 'sixty_and_older_men_who_resettled' ||
      name === 'zero_five_women_resettled' ||
      name === 'six_seventeen_women_resettled' ||
      name === 'eighteen_fifty_nine_women_resettled' ||
      name === 'sixty_and_older_women_who_resettled') {
      state.men_resettled = state.zero_five_men_resettled + state.six_seventeen_men_resettled + state.eighteen_fifty_nine_men_resettled + state.sixty_and_older_men_who_resettled;
      state.women_resettled = state.zero_five_women_resettled + state.six_seventeen_women_resettled + state.eighteen_fifty_nine_women_resettled + state.sixty_and_older_women_who_resettled;
      state.qty_resettled = state.men_resettled + state.women_resettled;
    } else if (name === 'first_third_degre_of_damage_individual_houses' ||
      name === 'fourth_fifth_degre_of_damage_individual_houses' ||
      name === 'first_third_degre_of_damage_apartment_buildings' ||
      name === 'fourth_fifth_degre_of_damage_apartment_buildings') {
      state.qty_individual_houses = state.first_third_degre_of_damage_individual_houses + state.fourth_fifth_degre_of_damage_individual_houses;
      state.qty_apartment_buildings = state.first_third_degre_of_damage_apartment_buildings + state.fourth_fifth_degre_of_damage_apartment_buildings;
      state.qty_damaged_houses = state.qty_individual_houses + state.qty_apartment_buildings;
    } else if (name === 'first_third_degre_of_damage_preschool' ||
      name === 'fourth_fifth_degre_of_damage_preschool' ||
      name === 'first_third_degre_of_damage_general_educational_institutions' ||
      name === 'fourth_fifth_degre_of_damage_general_educational_institutions' ||
      name === 'first_third_degre_of_damage_secondary_special_and_higher_educational_institutions' ||
      name === 'fourth_fifth_degre_of_damage_secondary_special_and_higher_educational_institutions') {
      state.preschool = state.first_third_degre_of_damage_preschool + state.fourth_fifth_degre_of_damage_preschool;
      state.general_educational_institutions = state.first_third_degre_of_damage_general_educational_institutions + state.fourth_fifth_degre_of_damage_general_educational_institutions;
      state.secondary_special_and_higher_educational_institutions = state.first_third_degre_of_damage_secondary_special_and_higher_educational_institutions + state.fourth_fifth_degre_of_damage_secondary_special_and_higher_educational_institutions;
      state.qty_objects_of_education = state.preschool + state.general_educational_institutions + state.secondary_special_and_higher_educational_institutions;
    } else if (name === 'first_third_degre_of_damage_cultural_objects' ||
      name === 'fourth_fifth_degre_of_damage_cultural_objects') {
      state.qty_cultural_objects = state.first_third_degre_of_damage_cultural_objects + state.fourth_fifth_degre_of_damage_cultural_objects;
    } else if (name === 'first_third_degre_of_damage_administrative_buildings' ||
      name === 'fourth_fifth_degre_of_damage_secondary_administrative_buildings') {
      state.qty_administrative_buildings = state.first_third_degre_of_damage_administrative_buildings + state.fourth_fifth_degre_of_damage_secondary_administrative_buildings;
    } else if (name === 'first_third_degre_of_damage_social_stationary_institutions' ||
      name === 'fourth_fifth_degre_of_damage_social_stationary_institutions' ||
      name === 'first_third_degre_of_damage_semi_permanent_institutions' ||
      name === 'fourth_fifth_degre_of_damage_semi_permanent_institutions') {
      state.social_stationary_institutions = state.first_third_degre_of_damage_social_stationary_institutions + state.fourth_fifth_degre_of_damage_social_stationary_institutions;
      state.semi_permanent_institutions = state.first_third_degre_of_damage_semi_permanent_institutions + state.fourth_fifth_degre_of_damage_semi_permanent_institutions;
      state.objects_of_social_protection = state.social_stationary_institutions + state.semi_permanent_institutions;
    } else if (name === 'budgetary_funds_of_the_ministry_of_emergency_situations' ||
      name === 'donor_funds') {
      state.the_total_amount_of_funds_allocated_to_eliminate_the_consequences_of_an_emergency = state.budgetary_funds_of_the_ministry_of_emergency_situations + state.donor_funds;
    } else if (name === 'budget_resources_restoration_of_management_functions_and_provision_of_state_and_municipal_services' ||
      name === 'donor_funds_restoration_of_management_functions_and_provision_of_state_and_municipal_services' ||
      name === 'budget_resources_resettlement_of_the_population_to_safe_areas' ||
      name === 'donor_funds_resettlement_of_the_population_to_safe_areas' ||
      name === 'budget_resources_restoration_of_the_vital_activity_of_the_economy' ||
      name === 'donor_funds_restoration_of_the_vital_activity_of_the_economy') {
      state.restoration_of_management_functions_and_provision_of_state_and_municipal_services = state.budget_resources_restoration_of_management_functions_and_provision_of_state_and_municipal_services + state.donor_funds_restoration_of_management_functions_and_provision_of_state_and_municipal_services;
      state.resettlement_of_the_population_to_safe_areas = state.budget_resources_resettlement_of_the_population_to_safe_areas + state.donor_funds_resettlement_of_the_population_to_safe_areas;
      state.restoration_of_the_vital_activity_of_the_economy = state.budget_resources_restoration_of_the_vital_activity_of_the_economy + state.donor_funds_restoration_of_the_vital_activity_of_the_economy;
      state.the_total_amount_of_funds_allocated_for_restoration_by_funding_sources = state.restoration_of_management_functions_and_provision_of_state_and_municipal_services + state.resettlement_of_the_population_to_safe_areas + state.restoration_of_the_vital_activity_of_the_economy;
      state.budget_resources_allocated_for_restoration_by_funding_sources = state.budget_resources_restoration_of_management_functions_and_provision_of_state_and_municipal_services + state.budget_resources_resettlement_of_the_population_to_safe_areas + state.budget_resources_restoration_of_the_vital_activity_of_the_economy;
      state.donor_funds_allocated_for_restoration_by_funding_sources = state.donor_funds_restoration_of_management_functions_and_provision_of_state_and_municipal_services + state.donor_funds_resettlement_of_the_population_to_safe_areas + state.donor_funds_restoration_of_the_vital_activity_of_the_economy;
    }

    setEmergencyState(state);
  }

  const handleChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {files} = e.target;
    let tempFiles = [...filesState];

    if (e.target.type === 'file') {
      const chosenFiles = Array.prototype.slice.call(files);
      chosenFiles.forEach(el => {
        if (!tempFiles.find(item => item.name === el.name)) {
          tempFiles.push(el);
        }
      });

      setFilesState(tempFiles);
    }
  };

  const handleDeleteFile = (name: string) => {
    let arr = [...filesState];
    let index = arr.findIndex(el => el.name === name);
    arr.splice(index, 1);
    setFilesState(arr);
  }

  const handleDeleteEmergencyFile = (id: number) => {
    let arr = [...emergencyFilesState];
    let idsArr = [...filesForDelete];
    let idx = arr.findIndex(el => el.id === id);
    arr.splice(idx, 1);

    if (!idsArr.includes(id)) {
      idsArr.push(id);
    }

    setEmergencyFilesState(arr);
    setFilesForDelete(idsArr);
  }

  const handleGetPosition = (e: any) => {
    setMainState(prevState => {
      return {
        ...prevState,
        latitude: e.lat,
        longitude: e.lng,
        location: {
          type: "Point",
          coordinates: [
            e.lng,
            e.lat
          ]
        }
      }
    })
  }

  const handleCreateEmergency = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSavingError(null);
    let data: {[key: string]: any} = {};

    let additionalData = {...emergencyState};

    let arr: any[] = [];
    currentEsType.forEach(el => {
      if (el.type !== 'image' && el.id && (el.value || el.value === 0)) {
        arr.push({field_type_emergency: el.id, meaning: el.value.toString()});
      }
    })

    Object.entries(mainState).forEach(el => {
      if (String(el[1])) {
        data[el[0]] = el[1];
      }
    })

    arr.length ? data.field_emergency = arr : delete data.field_emergency;
    data.title = title;
    data.latitude = mainState.latitude?.toString(10);
    data.longitude = mainState.longitude?.toString(10);
    data.late_information = mainState.late_information;
    data.date_emergency = dayjs(mainState.date_emergency).format("YYYY-MM-DDThh:mm:ss");

    createEmergency(data).then((res: any) => {
      if (res?.error?.data) {
        setSavingError(res.error.data);
        return res;
      }

      const promises = [];
      if (res?.data && mainState.type_report === 'emergency') {
        additionalData.emergency = res.data.id;
        promises.push(createAffectedPopulation(additionalData));
      }
      if (res?.data) {
        filesState.forEach(el => {
          const formData = new FormData();
          formData.append('emergency', res.data.id);
          formData.append('file', el);
          promises.push(createEmergencyFile(formData));
        })
      }
      return Promise.all(promises);
    }).then((res: any) => {
      if (res?.error?.data) {
        setSavingError(res.error.data);
        return res;
      }
      navigate({pathname: '/es-base'});
      // TODO: Сделать процесс загрузки файлов
    }).catch(() => {
      console.log("Something went wrong...");
    })
  }

  const handleUpdateEmergency = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSavingError(null);
    let data: {[key: string]: any} = {};

    let additionalData = {...emergencyState};

    let arr: any[] = [];
    currentEsType.forEach(el => {
      if (el.type !== 'image' && (el.value || el.value === 0)) {
        arr.push({field_id: el.field_id || '', emergency: Number(id), field_type_emergency: el.id, meaning: el.value.toString()});
      }
    })

    Object.entries(mainState).forEach(el => {
      if (String(el[1]) && el[1] !== "null") {
        if ((el[0] === 'region' || el[0] === 'district' || el[0] === 'county' || el[0] === 'locality')) {
          data[el[0]] = Number(el[1]);
        } else {
          data[el[0]] = el[1];
        }
      }
    })

    data.field_emergency = arr;
    data.title = title;
    data.latitude = mainState.latitude?.toString(10);
    data.longitude = mainState.longitude?.toString(10);
    data.late_information = mainState.late_information;
    data.date_emergency = dayjs(mainState.date_emergency).format("YYYY-MM-DDThh:mm:ss");

    let deleteEmergencyIds: number[] = [];
    if (emergencyById?.type_emergency?.id !== mainState.type_emergency && emergencyById?.field_emergency?.length) {
      emergencyById?.field_emergency.forEach((el: any) => {
        deleteEmergencyIds.push(el.id);
      })
    }

    if (deleteEmergencyIds.length) {
      data.delete_field_emergency = deleteEmergencyIds;
    }

    updateEmergency({body: data, id}).then((res: any) => {
      if (res?.error?.data) {
        setSavingError(res.error.data);
        return res;
      }

      const promises = [];
      if (res?.data && mainState.type_report === 'emergency') {
        additionalData.emergency = res.data.id;
        if (!affectedPopulationId) {
          promises.push(createAffectedPopulation(additionalData));
        } else {
          promises.push(updateAffectedPopulation({body: additionalData, id: affectedPopulationId}));
        }
      }

      if (res?.data) {
        filesState.forEach(el => {
          const formData = new FormData();
          formData.append('emergency', res.data.id);
          formData.append('file', el);
          promises.push(createEmergencyFile(formData));
        })
      }

      if (filesForDelete.length) {
        filesForDelete.forEach(el => {
          promises.push(deleteEmergencyFile(el));
        })
      }
      return Promise.all(promises);
    }).then((res: any) => {
      if (res?.error?.data) {
        setSavingError(res.error.data);
        return res;
      }
      setAffectedPopulationId(null);
      navigate({pathname: '/es-base'});
      // TODO: Сделать процесс загрузки файлов
    }).catch(() => {
      console.log("Something went wrong...");
    })
  }

  let emergencyTypesOptions: TSelectOptions[] = [],
    regionsOptions: TSelectOptions[] = [],
    districtsOptions: TSelectOptions[] = [],
    countiesOptions: TSelectOptions[] = [],
    localityOptions: TSelectOptions[] = [];

  if (emergencyTypes?.length) {
    emergencyTypesOptions = emergencyTypes.map(el => {
      return (
        {label: el.name, value: el.id}
      )
    })
  }

  if (regions?.length) {
    regionsOptions = regions.map(el => {
      return {label: el.coat_code + ' - ' + el.name, value: String(el.id)}
    })
  }

  if (districts?.length) {
    districtsOptions = districts.filter(el => String(el.region) === mainState.region).map(el => {
      return {label: el.coat_code + ' - ' + el.name, value: String(el.id)}
    })
  }

  if (counties?.length) {
    countiesOptions = counties.filter(el => String(el.district) === mainState.district).map(el => {
      return {label: el.coat_code + ' - ' + el.name, value: String(el.id)}
    })
  }

  if (locality?.length) {
    localityOptions = locality.filter(el => String(el.county) === mainState.county).map(el => {
      return {label: el.coat_code + ' - ' + el.name, value: String(el.id)}
    })
  }

  const colourStyles: StylesConfig<any> = {
    control: (styles) => (
      {
        ...styles,
        backgroundColor: '#ffffff',
        border: 0,
        boxShadow: 'none',
        borderRadius: '6px',
        maxHeight: 31,
        fontSize: '14px'
      }
    )
  };

  let position = [mainState.latitude, mainState.longitude], icon;

  let iconUrl = '';
  if (mainState.type_emergency !== 0 && responseEmergencyTypes?.results?.length) {
    // @ts-ignore
    iconUrl = responseEmergencyTypes?.results?.find((el: any) => el.id === mainState.type_emergency)?.geo_icon?.icon;
    if (iconUrl) {
      icon = new Leaflet.Icon({
        iconUrl: iconUrl,
        iconSize: [32, 37],
        iconAnchor: [16, 37],
        popupAnchor: [0, -32],
      })
    }
  }

  const extension = ['png', 'jpeg', 'jpg', 'gif', 'raw', 'tiff', 'bmp', 'psd'];

  return (
    <>
      <div className="pb-3 mb-4 title-divider">
        <h1>{location.pathname.includes('/es-report/update') ? t('nav.Update ES report') : t('nav.New ES report')}</h1>
        <LoaderLine visible={isLoading}/>
      </div>

      <CForm onSubmit={location.pathname.includes('/es-report/create') ? handleCreateEmergency : handleUpdateEmergency}>
        <div className={'mb-3'}>
          <CFormLabel>
            {t("settings.esTypes.emergencyType")}
            <span style={{color: 'red'}}>&nbsp;*</span>
          </CFormLabel>
          <Select options={emergencyTypesOptions}
                  value={emergencyTypesOptions?.find(el => el?.value === mainState.type_emergency) || null}
                  styles={{ control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state)) }}
                  // styles={colourStyles}
                  placeholder={t('Nothing is selected')}
                  onChange={e => handleSelectEmergencyType(e, 'type_emergency')}
                  isClearable
          />
          {(savingError?.type_emergency && !mainState.type_emergency) && <span style={{color: 'red'}}>{savingError.type_emergency}</span>}
        </div>

        <div className={'mb-3'}>
          <CFormLabel>
            {t("emergency.determineTheTypeOfReport")}
            <span style={{color: 'red'}}>&nbsp;*</span>
          </CFormLabel>
          <CFormCheck type="radio"
                      name="type_report"
                      id="exampleRadios1"
                      value="emergency"
                      label={t("emergency.emergency")}
                      checked={mainState.type_report === 'emergency'}
                      onChange={handleChecked}
          />
          <CFormCheck type="radio"
                      name="type_report"
                      id="exampleRadios2"
                      value="incident"
                      label={t("emergency.incident")}
                      checked={mainState.type_report === 'incident'}
                      onChange={handleChecked}
          />
          <CFormCheck type="radio"
                      name="type_report"
                      id="exampleRadios3"
                      value="other"
                      label={t("emergency.other")}
                      checked={mainState.type_report === 'other'}
                      onChange={handleChecked}
          />
          {(savingError && !mainState.type_report) && <span style={{color: 'red'}}>{t('Required field')}</span>}
        </div>

        <div style={{height: '50px'}} className="mb-4">
          <div className="react-date-picker-wrapper">
            <CFormLabel>{t("emergency.dateOfTheMessage")}</CFormLabel>
            <DatePicker onChange={handleSelectDateTime}
                        showTimeInput
                        selected={mainState.date_emergency}
                        dateFormat={'dd.MM.yyyy H:mm'}
                        locale={i18n.language}
                        className={"ml-5"}
            />
          </div>
        </div>

        <div className="mb-3">
          <CFormLabel>
            {t("gis.geoObjects.area")}
            <span style={{color: 'red'}}>&nbsp;*</span>
          </CFormLabel>
          <Select options={regionsOptions}
                  value={regionsOptions?.find(el => el.value === mainState.region) || ''}
                  styles={{ control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state)) }}
                  // styles={colourStyles}
                  placeholder={t('Nothing is selected')}
                  onChange={e => handleSelect(e, 'region')}
                  isClearable
          />
          {(savingError?.region && !mainState.region) && <span style={{color: 'red'}}>{savingError.region}</span>}
        </div>

        <div className="mb-3">
          <CFormLabel>{t("gis.geoObjects.district")}</CFormLabel>
          <Select options={districtsOptions}
                  value={districtsOptions?.find(el => String(el.value) === mainState.district) || ''}
                  styles={{ control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state)) }}
                  // styles={colourStyles}
                  placeholder={t('Nothing is selected')}
                  onChange={e => handleSelect(e, 'district')}
                  isClearable
                  isDisabled={!mainState.region || !districtsOptions?.length}
          />
        </div>

        <div className="mb-3">
          <CFormLabel>{t("gis.geoObjects.county")}</CFormLabel>
          <Select options={countiesOptions}
                  value={countiesOptions?.find(el => el.value === mainState.county) || ''}
                  styles={{ control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state)) }}
                  // styles={colourStyles}
                  placeholder={t('Nothing is selected')}
                  onChange={e => handleSelect(e, 'county')}
                  isClearable
                  isDisabled={!mainState.district || !countiesOptions?.length}
          />
        </div>

        <div className="mb-3">
          <CFormLabel>{t("gis.geoObjects.village")}</CFormLabel>
          <Select options={localityOptions}
                  value={localityOptions?.find(el => el.value === mainState.locality) || ''}
                  styles={{ control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state)) }}
                  // styles={colourStyles}
                  placeholder={t('Nothing is selected')}
                  onChange={e => handleSelect(e, 'locality')}
                  isClearable
                  isDisabled={!mainState.county || !localityOptions?.length}
          />
        </div>

        <div className="mb-3">
          <CFormLabel>
            {t("emergency.title")}
            <span style={{color: 'red'}}>&nbsp;*</span>
          </CFormLabel>
          <CFormInput type="text"
                      name={'title'}
                      value={title}
                      onChange={handleChange}
                      className={'iams-default-form-control'}
                      // className={'emergency-custom-input'}
          />
          {(savingError?.title && !title) && <span style={{color: 'red'}}>{savingError.title}</span>}
        </div>

        <div className="mb-3">
          <CFormLabel>
            {t("emergency.description")}
            <span style={{color: 'red'}}>&nbsp;*</span>
          </CFormLabel>
          <CFormTextarea name={'description'}
                         value={mainState.description}
                         onChange={handleChange}
                         className={'emergency-custom-textarea iams-default-form-control'}
                         // className={'emergency-custom-textarea emergency-custom-input'}
          />
          {(savingError?.description && !mainState.description) && <span style={{color: 'red'}}>{savingError.description}</span>}
        </div>

        <div className="mb-3">
          <CFormInput type="number"
                      label={t("emergency.materialDamage")}
                      name={'material_damage'}
                      value={mainState.material_damage}
                      onChange={handleChange}
                      className={'iams-default-form-control'}
                      // className={'emergency-custom-input'}
                      min={0}
          />
        </div>

        <div className="mb-3">
          <CFormInput type="number"
                      label={t("emergency.theNumberOfHumanCasualties")}
                      name={'human_casualties'}
                      value={mainState.human_casualties}
                      onChange={handleChange}
                      className={'iams-default-form-control'}
                      // className={'emergency-custom-input'}
                      min={0}
          />
        </div>

        {currentEsType?.length ?
          <CCard className="mb-3">
            <CCardHeader><CCardTitle>{t('emergency.additionalInformation')}</CCardTitle></CCardHeader>
            <CCardBody>
              {currentEsType.map(el => {
                if (el?.type === 'number') {
                  return (
                    // @ts-ignore
                    <CFormInput value={el.value} key={el.id} className="mb-3" name={el.id}
                                onChange={e => handleCustom(e, el.type, el.id)}
                                type={el.type}
                                label={el.name}
                                size="sm"
                                min={0}
                    />
                  )
                }
                if (el?.type === 'textarea') {
                  return (
                    // @ts-ignore
                    <CFormTextarea value={el.value} name={el.id}
                                   key={el.id}
                                   className="mb-3 iams-default-form-control"
                                   label={el.name}
                                   onChange={e => handleCustom(e, el.type, el.id)}
                    />
                  )
                }
                if (el?.type === 'string') {
                  return (
                    // @ts-ignore
                    <CFormInput value={el.value}
                                key={el.id}
                                className="mb-3 iams-default-form-control"
                                // name={el.id}
                                onChange={e => handleCustom(e, el.type, el.id)}
                      // type={el.type}
                                label={el.name}
                                size="sm"
                    />
                  )
                }
                if (el?.type === 'image') {
                  return (
                    <div key={el.id}>
                      {/*@ts-ignore*/}
                      <CFormInput className="mb-3" name={el.id}
                                  onChange={e => handleCustom(e, el.type, el.id)}
                                  type={'file'}
                                  label={el.name}
                                  size="sm"
                                  multiple
                      />
                      <div style={{display: "flex", justifyContent: 'flex-start', flexWrap: 'wrap'}}>
                        {/*@ts-ignore*/}
                        {el.value.map((pic: any, i: number) => {
                          return (
                            <div key={i + '_pic'} className="image-wrapper">
                              <div className="image-delete-icon-wrapper" onClick={(e) => handleDeleteCustomPicture(e, pic.name, el.id)}>
                                <CIcon icon={cilX} className="image-delete-icon"/>
                              </div>
                              <img alt={""}
                                   src={URL.createObjectURL(pic)}
                                   style={{height: '110px', width: 'auto'}}
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                }
                if (el?.type === 'date') {
                  return (
                    <div key={el.id} style={{height: '50px'}} className="mb-4">
                      <div className="react-date-picker-wrapper">
                        <CFormLabel>{el.name}</CFormLabel>
                        {/*/@ts-ignore*/}
                        <DatePicker onChange={e => handleCustom(e, el.type, el.id)} selected={el.value}
                          // showTimeInput
                          //           dateFormat={'dd-MM-YYYY'}
                                    dateFormat={'dd.MM.yyyy'}
                                    locale={i18n.language}
                                    className={"ml-5"}
                        />
                      </div>
                    </div>
                  )
                }
                if (el?.type === 'time') {
                  return (
                    <div key={el.id} className="mb-3">
                      <CFormLabel>{el.name}</CFormLabel>
                      <TimePicker defaultValue={el.value ? String(el.value) : null}
                                  onChange={value => changeCustomTimeHandler(value, el.type, el.id)}
                      />
                    </div>
                  )
                }
                // if (el?.type === 'time') {
                //   return (
                //     <div key={el.id} style={{height: '50px'}} className="mb-4">
                //       <div className="react-date-picker-wrapper">
                //         <CFormLabel>{el.name}</CFormLabel>
                //         {/*/@ts-ignore*/}
                //         <DatePicker onChange={e => handleCustom(e, el.type, el.id)} selected={new Date(el.value)}
                //                     showTimeSelect
                //                     showTimeSelectOnly
                //           // dateFormat={'H:mm'}
                //                     timeFormat={'HH:mm'}
                //                     locale={i18n.language}
                //                     timeIntervals={1}
                //                     className={"ml-5"}
                //         />
                //       </div>
                //     </div>
                //   )
                // }
                if (el?.type === 'datetime') {
                  return (
                    <div key={el.id} style={{height: '50px'}} className="mb-4">
                      <div className="react-date-picker-wrapper">
                        <CFormLabel>{el.name}</CFormLabel>
                        {/*/@ts-ignore*/}
                        <DatePicker onChange={e => handleCustom(e, el.type, el.id)} selected={el.value}
                                    showTimeInput
                                    // dateFormat={'dd-MM-YYYY H:mm'}
                                    dateFormat={'dd.MM.yyyy H:mm'}
                                    locale={i18n.language}
                                    className={"ml-5"}
                        />
                      </div>
                    </div>
                  )
                }
                if (el?.type === 'boolean') {
                  return (
                    <div key={el.id} className="mb-3">
                      {/*@ts-ignore*/}
                      <CFormCheck checked={el.value} id={el.name}
                                  name={el.name}
                                  label={el.name}
                                  onChange={e => handleCustom(e, el.type, el.id)} //  Здесь нужен свой хендлер
                      />
                      {/*<CFormLabel htmlFor={el.name} style={{marginLeft: '8px'}}>{el.name}</CFormLabel>*/}
                    </div>
                  )
                }
                return null
              })}
            </CCardBody>
          </CCard> : null}

        <div className="mb-3">
          {
            mainState.type_report === 'emergency' ?
              <AccordionEsReport handleChange={handleChangeEmergency}
                                 state={emergencyState}
              /> : null
          }
        </div>

        {/*{mainState.type_emergency === 0 ? null :*/}
          <div>
            <CFormLabel>{t("emergency.location")}</CFormLabel>
            <div className="mb-3 emergency-map-wrapper">
              <div className="whole-place-wrapper">
                <BaseMap layersControl
                         zoomControl
                         // crosshairsControl
                         // adjustControl
                         // filterControl
                         // drawControl
                            // @ts-ignore
                         center={position}
                         scaleControl
                         loading={false} handlerZoom={() => {
                }}
                         getPosition={handleGetPosition}
                >
                  {(!responseEmergencyTypes?.results?.length && mainState.type_emergency === 0) || !icon ? null :
                      // @ts-ignore
                      (position[0] && position[1]) ? <Marker position={position} icon={icon}/> : null}
                </BaseMap>
              </div>
            </div>
          </div>
        {/*}*/}

        <div className="mb-3">
          <CFormInput type="file"
                      label={t('labels.Images')}
                      name={'images'}
                      onChange={handleChangeFiles}
                      // className={'iams-default-form-control mb-3'}
                      className={'emergency-custom-input mb-3'}
                      multiple
          />
          <div style={{display: "flex", justifyContent: 'flex-start', flexWrap: 'wrap'}}>
            {filesState.map((item: any, i: number) => {
              return (
                <div key={i + '_pic'} className="image-wrapper">
                  <div className="image-delete-icon-wrapper"
                       onClick={() => handleDeleteFile(item.name)}
                  >
                    <CIcon icon={cilX} className="image-delete-icon"/>
                  </div>
                  {!item.type.includes("image") ?
                    <div className="file-item">
                      {item.name}
                    </div> :
                    <img alt={""}
                       src={URL.createObjectURL(item)}
                       style={{height: '110px', width: 'auto'}}
                  />}
                </div>
              )
            })}
            {emergencyFilesState.map((item: any, i: number) => {
              return (
                <div key={i + '_pic'} className="image-wrapper">
                  <div className="image-delete-icon-wrapper"
                       onClick={() => handleDeleteEmergencyFile(item.id)}
                  >
                    <CIcon icon={cilX} className="image-delete-icon"/>
                  </div>
                  {!extension.includes(item.name.split('.')[item.name.split('.').length - 1]) ?
                    <div className="file-item">
                      {item.name}
                    </div> :
                    <img alt={""}
                         src={item.file}
                         style={{height: '110px', width: 'auto'}}
                    />
                }
                </div>
              )
            })}
          </div>
        </div>

        <div className="mb-3">
          <CFormCheck id="status"
                      name="status"
                      label={t('labels.Report has been processed')}
                      checked={mainState.status === 'completed'}
                      onChange={handleChecked}
          />
        </div>

        <div className="mb-3">
          <CFormCheck id="late_information"
                      name="late_information"
                      label={t('labels.late_information')}
                      checked={mainState.late_information}
                      onChange={handleChecked}
          />
        </div>

        <div className="mb-5" style={{display: 'flex', alignItems: 'start', columnGap: '1rem', flexDirection: 'column'}}>
          {/*{error && <span style={{color: 'red'}}>{error}</span>}*/}
          <div className="mb-3">
            <span style={{color: 'red'}}>*&nbsp;</span><span>{t('createAssignment.field star')}</span>
          </div>
          {id !== "create"
              ?
              <CButton type="submit"
                  // color="info"
                  // className="color-white"
                       disabled={!mainState.type_emergency || !mainState.type_report || !mainState.region || !mainState.title || !mainState.description}
              >
                {t("save")}
              </CButton>
              :
              <CButton type="submit"
                  // color="info"
                  // className="color-white"
                       disabled={!mainState.type_emergency || !mainState.type_report || !mainState.region || !mainState.title || !mainState.description}
              >
                {t("gis.geoObjects.buttonCreate")}
              </CButton>
          }
          {/*<CButton type="submit"*/}
          {/*         // color="info"*/}
          {/*         // className="color-white"*/}
          {/*         disabled={!mainState.type_emergency || !mainState.type_report || !mainState.region || !mainState.title || !mainState.description}*/}
          {/*>*/}
          {/*  {t("save")}*/}
          {/*</CButton>*/}
          {saveIsLoading ?
            <SpinnerDotted size={16} color="#091b37"/>
            : savingError ?
              <span className="error-message">{t('Changes are not saved')}</span>
              : null
          }
        </div>
      </CForm>
    </>
  );
};

export default NewEsReport;

 const makeQuery = {
  parse: function (query: string) {
    const str = query[0] === '?' ? query.substring(1) : query;
    const res: {[key: string]: string} = {};
    str.split('&').forEach(el => {
      const tmp: string[] = el.split('=');
      if (tmp.length === 2) res[tmp[0]] = tmp[1];
    });
    return res;
  },
  stringify: function (data: { [key: string]: any}) {
    const query: {[key: string]: any} = {};
    Object.keys(data).forEach(key => {
      if (data[key] || String(data[key]) === '0') query[key] = data[key];
    });
    return (Object.keys(query).length ? ('?' + Object.keys(query).map(key => typeof query[key] === 'object' ?
      Object.keys(query[key]).map(k => key + '[' + k + ']=' + query[key][k]).join('&') :
      key + '=' + query[key]).join('&')) : '');
  }
};

export default makeQuery;

import React, {FC} from 'react';
import {CFormSelect} from "@coreui/react";
import {useTranslation} from "react-i18next";

interface iPaginationSize {
    name: string;
    value: string | number;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    options?: {
        label: string;
        value: string
    }[]
}

const opts: {
    label: string;
    value: string
}[] = [
    {label: '10', value: '10'},
    {label: '25', value: '25'},
    {label: '50', value: '50'},
    {label: '100', value: '100'},
    {label: '200', value: '200'}
];

const PaginationSize: FC<iPaginationSize> = (
    {
        name = 'PaginationSize',
        value,
        onChange,
        options = opts
    }
) => {
    const { t } = useTranslation();

    return (
        <div className="d-flex justify-content-between align-items-center">
          <span style={{marginRight: '20px'}}>
            {t('show')}
          </span>
                <CFormSelect size="sm"
                             name={name}
                             value={value}
                             options={options}
                             onChange={onChange}
                />
                <span style={{marginLeft: '20px'}}>
            {t('entries')}
          </span>
        </div>
    );
};

export default PaginationSize;
import React from 'react';

const BrowserPolicy = () => {
  return (
    <div>
      Browser policy
    </div>
  );
};

export default BrowserPolicy;

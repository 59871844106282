import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";

export const docTypesApi = createApi({
    reducerPath: 'docTypes/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['DocTypes'],
    endpoints: build => ({
        getDocTypes: build.query<any, string>({
            query: (query: string) => {
                return {
                    url: `/list/type/doc/${query || ''}`
                }
            },
            providesTags: ['DocTypes'],
        }),
        // getUsersById: build.query<IRegion, string>({
        //     query: (id: string) => ({
        //         url: `/detail/users/${id}`
        //     })
        // }),
        createDocType: build.mutation<any, any>({
            query: (body) => ({
                url: '/create/type/doc/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['DocTypes'],
        }),
        updateDocType: build.mutation<any, Partial<any> & Pick<any, 'id'>>({
            query: ({id, body}) => {
                return {
                    url: `/update/type/doc/${id}/`,
                    method: 'PUT',
                    body,
                    id,
                }
            },
            invalidatesTags: ['DocTypes'],
        }),
        removeDocType: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/delete/type/doc/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['DocTypes'],
        })
    })
})

export const {
    useLazyGetDocTypesQuery,
    useCreateDocTypeMutation,
    useUpdateDocTypeMutation,
    useRemoveDocTypeMutation
} = docTypesApi;
import React from 'react';
import {CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem, CFormInput, CFormLabel} from "@coreui/react";
import {IEmergencyState} from "../../../interfaces/IEmergencyState";
import {useTranslation} from "react-i18next";

interface IAccordionEsReport {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  state: IEmergencyState;
}

const AccordionEsReport: React.FC<IAccordionEsReport> = (
  {
    handleChange,
    state
  }
) => {
  const {t} = useTranslation();
  return (
    <>
      <CAccordion>

        {/*Всего погибших и без вести пропавших*/}

        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.totalDeadAndMissing")}</div>
          </CAccordionHeader>
          <CAccordionBody>
            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.totalAmount")}
                          value={state.qty_dead_and_missing}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.men")}
                          value={state.men_dead_and_missing}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_men_dead_and_missing">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_men_dead_and_missing"
                            value={state.zero_five_men_dead_and_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_men_dead_and_missing"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_men_dead_and_missing">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_men_dead_and_missing"
                            value={state.six_seventeen_men_dead_and_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_men_dead_and_missing"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_men_dead_and_missing">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_men_dead_and_missing"
                            value={state.eighteen_fifty_nine_men_dead_and_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_men_dead_and_missing"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_men_dead_and_missing">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_men_dead_and_missing"
                            value={state.sixty_and_older_men_dead_and_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_men_dead_and_missing"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.women")}
                          value={state.women_dead_and_missing}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_women_dead_and_missing">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_women_dead_and_missing"
                            value={state.zero_five_women_dead_and_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_women_dead_and_missing"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_women_dead_and_missing">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_women_dead_and_missing"
                            value={state.six_seventeen_women_dead_and_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_women_dead_and_missing"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_women_dead_and_missing">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_women_dead_and_missing"
                            value={state.eighteen_fifty_nine_women_dead_and_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_women_dead_and_missing"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_women_dead_and_missing">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_women_dead_and_missing"
                            value={state.sixty_and_older_women_dead_and_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_women_dead_and_missing"
                />
              </div>
            </div>
          </CAccordionBody>
        </CAccordionItem>

        {/*Всего погибших*/}

        <CAccordionItem itemKey={2}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.totalDead")}</div>
          </CAccordionHeader>
          <CAccordionBody>
            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.totalAmount")}
                          value={state.qty_dead}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.men")}
                          value={state.men_dead}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_men_dead">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_men_dead"
                            value={state.zero_five_men_dead}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_men_dead"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_men_dead">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_men_dead"
                            value={state.six_seventeen_men_dead}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_men_dead"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_men_dead">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_men_dead"
                            value={state.eighteen_fifty_nine_men_dead}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_men_dead"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_men_dead">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_men_dead"
                            value={state.sixty_and_older_men_dead}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_men_dead"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label="Женщины"
                          value={state.women_dead}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_women_dead">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_women_dead"
                            value={state.zero_five_women_dead}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_women_dead"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_women_dead">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_women_dead"
                            value={state.six_seventeen_women_dead}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_women_dead"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_women_dead">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_women_dead"
                            value={state.eighteen_fifty_nine_women_dead}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_women_dead"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_women_dead">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_women_dead"
                            value={state.sixty_and_older_women_dead}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_women_dead"
                />
              </div>
            </div>
          </CAccordionBody>
        </CAccordionItem>

        {/*Всего пропавших без вести*/}

        <CAccordionItem itemKey={3}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.totalMissing")}</div>
          </CAccordionHeader>
          <CAccordionBody>
            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.totalAmount")}
                          value={state.qty_missing}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.men")}
                          value={state.men_missing}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_men_missing">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_men_missing"
                            value={state.zero_five_men_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_men_missing"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_men_missing">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_men_missing"
                            value={state.six_seventeen_men_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_men_missing"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_men_missing">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_men_missing"
                            value={state.eighteen_fifty_nine_men_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_men_missing"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_men_missing">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_men_missing"
                            value={state.sixty_and_older_men_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_men_missing"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.women")}
                          value={state.women_missing}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_women_missing">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_women_missing"
                            value={state.zero_five_women_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_women_missing"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_women_missing">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_women_missing"
                            value={state.six_seventeen_women_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_women_missing"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_women_missing">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_women_missing"
                            value={state.eighteen_fifty_nine_women_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_women_missing"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_women_missing">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_women_missing"
                            value={state.sixty_and_older_women_missing}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_women_missing"
                />
              </div>
            </div>
          </CAccordionBody>
        </CAccordionItem>

        {/*Всего пострадавших*/}

        <CAccordionItem itemKey={4}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.totalAffected")}</div>
          </CAccordionHeader>
          <CAccordionBody>
            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.totalAmount")}
                          value={state.qty_affected}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.men")}
                          value={state.men_affected}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_men_affected">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_men_affected"
                            value={state.zero_five_men_affected}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_men_affected"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_men_affected">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_men_affected"
                            value={state.six_seventeen_men_affected}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_men_affected"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_men_affected">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_men_affected"
                            value={state.eighteen_fifty_nine_men_affected}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_men_affected"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_men_affected">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_men_affected"
                            value={state.sixty_and_older_men_affected}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_men_affected"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.women")}
                          value={state.women_affected}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_women_affected">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_women_affected"
                            value={state.zero_five_women_affected}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_women_affected"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_women_affected">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_women_affected"
                            value={state.six_seventeen_women_affected}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_women_affected"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_women_affected">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_women_affected"
                            value={state.eighteen_fifty_nine_women_affected}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_women_affected"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_women_affected">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_women_affected"
                            value={state.sixty_and_older_women_affected}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_women_affected"
                />
              </div>
            </div>
          </CAccordionBody>
        </CAccordionItem>

        {/*Всего травмированных, но не госпитализированных*/}

        <CAccordionItem itemKey={5}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.totalInjuredButNotHospitalized")}</div>
          </CAccordionHeader>
          <CAccordionBody>
            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.totalAmount")}
                          value={state.qty_injured_but_not_hospitalized}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.men")}
                          value={state.men_injured_but_not_hospitalized}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_men_injured_but_not_hospitalized">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_men_injured_but_not_hospitalized"
                            value={state.zero_five_men_injured_but_not_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_men_injured_but_not_hospitalized"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_men_injured_but_not_hospitalized">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_men_injured_but_not_hospitalized"
                            value={state.six_seventeen_men_injured_but_not_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_men_injured_but_not_hospitalized"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_men_injured_but_not_hospitalized">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_men_injured_but_not_hospitalized"
                            value={state.eighteen_fifty_nine_men_injured_but_not_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_men_injured_but_not_hospitalized"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_men_injured_but_not_hospitalized">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_men_injured_but_not_hospitalized"
                            value={state.sixty_and_older_men_injured_but_not_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_men_injured_but_not_hospitalized"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.women")}
                          value={state.women_injured_but_not_hospitalized}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_women_injured_but_not_hospitalized">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_women_injured_but_not_hospitalized"
                            value={state.zero_five_women_injured_but_not_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_women_injured_but_not_hospitalized"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_women_injured_but_not_hospitalized">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_women_injured_but_not_hospitalized"
                            value={state.six_seventeen_women_injured_but_not_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_women_injured_but_not_hospitalized"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_women_injured_but_not_hospitalized">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_women_injured_but_not_hospitalized"
                            value={state.eighteen_fifty_nine_women_injured_but_not_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_women_injured_but_not_hospitalized"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_women_injured_but_not_hospitalized">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_women_injured_but_not_hospitalized"
                            value={state.sixty_and_older_women_injured_but_not_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_women_injured_but_not_hospitalized"
                />
              </div>
            </div>
          </CAccordionBody>
        </CAccordionItem>

      {/*Всего травмированных, госпитализированных*/}

        <CAccordionItem itemKey={6}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.totalInjuredHospitalized")}</div>
          </CAccordionHeader>
          <CAccordionBody>
            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.totalAmount")}
                          value={state.qty_injured_hospitalized}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.men")}
                          value={state.men_injured_hospitalized}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_men_injured_hospitalized">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_men_injured_hospitalized"
                            value={state.zero_five_men_injured_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_men_injured_hospitalized"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_men_injured_hospitalized">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_men_injured_hospitalized"
                            value={state.six_seventeen_men_injured_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_men_injured_hospitalized"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_men_injured_hospitalized">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_men_injured_hospitalized"
                            value={state.eighteen_fifty_nine_men_injured_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_men_injured_hospitalized"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_men_injured_hospitalized">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_men_injured_hospitalized"
                            value={state.sixty_and_older_men_injured_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_men_injured_hospitalized"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.women")}
                          value={state.women_injured_hospitalized}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_women_injured_hospitalized">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_women_injured_hospitalized"
                            value={state.zero_five_women_injured_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_women_injured_hospitalized"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_women_injured_hospitalized">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_women_injured_hospitalized"
                            value={state.six_seventeen_women_injured_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_women_injured_hospitalized"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_women_injured_hospitalized">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_women_injured_hospitalized"
                            value={state.eighteen_fifty_nine_women_injured_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_women_injured_hospitalized"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_women_injured_hospitalized">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_women_injured_hospitalized"
                            value={state.sixty_and_older_women_injured_hospitalized}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_women_injured_hospitalized"
                />
              </div>
            </div>
          </CAccordionBody>
        </CAccordionItem>

      {/*Всего покинувших место жительства*/}

        <CAccordionItem itemKey={7}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.totalLeftLocation")}</div>
          </CAccordionHeader>
          <CAccordionBody>
            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.totalAmount")}
                          value={state.qty_who_left_their_place_of_residence}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.men")}
                          value={state.men_who_left_their_place_of_residence}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_men_who_left_their_place_of_residence">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_men_who_left_their_place_of_residence"
                            value={state.zero_five_men_who_left_their_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_men_who_left_their_place_of_residence"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_men_who_left_their_place_of_residence">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_men_who_left_their_place_of_residence"
                            value={state.six_seventeen_men_who_left_their_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_men_who_left_their_place_of_residence"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_men_who_left_their_place_of_residence">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_men_who_left_their_place_of_residence"
                            value={state.eighteen_fifty_nine_men_who_left_their_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_men_who_left_their_place_of_residence"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_men_who_left_their_place_of_residence">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_men_who_left_their_place_of_residence"
                            value={state.sixty_and_older_men_who_left_their_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_men_who_left_their_place_of_residence"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.women")}
                          value={state.women_who_left_their_place_of_residence}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_women_who_left_their_place_of_residence">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_women_who_left_their_place_of_residence"
                            value={state.zero_five_women_who_left_their_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_women_who_left_their_place_of_residence"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_women_who_left_their_place_of_residence">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_women_who_left_their_place_of_residence"
                            value={state.six_seventeen_women_who_left_their_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_women_who_left_their_place_of_residence"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_women_who_left_their_place_of_residence">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_women_who_left_their_place_of_residence"
                            value={state.eighteen_fifty_nine_women_who_left_their_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_women_who_left_their_place_of_residence"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_women_who_left_their_place_of_residence">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_women_who_left_their_place_of_residence"
                            value={state.sixty_and_older_women_who_left_their_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_women_who_left_their_place_of_residence"
                />
              </div>
            </div>
          </CAccordionBody>
        </CAccordionItem>

      {/*Временно оставивших место жительства*/}

        <CAccordionItem itemKey={8}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.temporarilyLeftLocation")}</div>
          </CAccordionHeader>
          <CAccordionBody>
            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.totalAmount")}
                          value={state.qty_temporarily_left_the_place_of_residence}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.men")}
                          value={state.men_temporarily_left_the_place_of_residence}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_men_temporarily_left_the_place_of_residence">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_men_temporarily_left_the_place_of_residence"
                            value={state.zero_five_men_temporarily_left_the_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_men_temporarily_left_the_place_of_residence"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_men_temporarily_left_the_place_of_residence">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_men_temporarily_left_the_place_of_residence"
                            value={state.six_seventeen_men_temporarily_left_the_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_men_temporarily_left_the_place_of_residence"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_men_temporarily_left_the_place_of_residence">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_men_temporarily_left_the_place_of_residence"
                            value={state.eighteen_fifty_nine_men_temporarily_left_the_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_men_temporarily_left_the_place_of_residence"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_men_who_temporarily_left_the_place_of_residence">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_men_who_temporarily_left_the_place_of_residence"
                            value={state.sixty_and_older_men_who_temporarily_left_the_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_men_who_temporarily_left_the_place_of_residence"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.women")}
                          value={state.women_temporarily_left_the_place_of_residence}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_women_temporarily_left_the_place_of_residence">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_women_temporarily_left_the_place_of_residence"
                            value={state.zero_five_women_temporarily_left_the_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_women_temporarily_left_the_place_of_residence"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_women_temporarily_left_the_place_of_residence">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_women_temporarily_left_the_place_of_residence"
                            value={state.six_seventeen_women_temporarily_left_the_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_women_temporarily_left_the_place_of_residence"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_women_temporarily_left_the_place_of_residence">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_women_temporarily_left_the_place_of_residence"
                            value={state.eighteen_fifty_nine_women_temporarily_left_the_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_women_temporarily_left_the_place_of_residence"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_women_who_temporarily_left_the_place_of_residence">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_women_who_temporarily_left_the_place_of_residence"
                            value={state.sixty_and_older_women_who_temporarily_left_the_place_of_residence}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_women_who_temporarily_left_the_place_of_residence"
                />
              </div>
            </div>
          </CAccordionBody>
        </CAccordionItem>

      {/*Отселенные - сменивших постоянное место жительства*/}

        <CAccordionItem itemKey={9}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.changedPermanentLocation")}</div>
          </CAccordionHeader>
          <CAccordionBody>
            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.totalAmount")}
                          value={state.qty_resettled}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.men")}
                          value={state.men_resettled}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_men_resettled">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_men_resettled"
                            value={state.zero_five_men_resettled}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_men_resettled"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_men_resettled">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_men_resettled"
                            value={state.six_seventeen_men_resettled}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_men_resettled"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_men_resettled">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_men_resettled"
                            value={state.eighteen_fifty_nine_men_resettled}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_men_resettled"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_men_who_resettled">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_men_who_resettled"
                            value={state.sixty_and_older_men_who_resettled}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_men_who_resettled"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.women")}
                          value={state.women_resettled}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="zero_five_women_resettled">{t("esReport.0-5 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="zero_five_women_resettled"
                            value={state.zero_five_women_resettled}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="zero_five_women_resettled"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="six_seventeen_women_resettled">{t("esReport.6-17 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="six_seventeen_women_resettled"
                            value={state.six_seventeen_women_resettled}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="six_seventeen_women_resettled"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="eighteen_fifty_nine_women_resettled">{t("esReport.18-59 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="eighteen_fifty_nine_women_resettled"
                            value={state.eighteen_fifty_nine_women_resettled}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="eighteen_fifty_nine_women_resettled"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sixty_and_older_women_who_resettled">{t("esReport.over 60 years old")}</CFormLabel>
                <CFormInput type="number"
                            name="sixty_and_older_women_who_resettled"
                            value={state.sixty_and_older_women_who_resettled}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sixty_and_older_women_who_resettled"
                />
              </div>
            </div>
          </CAccordionBody>
        </CAccordionItem>

      {/*Гуманитарная помощь*/}

        <CAccordionItem itemKey={10}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.humanitarianAid")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel htmlFor="total_recipients_of_food_and_humanitarian_aid">{t("esReport.totalFoodAndHumanitarianAidRecipients")}</CFormLabel>
              <CFormInput type="number"
                          name="total_recipients_of_food_and_humanitarian_aid"
                          value={state.total_recipients_of_food_and_humanitarian_aid}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="total_recipients_of_food_and_humanitarian_aid"
              />
            </div>
          </CAccordionBody>
        </CAccordionItem>

      {/*Пострадавшие домохозяйства*/}

        <CAccordionItem itemKey={11}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.affectedHouseholds")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel htmlFor="qty_households_affected_by_the_emergency">{t("esReport.numberOfHouseholdsAffectedByTheEmergencyOfWhich")}</CFormLabel>
              <CFormInput type="number"
                          name="qty_households_affected_by_the_emergency"
                          value={state.qty_households_affected_by_the_emergency}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="qty_households_affected_by_the_emergency"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="resettled">{t("esReport.resettled")}</CFormLabel>
                <CFormInput type="number"
                            name="resettled"
                            value={state.resettled}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="resettled"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="registered_in_local_government_records_as_low_income_families">{t("esReport.registeredInTheRecordsOfLocalGovernmentAsLowIncomeFamilies")}</CFormLabel>
                <CFormInput type="number"
                            name="registered_in_local_government_records_as_low_income_families"
                            value={state.registered_in_local_government_records_as_low_income_families}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="registered_in_local_government_records_as_low_income_families"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="qty_households_result_died">{t("esReport.numberOfHouseholdsWhoseMembersAreRegisteredInTheRecordsOfLocalGovernmentAsPoorCitizensAndWhoDiedAsAResultOfTheEmergency")}</CFormLabel>
              <CFormInput type="number"
                          name="qty_households_result_died"
                          value={state.qty_households_result_died}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="qty_households_result_died"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

      {/*Жилые дома*/}

        <CAccordionItem itemKey={12}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.residentialBuildings")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.totalDamagedIncludingDestroyedResidentialBuildingsIndividualAndMultiApartment")}
                          name="qty_damaged_houses"
                          value={state.qty_damaged_houses}
                          onChange={handleChange}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.individualHouses")}
                          name="qty_individual_houses"
                          value={state.qty_individual_houses}
                          onChange={handleChange}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="first_third_degre_of_damage_individual_houses">{t("esReport.Damage level 1-3")}</CFormLabel>
                <CFormInput type="number"
                            name="first_third_degre_of_damage_individual_houses"
                            value={state.first_third_degre_of_damage_individual_houses}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="first_third_degre_of_damage_individual_houses"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="fourth_fifth_degre_of_damage_individual_houses">{t("esReport.Damage level 4-5")}</CFormLabel>
                <CFormInput type="number"
                            name="fourth_fifth_degre_of_damage_individual_houses"
                            value={state.fourth_fifth_degre_of_damage_individual_houses}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="fourth_fifth_degre_of_damage_individual_houses"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.apartmentBuildings")}
                          name="qty_apartment_buildings"
                          value={state.qty_apartment_buildings}
                          onChange={handleChange}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="first_third_degre_of_damage_apartment_buildings">{t("esReport.Damage level 1-3")}</CFormLabel>
                <CFormInput type="number"
                            name="first_third_degre_of_damage_apartment_buildings"
                            value={state.first_third_degre_of_damage_apartment_buildings}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="first_third_degre_of_damage_apartment_buildings"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="fourth_fifth_degre_of_damage_apartment_buildings">{t("esReport.Damage level 4-5")}</CFormLabel>
                <CFormInput type="number"
                            name="fourth_fifth_degre_of_damage_apartment_buildings"
                            value={state.fourth_fifth_degre_of_damage_apartment_buildings}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="fourth_fifth_degre_of_damage_apartment_buildings"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="general_damage_to_residential_buildings">{t("esReport.totalAmountDamageToResidentialBuildingsStructures")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_residential_buildings"
                          value={state.general_damage_to_residential_buildings}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_residential_buildings"
              />
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="cost_of_damaged_and_destroyed_property">{t("esReport.costOfDamagedAndDestroyedProperty")}</CFormLabel>
              <CFormInput type="number"
                          name="cost_of_damaged_and_destroyed_property"
                          value={state.cost_of_damaged_and_destroyed_property}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="cost_of_damaged_and_destroyed_property"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

      {/*Объекты здравоохранения*/}

        <CAccordionItem itemKey={13}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.medicalFacilities")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel htmlFor="qty_health_facilities">{t("esReport.totalDamagedFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="qty_health_facilities"
                          value={state.qty_health_facilities}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="qty_health_facilities"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="first_third_degre_of_damage_health_facilities">{t("esReport.Damage level 1-3")}</CFormLabel>
                <CFormInput type="number"
                            name="first_third_degre_of_damage_health_facilities"
                            value={state.first_third_degre_of_damage_health_facilities}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="first_third_degre_of_damage_health_facilities"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="fourth_fifth_degre_of_damage_health_facilities">{t("esReport.Damage level 4-5")}</CFormLabel>
                <CFormInput type="number"
                            name="fourth_fifth_degre_of_damage_health_facilities"
                            value={state.fourth_fifth_degre_of_damage_health_facilities}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="fourth_fifth_degre_of_damage_health_facilities"
                />
              </div>
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="total_number_of_outpatient_facilities_affected">{t("esReport.totalOutpatientFacilitiesAffected")}</CFormLabel>
                <CFormInput type="number"
                            name="total_number_of_outpatient_facilities_affected"
                            value={state.total_number_of_outpatient_facilities_affected}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="total_number_of_outpatient_facilities_affected"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="total_number_of_affected_hospitals">{t("esReport.totalInpatientFacilitiesAffected")}</CFormLabel>
                <CFormInput type="number"
                            name="total_number_of_affected_hospitals"
                            value={state.total_number_of_affected_hospitals}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="total_number_of_affected_hospitals"
                />
              </div>
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="number_of_affected_beds_in_outpatient_facilities">{t("esReport.numberOfAffectedBedsInOutpatientFacilities")}</CFormLabel>
                <CFormInput type="number"
                            name="number_of_affected_beds_in_outpatient_facilities"
                            value={state.number_of_affected_beds_in_outpatient_facilities}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="number_of_affected_beds_in_outpatient_facilities"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="number_of_affected_beds_in_inpatient_facilities">{t("esReport.numberOfAffectedBedsInInpatientFacilities")}</CFormLabel>
                <CFormInput type="number"
                            name="number_of_affected_beds_in_inpatient_facilities"
                            value={state.number_of_affected_beds_in_inpatient_facilities}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="number_of_affected_beds_in_inpatient_facilities"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="general_damage_to_healthcare_buildings_and_facilities">{t("esReport.totalAmountDamageToFacilitiesIncludingHouseholdAndOtherStructures")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_healthcare_buildings_and_facilities"
                          value={state.general_damage_to_healthcare_buildings_and_facilities}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_healthcare_buildings_and_facilities"
              />
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="cost_of_damaged_and_destroyed_property_and_medical_equipment">{t("esReport.costOfDamagedAndDestroyedPropertyAndEquipment")}</CFormLabel>
              <CFormInput type="number"
                          name="cost_of_damaged_and_destroyed_property_and_medical_equipment"
                          value={state.cost_of_damaged_and_destroyed_property_and_medical_equipment}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="cost_of_damaged_and_destroyed_property_and_medical_equipment"
              />
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="number_of_medical_institutions_located_in_potentially_dangerous_areas">{t("esReport.theNumberOfFacilitiesLocatedInPotentiallyDangerousAreas")}</CFormLabel>
              <CFormInput type="number"
                          name="number_of_medical_institutions_located_in_potentially_dangerous_areas"
                          value={state.number_of_medical_institutions_located_in_potentially_dangerous_areas}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="number_of_medical_institutions_located_in_potentially_dangerous_areas"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

      {/*Объекты образования*/}

        <CAccordionItem itemKey={14}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.educationalFacilities")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.totalDamagedFacilities")}
                          name="qty_objects_of_education"
                          value={state.qty_objects_of_education}
                          onChange={handleChange}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.kindergartens")}
                          // name="preschool"
                          value={state.preschool}
                          onChange={handleChange}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="first_third_degre_of_damage_preschool">{t("esReport.Damage level 1-3")}</CFormLabel>
                <CFormInput type="number"
                            name="first_third_degre_of_damage_preschool"
                            value={state.first_third_degre_of_damage_preschool}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="first_third_degre_of_damage_preschool"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="fourth_fifth_degre_of_damage_preschool">{t("esReport.Damage level 4-5")}</CFormLabel>
                <CFormInput type="number"
                            name="fourth_fifth_degre_of_damage_preschool"
                            value={state.fourth_fifth_degre_of_damage_preschool}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="fourth_fifth_degre_of_damage_preschool"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.schoolsAndGymnasiums")}
                          name="general_educational_institutions"
                          value={state.general_educational_institutions}
                          onChange={handleChange}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="first_third_degre_of_damage_general_educational_institutions">{t("esReport.Damage level 1-3")}</CFormLabel>
                <CFormInput type="number"
                            name="first_third_degre_of_damage_general_educational_institutions"
                            value={state.first_third_degre_of_damage_general_educational_institutions}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="first_third_degre_of_damage_general_educational_institutions"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="fourth_fifth_degre_of_damage_general_educational_institutions">{t("esReport.Damage level 4-5")}</CFormLabel>
                <CFormInput type="number"
                            name="fourth_fifth_degre_of_damage_general_educational_institutions"
                            value={state.fourth_fifth_degre_of_damage_general_educational_institutions}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="fourth_fifth_degre_of_damage_general_educational_institutions"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.secondarySpecializedAndHigherEducationalInstitutions")}
                          name="secondary_special_and_higher_educational_institutions"
                          value={state.secondary_special_and_higher_educational_institutions}
                          onChange={handleChange}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="first_third_degre_of_damage_secondary_special_and_higher_educational_institutions">{t("esReport.Damage level 1-3")}</CFormLabel>
                <CFormInput type="number"
                            name="first_third_degre_of_damage_secondary_special_and_higher_educational_institutions"
                            value={state.first_third_degre_of_damage_secondary_special_and_higher_educational_institutions}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="first_third_degre_of_damage_secondary_special_and_higher_educational_institutions"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="fourth_fifth_degre_of_damage_secondary_special_and_higher_educational_institutions">{t("esReport.Damage level 4-5")}</CFormLabel>
                <CFormInput type="number"
                            name="fourth_fifth_degre_of_damage_secondary_special_and_higher_educational_institutions"
                            value={state.fourth_fifth_degre_of_damage_secondary_special_and_higher_educational_institutions}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="fourth_fifth_degre_of_damage_secondary_special_and_higher_educational_institutions"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="general_damage_to_educational_buildings_and_structures_secondary_special_and_higher_educational_institutions">{t("esReport.totalAmountDamageToFacilitiesIncludingHouseholdAndOtherStructures")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_educational_buildings_and_structures_secondary_special_and_higher_educational_institutions"
                          value={state.general_damage_to_educational_buildings_and_structures_secondary_special_and_higher_educational_institutions}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_educational_buildings_and_structures_secondary_special_and_higher_educational_institutions"
              />
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="cost_of_damaged_and_destroyed_property_and_equipment_secondary_special_and_higher_educational_institutions">{t("esReport.costOfDamagedAndDestroyedPropertyAndEquipment")}</CFormLabel>
              <CFormInput type="number"
                          name="cost_of_damaged_and_destroyed_property_and_equipment_secondary_special_and_higher_educational_institutions"
                          value={state.cost_of_damaged_and_destroyed_property_and_equipment_secondary_special_and_higher_educational_institutions}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="cost_of_damaged_and_destroyed_property_and_equipment_secondary_special_and_higher_educational_institutions"
              />
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="number_of_educational_facilities_located_in_potentially_hazardous_areas_secondary_special_and_higher_educational_institutions">{t("esReport.theNumberOfFacilitiesLocatedInPotentiallyDangerousAreas")}</CFormLabel>
              <CFormInput type="number"
                          name="number_of_educational_facilities_located_in_potentially_hazardous_areas_secondary_special_and_higher_educational_institutions"
                          value={state.number_of_educational_facilities_located_in_potentially_hazardous_areas_secondary_special_and_higher_educational_institutions}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="number_of_educational_facilities_located_in_potentially_hazardous_areas_secondary_special_and_higher_educational_institutions"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Объекты культуры*/}

        <CAccordionItem itemKey={15}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.cultureFacilities")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.totalDamagedFacilities")}
                          name="qty_cultural_objects"
                          value={state.qty_cultural_objects}
                          onChange={() => {}}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="first_third_degre_of_damage_cultural_objects">{t("esReport.Damage level 1-3")}</CFormLabel>
                <CFormInput type="number"
                            name="first_third_degre_of_damage_cultural_objects"
                            value={state.first_third_degre_of_damage_cultural_objects}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="first_third_degre_of_damage_cultural_objects"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="fourth_fifth_degre_of_damage_cultural_objects">{t("esReport.Damage level 4-5")}</CFormLabel>
                <CFormInput type="number"
                            name="fourth_fifth_degre_of_damage_cultural_objects"
                            value={state.fourth_fifth_degre_of_damage_cultural_objects}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="fourth_fifth_degre_of_damage_cultural_objects"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="general_damage_to_cultural_buildings_and_structures_cultural_objects">{t("esReport.totalAmountDamageToFacilitiesIncludingHouseholdAndOtherStructures")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_cultural_buildings_and_structures_cultural_objects"
                          value={state.general_damage_to_cultural_buildings_and_structures_cultural_objects}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_cultural_buildings_and_structures_cultural_objects"
              />
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="cost_of_damaged_and_destroyed_property_and_equipment_cultural_objects">{t("esReport.costOfDamagedAndDestroyedPropertyAndEquipment")}</CFormLabel>
              <CFormInput type="number"
                          name="cost_of_damaged_and_destroyed_property_and_equipment_cultural_objects"
                          value={state.cost_of_damaged_and_destroyed_property_and_equipment_cultural_objects}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="cost_of_damaged_and_destroyed_property_and_equipment_cultural_objects"
              />
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="number_of_educational_facilities_located_in_potentially_hazardous_areas_cultural_objects">{t("esReport.theNumberOfFacilitiesLocatedInPotentiallyDangerousAreas")}</CFormLabel>
              <CFormInput type="number"
                          name="number_of_educational_facilities_located_in_potentially_hazardous_areas_cultural_objects"
                          value={state.number_of_educational_facilities_located_in_potentially_hazardous_areas_cultural_objects}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="number_of_educational_facilities_located_in_potentially_hazardous_areas_cultural_objects"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Здания административного назначения*/}

        <CAccordionItem itemKey={16}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.administrativeBuildings")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormInput type="number"
                          label={t("esReport.totalDamagedFacilities")}
                          name="qty_administrative_buildings"
                          value={state.qty_administrative_buildings}
                          onChange={() => {
                          }}
                          disabled
                          min={0}
                          size="sm"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="first_third_degre_of_damage_administrative_buildings">{t("esReport.Damage level 1-3")}</CFormLabel>
                <CFormInput type="number"
                            name="first_third_degre_of_damage_administrative_buildings"
                            value={state.first_third_degre_of_damage_administrative_buildings}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="first_third_degre_of_damage_administrative_buildings"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="fourth_fifth_degre_of_damage_secondary_administrative_buildings">{t("esReport.Damage level 4-5")}</CFormLabel>
                <CFormInput type="number"
                            name="fourth_fifth_degre_of_damage_secondary_administrative_buildings"
                            value={state.fourth_fifth_degre_of_damage_secondary_administrative_buildings}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="fourth_fifth_degre_of_damage_secondary_administrative_buildings"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="general_damage_to_administrative_buildings">{t("esReport.costOfDamagedAndDestroyedPropertyAndEquipment")}</CFormLabel>
              <CFormInput type="number"
                          label={t("esReport.totalAmountDamageToFacilitiesIncludingHouseholdAndOtherStructures")}
                          name="general_damage_to_administrative_buildings"
                          value={state.general_damage_to_administrative_buildings}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_administrative_buildings"
              />
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="cost_of_damaged_and_destroyed_property_and_equipment_administrative_buildings">{t("esReport.costOfDamagedAndDestroyedPropertyAndEquipment")}</CFormLabel>
              <CFormInput type="number"
                          name="cost_of_damaged_and_destroyed_property_and_equipment_administrative_buildings"
                          value={state.cost_of_damaged_and_destroyed_property_and_equipment_administrative_buildings}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="cost_of_damaged_and_destroyed_property_and_equipment_administrative_buildings"
              />
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="number_of_educational_facilities_located_in_potentially_hazardous_areas_administrative_buildings">{t("esReport.theNumberOfFacilitiesLocatedInPotentiallyDangerousAreas")}</CFormLabel>
              <CFormInput type="number"
                          name="number_of_educational_facilities_located_in_potentially_hazardous_areas_administrative_buildings"
                          value={state.number_of_educational_facilities_located_in_potentially_hazardous_areas_administrative_buildings}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="number_of_educational_facilities_located_in_potentially_hazardous_areas_administrative_buildings"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Объекты дорожного хозяйства*/}

        <CAccordionItem itemKey={17}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.roadFacilities")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel htmlFor="qty_road_facilities">{t("esReport.totalDamagedFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="qty_road_facilities"
                          value={state.qty_road_facilities}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="qty_road_facilities"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="roads_of_republican_significance">{t("esReport.republicanRoads")}</CFormLabel>
                <CFormInput type="number"
                            name="roads_of_republican_significance"
                            value={state.roads_of_republican_significance}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="roads_of_republican_significance"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="on_farm_roads">{t("esReport.internalRoads")}</CFormLabel>
                <CFormInput type="number"
                            name="on_farm_roads"
                            value={state.on_farm_roads}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="on_farm_roads"
                />
              </div>
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="bridges_on_national_roads">{t("esReport.bridgesOnRepublicanRoads")}</CFormLabel>
                <CFormInput type="number"
                            name="bridges_on_national_roads"
                            value={state.bridges_on_national_roads}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="bridges_on_national_roads"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="bridges_on_roads_of_on_farm_significance">{t("esReport.bridgesOnInternalRoads")}</CFormLabel>
                <CFormInput type="number"
                            name="bridges_on_roads_of_on_farm_significance"
                            value={state.bridges_on_roads_of_on_farm_significance}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="bridges_on_roads_of_on_farm_significance"
                />
              </div>
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="railways">{t("esReport.railways")}</CFormLabel>
                <CFormInput type="number"
                            name="railways"
                            value={state.railways}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="railways"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="tunnels_galleries">{t("esReport.tunnelsGalleries")}</CFormLabel>
                <CFormInput type="number"
                            name="tunnels_galleries"
                            value={state.tunnels_galleries}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="tunnels_galleries"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="general_damage_to_road_facilities">{t("esReport.totalAmountDamageToFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_road_facilities"
                          value={state.general_damage_to_road_facilities}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_road_facilities"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Объекты энергетики*/}

        <CAccordionItem itemKey={18}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.energyFacilities")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel htmlFor="energy_facilities">{t("esReport.totalDamagedFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="energy_facilities"
                          value={state.energy_facilities}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="energy_facilities"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="power_stations">{t("esReport.powerStations")}</CFormLabel>
                <CFormInput type="number"
                            name="power_stations"
                            value={state.power_stations}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="power_stations"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="transformer_substations">{t("esReport.transformerSubstations")}</CFormLabel>
                <CFormInput type="number"
                            name="transformer_substations"
                            value={state.transformer_substations}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="transformer_substations"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="power_transmission_towers">{t("esReport.powerLineSupports")}</CFormLabel>
                <CFormInput type="number"
                            name="power_transmission_towers"
                            value={state.power_transmission_towers}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="power_transmission_towers"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="power_lines">{t("esReport.powerLines")}</CFormLabel>
                <CFormInput type="number"
                            name="power_lines"
                            value={state.power_lines}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="power_lines"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="general_damage_to_energy_facilities">{t("esReport.totalAmountDamageToFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_energy_facilities"
                          value={state.general_damage_to_energy_facilities}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_energy_facilities"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Объекты связи*/}

        <CAccordionItem itemKey={19}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.communicationFacilities")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel htmlFor="communication_objects">{t("esReport.totalDamagedFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="communication_objects"
                          value={state.communication_objects}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="communication_objects"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '32%'}}>
                <CFormLabel htmlFor="communication_lines">{t("esReport.communicationLines")}</CFormLabel>
                <CFormInput type="number"
                            name="communication_lines"
                            value={state.communication_lines}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="communication_lines"
                />
              </div>
              <div style={{width: '32%'}}>
                <CFormLabel htmlFor="communication_line_poles">{t("esReport.communicationLineSupports")}</CFormLabel>
                <CFormInput type="number"
                            name="communication_line_poles"
                            value={state.communication_line_poles}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="communication_line_poles"
                />
              </div>
              <div style={{width: '32%'}}>
                <CFormLabel htmlFor="equipment_and_devices">{t("esReport.equipmentAndDevicesReceivingConvertingRelaying")}</CFormLabel>
                <CFormInput type="number"
                            name="equipment_and_devices"
                            value={state.equipment_and_devices}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="equipment_and_devices"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="general_damage_to_communication_facilities">{t("esReport.totalAmountDamageToFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_communication_facilities"
                          value={state.general_damage_to_communication_facilities}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_communication_facilities"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Гидротехнические сооружения*/}

        <CAccordionItem itemKey={20}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.hydraulicStructures")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel htmlFor="hydraulic_structures">{t("esReport.totalDamagedFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="hydraulic_structures"
                          value={state.hydraulic_structures}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="hydraulic_structures"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="hydroelectric_power_plant">{t("esReport.hydroelectricPowerPlant")}</CFormLabel>
                <CFormInput type="number"
                            name="hydroelectric_power_plant"
                            value={state.hydroelectric_power_plant}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="hydroelectric_power_plant"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="dam">{t("esReport.dam")}</CFormLabel>
                <CFormInput type="number"
                            name="dam"
                            value={state.dam}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="dam"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="reservoir">{t("esReport.storageReservoir")}</CFormLabel>
                <CFormInput type="number"
                            name="reservoir"
                            value={state.reservoir}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="reservoir"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="other">{t("esReport.other")}</CFormLabel>
                <CFormInput type="number"
                            name="other"
                            value={state.other}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="other"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="general_damage_to_hydraulic_structures">{t("esReport.totalAmountDamageToFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_hydraulic_structures"
                          value={state.general_damage_to_hydraulic_structures}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_hydraulic_structures"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Объекты коммунального хозяйства*/}

        <CAccordionItem itemKey={21}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.utilitiesFacilities")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel htmlFor="utilities_facilities">{t("esReport.totalDamagedFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="utilities_facilities"
                          value={state.utilities_facilities}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="utilities_facilities"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="water_intake_facilities_for_drinking_purposes">{t("esReport.wells")}</CFormLabel>
                <CFormInput type="number"
                            name="water_intake_facilities_for_drinking_purposes"
                            value={state.water_intake_facilities_for_drinking_purposes}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="water_intake_facilities_for_drinking_purposes"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="water_lines">{t("esReport.waterLines")}</CFormLabel>
                <CFormInput type="number"
                            name="water_lines"
                            value={state.water_lines}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="water_lines"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="sewer_lines">{t("esReport.sewerLines")}</CFormLabel>
                <CFormInput type="number"
                            name="sewer_lines"
                            value={state.sewer_lines}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="sewer_lines"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="gas_distribution_stations">{t("esReport.gasDistributionStations")}</CFormLabel>
                <CFormInput type="number"
                            name="gas_distribution_stations"
                            value={state.gas_distribution_stations}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="gas_distribution_stations"
                />
              </div>
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="gas_pipelines">{t("esReport.gasPipelines")}</CFormLabel>
                <CFormInput type="number"
                            name="gas_pipelines"
                            value={state.gas_pipelines}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="gas_pipelines"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="boiler_houses">{t("esReport.boilerRooms")}</CFormLabel>
                <CFormInput type="number"
                            name="boiler_houses"
                            value={state.boiler_houses}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="boiler_houses"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="heat_pipes">{t("esReport.heatPipelines")}</CFormLabel>
                <CFormInput type="number"
                            name="heat_pipes"
                            value={state.heat_pipes}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="heat_pipes"
                />
              </div>
              <div style={{width: '24%'}}>
                <CFormLabel htmlFor="treatment_facilities">{t("esReport.sewageTreatmentPlant")}</CFormLabel>
                <CFormInput type="number"
                            name="treatment_facilities"
                            value={state.treatment_facilities}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="treatment_facilities"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="general_damage_to_public_utilities">{t("esReport.totalAmountDamageToFacilities")}</CFormLabel>
              <CFormInput type="number"
                          // label={t("esReport.totalAmountDamageToFacilities")}
                          name="general_damage_to_public_utilities"
                          value={state.general_damage_to_public_utilities}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_public_utilities"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Объекты ирригации*/}

        <CAccordionItem itemKey={22}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.irrigationFacilities")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel htmlFor="irrigation_objects">{t("esReport.totalDamagedFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="irrigation_objects"
                          value={state.irrigation_objects}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="irrigation_objects"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="water_intake_facilities_for_irrigation_purposes">{t("esReport.waterIntakeFacilitiesForIrrigationPurposes")}</CFormLabel>
                <CFormInput type="number"
                            name="water_intake_facilities_for_irrigation_purposes"
                            value={state.water_intake_facilities_for_irrigation_purposes}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="water_intake_facilities_for_irrigation_purposes"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="water_distribution_facilities">{t("esReport.waterDistributionFacilities")}</CFormLabel>
                <CFormInput type="number"
                            name="water_distribution_facilities"
                            value={state.water_distribution_facilities}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="water_distribution_facilities"
                />
              </div>
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="reservoirs_irrigation">{t("esReport.storageReservoir")}</CFormLabel>
                <CFormInput type="number"
                            name="reservoirs_irrigation"
                            value={state.reservoirs_irrigation}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="reservoirs_irrigation"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="irrigation_networks_of_on_farm_significance_destroyed">{t("esReport.onFarmIrrigationNetworks")}</CFormLabel>
                <CFormInput type="number"
                            name="irrigation_networks_of_on_farm_significance_destroyed"
                            value={state.irrigation_networks_of_on_farm_significance_destroyed}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="irrigation_networks_of_on_farm_significance_destroyed"
                />
              </div>
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="destruction_of_irrigation_networks_of_off_farm_significance">{t("esReport.interFarmIrrigationNetworks")}</CFormLabel>
                <CFormInput type="number"
                            name="destruction_of_irrigation_networks_of_off_farm_significance"
                            value={state.destruction_of_irrigation_networks_of_off_farm_significance}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="destruction_of_irrigation_networks_of_off_farm_significance"
                />
              </div>
              <div style={{width: '48%'}}>
                <CFormLabel htmlFor="destroyed_collector_drainage_networks">{t("esReport.collectorAndDrainageNetworks")}</CFormLabel>
                <CFormInput type="number"
                            name="destroyed_collector_drainage_networks"
                            value={state.destroyed_collector_drainage_networks}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="destroyed_collector_drainage_networks"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel htmlFor="general_damage_to_irrigation_facilities">{t("esReport.totalAmountDamageToFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_irrigation_facilities"
                          value={state.general_damage_to_irrigation_facilities}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_irrigation_facilities"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Промышленные и коммерческие объекты*/}

        <CAccordionItem itemKey={23}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.industrialAndCommercialFacilities")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel
                htmlFor="industrial_and_commercial_facilities">{t("esReport.totalDamagedFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="industrial_and_commercial_facilities"
                          value={state.industrial_and_commercial_facilities}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="industrial_and_commercial_facilities"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '32%'}}>
                <CFormLabel
                  htmlFor="state_owned_industrial">{t("esReport.stateProperty")}</CFormLabel>
                <CFormInput type="number"
                            name="state_owned_industrial"
                            value={state.state_owned_industrial}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="state_owned_industrial"
                />
              </div>
              <div style={{width: '32%'}}>
                <CFormLabel
                  htmlFor="in_municipal_ownership_industrial">{t("esReport.municipalProperty")}</CFormLabel>
                <CFormInput type="number"
                            name="in_municipal_ownership_industrial"
                            value={state.in_municipal_ownership_industrial}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="in_municipal_ownership_industrial"
                />
              </div>
              <div style={{width: '32%'}}>
                <CFormLabel
                  htmlFor="in_private_ownership_industrial">{t("esReport.privateProperty")}</CFormLabel>
                <CFormInput type="number"
                            name="in_private_ownership_industrial"
                            value={state.in_private_ownership_industrial}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="in_private_ownership_industrial"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="general_damage_to_industrial_and_commercial_facilities">{t("esReport.totalAmountDamageToFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_industrial_and_commercial_facilities"
                          value={state.general_damage_to_industrial_and_commercial_facilities}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_industrial_and_commercial_facilities"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Техника, передвижные устройства и оборудование*/}

        <CAccordionItem itemKey={24}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.machineryMobileDevicesAndEquipment")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel
                htmlFor="the_number_of_damaged_machinery_mobile_devices_and_equipment">{t("esReport.totalDamagedFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="the_number_of_damaged_machinery_mobile_devices_and_equipment"
                          value={state.the_number_of_damaged_machinery_mobile_devices_and_equipment}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="the_number_of_damaged_machinery_mobile_devices_and_equipment"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '32%'}}>
                <CFormLabel
                  htmlFor="state_owned_devices">{t("esReport.stateProperty")}</CFormLabel>
                <CFormInput type="number"
                            name="state_owned_devices"
                            value={state.state_owned_devices}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="state_owned_devices"
                />
              </div>
              <div style={{width: '32%'}}>
                <CFormLabel
                  htmlFor="in_municipal_ownership_devices">{t("esReport.municipalProperty")}</CFormLabel>
                <CFormInput type="number"
                            name="in_municipal_ownership_devices"
                            value={state.in_municipal_ownership_devices}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="in_municipal_ownership_devices"
                />
              </div>
              <div style={{width: '32%'}}>
                <CFormLabel
                  htmlFor="in_private_ownership_devices">{t("esReport.privateProperty")}</CFormLabel>
                <CFormInput type="number"
                            name="in_private_ownership_devices"
                            value={state.in_private_ownership_devices}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="in_private_ownership_devices"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="general_damage_to_machinery_mobile_devices_and_equipment">{t("esReport.totalAmountDamageToFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_machinery_mobile_devices_and_equipment"
                          value={state.general_damage_to_machinery_mobile_devices_and_equipment}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_machinery_mobile_devices_and_equipment"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Сельское хозяйство и лесное хозяйство*/}

        <CAccordionItem itemKey={25}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.agricultureAndForestry")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel
                htmlFor="agriculture_and_forestry">{t("esReport.totalAreaOfDamagedAgriculturalLand")}</CFormLabel>
              <CFormInput type="number"
                          name="agriculture_and_forestry"
                          value={state.agriculture_and_forestry}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="agriculture_and_forestry"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel
                  htmlFor="irrigated_arable_land">{t("esReport.irrigatedArableLand")}</CFormLabel>
                <CFormInput type="number"
                            name="irrigated_arable_land"
                            value={state.irrigated_arable_land}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="irrigated_arable_land"
                />
              </div>

              <div style={{width: '24%'}}>
                <CFormLabel
                  htmlFor="non_irrigated_arable_land">{t("esReport.nonIrrigatedArableLand")}</CFormLabel>
                <CFormInput type="number"
                            name="non_irrigated_arable_land"
                            value={state.non_irrigated_arable_land}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="non_irrigated_arable_land"
                />
              </div>

              <div style={{width: '24%'}}>
                <CFormLabel
                  htmlFor="pastures">{t("esReport.pasture")}</CFormLabel>
                <CFormInput type="number"
                            name="pastures"
                            value={state.pastures}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="pastures"
                />
              </div>

              <div style={{width: '24%'}}>
                <CFormLabel
                  htmlFor="forest_land">{t("esReport.forestLand")}</CFormLabel>
                <CFormInput type="number"
                            name="forest_land"
                            value={state.forest_land}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="forest_land"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="general_damage_to_agricultural_land">{t("esReport.totalAmountDamageToAgriculturalLand")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_agricultural_land"
                          value={state.general_damage_to_agricultural_land}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_agricultural_land"
              />
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="total_area_of_dead_crops">{t("esReport.totalAreaOfDeadCrops")}</CFormLabel>
              <CFormInput type="number"
                          name="total_area_of_dead_crops"
                          value={state.total_area_of_dead_crops}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="total_area_of_dead_crops"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '32%'}}>
                <CFormLabel
                  htmlFor="cereals">{t("esReport.cerealCrops")}</CFormLabel>
                <CFormInput type="number"
                            name="cereals"
                            value={state.cereals}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="cereals"
                />
              </div>

              <div style={{width: '32%'}}>
                <CFormLabel
                  htmlFor="roots">{t("esReport.roots")}</CFormLabel>
                <CFormInput type="number"
                            name="roots"
                            value={state.roots}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="roots"
                />
              </div>

              <div style={{width: '32%'}}>
                <CFormLabel
                  htmlFor="cotton">{t("esReport.cotton")}</CFormLabel>
                <CFormInput type="number"
                            name="cotton"
                            value={state.cotton}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="cotton"
                />
              </div>
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '32%'}}>
                <CFormLabel
                  htmlFor="vegetables">{t("esReport.vegetables")}</CFormLabel>
                <CFormInput type="number"
                            name="vegetables"
                            value={state.vegetables}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="vegetables"
                />
              </div>

              <div style={{width: '32%'}}>
                <CFormLabel
                  htmlFor="beans_and_other_legumes">{t("esReport.beansAndOtherLegumes")}</CFormLabel>
                <CFormInput type="number"
                            name="beans_and_other_legumes"
                            value={state.beans_and_other_legumes}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="beans_and_other_legumes"
                />
              </div>

              <div style={{width: '32%'}}>
                <CFormLabel
                  htmlFor="perennial_plantations">{t("esReport.perennialPlantings")}</CFormLabel>
                <CFormInput type="number"
                            name="perennial_plantations"
                            value={state.perennial_plantations}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="perennial_plantations"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="general_damage_to_crops">{t("esReport.totalAmountDamageToCrops")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_crops"
                          value={state.general_damage_to_crops}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_crops"
              />
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="total_number_of_dead_livestock_and_poultry">{t("esReport.totalDeadLivestockAndPoultry")}</CFormLabel>
              <CFormInput type="number"
                          name="total_number_of_dead_livestock_and_poultry"
                          value={state.total_number_of_dead_livestock_and_poultry}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="total_number_of_dead_livestock_and_poultry"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '24%'}}>
                <CFormLabel
                  htmlFor="cattle">{t("esReport.cattle")}</CFormLabel>
                <CFormInput type="number"
                            name="cattle"
                            value={state.cattle}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="cattle"
                />
              </div>

              <div style={{width: '24%'}}>
                <CFormLabel
                  htmlFor="mrs">{t("esReport.smallRuminants")}</CFormLabel>
                <CFormInput type="number"
                            name="mrs"
                            value={state.mrs}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="mrs"
                />
              </div>

              <div style={{width: '24%'}}>
                <CFormLabel
                  htmlFor="horses">{t("esReport.horses")}</CFormLabel>
                <CFormInput type="number"
                            name="horses"
                            value={state.horses}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="horses"
                />
              </div>

              <div style={{width: '24%'}}>
                <CFormLabel
                  htmlFor="birds">{t("esReport.poultry")}</CFormLabel>
                <CFormInput type="number"
                            name="birds"
                            value={state.birds}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="birds"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="general_damage_to_livestock_and_poultry">{t("esReport.totalAmountDamageToLivestockAndPoultry")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_livestock_and_poultry"
                          value={state.general_damage_to_livestock_and_poultry}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_livestock_and_poultry"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Новые земельные участки выделеные домохозяйствам, отселенным из опасных участков для строительства индивидуального жилья*/}

        <CAccordionItem itemKey={26}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.newLandPlotsAllocatedToHouseholdsResettledFromHazardousAreasForTheConstructionOfIndividualHousing")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel
                htmlFor="number_of_new_and_plots_allocated_to_households_resettled_from_hazardous_areas_for_the_construction_of_individual_housing">{t("esReport.totalAllocatedLandPlots")}</CFormLabel>
              <CFormInput type="number"
                          name="number_of_new_and_plots_allocated_to_households_resettled_from_hazardous_areas_for_the_construction_of_individual_housing"
                          value={state.number_of_new_and_plots_allocated_to_households_resettled_from_hazardous_areas_for_the_construction_of_individual_housing}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="number_of_new_and_plots_allocated_to_households_resettled_from_hazardous_areas_for_the_construction_of_individual_housing"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="number_of_households">{t("esReport.numberOfHouseholds")}</CFormLabel>
                <CFormInput type="number"
                            name="number_of_households"
                            value={state.number_of_households}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="number_of_households"
                />
              </div>

              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="number_of_community_members">{t("esReport.numberOfCommunityMembers")}</CFormLabel>
                <CFormInput type="number"
                            name="number_of_community_members"
                            value={state.number_of_community_members}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="number_of_community_members"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="number_of_allotted_apartments_and_or_houses">{t("esReport.totalAllocatedApartmentsAndOrHouses")}</CFormLabel>
              <CFormInput type="number"
                          name="number_of_allotted_apartments_and_or_houses"
                          value={state.number_of_allotted_apartments_and_or_houses}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="number_of_allotted_apartments_and_or_houses"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="number_of_families">{t("esReport.numberOfFamilies")}</CFormLabel>
                <CFormInput type="number"
                            name="number_of_families"
                            value={state.number_of_families}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="number_of_families"
                />
              </div>

              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="number_of_family_members">{t("esReport.numberOfFamilyMembers")}</CFormLabel>
                <CFormInput type="number"
                            name="number_of_family_members"
                            value={state.number_of_family_members}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="number_of_family_members"
                />
              </div>
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Новые земельные участки выделеные домохозяйствам, отселенным из опасных участков для строительства индивидуального жилья*/}

        <CAccordionItem itemKey={27}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.socialProtectionFacilities")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel
                htmlFor="objects_of_social_protection">{t("esReport.totalDamagedFacilities")}</CFormLabel>
              <CFormInput type="number"
                          name="objects_of_social_protection"
                          value={state.objects_of_social_protection}
                          onChange={handleChange}
                          min={0}
                          disabled
                          size="sm"
                          id="objects_of_social_protection"
              />
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="social_stationary_institutions">{t("esReport.socialStationaryInstitutions")}</CFormLabel>
              <CFormInput type="number"
                          name="social_stationary_institutions"
                          value={state.social_stationary_institutions}
                          onChange={handleChange}
                          min={0}
                          disabled
                          size="sm"
                          id="social_stationary_institutions"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="first_third_degre_of_damage_social_stationary_institutions">{t("esReport.Damage level 1-3")}</CFormLabel>
                <CFormInput type="number"
                            name="first_third_degre_of_damage_social_stationary_institutions"
                            value={state.first_third_degre_of_damage_social_stationary_institutions}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="first_third_degre_of_damage_social_stationary_institutions"
                />
              </div>

              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="fourth_fifth_degre_of_damage_social_stationary_institutions">{t("esReport.Damage level 4-5")}</CFormLabel>
                <CFormInput type="number"
                            name="fourth_fifth_degre_of_damage_social_stationary_institutions"
                            value={state.fourth_fifth_degre_of_damage_social_stationary_institutions}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="fourth_fifth_degre_of_damage_social_stationary_institutions"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="semi_permanent_institutions">{t("esReport.semiPermanentInstitutions")}</CFormLabel>
              <CFormInput type="number"
                          name="semi_permanent_institutions"
                          value={state.semi_permanent_institutions}
                          onChange={handleChange}
                          min={0}
                          disabled
                          size="sm"
                          id="semi_permanent_institutions"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="first_third_degre_of_damage_semi_permanent_institutions">{t("esReport.Damage level 1-3")}</CFormLabel>
                <CFormInput type="number"
                            name="first_third_degre_of_damage_semi_permanent_institutions"
                            value={state.first_third_degre_of_damage_semi_permanent_institutions}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="first_third_degre_of_damage_semi_permanent_institutions"
                />
              </div>

              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="fourth_fifth_degre_of_damage_semi_permanent_institutions">{t("esReport.Damage level 4-5")}</CFormLabel>
                <CFormInput type="number"
                            name="fourth_fifth_degre_of_damage_semi_permanent_institutions"
                            value={state.fourth_fifth_degre_of_damage_semi_permanent_institutions}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="fourth_fifth_degre_of_damage_semi_permanent_institutions"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="general_damage_to_buildings_and_structures_of_social_protection_facilities">{t("esReport.totalAmountDamageToFacilitiesIncludingHouseholdAndOtherStructures")}</CFormLabel>
              <CFormInput type="number"
                          name="general_damage_to_buildings_and_structures_of_social_protection_facilities"
                          value={state.general_damage_to_buildings_and_structures_of_social_protection_facilities}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="general_damage_to_buildings_and_structures_of_social_protection_facilities"
              />
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="cost_of_damaged_and_destroyed_property_and_equipment_objects_of_social_protection">{t("esReport.costOfDamagedAndDestroyedPropertyAndEquipment")}</CFormLabel>
              <CFormInput type="number"
                          name="cost_of_damaged_and_destroyed_property_and_equipment_objects_of_social_protection"
                          value={state.cost_of_damaged_and_destroyed_property_and_equipment_objects_of_social_protection}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="cost_of_damaged_and_destroyed_property_and_equipment_objects_of_social_protection"
              />
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="number_of_social_protection_facilities_located_in_potentially_dangerous_areas">{t("esReport.theNumberOfFacilitiesLocatedInPotentiallyDangerousAreas")}</CFormLabel>
              <CFormInput type="number"
                          name="number_of_social_protection_facilities_located_in_potentially_dangerous_areas"
                          value={state.number_of_social_protection_facilities_located_in_potentially_dangerous_areas}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="number_of_social_protection_facilities_located_in_potentially_dangerous_areas"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Суммарный объем средств, направленных на ликвидацию последствий чрезвычайной ситуации (средства МЧС)*/}

        <CAccordionItem itemKey={28}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.totalAmountOfFundsAllocatedToEliminateTheConsequencesOfAnEmergencyFundsOfTheMES")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel
                htmlFor="the_total_amount_of_funds_allocated_to_eliminate_the_consequences_of_an_emergency">{t("esReport.totalAmountOfFunds")}</CFormLabel>
              <CFormInput type="number"
                          name="the_total_amount_of_funds_allocated_to_eliminate_the_consequences_of_an_emergency"
                          value={state.the_total_amount_of_funds_allocated_to_eliminate_the_consequences_of_an_emergency}
                          onChange={handleChange}
                          min={0}
                          disabled
                          size="sm"
                          id="the_total_amount_of_funds_allocated_to_eliminate_the_consequences_of_an_emergency"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="budgetary_funds_of_the_ministry_of_emergency_situations">{t("esReport.budgetFunds")}</CFormLabel>
                <CFormInput type="number"
                            name="budgetary_funds_of_the_ministry_of_emergency_situations"
                            value={state.budgetary_funds_of_the_ministry_of_emergency_situations}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="budgetary_funds_of_the_ministry_of_emergency_situations"
                />
              </div>

              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="donor_funds">{t("esReport.donorFunds")}</CFormLabel>
                <CFormInput type="number"
                            name="donor_funds"
                            value={state.donor_funds}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="donor_funds"
                />
              </div>
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Суммарный объем средств, направленных на ликвидацию последствий чрезвычайной ситуации (средства МЧС)*/}

        <CAccordionItem itemKey={29}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.totalAmountOfFundsAllocatedForRestorationByFundingSourcesDataFromLGAndRelevantMinistries")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel
                htmlFor="the_total_amount_of_funds_allocated_for_restoration_by_funding_sources">{t("esReport.totalAmountOfFunds")}</CFormLabel>
              <CFormInput type="number"
                          name="the_total_amount_of_funds_allocated_for_restoration_by_funding_sources"
                          value={state.the_total_amount_of_funds_allocated_for_restoration_by_funding_sources}
                          onChange={handleChange}
                          min={0}
                          disabled
                          size="sm"
                          id="the_total_amount_of_funds_allocated_for_restoration_by_funding_sources"
              />
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="budget_resources_allocated_for_restoration_by_funding_sources">{t("esReport.totalBudgetFunds")}</CFormLabel>
              <CFormInput type="number"
                          name="budget_resources_allocated_for_restoration_by_funding_sources"
                          value={state.budget_resources_allocated_for_restoration_by_funding_sources}
                          onChange={handleChange}
                          min={0}
                          disabled
                          size="sm"
                          id="budget_resources_allocated_for_restoration_by_funding_sources"
              />
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="donor_funds_allocated_for_restoration_by_funding_sources">{t("esReport.totalDonorFunds")}</CFormLabel>
              <CFormInput type="number"
                          name="donor_funds_allocated_for_restoration_by_funding_sources"
                          value={state.donor_funds_allocated_for_restoration_by_funding_sources}
                          onChange={handleChange}
                          min={0}
                          disabled
                          size="sm"
                          id="donor_funds_allocated_for_restoration_by_funding_sources"
              />
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="restoration_of_management_functions_and_provision_of_state_and_municipal_services">{t("esReport.restorationOfManagementFunctionsAndProvisionOfStateAndMunicipalServices")}</CFormLabel>
              <CFormInput type="number"
                          name="restoration_of_management_functions_and_provision_of_state_and_municipal_services"
                          value={state.restoration_of_management_functions_and_provision_of_state_and_municipal_services}
                          onChange={handleChange}
                          min={0}
                          disabled
                          size="sm"
                          id="restoration_of_management_functions_and_provision_of_state_and_municipal_services"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="budget_resources_restoration_of_management_functions_and_provision_of_state_and_municipal_services">{t("esReport.budgetFunds")}</CFormLabel>
                <CFormInput type="number"
                            name="budget_resources_restoration_of_management_functions_and_provision_of_state_and_municipal_services"
                            value={state.budget_resources_restoration_of_management_functions_and_provision_of_state_and_municipal_services}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="budget_resources_restoration_of_management_functions_and_provision_of_state_and_municipal_services"
                />
              </div>

              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="donor_funds_restoration_of_management_functions_and_provision_of_state_and_municipal_services">{t("esReport.donorFunds")}</CFormLabel>
                <CFormInput type="number"
                            name="donor_funds_restoration_of_management_functions_and_provision_of_state_and_municipal_services"
                            value={state.donor_funds_restoration_of_management_functions_and_provision_of_state_and_municipal_services}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="donor_funds_restoration_of_management_functions_and_provision_of_state_and_municipal_services"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="resettlement_of_the_population_to_safe_areas">{t("esReport.resettlementOfThePopulationToSafeAreasAllocationOfLandPlotsConstructionOfWaterSupplySystemsElectricitySupply")}</CFormLabel>
              <CFormInput type="number"
                          name="resettlement_of_the_population_to_safe_areas"
                          value={state.resettlement_of_the_population_to_safe_areas}
                          onChange={handleChange}
                          min={0}
                          disabled
                          size="sm"
                          id="resettlement_of_the_population_to_safe_areas"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="budget_resources_resettlement_of_the_population_to_safe_areas">{t("esReport.budgetFunds")}</CFormLabel>
                <CFormInput type="number"
                            name="budget_resources_resettlement_of_the_population_to_safe_areas"
                            value={state.budget_resources_resettlement_of_the_population_to_safe_areas}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="budget_resources_resettlement_of_the_population_to_safe_areas"
                />
              </div>

              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="donor_funds_resettlement_of_the_population_to_safe_areas">{t("esReport.donorFunds")}</CFormLabel>
                <CFormInput type="number"
                            name="donor_funds_resettlement_of_the_population_to_safe_areas"
                            value={state.donor_funds_resettlement_of_the_population_to_safe_areas}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="donor_funds_resettlement_of_the_population_to_safe_areas"
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormLabel
                htmlFor="restoration_of_the_vital_activity_of_the_economy">{t("esReport.restorationOfTheVitalActivityOfTheEconomySocialAndCulturalFacilitiesProductionIndustrialProductionHealthcareEducationEtc")}</CFormLabel>
              <CFormInput type="number"
                          name="restoration_of_the_vital_activity_of_the_economy"
                          value={state.restoration_of_the_vital_activity_of_the_economy}
                          onChange={handleChange}
                          min={0}
                          disabled
                          size="sm"
                          id="restoration_of_the_vital_activity_of_the_economy"
              />
            </div>

            <div className="mb-3 input-form-display-flex">
              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="budget_resources_restoration_of_the_vital_activity_of_the_economy">{t("esReport.budgetFunds")}</CFormLabel>
                <CFormInput type="number"
                            name="budget_resources_restoration_of_the_vital_activity_of_the_economy"
                            value={state.budget_resources_restoration_of_the_vital_activity_of_the_economy}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="budget_resources_restoration_of_the_vital_activity_of_the_economy"
                />
              </div>

              <div style={{width: '48%'}}>
                <CFormLabel
                  htmlFor="donor_funds_restoration_of_the_vital_activity_of_the_economy">{t("esReport.donorFunds")}</CFormLabel>
                <CFormInput type="number"
                            name="donor_funds_restoration_of_the_vital_activity_of_the_economy"
                            value={state.donor_funds_restoration_of_the_vital_activity_of_the_economy}
                            onChange={handleChange}
                            min={0}
                            size="sm"
                            id="donor_funds_restoration_of_the_vital_activity_of_the_economy"
                />
              </div>
            </div>

          </CAccordionBody>
        </CAccordionItem>

        {/*Суммарный объем средств, направленных на ликвидацию последствий чрезвычайной ситуации (средства МЧС)*/}

        <CAccordionItem itemKey={30}>
          <CAccordionHeader>
            <div className="fw-bold">{t("esReport.totalAmountOfHumanitarianAid")}</div>
          </CAccordionHeader>
          <CAccordionBody>

            <div className="mb-3">
              <CFormLabel
                htmlFor="total_volume_of_humanitarian_aid">{t("esReport.totalAmountOfFunds")}</CFormLabel>
              <CFormInput type="number"
                          name="total_volume_of_humanitarian_aid"
                          value={state.total_volume_of_humanitarian_aid}
                          onChange={handleChange}
                          min={0}
                          size="sm"
                          id="total_volume_of_humanitarian_aid"
              />
            </div>

          </CAccordionBody>
        </CAccordionItem>

      </CAccordion>
    </>
  );
};

export default AccordionEsReport;
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IService, IServices} from "../../interfaces/IServices";

export const servicesApi = createApi({
    reducerPath: 'services/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['Services'],
    endpoints: build => ({
        getServices: build.query<IServices, string | void>({
            query: (query: string | undefined) => {
                return {
                    url: `/services/${query || ''}`
                }
            },
            providesTags: ['Services'],
        }),
        getService: build.query<IService, string>({
            query: (id: string) => ({
                url: `/services/${id}/`
            })
        }),
        createService: build.mutation<any, any>({
            query: (body) => ({
                url: '/services/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Services'],
        }),
        updateService: build.mutation<any, Partial<any> & Pick<any, 'id'>>({
            query: ({id, body}) => {
                return {
                    url: `/services/${id}/`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: ['Services'],
        }),
        removeService: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/services/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Services'],
        })
    })
})

export const {
    useGetServicesQuery,
    useLazyGetServicesQuery,
    useCreateServiceMutation,
    useUpdateServiceMutation,
    useRemoveServiceMutation
} = servicesApi;
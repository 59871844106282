import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IEmergencyType} from "../../interfaces/IEmergency";
import {IPaginationResponse} from "../models";

export const emergencyTypesApi = createApi({
    reducerPath: 'emergencyType/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['EmergencyType'],
    endpoints: build => ({
        getEmergencyTypes: build.query<IPaginationResponse<IEmergencyType[]>, string | void>({
            query: (query) => {
                return {
                    url: `/list/type/emergency/${query || ''}`
                }
            },
            providesTags: ['EmergencyType'],
        }),
        // getUsersById: build.query<IRegion, string>({
        //     query: (id: string) => ({
        //         url: `/detail/users/${id}`
        //     })
        // }),
        createEmergencyType: build.mutation<any, any>({
            query: (body) => ({
                url: '/create/type/emergency/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['EmergencyType'],
        }),
        updateEmergencyType: build.mutation<any, Partial<any> & Pick<any, 'id'>>({
            query: ({id, body}) => {
                return {
                    url: `/update/type/emergency/${id}/`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: ['EmergencyType'],
        }),
        removeEmergencyType: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/delete/type/emergency/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['EmergencyType'],
        })
    })
})

export const {
    useLazyGetEmergencyTypesQuery,
    useGetEmergencyTypesQuery,
    useCreateEmergencyTypeMutation,
    useUpdateEmergencyTypeMutation,
    useRemoveEmergencyTypeMutation
} = emergencyTypesApi;
import React from 'react';

const PublicEsReports = () => {
  return (
    <div>
      Public ES Reports
    </div>
  );
};

export default PublicEsReports;

import React, {SetStateAction, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
    CButton, CButtonGroup,
    CFormInput,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow
} from "@coreui/react";
import {Editor} from "react-draft-wysiwyg";
import {EditorState} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import {htmlToEditorState, EditorStateToHtml, Query} from "../../../functions";
import FormGroup from "../../../components/UI/Forms/FormGroup";
import DefaultPaginationWrapper from "../../../components/UI/Pagination/DefaultPaginationWrapper";
// import {
//     createDocument,
//     deleteDocument,
//     fetchDocuments,
//     setClearOneDocument,
//     updateDocument
// } from "../../../store/actions/documents";
import {useTranslation} from "react-i18next";
import {
    useCreateDocumentMutation, useLazyGetDocumentQuery, useLazyGetDocumentsQuery,
    useRemoveDocumentMutation,
    useUpdateDocumentMutation
} from "../../../store/documents/documents.api";

const Documents = () => {
    // const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [fetchDocuments, {isError: error,  isLoading, data: documents}] = useLazyGetDocumentsQuery();
    const [createDocument] = useCreateDocumentMutation();
    const [updateDocument] = useUpdateDocumentMutation();
    const [deleteDocument] = useRemoveDocumentMutation();
    // const {documents} = useSelector(state => state.documents);
    // const {error} = useSelector(state => state.documents);

    const [pagination, setPagination] = useState({page_size: '100', search: '', page: 1});
    const [filter, setFilter] = useState({sort_by: '', direction: 'asc'});
    const [visible, setVisible] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [validateError, setValidateError] = useState('');
    const [state, setState]: any = useState({name: ''});
    const [editor, setEditor]: any = useState(EditorState.createEmpty());
    const countDocuments: any = documents;

    useEffect(() => {
        // dispatch(fetchDocuments(params.id));
        // dispatch(setClearOneDocument());
        fetchDocuments(params.id);
        // setClearOneDocument();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (event: any) => {
        setState((prevState: any) => {
            return {...prevState, [event.target.name]: event.target.value}
        });
    }

    const handleEditorChange = (editorState: any) => {
        setEditor(editorState);
    }

    const handleToggleModal = (visible: boolean) => {
        setTimeout(() => {
            if (visible === false) {
                setState({name: '', description: ''});
                setSelectedId(null);
                setEditor(EditorState.createEmpty());
            }
        }, 200)
        setVisible(visible);
    }

    const handleOpenEditModal = (visible?: any, item?: any) => {
        setSelectedId(item.id);
        setVisible(visible);
        setState({name: item.name})
        setEditor(htmlToEditorState(item.description));
    }

    const handleToggleDeleteModal = (visible: any) => {
        if (visible === false) {
            setSelectedId(null);
        }
        setDeleteModal(visible);
    }

    const handleOpenDeleteModal = (id: any) => {
        setDeleteModal(true);
        setSelectedId(id);
    }

    const handleDelete = () => {
        deleteDocument(Number(selectedId)).then(
            () => {
                // dispatch(fetchDocuments());
                handleToggleDeleteModal(false);
            },
            (error: string) => {
                //console.log(error);
            }
        )
        // dispatch(deleteDocument(selectedId,
        //     () => {
        //         dispatch(fetchDocuments(params.id));
        //         handleToggleDeleteModal(false);
        //     },
        //     (error: string) => {
        //         // console.log(error);
        //     }
        // ))
    }

    const handleEdit = () => {
        let editorData = EditorStateToHtml(editor), typeDoc, data;
        typeDoc = Number(params.id);

        data = {
            name: state.name,
            description: editorData,
            type_doc: typeDoc,
        }

        for (const item of Object.keys(state)) {
            if (!state[item]) {
                setValidateError(t('allFieldsValidate'));
                return null
            }
        }

        // if (!getTextFromHtmlString(EditorStateToHtml(editor))) {
        //     setValidateError(t('allFieldsValidate'));
        //     return null
        // }

        if (selectedId) {
            updateDocument({body: data, id: selectedId}).then(
                () => {
                    // dispatch(fetchDocuments());
                    handleToggleModal(false);
                },
                (error: string) => {
                    // console.log(error);
                }
            )
            // dispatch(updateDocument(selectedId, data,
            //     () => {
            //         dispatch(fetchDocuments(params.id));
            //         handleToggleModal(false);
            //     },
            //     (error: string) => {
            //         // console.log(error);
            //     }
            // ))
        } else {
            createDocument(data).then(
                () => {
                    // dispatch(fetchDocuments());
                    handleToggleModal(false);
                },
                (error: string) => {
                    // console.log(error);
                }
            )
            // dispatch(createDocument(data,
            //     () => {
            //         dispatch(fetchDocuments(params.id));
            //         handleToggleModal(false);
            //     },
            //     (error: string) => {
            //         // console.log(error);
            //     }
            // ))
        }
    }

    const onPaginationHandler = (state: any) => {
        let innerState = {...pagination, ...state};

        if(countDocuments && countDocuments <= Number(pagination.page_size)) {
            innerState = {
                page_size: pagination.page_size,
                search: pagination.search,
                page: 1
                , ...state}
        }
        if (state.search === undefined) onSearchDataHandler({...innerState, ...filter});
    };

    const onFilterHandler = (field: string) => {
        const state = {...filter};
        if (field === state.sort_by) state.direction = state.direction === 'asc' ? 'desc' : 'asc';
        else {
            state.sort_by = field;
            state.direction = 'asc';
        }
        setFilter(state);
        onSearchDataHandler({...pagination, ...state});
    };

    const onSearchCallbackHandler = (search: string) => {
        onSearchDataHandler({...pagination, ...filter, search});
    };

    const onSearchDataHandler = (data: any) => {
        const query: any = {};
        if (data.page_size) query.page_size = data.page_size;
        if (data.page) query.page = data.page;
        if (data.search && data.search !== '') query.search = data.search;
        if (data.sort_by) query.sort_by = data.sort_by;
        if (data.direction === 'desc') query.direction = 'desc';

        // dispatch(fetchDocuments(params.id, Query.stringify(query)));
        fetchDocuments(params.id, query);
    }

    const handleRedirect = (id: number) => {
        navigate(`/registry-document/${id}`)
    }

    let documentsForTable;

    // @ts-ignore
    if (documents?.results?.length) {
        // @ts-ignore
        documentsForTable = documents?.results.map((el: any) => {
            return (
                <CTableRow key={el.id}>
                    <CTableDataCell>
                        <span className="document-types-link" onClick={() => handleRedirect(el.id)}>{el.name}</span>
                    </CTableDataCell>

                    <CTableDataCell>
            <span>
              {/*{getTextFromHtmlString(el.description).substring(0, 200)}*/}
              {/*  {getTextFromHtmlString(el.description).length > 200 ? '...' : ''}*/}
            </span>
                    </CTableDataCell>

                    <CTableDataCell className="text-center">
                        <CButtonGroup size="sm">

                            <CButton size="sm" color="info" className="default-table__action-btn"
                                     onClick={() => handleOpenEditModal(true, el)}>
                                <i className="fa fa-pencil-square-o color-white"/>
                            </CButton>
                            <CButton size="sm" color="danger" className="default-table__action-btn"
                                     onClick={() => handleOpenDeleteModal(el.id)}>
                                <i className="fa fa-times color-white" aria-hidden="true"> </i>
                            </CButton>
                        </CButtonGroup>
                    </CTableDataCell>
                </CTableRow>
            )
        })
    }

    return (
        <>
            <div className="pb-3 mb-4 title-divider">
                <h1>{t('registry.documents.documents')}</h1>
            </div>

            <div className="d-flex justify-content-md-end mb-3">
                <CButton size="sm" color="success" className="color-white" onClick={() => handleToggleModal(true)}>
                    {t('registry.documents.addDocument')}
                </CButton>
            </div>

            <DefaultPaginationWrapper data={documents}
                                      state={pagination}
                                      onChange={onPaginationHandler}
                                      onSearchDelay={2000}
                                      onSearchCallback={onSearchCallbackHandler}
            >
                <CTable bordered striped className="default-table">
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell style={{width: '20%'}}
                                              scope="col"
                                              className="sorted-table-cell"
                                              onClick={() => onFilterHandler('name')}
                            >
                                <div className="d-flex justify-content-between">
                                    <span>{t('gis.geoObjects.naming')}</span>
                                    <i className="fa fa-sort" aria-hidden="true"/>
                                </div>
                            </CTableHeaderCell>

                            <CTableHeaderCell style={{width: '25%'}}
                                              scope="col"
                                              className="sorted-table-cell"
                            >
                                <div className="d-flex justify-content-between">
                                    <span>{t('gis.geoObjects.description')}</span>
                                </div>
                            </CTableHeaderCell>

                            <CTableHeaderCell style={{width: '8%'}} scope="col" className="default-table__actions">
                                {t('actions')}
                            </CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        {documentsForTable}
                    </CTableBody>
                </CTable>
            </DefaultPaginationWrapper>

            <DefaultModal visible={visible}
                          setVisible={handleToggleModal}
                          title={selectedId ?
                              t('registry.documents.editDocument') :
                              t('registry.documents.addDocument')}
                          type="info"
                          cancelButton={t('cancel')}
                          onOk={handleEdit}
                          size="lg"
                          error={validateError}
            >
                <FormGroup htmlFor="name" label={t('gis.geoObjects.naming')}>
                    <CFormInput id="name"
                                type="text"
                                value={state.name}
                                name="name"
                                onChange={handleChange}
                                size="sm"
                    />
                </FormGroup>
                <FormGroup htmlFor="name" label={t('gis.geoObjects.description')}>
                    <Editor
                        editorState={editor}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={handleEditorChange}
                    />
                </FormGroup>
            </DefaultModal>

            <DefaultModal visible={deleteModal}
                          setVisible={handleToggleDeleteModal}
                          title={t('deletion')}
                          type="danger"
                          cancelButton={t('cancel')}
                          onOk={handleDelete}
                // @ts-ignore
                          error={error?.data?.error}
                          size="lg"
            >
                <div>
                    <p className="mb-0 fs-6">{t('registry.documents.deleteDocument')}</p>
                </div>
            </DefaultModal>
        </>
    );
};

export default Documents;

import React, {FC} from 'react';
import './LoaderLine.css';

interface IProps {
  width?: string,
  height?: string,
  visible?: boolean
}

const LoaderLine: FC<IProps> = ({width = '100%', height = '5px', visible = false}) => {
  return (
    <div className={`loader-line${visible ? ' loader-line--visible' : ''}`} style={{width: width, height: height}}>
      <div className="loader-line__flash"/>
    </div>
  );
};

export default LoaderLine;

import React from 'react';

const CardsFrom112 = () => {
  return (
    <div>
      Cards from 112
    </div>
  );
};

export default CardsFrom112;

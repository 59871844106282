import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";

import icon4 from '../../../assets/icons/ic-videowall-4.png';
import icon6 from '../../../assets/icons/ic-videowall-6.png';
import icon9 from '../../../assets/icons/ic-videowall-9.png';
import icon10 from '../../../assets/icons/ic-videowall-10.png';
import icon16 from '../../../assets/icons/ic-videowall-16.png';
import { useGetVideoStreamsQuery } from '../../../store/videoStreams/videoStreams.api';
import {CButton, CFormCheck} from "@coreui/react";
import Select, {MultiValue} from "react-select";
import VideoWall from "../../../components/VideoPlayers/VideoWall";

type TDefaultStream = {
  label: string,
  value: string
};

const ChannelsWall = () => {
  const { t } = useTranslation();

  const {isError, isLoading, data: videoStreams} = useGetVideoStreamsQuery();

  const startSelectedSources = useMemo(() => {
    return videoStreams?.length ? videoStreams.map(el => ({value: el.url, label: el.videowall_name})).splice(0, 9) : [];
  }, [videoStreams]);

  const [cols, setCols] = useState(3);
  const [streamsLayout, setStreamsLayout] = useState(9);
  const [checkboxes, setCheckboxes] = useState<{[key: string]: boolean}>({allowManagement: false});
  const [selectedVideoStreams, setSelectedVideoStreams] = useState<TDefaultStream[]>([]);
  const [streamsOntoWall, setStreamsOntoWall] = useState(startSelectedSources);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const defaultStreams: TDefaultStream[] = videoStreams?.length ? videoStreams.map(el => ({value: el.url, label: el.videowall_name})).splice(0, streamsLayout) : [];
    setSelectedVideoStreams(defaultStreams);
    reloadWall(defaultStreams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoStreams]);

  const changeLayoutHandler = (event: React.MouseEvent<HTMLElement>, factor: number = 9) => {
    event.preventDefault();
    console.log(factor);
    if (factor === streamsLayout) return null;
    if (factor) {
      if (factor === 4) setCols(2);
      if (factor === 6 || factor === 9) setCols(3);
      if (factor === 10 || factor === 16) setCols(4);
      setStreamsLayout(factor);
    } else {
      setCols(3);
      setStreamsLayout(9);
    }
    const streams = [];
    for (let i = 0; i < factor; i++) {
      if (selectedVideoStreams[i]) streams.push(selectedVideoStreams[i]);
      else streams.push({value: '', label: ''});
    }
    setStreamsOntoWall(streams);
  };

  const onChangeMultipleHandler = (event: MultiValue<TDefaultStream>) => {
    let arr: TDefaultStream[] = [];
    if (event.length) {
      event.map((el) => {
        arr.push(el);
        if (arr.length > streamsLayout) return null;
        else return setSelectedVideoStreams(arr);
      })
    } else setSelectedVideoStreams([]);
  };

  const allowManagementHandler = (event: React.MouseEvent<HTMLInputElement>) => {
    const newCheckboxes: {[key: string]: boolean} = {...checkboxes};
    newCheckboxes.allowManagement = !newCheckboxes.allowManagement;
    setCheckboxes(newCheckboxes);
  }

  const reloadWall = (defaultStreams: any) => {
    setReload(true);
    const streams: TDefaultStream[] = [];
    for (let i = 0; i < streamsLayout; i++) {
      if (defaultStreams && defaultStreams.length) {
        if (defaultStreams[i]) streams.push(defaultStreams[i]);
        else streams.push({value: '', label: ''});
      } else {
        if (selectedVideoStreams[i]) streams.push(selectedVideoStreams[i]);
        else streams.push({value: '', label: ''});
      }
    }
    setStreamsOntoWall(streams);
    setTimeout(() => {
      setReload(false)
    }, 200);
  };

  let options;
  if (videoStreams && videoStreams.length) options = videoStreams.map(el => ({value: el.url, label: el.videowall_name}));

  return (
    <>
      <div className="video-wall__settings-row">
        <div>
          <CButton className="video-wall__preset-btn" onClick={e => changeLayoutHandler(e, 4)}>
            <img src={icon4} alt="4"/>
          </CButton>
        </div>
        <div>
          <CButton className="video-wall__preset-btn" onClick={e => changeLayoutHandler(e, 6)}>
            <img src={icon6} alt="6"/>
          </CButton>
        </div>
        <div>
          <CButton className="video-wall__preset-btn" onClick={e => changeLayoutHandler(e, 9)}>
            <img src={icon9} alt="9"/>
          </CButton>
        </div>
        <div>
          <CButton className="video-wall__preset-btn" onClick={e => changeLayoutHandler(e, 10)}>
            <img src={icon10} alt="10"/>
          </CButton>
        </div>
        <div>
          <CButton className="video-wall__preset-btn" onClick={e => changeLayoutHandler(e, 16)}>
            <img src={icon16} alt="16"/>
          </CButton>
        </div>

        <CFormCheck button={{color: 'primary', variant: 'outline'}}
                    id="btn-check-outlined"
                    autoComplete="off"
                    defaultChecked={checkboxes.allowManagement}
                    label={t('videoStreams.Allow management of third-party players')}
                    onClick={allowManagementHandler}
        />
      </div>

      <div className="video-wall__settings-row">
        <div className="video-wall__select-wrapper">
          <Select options={options}
                  isMulti
                  closeMenuOnSelect={false}
                  value={selectedVideoStreams}
                  onChange={onChangeMultipleHandler}
          />
        </div>
        <CButton onClick={reloadWall}>{t('apply')}</CButton>
      </div>

      <div>
        {(isLoading || reload) ?
          <div>Loading...</div> :
          <VideoWall cols={cols}
                     layout={streamsLayout}
                     sources={streamsOntoWall || [{value: '', label: ''}]}
                     allowManagement={checkboxes.allowManagement}
                     loading={reload}
          />
        }
      </div>
    </>
  );
};

export default ChannelsWall;

export const colors = [
  'rgba(255,99,132,0.7)',
  'rgba(255,206,86,0.7)',
  'rgba(75,192,192,0.7)',
  'rgba(255,0,0,0.7)',
  'rgba(54,162,235,0.7)',
  'rgba(143,255,101,0.7)',
  'rgba(76,112,255,0.7)',
  'rgba(255,90,24,0.7)',
  'rgba(26,255,134,0.7)',
  'rgba(248,255,144,0.7)',
  'rgba(26,115,232,0.7)',
  'rgba(18,181,203,0.7)',
  'rgba(229,37,146,0.7)',
  'rgba(232,113,10,0.7)',
  'rgba(255,211,120,0.7)',
  'rgba(124,179,66,0.7)',
  'rgba(147,52,230,0.7)',
  'rgba(128,134,139,0.7)',
  'rgba(7,156,152,0.7)',
  'rgba(168,161,22,0.7)',
  'rgba(234,67,53,0.7)',
  'rgba(255,129,104,0.7)',
  'rgba(62,176,213,0.7)',
  'rgba(177,57,158,0.7)',
  'rgba(194,221,103,0.7)',
  'rgba(89,46,194,0.7)',
  'rgba(66,118,190,0.7)',
  'rgba(114,209,109,0.7)',
  'rgba(255,217,95,0.7)',
  'rgba(179,47,55,0.7)',
  'rgba(145,116,240,0.7)',
  'rgba(229,121,71,0.7)',
  'rgba(117,226,226,0.7)',
  'rgba(251,181,85,0.7)',
  'rgba(61,82,185,0.7)',
  'rgba(8,178,72,0.7)',
  'rgba(169,24,180,0.7)',
  'rgba(252,46,49,0.7)',
  'rgba(252,146,0,0.7)',
  'rgba(43,153,247,0.7)',
  'rgba(201,220,16,0.7)',
  'rgba(250,47,144,0.7)',
  'rgba(252,191,0,0.7)',
  'rgba(36,190,213,0.7)',
  'rgba(20,152,136,0.7)',
  'rgba(111,56,187,0.7)',
  'rgba(241,89,34,0.7)',
  'rgba(6,137,147,0.7)',
  'rgba(168,85,115,0.7)',
  'rgba(155,142,32,0.7)',
  'rgba(199,90,49,0.7)',
  'rgba(217,194,2,0.7)',
  'rgba(63,97,115,0.7)',
  'rgba(133,57,85,0.7)',
  'rgba(242,199,60,0.7)',
  'rgba(232,127,47,0.7)',
  'rgba(155,32,48,0.7)',
  'rgba(168,135,108,0.7)',
  'rgba(82,69,237,0.7)',
  'rgba(237,97,104,0.7)',
  'rgba(30,168,223,0.7)',
  'rgba(53,59,73,0.7)',
  'rgba(73,206,193,0.7)',
  'rgba(179,160,221,0.7)',
  'rgba(219,127,42,0.7)',
  'rgba(112,96,128,0.7)',
  'rgba(162,220,243,0.7)',
  'rgba(119,111,223,0.7)',
  'rgba(233,180,4,0.7)',
  'rgba(99,81,137,0.7)',
  'rgba(227,26,28,0.7)',
];
import React from 'react';

const EsDailyReports = () => {
  return (
    <div>
      ES Daily Reports
    </div>
  );
};

export default EsDailyReports;

import React, {FC} from 'react';
import {CPagination, CPaginationItem} from "@coreui/react";
import {useTranslation} from "react-i18next";

interface iPaginationManage {
  active: number;
  total: number;
  onClick: (value: number | string) => void;
  onChange?: (value: string) => void;
}

const PaginationManage: FC<iPaginationManage> = ({active = 1, total = 1, onClick, onChange}) => {
  const { t } = useTranslation();

  const clickHandler = (page: number) => {
    if (page && !isNaN(page)) onClick(page);
  }

  const pages = [];
  if (total > 7) {
    pages.push(1);
    if (active <= 3) {
      pages.push(2);
      pages.push(3);
      if (active === 3) pages.push(4);
      pages.push('...');
      if (active !== 3) pages.push(total - 2);
      pages.push(total - 1);
      pages.push(total);
    } else if (active >= (total - 2)) {
      pages.push(2);
      if (active === (total - 2)) {
        pages.push('...');
        pages.push(total - 3);
      } else {
        pages.push(3);
        pages.push('...');
      }
      pages.push(total - 2);
      pages.push(total - 1);
      pages.push(total);
    } else {
      pages.push('...');
      pages.push(active - 1);
      pages.push(active);
      pages.push(active + 1);
      pages.push('...');
      pages.push(total);
    }
  } else {
    for (let i = 1; i <= total; i++) pages.push(i);
  }


  return (
    <CPagination size="sm">
      <CPaginationItem disabled={active === 1}
                       onClick={() => clickHandler(active - 1)}
      >
        {t("pagination.previous")}
      </CPaginationItem>
      {pages.map((el: any, idx: number) => (
        <CPaginationItem key={'pagination' + el + idx}
                         active={active === el}
                         disabled={el === '...'}
                         onClick={() => clickHandler(el)}
        >
          {el}
        </CPaginationItem>
      ))}
      <CPaginationItem disabled={active === total}
                       onClick={() => clickHandler(active + 1)}
      >
        {t("pagination.next")}
      </CPaginationItem>
    </CPagination>
  );
};

export default PaginationManage;
import React, {useEffect, useState} from 'react';
import {
  CButton,
  CCard,
  CFormInput, CModal, CModalBody,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableRow
} from "@coreui/react";
import moment from "moment";

import {sortArrayOfObjectsByKey} from "../../../functions";
// import {createGeneralInfoEntry, fetchGeneralInfoList} from "../../../store/actions/es1/generalInfo";
import {useTranslation} from "react-i18next";
import {
  useCreateGeneralInfoEmergencyMutation,
  useLazyGetGeneralInfoEmergenciesQuery
} from "../../../store/generalInfoEmergency/generalInfoEmergency.api";

const EsGeneralInformation = () => {
  const { t } = useTranslation();

  const [fetchGeneralInfoList, {isError,  isLoading, data: generalInfoList}] = useLazyGetGeneralInfoEmergenciesQuery();
  const [createGeneralInfoEntry] = useCreateGeneralInfoEmergencyMutation();
  // const generalInfoList = useSelector(state => state.es1GeneralInfo.generalInfoList);

  const [editMode, setEditMode] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [actualState, setActualState]: any = useState({});
  const [state, setState]: any = useState({
    qty_emergency: 0,
    natural: 0,
    technogenic: 0,
    biological_and_social: 0,
    ecological: 0,
    conflict: 0,
    qty_prescriptions: 0,
    related_to_resettlement: 0,
    associated_elimination: 0,
    implemented_prescriptions: 0,
    unfulfilled_prescriptions: 0,
    qty_people: 0,
    men: 0,
    zero_five_men: 0,
    six_seventeen_men: 0,
    eighteen_fifty_nine_men: 0,
    sixty_and_older_men: 0,
    women: 0,
    zero_five_women: 0,
    six_seventeen_women: 0,
    eighteen_fifty_nine_women: 0,
    sixty_and_older_women: 0,
    disabled_people_first_second_groups: 0,
    number_of_orphans: 0,
    prescribed_for_resettlement: 0,
    resettled: 0,
    in_line_for_a_plot: 0,
    qty_households: 0,
    pres_resettlement: 0,
    resettled_households: 0,
    low_income_families: 0
  });

  useEffect(() => {
    fetchGeneralInfoList();
    // dispatch(fetchGeneralInfoList());
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (generalInfoList?.length) {
      const innerState: any = {...state};
      let lastId = null;
      // @ts-ignore

      Object.entries(sortArrayOfObjectsByKey(generalInfoList, 'created_at')[generalInfoList?.length - 1]).forEach(entry => {
        if (innerState.hasOwnProperty(entry[0])) innerState[entry[0]] = entry[1];
        if (entry[0] === 'id') lastId = entry[1];
      });
      setState(innerState);
      setActualState(innerState);
      setSelectedId(lastId);
    }
  }, [generalInfoList]);

  const toggleEditModeHandler = (edit?: any) => {
    setEditMode(!!edit);
  };

  const toggleHistoryModalHandler = (visible?: any) => {
    setHistoryModal(!!visible);
  };

  const onChangeHandler = (event: any) => {
    let value = event.target.value;
    if (!value) value = 0;
    if (isNaN(value)) return null;

    const innerState: any = {...state};
    try {
      innerState[event.target.name] = Math.floor(parseInt(value, 10));
    } catch (e) {
      return null;
    }

    innerState.qty_emergency = innerState.natural + innerState.technogenic + innerState.biological_and_social + innerState.ecological + innerState.conflict;
    innerState.qty_prescriptions = innerState.related_to_resettlement + innerState.associated_elimination + innerState.implemented_prescriptions + innerState.unfulfilled_prescriptions;
    innerState.men = innerState.zero_five_men + innerState.six_seventeen_men + innerState.eighteen_fifty_nine_men + innerState.sixty_and_older_men;
    innerState.women = innerState.zero_five_women + innerState.six_seventeen_women + innerState.eighteen_fifty_nine_women + innerState.sixty_and_older_women;
    innerState.qty_people = innerState.men + innerState.women;
    innerState.qty_households = innerState.pres_resettlement + innerState.resettled_households + innerState.low_income_families;

    setState(innerState);
  };

  const onEditHandler = () => {
    let isChanged = false;
    for (let key of Object.keys(state)) {
      if (state[key] !== actualState[key]) {
        isChanged = true;
        break;
      }
    }
    if (isChanged) {
      createGeneralInfoEntry(state)
        .then(() => {
          toggleEditModeHandler(false);
        });
    } else {
      toggleEditModeHandler(false);
    }

    // createGeneralInfoEntry(state).then(
    //     () => {
    //       // dispatch(fetchGeneralInfoList());
    //       toggleEditModeHandler(false);
    //     },
    // )
    // dispatch(createGeneralInfoEntry(state,
    //   () => {
    //     dispatch(fetchGeneralInfoList());
    //     toggleEditModeHandler();
    //   }
    // ));
  };

  const onCancelEditHandler = () => {
    setState({...actualState});
    toggleEditModeHandler();
  };

  const createRow = (name?: any, bold?: any, notChangeable?: any) => {
    let row = null;
    try {
      row = (
        <CTableRow>
          <CTableDataCell>
            {
              editMode ?
                <CFormInput size="sm"
                            type="text"
                            name={name}
                            value={state[name]}
                            onChange={onChangeHandler}
                            disabled={!!notChangeable}
                />
                :
                !!bold ? <strong>{state[name]}</strong> : state[name]
            }
          </CTableDataCell>
          <CTableDataCell>
            {!!bold ? <strong>{t('esManagement.esGeneral.' + name)}</strong> : t('esManagement.esGeneral.' + name)}
          </CTableDataCell>
        </CTableRow>
      );
    } catch (e) {}
    return row;
  };

  const createHistoryRow = (name?: any, bold?: boolean) => {
    let row = null;
    try {
      // @ts-ignore
      const idx = generalInfoList?.findIndex((el: any) => el.id === selectedId);
      if (!idx && idx !== 0) return null;
      const prevIdx = (idx + 1) === generalInfoList?.length ? idx : idx + 1;
      // @ts-ignore
      const isChanged = (idx >= 0 && generalInfoList[idx][name] !== generalInfoList[prevIdx][name]);
      row = (
        <CTableRow className={isChanged ? 'es-general-history-changed' : ''}>
          <CTableDataCell>
            {/*// @ts-ignore*/}
            {!!bold ? <strong>{generalInfoList[prevIdx][name]}</strong> : generalInfoList[prevIdx][name]}
          </CTableDataCell>
          <CTableDataCell>
            {/*// @ts-ignore*/}
            {!!bold ? <strong>{generalInfoList[idx][name]}</strong> : generalInfoList[idx][name]}
          </CTableDataCell>
          <CTableDataCell>
            {!!bold ? <strong>{t('esManagement.esGeneral.' + name)}</strong> : t('esManagement.esGeneral.' + name)}
          </CTableDataCell>
        </CTableRow>
      );
    } catch (e) {}
    return row;
  };

  return (
    <>
      <div className="pb-3 mb-4 title-divider">
        <h1>{t('nav.ES general information')}</h1>
      </div>

      <div className="button-group-horizontal mb-4">
        {editMode ?
          <>
            <CButton size="sm"
                     color="success"
                     className="color-white"
                     onClick={onEditHandler}
            >
              {t('save')}
            </CButton>
            <CButton size="sm"
                     color="secondary"
                     className="color-white"
                     onClick={onCancelEditHandler}
            >
              {t('cancel')}
            </CButton>
          </>
          :
          <CButton size="sm"
                   color="info"
                   className="color-white"
                   onClick={() => toggleEditModeHandler(true)}
          >
            {t('edit')}
          </CButton>
        }
        <CButton size="sm"
                 color="info"
                 className="color-white"
                 onClick={() => toggleHistoryModalHandler(true)}
        >
          {t('esManagement.esGeneral.viewChangesHistory')}
        </CButton>
      </div>

      <CCard className="es-general-card">
        <CTable striped className="es-general-table">
          <CTableBody>
            {createRow('qty_emergency', true, true)}
            {createRow('natural')}
            {createRow('technogenic')}
            {createRow('biological_and_social')}
            {createRow('ecological')}
            {createRow('conflict')}
          </CTableBody>
        </CTable>
      </CCard>

      <CCard className="es-general-card">
        <CTable striped className="es-general-table">
          <CTableBody>
            {createRow('qty_prescriptions', true, true)}
            {createRow('related_to_resettlement')}
            {createRow('associated_elimination')}
            {createRow('implemented_prescriptions')}
            {createRow('unfulfilled_prescriptions')}
          </CTableBody>
        </CTable>
      </CCard>

      <CCard className="es-general-card">
        <CTable striped className="es-general-table">
          <CTableBody>
            {createRow('qty_people', true, true)}
            {createRow('men', true, true)}
            {createRow('zero_five_men')}
            {createRow('six_seventeen_men')}
            {createRow('eighteen_fifty_nine_men')}
            {createRow('sixty_and_older_men')}
            {createRow('women', true, true)}
            {createRow('zero_five_women')}
            {createRow('six_seventeen_women')}
            {createRow('eighteen_fifty_nine_women')}
            {createRow('sixty_and_older_women')}
          </CTableBody>
        </CTable>
      </CCard>

      <CCard className="es-general-card">
        <CTable striped className="es-general-table">
          <CTableBody>
            {createRow('disabled_people_first_second_groups', true)}
            {createRow('number_of_orphans', true)}
            {createRow('prescribed_for_resettlement', true)}
            {createRow('resettled', true)}
            {createRow('in_line_for_a_plot', true)}
          </CTableBody>
        </CTable>
      </CCard>

      <CCard className="es-general-card">
        <CTable striped className="es-general-table">
          <CTableBody>
            {createRow('qty_households', true, true)}
            {createRow('pres_resettlement')}
            {createRow('resettled_households')}
            {createRow('low_income_families')}
          </CTableBody>
        </CTable>
      </CCard>

      <div className="button-group-horizontal mt-4 mb-4">
        {editMode ?
          <>
            <CButton size="sm"
                     color="success"
                     className="color-white"
                     onClick={onEditHandler}
            >
              {t('save')}
            </CButton>
            <CButton size="sm"
                     color="secondary"
                     className="color-white"
                     onClick={onCancelEditHandler}
            >
              {t('cancel')}
            </CButton>
          </>
          :
          <CButton size="sm"
                   color="info"
                   className="color-white"
                   onClick={() => toggleEditModeHandler(true)}
          >
            {t('edit')}
          </CButton>
        }
        <CButton size="sm"
                 color="info"
                 className="color-white"
                 onClick={() => toggleHistoryModalHandler(true)}
        >
          {t('esManagement.esGeneral.viewChangesHistory')}
        </CButton>
      </div>

      <CModal className="default-modal"
              backdrop={true}
              onClose={() => toggleHistoryModalHandler()}
              visible={historyModal}
              size="xl"
      >
        <CModalBody className="p-3 d-flex">
          <div className="es-general-history-list">
            {/*@ts-ignore*/}
            {generalInfoList?.length ? sortArrayOfObjectsByKey(generalInfoList, 'created_at').reverse().map(el => (
              <div key={el.created_at}
                   className={'es-general-history-item' + (selectedId === el.id ? ' es-general-history-item--selected' : '')}
                   onClick={() => setSelectedId(el.id)}
              >
                {moment(el.created_at).format('YYYY.MM.DD HH:mm:ss')}
              </div>
            )) : null}
          </div>

          <div style={{width: '100%'}}>
            <CCard className="es-general-card">
              <CTable striped className="es-general-table es-general-table--history">
                <CTableBody>
                  {createHistoryRow('qty_emergency', true,)}
                  {createHistoryRow('natural')}
                  {createHistoryRow('technogenic')}
                  {createHistoryRow('biological_and_social')}
                  {createHistoryRow('ecological')}
                  {createHistoryRow('conflict')}
                </CTableBody>
              </CTable>
            </CCard>

            <CCard className="es-general-card">
              <CTable striped className="es-general-table es-general-table--history">
                <CTableBody>
                  {createHistoryRow('qty_prescriptions', true)}
                  {createHistoryRow('related_to_resettlement')}
                  {createHistoryRow('associated_elimination')}
                  {createHistoryRow('implemented_prescriptions')}
                  {createHistoryRow('unfulfilled_prescriptions')}
                </CTableBody>
              </CTable>
            </CCard>

            <CCard className="es-general-card">
              <CTable striped className="es-general-table es-general-table--history">
                <CTableBody>
                  {createHistoryRow('qty_people', true)}
                  {createHistoryRow('men', true)}
                  {createHistoryRow('zero_five_men')}
                  {createHistoryRow('six_seventeen_men')}
                  {createHistoryRow('eighteen_fifty_nine_men')}
                  {createHistoryRow('sixty_and_older_men')}
                  {createHistoryRow('women', true)}
                  {createHistoryRow('zero_five_women')}
                  {createHistoryRow('six_seventeen_women')}
                  {createHistoryRow('eighteen_fifty_nine_women')}
                  {createHistoryRow('sixty_and_older_women')}
                </CTableBody>
              </CTable>
            </CCard>

            <CCard className="es-general-card">
              <CTable striped className="es-general-table es-general-table--history">
                <CTableBody>
                  {createHistoryRow('disabled_people_first_second_groups', true)}
                  {createHistoryRow('number_of_orphans', true)}
                  {createHistoryRow('prescribed_for_resettlement', true)}
                  {createHistoryRow('resettled', true)}
                  {createHistoryRow('in_line_for_a_plot', true)}
                </CTableBody>
              </CTable>
            </CCard>

            <CCard className="es-general-card">
              <CTable striped className="es-general-table es-general-table--history">
                <CTableBody>
                  {createHistoryRow('qty_households', true)}
                  {createHistoryRow('pres_resettlement')}
                  {createHistoryRow('resettled_households')}
                  {createHistoryRow('low_income_families')}
                </CTableBody>
              </CTable>
            </CCard>
            <div style={{opacity: 0}}>.</div>
          </div>
        </CModalBody>
      </CModal>
    </>
  );
};

export default EsGeneralInformation;

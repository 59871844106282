const initialState: any= {
    sidebarBarShow: false,
}

// @ts-ignore
const btnSideBarSlice = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'sett':
            return { ...state, ...rest }
        default:
            return state
    }
}


export default btnSideBarSlice

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IGeoObject, IGeoObjectArray} from "../../interfaces/IGeoObjects";

export const geoObjectsApi = createApi({
    reducerPath: 'geoObjects/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['GeoObjects'],
    endpoints: build => ({
        getGeoObjects: build.query<IGeoObjectArray, string | void>({
            query: (query: string) => {
                return {
                    url: `/geoobject/list/${query || ''}`
                }
            },
            providesTags: ['GeoObjects'],
        }),
        getGeoObjectsGeom: build.query<any, string | void>({
            query: (query: string) => {
                return {
                    url: `/geoobject/geom/list/${query || ''}`
                }
            },
            providesTags: ['GeoObjects'],
        }),
        getUsersById: build.query<IGeoObject, string>({
            query: (id) => ({
                url: `/geoobject/detail/${id}/`
            })
        }),
        createGeoObject: build.mutation<any, void>({
            query: (body) => ({
                url: '/geoobject/create/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['GeoObjects'],
        }),
        updateGeoObject: build.mutation<any, Partial<any> & Pick<any, 'id'>>({
            query: ({id, body}) => {
                return {
                    url: `/geoobject/update/${id}/`,
                    method: 'PATCH',
                    body,
                }
            },
            invalidatesTags: ['GeoObjects'],
        }),
        removeGeoObject: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/geoobject/delete/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['GeoObjects'],
        })
    })
})

export const {
    useLazyGetGeoObjectsQuery,
    useGetGeoObjectsQuery,
    useLazyGetUsersByIdQuery,
    useLazyGetGeoObjectsGeomQuery,
    useCreateGeoObjectMutation,
    useUpdateGeoObjectMutation,
    useRemoveGeoObjectMutation
} = geoObjectsApi;
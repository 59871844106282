import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {CButton, CFormCheck, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/react";
import useWebSocket from "react-use-websocket";
import dayjs from "dayjs";

import {useGetEmergencyTypesQuery} from "../../../store/emergencyTypes/emergencyTypes.api";
import {useLazyGetEsFeedQuery} from "../../../store/emergencies/emergencies.api";
import {useGetRegionsQuery} from "../../../store/regions/regions.api";
import {useLazyGetUsersQuery} from "../../../store/users/users.api";
import makeQuery from "../../../functions/makeQuery";
import config from "../../../config";
import {useDebounce} from "../../../hooks/debounce";
import FilterBlock from "./FilterBlock";
import EmergencyTypesList from "./EmergencyTypesList";
import EmergencyList from "./EmergencyList";
import {useGetInfoUserQuery} from "../../../store/account/account.api";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";

export interface IState {
  region: string
  reportType: string
  status: string
  reportStatus: string
}

export interface esFeed {
  action_created?: boolean | null
  action_updated?: boolean | null
  action_deleted?: boolean | null
  created_at: string
  date_emergency: Date
  description: string
  executor: string
  id: number
  late_information: boolean
  region: number
  status: string
  title: string
  type_emergency: number
  type_report: string
  updated_at: Date
}

const WS_URL = `${config.webSocketSecure}/ws/emergency/feed/`;

const EsFeed = () => {
  const {t} = useTranslation();

  const {data: responseEmergencyTypes, isLoading: typesLoading} = useGetEmergencyTypesQuery(makeQuery.stringify({page_size: 100000}));
  const [getEmergencies, {isLoading: emergenciesLoading}] = useLazyGetEsFeedQuery();
  const {data: regions, isLoading: regionsLoading} = useGetRegionsQuery();
  const [getUsers, {data: users, isLoading: usersLoading}] = useLazyGetUsersQuery();

  const [esTypesChecked, setEsTypesChecked] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [state, setState] = useState<IState>({
    region: '',
    reportType: '',
    status: '',
    reportStatus: ''
  });
  const [isMenuOpen, setMenuOpen] = useState({value: '', isOpen: false});
  const [dropdownPosition, setDropdownPosition] = useState('bottom');
  const [visible, setVisible] = useState(false);
  const [userId, setUserId] = useState(0);
  const [esFeedState, setEsFeedState] = useState<esFeed[] | []>([]);
  const [queryState, setQueryState] = useState<string>(makeQuery.stringify({page_size: 20}));
  const [lastQueryDate, setLastQueryDate] = useState<string>('');

  const filterDebounce = useDebounce(queryState, 2000);
  const token = localStorage.getItem('getMe');

  useEffect(() => {
    getEmergencyFunc(queryState)
  }, [filterDebounce]);

  const getEmergencyFunc = (query: string, date?: string) => {
    const customQuery = query + (date ? `&created_at__lt=${date}` : '');
    getEmergencies(customQuery).then(res => {
      let prevEsFeed: esFeed[] = date ? [...esFeedState] : [];
      let newEsFeed = res?.data?.map((el: any) => ({
        created_at: el.created_at,
        date_emergency: el.date_emergency,
        description: el.description,
        executor: el.executor.first_name + " " + el.executor.last_name,
        id: el.id,
        late_information: el.late_information,
        region: el.region,
        status: el.status,
        title: el.title,
        type_emergency: el.type_emergency,
        type_report: el.type_report,
        updated_at: el.updated_at
      })) || []
        setEsFeedState(prevEsFeed.concat(newEsFeed))
    })
  }

  const {lastJsonMessage} = useWebSocket(`${WS_URL}?authorization=${token}`);

  useEffect(() => {
    if (lastJsonMessage?.data && typeof lastJsonMessage?.data === 'object') {
      const data = lastJsonMessage.data
      let array = [...esFeedState];
      if (data.action_created && filterValidate(data)) {
        array.unshift(data);
      }
      if (data.action_updated) {
        const index = array.findIndex((el) => el.id === data.id);
        if (index >= 0) {
          array[index] = data;
        }
      }
      setEsFeedState(array)
    }
  }, [lastJsonMessage])

  const filterValidate = (data: esFeed) => {
    if (!esTypesChecked.includes(data.type_emergency)) return null;
    if (state.region && state.region !== String(data.region)) return null;
    if (state.reportType && state.reportType !== data.type_report) return null;
    if (state.status && state.status !== data.status) return null;
    if (state.reportStatus && state.reportStatus !== String(data.late_information)) return null;
    return data;
  }

  useEffect(() => {
    getUsers('');
  }, [users]);

  useEffect(() => {
    if (esFeedState.length) {
      const element = document.getElementById("es-scrollable-block");
      element?.addEventListener('scroll', handleEmergenciesListScroll);
      return () => element?.removeEventListener('scroll', handleEmergenciesListScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen.value, esFeedState]);

  useEffect(() => {
    const arr: any[] = []
    if (responseEmergencyTypes?.results?.length) {
      responseEmergencyTypes?.results?.forEach(el => {
        arr.push(el.id)
      })
    }
    setEsTypesChecked(arr);
    setSelectAll(true);
  }, [responseEmergencyTypes?.results]);

  useEffect(() => {
    const query: { [key: string]: string } = {
      page_size: '20',
    }

    if (!selectAll) query.type_emergency = esTypesChecked.join(',');
    if (!esTypesChecked.length && !selectAll) query.type_emergency = '0';
    if (state.region) query.region = state.region;
    if (state.reportType) query.type_report = state.reportType;
    if (state.status) query.status = state.status;
    if (state.reportStatus) query.late_information = state.reportStatus;

    setQueryState(makeQuery.stringify(query))
  }, [state, esTypesChecked]);

  const isLoading = useMemo(() => {
    return (typesLoading || emergenciesLoading || regionsLoading || usersLoading);
  }, [typesLoading, emergenciesLoading, regionsLoading, usersLoading]);

  const handleEmergenciesListScroll = () => {
    try {
      let position = window.document.getElementById(isMenuOpen.value)?.getBoundingClientRect().y;

      let element = document.getElementById("es-scrollable-block");

      let pageCenter = ((element?.clientHeight || 0) + 420) / 2;

      setDropdownPosition((position || 0) < pageCenter ? 'bottom' : 'top');
    } catch (e) {
      // do nothing
    }

    try {
      let position = window.document.getElementById('feed_bottom')?.getBoundingClientRect().y;

      let element = document.getElementById("es-scrollable-block")
      let elementPosition = element?.getBoundingClientRect().y;

      const lastEntryDate = dayjs(esFeedState[esFeedState.length - 1].created_at).format('YYYY-MM-DDTHH:mm:ss');

      if ((position! - elementPosition!) <= element?.clientHeight! && !emergenciesLoading && lastQueryDate !== lastEntryDate) {
        setLastQueryDate(lastEntryDate || '');
        getEmergencyFunc(queryState, lastEntryDate);
      }
    } catch (e) {
      // do nothing
    }
  }

  const handleChangeChecked = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    let arr = [...esTypesChecked];
    let index;
    if (!arr.includes(id)) {
      arr.push(id);
    } else {
      index = arr.findIndex(el => el === id);
      arr.splice(index, 1);
    }
    if (responseEmergencyTypes?.results?.length !== arr.length) {
      setSelectAll(false)
    } else {
      setSelectAll(true)
    }

    setEsTypesChecked(arr);
  }

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked} = e.target;
    setSelectAll(!selectAll)
    let arr: any[] = []
    if (checked) {
      responseEmergencyTypes?.results.forEach(el => {
        arr.push(el.id)
      })
      setEsTypesChecked(arr);
    } else {
      setEsTypesChecked([]);
    }
  }

  const handleSelect = (e: any, name: string) => {
    if (e?.value) {
      setState(prevState => {
        return {...prevState, [name]: e.value}
      })
    } else {
      setState(prevState => {
        return {...prevState, [name]: ''}
      })
    }
  }

  const handleOpenMenu = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    e.stopPropagation();
    let obj = {...isMenuOpen}

    let position = window.document.getElementById(String(id))?.getBoundingClientRect().y;

    let element = document.getElementById("es-scrollable-block");

    let pageCenter = ((element?.clientHeight || 0) + 420) / 2;
    if (String(id) === obj.value) {
      setMenuOpen({value: String(id), isOpen: !isMenuOpen.isOpen})
    } else {
      setDropdownPosition((position || 0) < pageCenter ? 'bottom' : 'top');
      setMenuOpen({value: String(id), isOpen: true})
    }
  }

  const handleCloseMenu = () => {
    setMenuOpen({value: '', isOpen: false})
  }

  const handleOpenModal = (id: number) => {
    setVisible(true);
    setUserId(id);
  }


  let username = users?.results?.length ? users?.results?.find(el => el.id === userId)?.first_name + " " + users?.results?.find(el => el.id === userId)?.last_name : ''

  return (
    <>
      <div className="pb-3 mb-4 title-divider">
        <h1>{t('nav.ES feed')}</h1>
        <LoaderLine visible={isLoading}/>
      </div>
      <div style={{display: 'flex'}}>
        <div className="es-columns es-type-column ">
          <div className="es-type-column__header">
            <div className="es-type-column__title">{t("esFeed.filterBy")}</div>
            <CFormCheck id={'select-all'}
                        style={{marginRight: '8px'}}
                        name={'select-all'}
                        onChange={handleSelectAll}
                        checked={selectAll}
            />
            <label htmlFor={'select-all'}>{t("esFeed.markAll")}</label>
          </div>
          <div className="es-scrollable-block">
            {
              responseEmergencyTypes?.results?.length ?
                <EmergencyTypesList data={responseEmergencyTypes?.results} state={esTypesChecked}
                                    onChange={handleChangeChecked}/> :
                null
            }
          </div>
        </div>
        <div className="es-columns es-column" onClick={handleCloseMenu}>
          <FilterBlock regions={regions!} onSelect={handleSelect} state={state} t={t}/>
          {
            (esFeedState.length) ?
              <EmergencyList data={esFeedState}
                             onClick={handleOpenMenu}
                             isMenuOpen={isMenuOpen}
                             menuPosition={dropdownPosition}
                             users={users}
                             openModal={handleOpenModal}
                             emergencyTypes={responseEmergencyTypes}
              /> :
              null
          }
        </div>
      </div>

      <CModal
        alignment="center"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalHeader style={{padding: '12px'}}>
          <CModalTitle id="VerticallyCenteredExample">{t("esFeed.shareAnEmergency")}</CModalTitle>
        </CModalHeader>
        <CModalBody style={{padding: '18px 12px', fontSize: '14px'}}>
          <div>{t("esFeed.areYouSureYouWantToShareTheEmergencyWithTheUser")} <strong>{username}</strong>?</div>
        </CModalBody>
        <CModalFooter style={{padding: '8px 12px 8px 12px'}}>
          <CButton size={'sm'} color="secondary" onClick={() => setVisible(false)}>
            {t("cancel")}
          </CButton>
          <CButton size={'sm'} color="primary">{t("esFeed.toShare")}</CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default EsFeed;

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IGeoType, IGeoTypes} from "../../interfaces/IGeoTypes";

export const geoTypesApi = createApi({
    reducerPath: 'geoTypes/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['GeoTypes'],
    endpoints: build => ({
        getGeoTypes: build.query<IGeoTypes, any | void>({
            query: ({ query = '', page_size, page }) => {
                const params: any = {};

                if(page) {
                    params.page = page;
                }

                return {
                    url: `/geotype/list/${query}`,
                    params: {
                        page_size: page_size || 100000000000,
                    },
                };
            },
            providesTags: ['GeoTypes'],
        }),
        createGeoType: build.mutation<IGeoType, any>({
            query: (body) => ({
                url: '/geotype/create/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['GeoTypes'],
        }),
        updateGeoType: build.mutation<IGeoType, Partial<any> & Pick<any, 'id'>>({
            query: ({id, body}) => {
                return {
                    url: `/geotype/update/${id}/`,
                    method: 'PATCH',
                    body,
                }
            },
            invalidatesTags: ['GeoTypes'],
        }),
        removeGeoType: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/geotype/delete/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['GeoTypes'],
        })
    })
})

export const {
    useLazyGetGeoTypesQuery,
    useGetGeoTypesQuery,
    useCreateGeoTypeMutation,
    useUpdateGeoTypeMutation,
    useRemoveGeoTypeMutation
} = geoTypesApi;
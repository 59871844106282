import React, {useEffect, useRef, useState, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {
  CButton,
  CButtonGroup,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import DefaultPaginationWrapper from '../../../components/UI/Pagination/DefaultPaginationWrapper';
import {getFormData, Query} from "../../../functions";
import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import {useTranslation} from "react-i18next";
import {
  useCreateGeoIconMutation,
  useLazyGetGeoIconsQuery, useRemoveGeoIconMutation,
  useUpdateGeoIconMutation
} from "../../../store/geoIcons/geoIcons.api";

const GeoIcons = () => {
  const ref: any = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({page_size: '100', search: '', page: 1});
  const [filter, setFilter] = useState({sort_by: '', direction: 'asc'});
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  // const { geoIcons, error }: GeoIconsState = useSelector((state: any) => state.geoIcons);
  const [fetchGeoIcons, {isError: error,  isLoading: loading, data: geoIcons}] = useLazyGetGeoIconsQuery();
  const [createGeoIcon] = useCreateGeoIconMutation();
  const [updateGeoIcon] = useUpdateGeoIconMutation();
  const [deleteGeoIcon] = useRemoveGeoIconMutation();
  const countGeoIcons = geoIcons?.results.length;

  useEffect(() => {
    fetchGeoIcons('');
  }, []);

  const reset = () => {
    ref.current.value = "";
  };

  const handleToggleDeleteModal = (visible: boolean) => {
    if (visible === false) setSelectedId(null);
    setDeleteModal(visible)
  }

  const handleAddIcon = async (event: any) => {
    if (event.target.files.length) {
      Object.keys(event.target.files).forEach((key: number | string) => {
        const data = { icon: event.target.files[key] };
        if (Object.keys(data).length) {
          const formData: any = getFormData(data);
          createGeoIcon(formData);
        }
      });
    }
  }

  const handleEditIcon = async (event: any, id: number) => {
    let data: any, formData: any;

    if (event.target.files[0]) {
      data = {
        icon: event.target.files[0]
      }
    }

    if (Object.keys(data).length) {
      formData = getFormData(data)
    }

    await updateGeoIcon({id: id, body: formData});
    // fetchGeoIcons('');
    reset();
  }

  const handleDelete = () => {
    deleteGeoIcon(Number(selectedId)).then(
        () => {
          // dispatch(fetchRegions());
          setDeleteModal(false);
        },
        (error: string) => {
          console.log(error);
        }
    )
   // dispatch(deleteGeoIcon(
   //    selectedId,
   //    () => {
   //      dispatch(fetchGeoIcons());
   //      setDeleteModal(false);
   //    },
   //    (error: string) => {
   //      // console.log(error);
   //    }
   //  ));
  }

  const handleOpenDeleteModal = (id: any) => {
    setDeleteModal(true);
    setSelectedId(id);
  }

  const onPaginationHandler = (state: any) => {
    let innerState = {...pagination, ...state};

    if(countGeoIcons && countGeoIcons <= Number(pagination.page_size)) {
      innerState = {
        page_size: pagination.page_size,
        search: pagination.search,
        page: 1
        , ...state}
    }

    setPagination(innerState);
    if (state.search === undefined) onSearchDataHandler({...innerState, ...filter});
  };

  const onFilterHandler = (field: string) => {
    const state = {...filter};
    if (field === state.sort_by) state.direction = state.direction === 'asc' ? 'desc' : 'asc';
    else {
      state.sort_by = field;
      state.direction = 'asc';
    }
    setFilter(state);
    onSearchDataHandler({...pagination, ...state});
  };

  const onSearchCallbackHandler = (search: string) => {
    onSearchDataHandler({...pagination, ...filter, search});
  };

  const onSearchDataHandler = (data: any) => {
    const query: any = {};
    if (data.page_size) query.page_size = data.page_size;
    if (data.page) query.page = data.page;
    if (data.search && data.search !== '') query.search = data.search;
    if (data.sort_by) query.sort_by = data.sort_by;
    if (data.direction === 'desc') query.direction = 'desc';

    // dispatch(fetchGeoIcons(Query.stringify(query)))
    fetchGeoIcons(Query.stringify(query))
  }

  let icons;
  // @ts-ignore
  let geoIconsSort = useMemo(() => {
    // @ts-ignore
    return geoIcons?.results ? [...geoIcons.results] : [];
  }, [geoIcons]);

  // @ts-ignore
  if (geoIconsSort?.length) {
    // @ts-ignore
    icons = geoIconsSort?.sort((a, b) => a.id - b.id).map((icon: any) => {
      return (
        <CTableRow key={icon.id}>
          <CTableDataCell>
            <img className="geo-icon-image" alt="" src={icon.icon}/>
            <span style={{marginLeft: '10px'}}>{icon.icon.split('/')[icon.icon.split('/').length - 1]}</span>
          </CTableDataCell>
          <CTableDataCell className="text-center">
            <CButtonGroup size="sm">
              <label htmlFor={"file-update" + icon.id}
                     className="btn btn-info">
              <span className="align-items-center">
                <i className="fa fa-pencil-square-o color-white" aria-hidden="true"/>
              </span>
              </label>

              <input type="file"
                     id={"file-update" + icon.id}
                     className="input-file-hidden"
                     accept="image/png/svg"
                     ref={ref}
                     onChange={e => handleEditIcon(e, icon.id)}
              />
              <CButton size="sm" color="danger" className="default-table__action-btn"
                // onClick={e => handleDelete(e, icon)}
                       onClick={() => handleOpenDeleteModal(icon.id)}
              >
                <i className="fa fa-times color-white" aria-hidden="true"> </i>
              </CButton>
            </CButtonGroup>
          </CTableDataCell>
        </CTableRow>
      )
    })
  }

  return (
    <>
      <div className="pb-3 mb-4 title-divider">
        <h1>{t('settings.geoIcons.geoIcons')}</h1>
      </div>

      <div className="d-flex justify-content-md-end mb-3">

        <label htmlFor="file">
          <div className="btn btn-success color-white btn-sm">
            <i className="fa fa-cloud-upload" style={{marginRight: '10px'}}/>
            {t('settings.geoIcons.uploadIcon')}
          </div>
        </label>
        <input type="file"
               id="file"
               multiple
               className="input-file-hidden"
               accept="image/png"
               onChange={handleAddIcon}
        />
      </div>

      <DefaultPaginationWrapper data={geoIcons}
                                state={pagination}
                                onChange={onPaginationHandler}
                                onSearchDelay={2000}
                                onSearchCallback={onSearchCallbackHandler}
      >

        <CTable bordered striped className="default-table">
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell style={{width: '20%'}}
                                scope="col"
                                className="sorted-table-cell"
                                onClick={() => onFilterHandler('icon')}
              >

                <div className="d-flex justify-content-between">
                  <span>{t('settings.geoIcons.icon')}</span>
                  {filter.sort_by === 'icon' ?
                    <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                    <i className="fa fa-sort" aria-hidden="true"/>
                  }
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '13%'}} scope="col" className="default-table__actions">
                {t('settings.geoIcons.actions')}
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {icons}
          </CTableBody>
        </CTable>
      </DefaultPaginationWrapper>

      <DefaultModal visible={deleteModal}
                    setVisible={handleToggleDeleteModal}
                    title={t('deletion')}
                    type="danger"
                    cancelButton={t('cancel')}
                    onOk={handleDelete}
                    // @ts-ignore
                    error={error?.data?.error}
                    size="lg"
      >
        <div>
          <p className="mb-0 fs-6">{t('settings.geoIcons.iconDeletion')}</p>
        </div>
      </DefaultModal>
    </>
  );
};

export default GeoIcons;

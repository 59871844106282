import React from 'react';

const VersionHistory = () => {
  return (
    <div>
      Version history
    </div>
  );
};

export default VersionHistory;

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {ITemplate} from "../../interfaces/ITemplates";
import {IAnalyticsTemplate} from "../../interfaces/IAnalyticsTemplates";

export const analyticsApi = createApi({
  reducerPath: 'analytics/api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: headers => headerConfig(headers)
  }),
  tagTypes: ['analytics'],
  endpoints: build => ({
    getTemplates: build.query<ITemplate[], string | void>({
      query: query => {
        return {
          url: `/list/template/${query || ''}`
        }
      },
      providesTags: ['analytics']
    }),
    getTemplateById: build.query<ITemplate, string | void>({
      query: id => {
        return {
          url: `/detail/template/${id}/`
        }
      }
    }),
    getAnalyticTemplate: build.query<IAnalyticsTemplate[], any | void>({
      query: ({id, query}) => {
        return {
          url: `/get/template/${id}/${query || ''}`
        }
      }
    }),
    createTemplate: build.mutation<ITemplate, ITemplate>({
      query: body => {
        return {
          url: `/create/template/`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: ['analytics']
    }),
    updateTemplate: build.mutation<any, any>({
      query: ({id, body}) => {
        return {
          url: `/update/template/${id}/`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: ['analytics']
    })
  })
})

export const {
  useGetTemplatesQuery,
  useLazyGetTemplateByIdQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useLazyGetAnalyticTemplateQuery,
} = analyticsApi;
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IGeoCategory} from "../../interfaces/IGeoCategories";

export const geoCategoriesApi = createApi({
    reducerPath: 'geoCategories/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['GeoCategories'],
    endpoints: build => ({
        getGeoCategories: build.query<any, string | void>({
            query: () => {
                return {
                    url: `/geo-categories/list/`
                }
            },
            providesTags: ['GeoCategories'],
        }),
        // getUsersById: build.query<IRegion, string>({
        //     query: (id: string) => ({
        //         url: `/detail/users/${id}`
        //     })
        // }),
        createGeoCategory: build.mutation<any, any>({
            query: (body) => ({
                url: '/geo-categories/create/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['GeoCategories'],
        }),
        updateGeoCategory: build.mutation<any, Partial<any> & Pick<any, 'id'>>({
            query: ({id, body}) => {
                return {
                    url: `/geo-categories/update/${id}/`,
                    method: 'PATCH',
                    body,
                }
            },
            invalidatesTags: ['GeoCategories'],
        }),
        removeGeoCategory: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/geo-categories/delete/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['GeoCategories'],
        })
    })
})

export const {
    useGetGeoCategoriesQuery,
    useLazyGetGeoCategoriesQuery,
    useCreateGeoCategoryMutation,
    useUpdateGeoCategoryMutation,
    useRemoveGeoCategoryMutation
} = geoCategoriesApi;
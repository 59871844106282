import dayjs from 'dayjs';
import { IAssignmentsList } from '../../../../interfaces/IAssignments';
import { descriptionEdit } from '../utils/tasks';
import PriorityComponent from './PriorityComponent';
import '../../../../scss/_drag_n_drop.scss';
import React from "react";
import {useTranslation} from "react-i18next";

type TaskItemProps = {
  task: IAssignmentsList;
};

const TaskItem = ({ task }: TaskItemProps) => {
    const {t} = useTranslation();

    if (!task) return null;

    return (
        <div className='board-block_tasks_content'>
            <div className='board-block_tasks_content_text'>
                <span className='board-block_tasks_content_text_title'>{descriptionEdit(task.title, 50)}</span>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <span className='board-block_tasks_content_info_date'>
                    <b>{t('assignmentsPage.create_at')}</b>
                    {dayjs(task.created_at).format('DD-MM-YYYY HH:mm:ss')}
                </span>

                <span className='board-block_tasks_content_info_date'>
                    <b>{t('assignmentsPage.deadline')}</b>
                    {dayjs(task.datetime_deadline).format('DD-MM-YYYY HH:mm:ss')}
                </span>
            </div>
            <div className='board-block_tasks_content_info'>
                <span className='board-block_tasks_content_info_author'>
                  <PriorityComponent priority={task.priority}/>
                  {task.responsible_executor?.username || t('assignmentsPage.Not assigned')}
              </span>
            </div>
            {/*<span className='board-block_tasks_content_text_desc'>{descriptionEdit(task.description, 50)}</span>*/}
        </div>

    );
};

export default TaskItem;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
  CCollapse, CFormInput, CFormLabel,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import ReactDiffViewer, {DiffMethod} from 'react-diff-viewer';
import moment from "moment";

import DefaultPaginationWrapper from "../../../components/UI/Pagination/DefaultPaginationWrapper";
import DefaultModal from "../../../components/UI/Modals/DefaultModal";

import {Query} from "../../../functions";
// import {clearLogEntry, fetchLogs, readLogEntry} from "../../../store/actions/auditLog";
// import {fetchUsers} from "../../../store/actions/users";
import DateTimeRangePicker from "../../../components/UI/DateTimeRangePicker/DateTimeRangePicker";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {useLazyGetUsersQuery} from "../../../store/users/users.api";
import {useLazyGetLogQuery, useLazyGetLogsQuery} from "../../../store/logs/logs.api";
import dayjs from "dayjs";

interface Query {
  [key: string]: any;
}
const AuditLog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const {logsList, logEntry, loadingEntry} = useSelector(state => state.auditLog);
  const [fetchLogs, {isLoading: loadingEntry, data: logsList}]: any = useLazyGetLogsQuery();
  const [readLogEntry, {data: logEntry}] = useLazyGetLogQuery();
  // const users = useSelector(state => state.users.users);
  const [fetchUsers, {data: users}] = useLazyGetUsersQuery();

  const [pagination, setPagination] = useState({ page_size: '100', search: '', page: 1 });
  const [filter, setFilter] = useState({sort_by: 'datetime', direction: 'desc'});
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen]: any = useState([]);
  const [dateRange, setDateRange] = useState({start: '', end: ''})
  const [dateTimePickerVisible, setDateTimePickerVisible] = useState(false);
  const [dateRangeVisible, setDateRangeVisible] = useState(false);

  const countLogsList = logsList?.results.length;

  useEffect(() => {
    const range = {date_emergency__gte: '', date_emergency__lte: ''}
    if (dateRange.start && dateRange.end) {
      range.date_emergency__gte = JSON.parse(JSON.stringify(dateRange.start))
      range.date_emergency__lte = JSON.parse(JSON.stringify(dateRange.end))
    }
    fetchLogs(Query.stringify({...pagination, ...filter, ...range}))
    fetchUsers('')
  }, [pagination, filter, dateRange]);

  const toggleDateTimePickerHandler = () => {
    setDateTimePickerVisible(prevState => !prevState);
  };

  const toggleDetailsModalHandler = async (visible: boolean, id: any) => {
    if (id)
      // await dispatch(readLogEntry(id));
      await readLogEntry(id);
    else {
      setTimeout(() => {
        // dispatch(clearLogEntry());
      }, 200);
    }
    setDetailsModalVisible(!!visible);
  };

  const collapseHandler = (id: number | string) => {
    const collapses: any = [...collapseIsOpen];
    if (collapses.includes(id)) collapses.splice(collapses.findIndex((el: any) => el === id), 1);
    else collapses.push(id);
    setCollapseIsOpen(collapses);
  };

  // const onSetRangeHandler = (value: any) => {
  //   setDateTimeRange(value);
  //   toggleDateTimePickerHandler();
  // };

  const handleSelectDates = (value: { start: Date; end: Date }) => {
    // @ts-ignore
    setDateRange(value)
    setDateRangeVisible(false)
  }

  const handleGetValue = (value: { start: Date; end: Date }) => {
    // @ts-ignore
    setDateRange(value);
  }

  const onPaginationHandler = (state: any) => {
    let innerState = {...pagination, ...state};
    if(countLogsList && countLogsList <= Number(pagination.page_size)) {
      innerState = {
        page_size: pagination.page_size,
        search: pagination.search,
        page: 1
        , ...state}
    }
    setPagination(innerState);
    if (state.search === undefined) onSearchDataHandler({...innerState, ...filter});
  };

  const onFilterHandler = (field: any) => {
    const state = {...filter};
    if (field === state.sort_by) state.direction = state.direction === 'asc' ? 'desc' : 'asc';
    else {
      state.sort_by = field;
      state.direction = 'asc';
    }
    setFilter(state);
    onSearchDataHandler({...pagination, ...state});
  };

  const onSearchCallbackHandler = (search: string) => {
    onSearchDataHandler({...pagination, ...filter, search});
  };

  const onSearchDataHandler = (data: any) => {
    const query: Query = {};
    if (data.page_size) query.page_size = data.page_size;
    if (data.page) query.page = data.page;
    if (data.search && data.search !== '') query.search = data.search;
    if (data.sort_by) query.sort_by = data.sort_by;
    if (data.direction === 'desc') query.direction = 'desc';

    // dispatch(fetchLogs(Query.stringify(query)))
    fetchLogs(Query.stringify(query))
  }

  let list = [], detailsModalTitle = '';
  try {
    //@ts-ignore
    list = logsList?.results.map((el: any, idx: number) => {
      //@ts-ignore
      const userIdx = users?.results.findIndex((item: any) => item.id === el.user);
      if (logEntry && logEntry.id === idx)
        detailsModalTitle = el.table_name + ' / ' + moment(el.datetime).format('YYYY-MM-DD HH:mm:ss');
      let user: any = {};
      //@ts-ignore
      if (userIdx >= 0) user = {...users.results[userIdx]};
      return (
        <CTableRow key={'log' + idx}>
          <CTableDataCell>{el.log_type}</CTableDataCell>
          <CTableDataCell>{moment(el.datetime).format('YYYY-MM-DD HH:mm:ss')}</CTableDataCell>
          <CTableDataCell>{el.table_name}</CTableDataCell>
          <CTableDataCell>
            <div onClick={() => collapseHandler('log' + el.id)}>
              <span className="text-primary cursor-pointer">{user.username}</span>
              <CCollapse className="spoiler" visible={collapseIsOpen.includes('log' + el.id)}>
                <div>{user.first_name}</div>
                <div>{user.last_name}</div>
              </CCollapse>
            </div>
          </CTableDataCell>
          <CTableDataCell className="text-center">
            <span className="text-primary cursor-pointer" onClick={() => toggleDetailsModalHandler(true, el.id)}>
              {t('settings.auditLog.viewChanges')}
            </span>
          </CTableDataCell>
        </CTableRow>
      )
    });
  } catch (e) {}

  let oldCode = '', newCode = '';

  try {
    // @ts-ignore
    oldCode = JSON.stringify(logEntry?.changes.initial, null, '\t');
    // @ts-ignore
    newCode = JSON.stringify(logEntry?.changes.updated, null, '\t');
  } catch (e) {}

  // let rangeValue = moment(dateTimeRange.start).format('DD.MM.YYYY HH:mm') + '  -  ' + moment(dateTimeRange.end).format('DD.MM.YYYY HH:mm');
  // if (!dateTimeRange.start || !dateTimeRange.end) rangeValue = t('settings.auditLog.selectRange');

  return (
      <>
        <div className="pb-3 mb-4 title-divider">
          <h1>{t('settings.auditLog.chronology')}</h1>
        </div>

        <div className="d-flex justify-content-md-end mb-3" style={{position: 'relative'}}>
          <div style={{width: '305px'}}>
            <CFormLabel>Выберите даты</CFormLabel>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              {/*@ts-ignore*/}
              <CFormInput style={{borderRadius: '6px', width: '305px'}}
                          type="text"
                          value={(dateRange.start ? dayjs(dateRange.start).format("DD/MM/YYYY") + " - " : "") + (dateRange.end ? dayjs(dateRange.end).format("DD/MM/YYYY") : "")}
                          placeholder={dateRange.start || dateRange.end ? "" : "Дата не выбрана"}
                          onClick={() => setDateRangeVisible(true)}
              />
            </div>

          </div>
          {dateRangeVisible && <div className="language-select-overlay oioioioio" onClick={() => {
            setDateRangeVisible(false)
          }}/>}
          {dateRangeVisible ? <div style={{position: 'absolute', zIndex: 1031, top: '75px'}}>
            <DateTimeRangePicker onChange={handleSelectDates} getValue={handleGetValue}/>
          </div> : null}
        </div>

        {/*<div className="date-picker-wrapper mb-3">*/}
        {/*  <CFormInput type="text"*/}
        {/*              size="sm"*/}
        {/*              value={rangeValue}*/}
        {/*              onChange={() => {return null}}*/}
        {/*              onClick={toggleDateTimePickerHandler}*/}
        {/*  />*/}
        {/*  {dateTimePickerVisible ?*/}
        {/*    <DateTimeRangePicker locale={i18n.language}*/}
        {/*                         presets*/}
        {/*                         value={dateTimeRange}*/}
        {/*                         onChange={onSetRangeHandler}*/}
        {/*    />*/}
        {/*    : null}*/}
        {/*</div>*/}

        <DefaultPaginationWrapper data={logsList}
                                  state={pagination}
                                  onChange={onPaginationHandler}
                                  onSearchDelay={2000}
                                  onSearchCallback={onSearchCallbackHandler}
        >
          <CTable bordered striped className="default-table">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell style={{width: '120px', maxWidth: '120px'}}
                                  scope="col"
                                  className="sorted-table-cell"
                                  onClick={() => onFilterHandler('log_type')}
                >
                  <div className="d-flex justify-content-between">
                    <span>{t('settings.auditLog.event')}</span>
                    {filter.sort_by === 'log_type' ?
                        <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                        <i className="fa fa-sort" aria-hidden="true"/>
                    }
                  </div>
                </CTableHeaderCell>

                <CTableHeaderCell style={{width: '25%'}}
                                  scope="col"
                                  className="sorted-table-cell"
                                  onClick={() => onFilterHandler('datetime')}
                >
                  <div className="d-flex justify-content-between">
                    <span>{t('settings.auditLog.when')}</span>
                    {filter.sort_by === 'datetime' ?
                        <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                        <i className="fa fa-sort" aria-hidden="true"/>
                    }
                  </div>
                </CTableHeaderCell>

                <CTableHeaderCell style={{width: '25%'}}
                                  scope="col"
                                  className="sorted-table-cell"
                                  onClick={() => onFilterHandler('table_name')}
                >
                  <div className="d-flex justify-content-between">
                    <span>{t('settings.auditLog.collection')}</span>
                    {filter.sort_by === 'table_name' ?
                        <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                        <i className="fa fa-sort" aria-hidden="true"/>
                    }
                  </div>
                </CTableHeaderCell>

                <CTableHeaderCell style={{width: '25%'}}
                                  scope="col"
                                  className="sorted-table-cell"
                                  onClick={() => onFilterHandler('user')}
                >
                  <div className="d-flex justify-content-between">
                    <span>{t('settings.auditLog.user')}</span>
                    {filter.sort_by === 'user' ?
                        <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                        <i className="fa fa-sort" aria-hidden="true"/>
                    }
                  </div>
                </CTableHeaderCell>

                <CTableHeaderCell style={{width: '120px', maxWidth: '120px'}}
                                  scope="col"
                                  className="default-table__actions"
                >
                  {t('settings.auditLog.changes')}
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>

            <CTableBody>
              {list}
            </CTableBody>
          </CTable>
        </DefaultPaginationWrapper>

        <DefaultModal visible={detailsModalVisible}
                      setVisible={toggleDetailsModalHandler}
                      backdrop={true}
                      title={detailsModalTitle}
                      cancelButton={t('close')}
            //@ts-ignore
                      loading={loadingEntry}
                      size='xl'
        >
          <ReactDiffViewer oldValue={oldCode}
                           newValue={newCode}
                           splitView={true}
                           compareMethod={DiffMethod.LINES}
          />
        </DefaultModal>
      </>
  );
};

export default AuditLog;

import { ChangeEvent, FormEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import { useCreateCommentFilesMutation, useCreateCommentMutation, useLazyGetAssigmentByIdQuery, useRemoveCommentMutation } from "../../../../store/assignments/assignments.api";
import { useGetReadCurrentUserQuery } from "../../../../store/account/account.api";
import { cilTrash } from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import InputComment from "../InputComment/InputComment";
import MessageComment from "../MessageComment/MessageComment";
import '../../../../scss/_comments.scss';
import {useTranslation} from "react-i18next";
import {sortArrayOfObjectsByKey} from "../../../../functions";

const CommentsComponent = ({ taskID }:any ) => {
    const {t} = useTranslation();

    const [description, setDescription] = useState('');
    const [createComment] = useCreateCommentMutation();
    const [getAssigmentById, {data: task}] = useLazyGetAssigmentByIdQuery();
    const {data:me} = useGetReadCurrentUserQuery();
    const [removeComment] = useRemoveCommentMutation();
    const [filesState, setFilesState] = useState<any[]>([]);
    const [createCommentFiles] = useCreateCommentFilesMutation();
    const [visibleRemButton, setVisibleRemButton] = useState<number | null>(null);
    const container: MutableRefObject<any> = useRef(null);
    const [sortDirection, setSortDirection] = useState('desc');

    useEffect(() => {
        container.current.scrollTop = container.current.scrollHeight;
      }, [task?.comments]);

    useEffect(() => {
        getAssigmentById(taskID);
    }, [taskID]);
    
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };

    const toggleSortDirection = () => {
      setSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'));
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = {
            description: description,
            assignment: taskID,
        };
        createComment(body)
            .then(res => {
                if (filesState.length) {
                    filesState.forEach((el) => {
                        const formData = new FormData();
                        // @ts-ignore
                        formData.append('comment', res?.data?.id);
                        formData.append('file', el);
                        createCommentFiles(formData)
                            .then((res) => {
                                // @ts-ignore
                                if(res?.data?.Success === 'File Created!') {
                                    getAssigmentById(taskID);
                                    setFilesState([]);
                                } 
                        })
                    });
                } 
            })
            .then(() => {    
                setDescription('');
                getAssigmentById(taskID);
            })
            .catch(() => {
                console.log("Something went wrong...")
        });
    };

    const changeVisibleRemButtonState = (event:React.MouseEvent<HTMLDivElement, MouseEvent>, id: number) => {
        event.type === 'mousemove' 
            ? setVisibleRemButton(id)
            : setVisibleRemButton(null)
    };

    const deleteCoommentHandler = (id: number) => {
        removeComment(id)
            .then(() => {
                getAssigmentById(taskID);
            })
            .catch(() => {
                console.log("Something went wrong...")
            })
    };

    return (
        <>
            <InputComment
                description={description}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                filesState={filesState}
                setFilesState={setFilesState}
            />
            <div className="comments-block card" ref={container}>
                <div style={{display: 'flex', alignItems: 'center', columnGap: '1rem', paddingBottom: '0.5rem'}}>
                    <h6 style={{margin: 0, padding: 0}}>{t('assignmentsPage.All comments')}</h6>
                    <i className={`fa fa-sort-amount-${sortDirection}`}
                       style={{fontSize: '1.125rem', cursor: 'pointer'}}
                       aria-hidden="true"
                       onClick={toggleSortDirection}
                    />
                </div>
                {(sortDirection === 'asc' ? sortArrayOfObjectsByKey(task?.comments || [], 'created_at') : sortArrayOfObjectsByKey(task?.comments || [], 'created_at', true))?.map((comment: any) => (
                  <div
                    className="comments-block_mes-block"
                    key={'comment' + comment.id}
                    onMouseLeave={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                      changeVisibleRemButtonState(event, comment.id)}
                    onMouseMove={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                      changeVisibleRemButtonState(event, comment.id)}
                  >
                      <MessageComment
                        id={comment.id}
                        assignment={taskID}
                        author={comment.author}
                        description={comment.description}
                        created_at={comment.created_at}
                        files={comment?.file_comments}
                      />
                      {comment.author === me.username &&
                        <>
                            {visibleRemButton === comment.id
                              ?
                              <button
                                className="cis-trash-alt comments-block_mes-block_rem-btn"
                                onClick={() => deleteCoommentHandler(comment.id)}
                              >
                                  <CIcon icon={cilTrash}/>
                              </button>
                              : null
                            }
                        </>
                      }
                  </div>
                ))}
            </div>
        </>
    );
};

export default CommentsComponent;
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IVideoStream} from "../../interfaces/IVideoStreams";

export const videoStreamsApi = createApi({
    reducerPath: 'videoStreams/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['VideoStreams'],
    endpoints: build => ({
        getVideoStreams: build.query<IVideoStream[], void>({
            query: () => {
                return {
                    url: `/videowalls/`
                }
            },
            providesTags: ['VideoStreams']
        }),
        getVideoStream: build.query<IVideoStream, any>({
            query: (id: any) => ({
                url: `/videowalls/${id}/`
            })
        }),
        createVideoStream: build.mutation<any, any>({
            query: (body) => ({
                url: '/videowalls/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['VideoStreams']
        }),
        updateVideoStream: build.mutation<any, Partial<any> & Pick<any, 'id'>>({
            query: ({id, body}) => {
                return {
                    url: `/videowalls/${id}/`,
                    method: 'PATCH',
                    body
                }
            },
            invalidatesTags: ['VideoStreams']
        }),
        removeVideoStream: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/videowalls/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['VideoStreams']
        })
    })
})

export const {
    useGetVideoStreamsQuery,
    useLazyGetVideoStreamsQuery,
    useLazyGetVideoStreamQuery,
    useCreateVideoStreamMutation,
    useUpdateVideoStreamMutation,
    useRemoveVideoStreamMutation
} = videoStreamsApi;
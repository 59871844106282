import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IDistrict, IDistrictResponse} from "../../interfaces/IDistricts";

export const districtsApi = createApi({
    reducerPath: 'districts/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['Districts'],
    endpoints: build => ({
        getDistricts: build.query<IDistrictResponse[], string | void>({
            query: (query) => {
                return {
                    url: `/list/district/${query || ''}`
                }
            },
            providesTags: ['Districts'],
        }),
        // getUsersById: build.query<IRegion, string>({
        //     query: (id: string) => ({
        //         url: `/detail/users/${id}`
        //     })
        // }),
        createDistrict: build.mutation<any, IDistrict>({
            query: (body) => ({
                url: '/create/district/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Districts'],
        }),
        updateDistrict: build.mutation<any, Partial<any> & Pick<any, 'id'>>({
            query: ({id, body}) => {
                return {
                    url: `/update/district/${id}/`,
                    method: 'PATCH',
                    body
                }
            },
            invalidatesTags: ['Districts'],
        }),
        removeDistrict: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/delete/district/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Districts'],
        })
    })
})

export const {
    useGetDistrictsQuery,
    useLazyGetDistrictsQuery,
    useCreateDistrictMutation,
    useUpdateDistrictMutation,
    useRemoveDistrictMutation
} = districtsApi;
import React from 'react';

const UserManual = () => {
  return (
    <div>
      User manual
    </div>
  );
};

export default UserManual;

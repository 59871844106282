import React, {FC} from 'react';
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {statusMessagesES} from "./PublicEsReports";
import {CFormInput} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilX} from "@coreui/icons";

interface ICard {
    item: any;
    onClick: (e: React.MouseEvent<HTMLDivElement>, id: number, status: string) => void;
}


const PublicEsReportsCard: FC<ICard> = ({item, onClick}) => {
    const {t} = useTranslation();
    const url = 'https://new.mes.yorc.org/reports/';

    const getFileExtension = (url: string) => {
        if (!url) return '';  // Проверка на случай, если URL пустой
        return url?.split('.')?.pop()?.toLowerCase();
    };

    const renderMedia = (mediaUrl: string) => {
        const extension: any = getFileExtension(mediaUrl);

        if (!mediaUrl) {
            return <p>URL is undefined or empty</p>;
        }

        if (['mp4', 'webm', 'ogg'].includes(extension)) {
            return (
                <video className="es-report-video" controls>
                    <source src={`${url}${mediaUrl}`} type={`video/${extension}`}/>
                    Your browser does not support the video element.
                </video>
            );
        }

        if (['mp3', 'm4a', 'wav', 'ogg'].includes(extension)) {
            return (
                <audio className="es-report-audio" controls>
                    <source src={`${url}${mediaUrl}`} type={`audio/mpeg`}/>
                    Your browser does not support the audio element.
                </audio>
            );
        }

        if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension)) {
            return <img className="es-report-img" src={`${url}${mediaUrl}`}
                        alt="Media content"/>;
        }

        return <p>Unsupported media format</p>;
    };

    return (
        <div className="emergency-card">
            <div className="emergency-card__header">
                <div>
                    <h3 className="emergency-card__title public-es-report-title">
                        {t(`esFeed.esReportTypeTitle`)}: {item?.es_type}
                    </h3>
                </div>
            </div>

            <div className="emergency-card__body">
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                    <div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                                <div>
                                    <strong style={{marginRight: '10px'}}>{t("profile.phone")}:</strong>
                                    <span>{item.phone_number}</span>
                                </div>
                                <div>
                                    <strong style={{marginRight: '10px'}}>{t('Date of creation')}:</strong>
                                    <span>{dayjs(item?.created_at).format('DD.MM.YYYY HH:mm')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {item?.status !== statusMessagesES.processed && item?.status !== statusMessagesES.rejected ?
                            <>
                                <span className={`emergency-card__indicators__processed-created`}
                                      style={{cursor: "pointer"}}
                                      onClick={(e: React.MouseEvent<HTMLDivElement>) => onClick(e, item.id, statusMessagesES.processed)}>
              {t('esFeed.statusProcessed')}
            </span>
                                <span
                                    className={`emergency-card__indicators__rejected-created`}
                                    onClick={(e: React.MouseEvent<HTMLDivElement>) => onClick(e, item.id, statusMessagesES.rejected)}
                                    style={{marginLeft: '15px', cursor: "pointer"}}
                                >
              {t('esFeed.statusRejected')}
            </span>
                            </> :
                            item.status === statusMessagesES.processed ?
                                <span className={`emergency-card__indicators__processed`}
                                >
              {t('esFeed.statusProcessedDone')}
            </span>
                                :
                                <span
                                    className={`emergency-card__indicators__rejected`}
                                >
              {t('esFeed.statusRejectedDone')}
            </span>
                        }

                    </div>
                </div>
                <div style={{overflow: 'hidden', maxHeight: '75px', textOverflow: 'ellipsis'}}>
                    {item.description}
                </div>
                <div>
                    {
                        <div className="mb-3">
                            <div style={{
                                display: "flex",
                                flexWrap: 'wrap',
                                gap: '15px',
                                marginTop: '20px',
                                alignItems: 'center'
                            }}>
                                {item?.attachments?.map((item: any, i: number) => {
                                    getFileExtension(`${url}${item?.name}`);
                                    return (
                                        <div key={i + '_pic'} className="es-files-wrapper">
                                            {renderMedia(item?.name)}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default PublicEsReportsCard;
import React, {FC} from 'react';
import {CChart} from "@coreui/react-chartjs";
import {getStyle} from "@coreui/utils";

import {colors} from "../../../../colorsForCharts/colorsForCharts";

interface IChartsProps {
  labels: string[];
  data: number[];
  field: string;
}

const DonutChart: FC<IChartsProps> = ({labels, data}) => {
  return (
    <>
      <CChart
        type="doughnut"
        data={{
          labels: labels || [],
          datasets: [
            {
              backgroundColor: colors,
              data: data || [],
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              labels: {
                color: getStyle('--cui-body-color'),
              }
            }
          },
        }}
      />
    </>
  );
};

export default DonutChart;
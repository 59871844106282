import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IPermissions} from "../../interfaces/IPermissions";

export const permissionsApi = createApi({
    reducerPath: 'permissions/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['Permissions'],
    endpoints: build => ({
        getPermissions: build.query<IPermissions, string>({
            query: () => {
                return {
                    url: `/list/permissions/`
                }
            }
        })
    })
})

export const {
    useLazyGetPermissionsQuery,
} = permissionsApi;
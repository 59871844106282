import React, {useMemo, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CButton} from "@coreui/react";
import {Marker} from "react-leaflet";
import Leaflet from "leaflet";
import dayjs from "dayjs";

import {
  useGetEmergencyByIdQuery,
  useRemoveEmergencyMutation,
  useUpdateEmergencyMutation
} from "../../../store/emergencies/emergencies.api";
import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import BaseMap from "../../../components/maps/BaseMap";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";

const EsDetail = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const id = location.pathname.split('/')[location.pathname.split('/').length - 1];

  const {data: emergencyById, isLoading: getLoading} = useGetEmergencyByIdQuery(id)
  const [removeEmergency, {isLoading: updateLoading}] = useRemoveEmergencyMutation();
  const [updateEmergency, {isLoading: removeLoading}] = useUpdateEmergencyMutation();

  const [removeVisible, setRemoveVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [type, setType] = useState('');

  const isLoading = useMemo(() => {
    return (getLoading || updateLoading || removeLoading);
  }, [getLoading, updateLoading, removeLoading]);

  let icon, iconUrl
  let position

  if (emergencyById?.properties?.hasOwnProperty('latitude')) {
    position = [emergencyById?.properties?.latitude, emergencyById?.properties?.longitude]
    iconUrl = emergencyById?.properties?.type_emergency?.geo_icon?.icon
    if (iconUrl) {
      icon = new Leaflet.Icon({
        iconUrl: iconUrl,
        iconSize: [32, 42],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
      })
    }
  }

  const handleOpenDeleteModal = () => {
    setRemoveVisible(true);
  }

  const handleDeleteEmergency = () => {
    removeEmergency(Number(id)).then(() => {
      setRemoveVisible(false);
      navigate({pathname: location.pathname.split('/')[3] === 'base' ? '/es-base' : '/es-feed'})
    })
  }

  const handleRedirectToUpdate = () => {
    navigate({pathname: `/es-report/update/${id}`})
  }

  const handleOpenUpdateModal = (type: string) => {
    setType(type);
    setUpdateVisible(true);
  }

  const handleUpdateSettings = () => {
    if (type === 'late') {
      let data = {
        late_information: !emergencyById?.properties?.late_information
      }
      updateEmergency({body: data, id: emergencyById?.properties?.id})
      setUpdateVisible(false);
    }
    if (type === 'status') {
      let data = {
        status: emergencyById?.properties?.status === 'in processing' ? 'completed' : 'in processing'
      }
      updateEmergency({body: data, id: emergencyById?.properties?.id})
      setUpdateVisible(false);
    }
  }

  if (isLoading) return <LoaderLine visible={isLoading}/>;

  return (
    <>
      <div className="pb-3 mb-4 title-divider">
        <h1>{emergencyById?.properties?.title}</h1>
        <LoaderLine visible={isLoading}/>
      </div>

      <div className="pb-3 mb-4 title-divider"
           style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{marginRight: '10px'}}>
              <img alt={''} src={emergencyById?.properties?.type_emergency?.geo_icon?.icon}
                   style={{width: '40px', height: '40px'}}/>
            </div>
            <div>
              <div>
                <strong style={{marginRight: '10px'}}>{t("emergency.thisEvent")}</strong>
                <span>{emergencyById?.properties?.type_report === 'incident' ? t("emergency.incident") : emergencyById?.properties?.type_report === 'emergency' ? t("emergency.emergency") : t("emergency.other")}</span>
              </div>
              <div>
                <strong style={{marginRight: '10px'}}>{t("settings.esTypes.emergencyType")}:</strong>
                <span>{emergencyById?.properties?.type_emergency?.name}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <CButton className="detail-es__btn" size="sm" color="success" style={{color: 'white'}}
                   onClick={() => handleOpenUpdateModal('late')}>
            <i className="fa fa-calendar" aria-hidden="true"
               style={{marginRight: '7px'}}></i>{emergencyById?.properties?.late_information ? t("emergency.markAsWithoutDelay") : t("emergency.markAsLate")}
          </CButton>
          <CButton className="detail-es__btn" size="sm" color="secondary" style={{color: 'white', marginLeft: '10px'}}
                   onClick={() => handleOpenUpdateModal('status')}>
            <i className="fa fa-square-o" aria-hidden="true"
               style={{marginRight: '7px'}}></i>{emergencyById?.properties?.status === 'in processing' ? t("emergency.complete") : t("emergency.resume")}
          </CButton>
          <CButton className="detail-es__btn" size="sm" color="info" style={{color: 'white', marginLeft: '10px'}}
                   onClick={handleRedirectToUpdate}>
            <i className="fa fa-pencil-square-o" aria-hidden="true" style={{marginRight: '7px'}}></i>{t("edit")}
          </CButton>
          <CButton className="detail-es__btn detail-es__remove-btn" size="sm" style={{marginLeft: '10px'}}
                   onClick={handleOpenDeleteModal}>
            <i className="fa fa-times" aria-hidden="true" style={{marginRight: '7px'}}></i>{t("delete")}
          </CButton>
        </div>
      </div>

      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{width: '30%'}}>
          <div className="pb-3 mb-2">

          </div>
          <div className="pb-3 mb-2">
            <div><strong>{t("settings.esTypes.description")}:</strong></div>
            <div>{emergencyById?.properties?.description}</div>
          </div>

          <div className="pb-3 mb-2">
            <div><strong>{t("gis.geoObjects.additionalInformation")}:</strong></div>
            <ul style={{paddingLeft: '16px'}}>{emergencyById?.properties?.field_emergency?.map((el: any) => {
              return (
                <li key={el.id}>
                  <strong style={{marginRight: '10px'}}>{el?.field_type_emergency?.name}:</strong>
                  <span>
                    {
                      el?.field_type_emergency?.type === 'date' ?
                        dayjs(el.meaning).format('DD-MM-YYYY') :
                        el?.field_type_emergency?.type === 'time' ?
                          dayjs(el.meaning).format('HH:mm') :
                          el?.field_type_emergency?.type === 'datetime' ?
                            dayjs(el.meaning).format('DD-MM-YYYY / HH:mm') :
                            el.meaning
                    }
                  </span>
                </li>
              )
            })}</ul>
          </div>


        </div>
        <div style={{width: '68%'}} className="mb-4">
          {emergencyById?.properties?.hasOwnProperty('title') ? <div className="mb-3 emergency-map-wrapper">
            <div className="whole-place-wrapper">
              <BaseMap layersControl
                       zoomControl
                       center={[Number(emergencyById?.properties?.latitude), Number(emergencyById?.properties?.longitude)]}
                       zoom={10}
                       scaleControl
                       loading={false}
                       handlerZoom={() => {
                       }}
                       getPosition={() => {
                       }}
              >
                {/*  @ts-ignore*/}
                <Marker position={position} icon={icon}/>
              </BaseMap>
            </div>
          </div> : null}
        </div>
      </div>
      <div className="pb-3 mb-2">
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          {emergencyById?.properties?.file_emergency?.map((item: any, i: number) => {
            return (
              <div key={i + '_pic'} className="image-wrapper">
                <img alt={""}
                     src={item.file}
                     style={{height: '110px', width: 'auto'}}
                />
                {/*}*/}
              </div>
            )
          })}
        </div>
      </div>

      <DefaultModal visible={removeVisible}
                    setVisible={() => setRemoveVisible(false)}
                    title={t('deletion')}
                    type="danger"
                    cancelButton={t('cancel')}
                    onOk={handleDeleteEmergency}
                    size="lg"
      >
        <div>
          <p className="mb-2 fs-6">{t('esBase.doYouReallyWantToDeleteAnEmergency')}</p>
          {/*@ts-ignore*/}
          <div className="fs-6 fw-bolder">{emergencyById?.properties?.title}?</div>
        </div>
      </DefaultModal>

      <DefaultModal visible={updateVisible}
                    setVisible={() => setUpdateVisible(false)}
                    title={t('edit')}
                    type="info"
                    cancelButton={t('cancel')}
                    onOk={handleUpdateSettings}
                    size="lg"
      >
        <div>
          <p className="mb-2 fs-6">
            {
              type === 'late' ?
                (emergencyById?.properties?.late_information ? t("emergency.markAsWithoutDelay") : t("emergency.markAsLate")) :
                emergencyById?.properties?.status === 'in processing' ? t("emergency.complete") : t("emergency.resume")
            }?
          </p>
        </div>
      </DefaultModal>

    </>
  );
};

export default EsDetail;
const env = process.env.NODE_ENV;
const baseUrl = env === 'development' ? 'https://iamsui.yorc.org' : '';
const webSocketSecure = env === 'development' ? 'wss://iamsui.yorc.org' : ''; // TODO: добавить для прода вебсокет

const config = {
  baseUrl,
  apiUrl: baseUrl + '/api',
  storageUrl: baseUrl + '/api/storage/',
  webSocketSecure
};

export const ASSIGNMENTS_FILTER_SETTINGS = 'assignmentsFilterSettings';

// later_or_equal earlier

// позже или равно
let arr1 = [
  {
    field_name: 'created_at',
    field_method: 'later_or_equal'
  }
]

// раньше
let arr2 = [
  {
    field_name: 'created_at',
    field_method: 'earlier'
  }
]

// В этот день
let arr3 = [
  {
    field_name: 'created_at',
    field_method: 'later_or_equal'
  },
  {
    field_name: 'created_at',
    field_method: 'earlier'
  }
]

export default config;

import React, {useEffect, useMemo, useState} from 'react';
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CForm,
  CFormInput,
  CFormLabel
} from "@coreui/react";
import Select, {StylesConfig} from "react-select";
import {useTranslation} from "react-i18next";

import {useGetEsFieldsQuery} from "../../store/esFields/esFields";
import {
  useCreateTemplateMutation,
  useLazyGetTemplateByIdQuery,
  useUpdateTemplateMutation
} from "../../store/analytics/analytics.api";
import {useLocation, useNavigate} from "react-router-dom";

interface ISettingsState {
  id?: number | null,
  name: string,
  collection: string
}

interface ILimitationsItem {
  field_name: string,
  field_method: string
}

interface IRestrictionItem extends ILimitationsItem {
  restriction_id?: number
}

interface ISortConditionItem extends ILimitationsItem {
  sort_conditions_id?: number
}

interface IGroupByFieldItem extends ILimitationsItem {
  group_by_fields_id?: number
}

interface IGroupAggregationItem extends ILimitationsItem {
  group_aggregation_functions_id?: number
}

type TSelectOption = {
  label: string,
  value: string
};

const datetimeMethods = ['later_or_equal', 'earlier', 'on_this_day'];
const comparisonMethods = ['contains'];
const sortMethods = ['asc', 'desc'];
const aggregationMethods = ['Sum', 'Avg', 'Min', 'Max'];
const countableFieldTypes = ['IntegerField', 'FloatField'];
const timeFieldLabels = ['date_emergency', 'created_at', 'updated_at'];
const groupByFieldsMethods = [
  {
    name: 'type_emergency',
    method: 'TypeEmergency'
  },
  {
    name: 'author',
    method: 'User'
  },
  {
    name: 'executor',
    method: 'User'
  },
  // {
  //   name: '',
  //   method: 'ComparisonRegion'
  // },
  // {
  //   name: '',
  //   method: 'ComparisonDistrict'
  // },
  {
    name: 'type_report',
    method: 'EmergencyTypeReport'
  },
  {
    name: 'status',
    method: 'EmergencyStatus'
  },
  {
    name: 'late_information',
    method: 'EmergencyLateInformation'
  },
  {
    name: 'region',
    method: 'Region'
  },
  {
    name: 'district',
    method: 'District'
  }
];

const AnalyticsTemplate = () => {
  const {i18n, t} = useTranslation();
  const currentLanguage = i18n?.language;
  const navigate = useNavigate();
  const location = useLocation();

  const templateId = location.pathname.split('/').reverse()[0];

  const {data: esFields} = useGetEsFieldsQuery();
  const [createTemplate] = useCreateTemplateMutation();
  const [getTemplateById] = useLazyGetTemplateByIdQuery();
  const [updateTemplate] = useUpdateTemplateMutation();

  const [settingsState, setSettingsState] = useState<ISettingsState>({
    id: null,
    name: '',
    collection: 'ESReports'
  });
  const [restrictionsState, setRestrictionsState] = useState<IRestrictionItem[]>([]);
  const [sortConditionsState, setSortConditionsState] = useState<ISortConditionItem[]>([{
    field_name: '',
    field_method: ''
  }]);
  const [groupByFieldsState, setGroupByFieldsState] = useState<IGroupByFieldItem[]>([{
    field_name: '',
    field_method: ''
  }]);
  const [groupAggregationState, setGroupAggregationState] = useState<IGroupAggregationItem[]>([]);
  const [restrictionsRemove, setRestrictionsRemove] = useState<number[]>([]);
  const [groupAggregationsRemove, setGroupAggregationsRemove] = useState<number[]>([]);

  const restrictionsSelectOptions: TSelectOption[] = useMemo(() => {
    if (esFields?.labels) return Object.keys(esFields.labels).map(key => ({label: t(`labels.${key}`), value: key}));
    return [];
    // eslint-disable-next-line
  }, [esFields, currentLanguage]);

  const comparisonSelectOptions: TSelectOption[] = useMemo(() => {
    return groupByFieldsMethods.map(el => ({label: t(`labels.${el.name}`), value: el.name}));
    // eslint-disable-next-line
  }, [groupByFieldsMethods, currentLanguage]);

  const aggregationsSelectOptions: TSelectOption[] = useMemo(() => {
    const res: TSelectOption[] = [];
    if (esFields?.field_types) {
      Object.keys(esFields.field_types).forEach(key => {
        if (countableFieldTypes.includes(esFields.field_types[key])) {
          res.push({label: t(`labels.${key}`), value: key})
        }
      });
    }
    return res;
    // eslint-disable-next-line
  }, [esFields, currentLanguage]);

  const datetimeMethodsSelectOptions = useMemo(() => {
    return datetimeMethods.map(el => ({label: t(`labels.${el}`), value: el}));
    // eslint-disable-next-line
  }, [currentLanguage]);

  const comparisonMethodsSelectOptions = useMemo(() => {
    return comparisonMethods.map(el => ({label: t(`labels.${el}`), value: el}));
    // eslint-disable-next-line
  }, [currentLanguage]);

  const sortMethodsSelectOptions = useMemo(() => {
    return sortMethods.map(el => ({label: t(`labels.${el}`), value: el}));
    // eslint-disable-next-line
  }, [currentLanguage]);

  const aggregationMethodsSelectOptions = useMemo(() => {
    return aggregationMethods.map(el => ({label: t(`labels.${el}`), value: el}));
    // eslint-disable-next-line
  }, [currentLanguage]);

  useEffect(() => {
    if (!isNaN(Number(templateId))) {
      getTemplateById(templateId).then(res => {
        setSettingsState({
          id: res?.data?.id,
          name: res?.data?.name || '',
          collection: res?.data?.collection || 'ESReports'
        })

        setRestrictionsState(res?.data?.restrictions?.map(el => ({
          restriction_id: el.id,
          field_name: el.field_name,
          field_method: el.field_method
        })) || [])

        setSortConditionsState(res?.data?.sort_conditions?.map(el => ({
          sort_conditions_id: el.id,
          field_name: el.field_name.split('__')[1],
          field_method: el.field_method
        })) || [{
          field_name: '',
          field_method: ''
        }])

        setGroupByFieldsState(res?.data?.group_by_fields?.map(el => ({
          group_by_fields_id: el.id,
          field_name: el.field_name,
          field_method: el.field_method
        })) || [{
          field_name: '',
          field_method: ''
        }])

        setGroupAggregationState(res?.data?.group_aggregation_functions?.map(el => ({
          group_aggregation_functions_id: el.id,
          field_name: el.field_name,
          field_method: el.field_method
        })) || [])
      })
    }
  }, [templateId]);

  const onChangeNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettingsState(prev => ({...prev, name: event.target.value}));
  };

  const onSelectChangeHandler = (data: TSelectOption, collection: string, field: string, idx: number) => {
    let innerState = [], item: ILimitationsItem = {field_name: '', field_method: ''};

    switch (collection) {
      case 'restrictions':
        innerState = [...restrictionsState];
        item = {...innerState[idx], [field]: data?.value || ''};
        if (field === 'field_name') item.field_method = '';
        innerState[idx] = item;
        setRestrictionsState(innerState);
        break;
      case 'sortConditions':
        innerState = [...sortConditionsState];
        item = {...innerState[idx], [field]: data?.value || ''};
        if (field === 'field_name') item.field_method = '';
        innerState[idx] = item;
        setSortConditionsState(innerState);
        break;
      case 'groupByFields':
        innerState = [...groupByFieldsState];
        item = {...innerState[idx], [field]: data?.value || '', field_method: groupByFieldsMethods.find(el => el.name === data.value)?.method || ''};
        innerState[idx] = item;
        setGroupByFieldsState(innerState);
        break;
      case 'groupAggregation':
        innerState = [...groupAggregationState];
        item = {...innerState[idx], [field]: data?.value || ''};
        if (field === 'field_name') item.field_method = '';
        innerState[idx] = item;
        setGroupAggregationState(innerState);
        break;
      default:
      // do nothing
    }
  };

  const onChangeRestrictionsArrayHandler = (event: React.MouseEvent<HTMLElement>, idx: number = -1, item?: any) => {
    event.preventDefault();
    const innerState = [...restrictionsState];
    if (Number(idx) >= 0) {
      innerState.splice(idx, 1);
      let arr = [...restrictionsRemove];
      arr.push(item.restriction_id);
      setRestrictionsRemove(arr)
    } else innerState.push({field_name: '', field_method: ''});
    setRestrictionsState(innerState);
  };

  const onChangeGroupAggregationArrayHandler = (event: React.MouseEvent<HTMLElement>, idx: number = -1, item?: any) => {
    event.preventDefault();
    const innerState = [...groupAggregationState];
    if (Number(idx) >= 0) {
      innerState.splice(idx, 1);
      let arr = [...groupAggregationsRemove];
      arr.push(item.group_aggregation_functions_id);
      setGroupAggregationsRemove(arr);
    } else innerState.push({field_name: '', field_method: ''});
    setGroupAggregationState(innerState);
  };

  const onSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();

    // let debugErrorMessage = 'Все тип-топ!!!';

    const data: any = {...settingsState};
    // if (!data.name) debugErrorMessage = 'Нет имени';
    // if (!data.collection) debugErrorMessage = 'Нет коллекции';

    if (!isNaN(Number(templateId))) {
      data.field_restrictions = restrictionsState
        .filter(el => ((el.field_name && el.field_method) || el.field_name === 'type_report'))
        .map(el => ({...el, field_method: el.field_name === 'type_report' ? '' : el.field_method}));
      data.field_sort_conditions = sortConditionsState
        .filter(el => (el.field_name && el.field_method))
        .map(el => ({...el, field_name: `emergency__${el.field_name}`}));
      data.field_group_by_fields = groupByFieldsState.filter(el => (el.field_name && el.field_method));
      data.field_group_aggregation_functions = groupAggregationState.filter(el => (el.field_name && el.field_method));

      if (restrictionsRemove.length) {
        data.delete_restrictions = restrictionsRemove;
      }

      if (groupAggregationsRemove.length) {
        data.delete_group_aggregation_functions = groupAggregationsRemove;
      }

      updateTemplate({id: templateId, body: data}).then(res => {
        // @ts-ignore
        if (res.data) {
          navigate({pathname: '/analytic-templates'});
        }
      })


    } else {
      data.restrictions = restrictionsState
        .filter(el => ((el.field_name && el.field_method) || el.field_name === 'type_report'))
        .map(el => ({...el, field_method: el.field_name === 'type_report' ? '' : el.field_method}));
      data.sort_conditions = sortConditionsState
        .filter(el => (el.field_name && el.field_method))
        .map(el => ({...el, field_name: `emergency__${el.field_name}`}));
      data.group_by_fields = groupByFieldsState.filter(el => (el.field_name && el.field_method));
      data.group_aggregation_functions = groupAggregationState.filter(el => (el.field_name && el.field_method));

      delete data.id;

      createTemplate(data).then(res => {
        // @ts-ignore
        if (res.data) {
          navigate({pathname: '/analytic-templates'});
        }
      })
    }

    // console.log(debugErrorMessage);
  };

  const colorStyles: StylesConfig<any> = {
    control: (styles) => (
      {
        ...styles,
        backgroundColor: '#ffffff',
        border: '1px solid grey',
        boxShadow: 'none',
        borderRadius: '6px',
        maxHeight: 31,
        fontSize: '14px'
      }
    )
  };

  return (
    <>
      <div className="pb-3 mb-4 title-divider">
        <h1>{t("templates.newTemplate")}</h1>
      </div>

      <CForm onSubmit={onSubmitHandler}>
        <div className="mb-4">
          <CAccordion alwaysOpen activeItemKey={1}>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader><strong>{t("templates.settings")}</strong></CAccordionHeader>
              <CAccordionBody>
                <CFormInput label={t("templates.templateName")}
                            name="name"
                            value={settingsState.name}
                            onChange={onChangeNameHandler}
                />
              </CAccordionBody>
            </CAccordionItem>

            <CAccordionItem itemKey={2}>
              {/*@ts-ignore*/}
              <CAccordionHeader><strong>{t("templates.restrictions")}</strong></CAccordionHeader>
              <CAccordionBody style={{width: '100%'}}>
                {restrictionsState.map((el, idx: number) => {
                  const methodOpts = timeFieldLabels.includes(el.field_name) ? datetimeMethodsSelectOptions : comparisonMethodsSelectOptions;
                  return (
                    <div key={`restriction_${idx}`} className={'mb-3'}
                         style={{
                           minWidth: '100%',
                           display: 'flex',
                           alignContent: 'center',
                           justifyContent: 'space-between'
                         }}>
                      <div style={{width: '47%'}}>
                        {/*{JSON.stringify(restrictionsSelectOptions.find(l => l.value === el.field_name))}*/}
                        <CFormLabel>{t("templates.field")}</CFormLabel>
                        <Select options={restrictionsSelectOptions}
                                value={restrictionsSelectOptions.find(l => l.value === el.field_name)}
                                styles={colorStyles}
                                placeholder={'Select one'}
                                onChange={e => onSelectChangeHandler(e, 'restrictions', 'field_name', idx)}
                                isClearable
                        />
                      </div>
                      <div style={{width: '47%'}}>
                        {/*{JSON.stringify(methodOpts.find(l => l.value === el.field_method))}*/}
                        <CFormLabel>{t("templates.method")}</CFormLabel>
                        <Select options={methodOpts}
                                value={methodOpts.find(l => l.value === el.field_method) || ''}
                                styles={colorStyles}
                                placeholder={'Select one'}
                                onChange={e => onSelectChangeHandler(e, 'restrictions', 'field_method', idx)}
                                isDisabled={!restrictionsSelectOptions.find(l => l.value === el.field_name)}
                                isClearable
                        />
                      </div>
                      <div style={{width: '4%', display: "flex", alignItems: "flex-end", justifyContent: 'flex-end'}}>
                        <CButton onClick={(e) => onChangeRestrictionsArrayHandler(e, idx, el)}>-</CButton>
                      </div>
                    </div>
                  )
                })}
                <CButton size="sm" onClick={onChangeRestrictionsArrayHandler}>{t("templates.add")}</CButton>
              </CAccordionBody>
            </CAccordionItem>

            <CAccordionItem itemKey={3}>
              <CAccordionHeader><strong>{t("templates.sortingConditions")}</strong></CAccordionHeader>
              <CAccordionBody>
                <div className={'mb-3'} style={{
                  minWidth: '100%',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'space-between'
                }}>
                  <div style={{width: '47%'}}>
                    {/*{JSON.stringify(restrictionsSelectOptions.find(l => l.value === sortConditionsState[0].field_name))}*/}
                    <CFormLabel>{t("templates.field")}</CFormLabel>
                    <Select options={comparisonSelectOptions}
                            value={comparisonSelectOptions.find(l => l.value === sortConditionsState[0].field_name)}
                            styles={colorStyles}
                            placeholder={'Select one'}
                            onChange={e => onSelectChangeHandler(e, 'sortConditions', 'field_name', 0)}
                            isClearable
                    />
                  </div>
                  <div style={{width: '47%'}}>
                    {/*{JSON.stringify(sortMethodsSelectOptions.find(l => l.value === sortConditionsState[0].field_method))}*/}
                    <CFormLabel>{t("templates.method")}</CFormLabel>
                    <Select options={sortMethodsSelectOptions}
                            value={sortMethodsSelectOptions.find(l => l.value === sortConditionsState[0].field_method)}
                            styles={colorStyles}
                            placeholder={'Select one'}
                            onChange={e => onSelectChangeHandler(e, 'sortConditions', 'field_method', 0)}
                            isClearable
                    />
                  </div>
                  <div style={{width: '4%', display: "flex", alignItems: "flex-end", justifyContent: 'flex-end'}}/>
                </div>
              </CAccordionBody>
            </CAccordionItem>

            <CAccordionItem itemKey={4}>
              <CAccordionHeader><strong>{t("templates.groupByFields")}</strong></CAccordionHeader>
              <CAccordionBody>
                <div className={'mb-3'} style={{
                  minWidth: '100%',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'space-between'
                }}>
                  <div style={{width: '95%'}}>
                    {/*{JSON.stringify(restrictionsSelectOptions.find(l => l.value === groupByFieldsState[0].field_name))}*/}
                    <CFormLabel>{t("templates.field")}</CFormLabel>
                    <Select options={comparisonSelectOptions}
                            value={comparisonSelectOptions.find(l => l.value === groupByFieldsState[0].field_name)}
                            styles={colorStyles}
                            placeholder={'Select one'}
                            onChange={e => onSelectChangeHandler(e, 'groupByFields', 'field_name', 0)}
                            isClearable
                    />
                  </div>
                </div>
              </CAccordionBody>
            </CAccordionItem>

            <CAccordionItem itemKey={5}>
              <CAccordionHeader><strong>{t("templates.groupedAggregationFunctions")}</strong></CAccordionHeader>
              <CAccordionBody style={{width: '100%'}}>
                {groupAggregationState.map((el, idx: number) => {
                  return (
                    <div key={`restriction_${idx}`} className={'mb-3'}
                         style={{
                           minWidth: '100%',
                           display: 'flex',
                           alignContent: 'center',
                           justifyContent: 'space-between'
                         }}>
                      <div style={{width: '47%'}}>
                        {/*{JSON.stringify(aggregationsSelectOptions.find(l => l.value === el.field_name))}*/}
                        <CFormLabel>{t("templates.field")}</CFormLabel>
                        <Select options={aggregationsSelectOptions}
                                value={aggregationsSelectOptions.find(l => l.value === el.field_name)}
                                styles={colorStyles}
                                placeholder={'Select one'}
                                onChange={e => onSelectChangeHandler(e, 'groupAggregation', 'field_name', idx)}
                                isClearable
                        />
                      </div>
                      <div style={{width: '47%'}}>
                        {/*{JSON.stringify(aggregationMethodsSelectOptions.find(l => l.value === el.field_method))}*/}
                        <CFormLabel>{t("templates.method")}</CFormLabel>
                        <Select options={aggregationMethodsSelectOptions}
                                value={aggregationMethodsSelectOptions.find(l => l.value === el.field_method)}
                                styles={colorStyles}
                                placeholder={'Select one'}
                                onChange={e => onSelectChangeHandler(e, 'groupAggregation', 'field_method', idx)}
                                isClearable
                        />
                      </div>
                      <div style={{width: '4%', display: "flex", alignItems: "flex-end", justifyContent: 'flex-end'}}>
                        <CButton onClick={(e) => onChangeGroupAggregationArrayHandler(e, idx, el)}>-</CButton>
                      </div>
                    </div>
                  )
                })}
                <CButton size="sm" onClick={onChangeGroupAggregationArrayHandler}>{t("templates.add")}</CButton>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>
        </div>

        <CButton type="submit">{t("templates.save")}</CButton>
      </CForm>
    </>
  );
};

export default AnalyticsTemplate;

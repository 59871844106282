import {IButtonsApp} from "../../models/IButtonsApp";

const initialState: IButtonsApp = {
    sidebarShow: true,
}

// @ts-ignore
const btnSlice = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest }
        default:
            return state
    }
}


export default btnSlice

import React from 'react';

const Ris = () => {
  return (
    <div className="whole-place-wrapper">
      <iframe title="ris"
              className="third-party-app-iframe"
              src="https://192.168.33.16/frame/error/app" // need link
      />
    </div>
  );
};

export default Ris;

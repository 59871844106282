import { CButton, CForm, CFormInput } from '@coreui/react';
import { ChangeEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';
interface Props {
    description: string,
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void,
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void,
    filesState: any,
    setFilesState: (e:any) => void,
}
const InputComment = ({ 
    description, 
    handleChange, 
    handleSubmit, 
    filesState, 
    setFilesState, 
}:Props ) => {
    const {t} = useTranslation();

    const handleChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {files} = e.target;
        let tempFiles = [...filesState];
    
        if (e.target.type === 'file') {
          const chosenFiles = Array.prototype.slice.call(files);
          chosenFiles.forEach(el => {
            if (!tempFiles.find(item => item.name === el.name)) {
              tempFiles.push(el);
            }
          });
    
          setFilesState(tempFiles);
        }
      };

      const handleDeleteFile = (name: string) => {
        let arr = [...filesState];
        let index = arr.findIndex(el => el.name === name);
        arr.splice(index, 1);
        setFilesState(arr);
    };

    return (
        <>
            <CForm className="d-flex flex-column gap-3" onSubmit={(e:FormEvent<HTMLFormElement>) => handleSubmit(e)}>
                <div className="main-modal-block-input">
                    <CFormInput
                        className="main-input-main"
                        type="text"
                        placeholder={t('assignmentsPage.enterCommit')}
                        name="description"
                        value={description}
                        onChange={(e:ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    />
                </div>
                <div className="">
                    <CFormInput type="file"
                        name={'material_damage'}
                        onChange={handleChangeFiles}
                        className={'emergency-custom-input mb-3'}
                        multiple
                    />
                    <div style={{display: "flex", justifyContent: 'flex-start', flexWrap: 'wrap'}}>
                        {filesState.map((item: any, i: number) => {
                            return (
                                <div key={i + '_pic'} className="image-wrapper">
                                    <div className="image-delete-icon-wrapper"
                                        onClick={() => handleDeleteFile(item.name)}
                                    >
                                        <CIcon icon={icon.cilX} className="image-delete-icon"/>
                                    </div>
                                    {!item.type.includes("image") ?
                                        <div className="file-item">
                                            {item.name}
                                        </div> :
                                        <img alt={""}
                                            src={URL.createObjectURL(item)}
                                            style={{height: '60px', width: 'auto'}}
                                        />
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="main-modal-link-block">
                    <CButton type="submit" className="main-modal-btn">{t('assignmentsPage.sendComment')}</CButton>
                </div>
            </CForm>
        </>
    );
};

export default InputComment;
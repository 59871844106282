import React, {FC, useEffect, useState} from 'react';
import {CFormInput} from "@coreui/react";

import {useDebounce} from "../../../hooks/debounce";
import {useTranslation} from "react-i18next";

interface iPaginationSearch {
    name: string;
    onChange: (value: string) => void;
    value?: string,
    onSearchDelay?: number,
    onSearchCallback?: any
}

const PaginationSearch: FC<iPaginationSearch> = (
    {
        name = 'PaginationSearch',
        onChange
    }
) => {
    const [search, setSearch] = useState('');
    const debounced = useDebounce(search);
    const {t} = useTranslation();

    useEffect(() => {
        onChange(debounced);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounced]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    return (
        <div className="d-flex justify-content-between align-items-center search-wrapper">
            <CFormInput type="text"
                        placeholder={t("pagination.search")}
                        className="search-input"
                        name={name}
                        value={search}
                        onChange={handleSearch}
            />
            <div className="search-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_151_95)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.665 15.6636C15.8043 15.5241 15.9697 15.4135 16.1518 15.338C16.3339 15.2625 16.5291 15.2236 16.7262 15.2236C16.9234 15.2236 17.1186 15.2625 17.3007 15.338C17.4827 15.4135 17.6482 15.5241 17.7875 15.6636L23.5625 21.4386C23.844 21.7198 24.0022 22.1014 24.0023 22.4993C24.0024 22.8972 23.8445 23.2789 23.5632 23.5603C23.282 23.8418 22.9004 24 22.5025 24.0001C22.1046 24.0003 21.723 23.8423 21.4415 23.5611L15.6665 17.7861C15.527 17.6468 15.4164 17.4813 15.3409 17.2992C15.2654 17.1171 15.2266 16.9219 15.2266 16.7248C15.2266 16.5277 15.2654 16.3325 15.3409 16.1504C15.4164 15.9683 15.527 15.8029 15.6665 15.6636H15.665Z" fill="#343A40"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.75 18C10.8334 18 11.9062 17.7866 12.9071 17.372C13.9081 16.9574 14.8175 16.3497 15.5836 15.5836C16.3497 14.8175 16.9574 13.9081 17.372 12.9071C17.7866 11.9062 18 10.8334 18 9.75C18 8.66659 17.7866 7.5938 17.372 6.59286C16.9574 5.59193 16.3497 4.68245 15.5836 3.91637C14.8175 3.15029 13.9081 2.5426 12.9071 2.12799C11.9062 1.71339 10.8334 1.5 9.75 1.5C7.56196 1.5 5.46354 2.36919 3.91637 3.91637C2.36919 5.46354 1.5 7.56196 1.5 9.75C1.5 11.938 2.36919 14.0365 3.91637 15.5836C5.46354 17.1308 7.56196 18 9.75 18ZM19.5 9.75C19.5 12.3359 18.4728 14.8158 16.6443 16.6443C14.8158 18.4728 12.3359 19.5 9.75 19.5C7.16414 19.5 4.68419 18.4728 2.85571 16.6443C1.02723 14.8158 0 12.3359 0 9.75C0 7.16414 1.02723 4.68419 2.85571 2.85571C4.68419 1.02723 7.16414 0 9.75 0C12.3359 0 14.8158 1.02723 16.6443 2.85571C18.4728 4.68419 19.5 7.16414 19.5 9.75Z" fill="#343A40"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_151_95">
                            <rect width="24" height="24" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
    );
};

export default PaginationSearch;

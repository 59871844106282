import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";

export const chatsApi = createApi({
    reducerPath: 'chats/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['chats'],
    endpoints: build => ({
        chatUsers: build.query<any, string | void>({
            query: () => {
                return {
                    url: `/personal/chat/users/`,
                }
            },
            providesTags: ['chats'],
        }),

        chatPersonalList: build.query<any, string | void>({
            query: () => {
                return {
                    url: `/personal/chat/list/`,
                }
            },
            providesTags: ['chats'],
        }),

        chatMessagesId: build.query<any, string | number>({
            query: (id: string | number) => ({
                url: `/personal/chat/messages/${id}/`,
            })
        }),

        chatGroupList: build.query<any, void>({
            query: () => ({
                url: `/chatgroup/list/`,
            })
        }),

        chatGroupMessagesList: build.query<any, string | number>({
            query: (id: string | number) => ({
                url: `/chatgroup/messages/list/${id}/`
            })
        }),

        changeStatusGroup: build.mutation<any, any>({
            query: ({body, id}) => {
                return {
                    url: `/change/status/group/chat/messages/${id}/`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['chats'],
        }),

        changeStatusPersonal: build.mutation<any, any>({
            query: (body) => {
                return {
                    url: '/change/status/personal/chat/messages/',
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['chats'],
        }),
    })
})

export const {
    useLazyChatPersonalListQuery,
    useLazyChatMessagesIdQuery,
    useLazyChatGroupListQuery,
    useLazyChatGroupMessagesListQuery,
    useChangeStatusGroupMutation,
    useChangeStatusPersonalMutation,
} = chatsApi;
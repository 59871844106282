import React, {ChangeEvent, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {CButton, CFormSelect} from "@coreui/react";

import SelectForFilter from "../SelectForFilter/SelectForFilter";

import {ASSIGNMENTS_FILTER_SETTINGS} from "../../../config";
import {IFilterSettings} from "../../../views/Assignments/dragAndDrop/utils/tasks";

interface SortAndFilterProps {
    filter?: any,
    setFilter?: any,
    beforeTasks?: any,
    afterTasks?: any,
    sort?: boolean,
    defaultSettings?: IFilterSettings,
    onChangeSettings?: (settings: IFilterSettings) => void
}

const convertSettings = {
    stringify: (data: any) => {
        const values: IFilterSettings = {};
        if (data?.sort_by) values.sort_by = data.sort_by;
        if (data?.sort_direction) values.sort_direction = data.sort_direction;
        if (data?.filter_by) values.filter_by = data.filter_by;
        return JSON.stringify(values);
    },
    parse: (data: any) => {
        const values: IFilterSettings = { sort_by: 'date', sort_direction: 'asc' };
        try {
            const parsedData = JSON.parse(data);
            if (parsedData?.sort_by) values.sort_by = parsedData.sort_by;
            if (parsedData?.sort_direction) values.sort_direction = parsedData.sort_direction;
            if (parsedData?.filter_by) values.filter_by = parsedData.filter_by;
            return values;
        } catch (e) {
            return values;
        }
    }
};

const SortAndFilter = ({ sort, onChangeSettings }:SortAndFilterProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [toggleSelect, setToggleSelect] = useState(false);
    const [settings, setSettings] = useState<IFilterSettings>({});

    useEffect(() => {
        setSettings(convertSettings.parse(ASSIGNMENTS_FILTER_SETTINGS));
        if (onChangeSettings) onChangeSettings(convertSettings.parse(ASSIGNMENTS_FILTER_SETTINGS));
    }, []);

    const setSettingsHandler = (name: string, value: string) => {
        const innerState = {...settings, [name]: value};
        if (name === 'filter_by' && settings.filter_by === value) innerState.filter_by = '';
        localStorage.setItem(ASSIGNMENTS_FILTER_SETTINGS, convertSettings.stringify(innerState));
        setSettings(innerState);
        if (onChangeSettings) onChangeSettings(innerState);
    };

    const selectOptions = [
        {value: 'priority', label: t('assignmentsPage.by_priority')},
        {value: 'date', label: t('assignmentsPage.by_date')},
    ];

    const selectFilter = [
        {
            value: 'author',
            label:
                <label style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '300px', fontSize: '1rem'}}>
                    {t('assignmentsPage.my_assignments')}
                    <input type="checkbox"
                           checked={settings.filter_by === 'author'}
                           onChange={() => setSettingsHandler('filter_by', 'author')}
                    />
                </label>
        },
        {
            value: 'executor',
            label:
                <label style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '1rem'}}>
                    {t('assignmentsPage.assigned_to_me')}
                    <input type="checkbox"
                           checked={settings.filter_by === 'executor'}
                           onChange={() => setSettingsHandler('filter_by', 'executor')}
                    />
                </label>
        },
    ];

    return (
        <div style={{display: 'flex', justifyContent: sort ? 'space-between' : 'flex-end'}}>
            {sort &&
                <div style={{display: 'flex'}}>
                    <CFormSelect
                        style={{width: '180px'}}
                        value={settings.sort_by}
                        options={selectOptions}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => setSettingsHandler('sort_by', e.target.value)}
                    />
                    <CButton
                        style={{
                            background: 'white',
                            color: 'black',
                            border: '1px solid rgb(216, 219, 224)',
                        }}
                        onClick={() => setSettingsHandler('sort_direction', settings.sort_direction === 'asc' ? 'desc' : 'asc')}
                    >
                        <svg width="15" height="15" viewBox="0 0 401.998 401.998">
                            <g>
                                <g>
                                    <path d="M73.092,164.452h255.813c4.949,0,9.233-1.807,12.848-5.424c3.613-3.616,5.427-7.898,5.427-12.847
                                        c0-4.949-1.813-9.229-5.427-12.85L213.846,5.424C210.232,1.812,205.951,0,200.999,0s-9.233,1.812-12.85,5.424L60.242,133.331
                                        c-3.617,3.617-5.424,7.901-5.424,12.85c0,4.948,1.807,9.231,5.424,12.847C63.863,162.645,68.144,164.452,73.092,164.452z" fill={settings.sort_direction === 'asc' ? "grey" : 'black'}/>
                                    <path d="M328.905,237.549H73.092c-4.952,0-9.233,1.808-12.85,5.421c-3.617,3.617-5.424,7.898-5.424,12.847
                                        c0,4.949,1.807,9.233,5.424,12.848L188.149,396.57c3.621,3.617,7.902,5.428,12.85,5.428s9.233-1.811,12.847-5.428l127.907-127.906
                                        c3.613-3.614,5.427-7.898,5.427-12.848c0-4.948-1.813-9.229-5.427-12.847C338.139,239.353,333.854,237.549,328.905,237.549z" fill={settings.sort_direction === 'desc' ? "grey" : 'black'}/>
                                </g>
                            </g>
                        </svg>

                    </CButton>
                </div>
            }
            <div style={{display: 'flex'}}>
                <SelectForFilter
                    options={selectFilter}
                    onclick={() => setToggleSelect(!toggleSelect)}
                    visible={toggleSelect}
                    onMouse={(event: boolean) => setToggleSelect(event)}
                />
                {sort &&
                    <CButton
                        style={{
                            background: 'green',
                            color: 'white',
                            border: 'none'
                        }}
                        onClick={() => navigate('/assignments/assignments-main/create-edit-assignment')}
                    >
                        {t('createAssignment.create_assignment_button')}
                    </CButton>
                }
            </div>
        </div>
    );
}

export default SortAndFilter;

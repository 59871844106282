import React, {useEffect, useState} from 'react';
import {
    CButton,
    CContainer, CForm, CFormInput,
    CHeaderNav, CLink,
    CModal,
    CModalBody,
    CModalTitle
} from "@coreui/react";

import {useFormik} from 'formik';
import * as Yup from 'yup';

import herbKr from '../../assets/images/herb-kr.png';
import herbMes from '../../assets/images/herb-mes.png';
import herbCmc from '../../assets/images/herb-cmc.png';
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import CIcon from "@coreui/icons-react";
import {cifKg, cifRu, cifUs} from "@coreui/icons";
import {useCreateAuthMutation, useRefreshAuthMutation} from "../../store/auth/auth.api";
import {
    useLazyGetInfoUserQuery,
    useLazyGetReadCurrentUserQuery
} from "../../store/account/account.api";
import {useNavigate} from "react-router-dom";

// const validationSchema = Yup.object().shape({
//     username: Yup.string().required('Username is required'),
//     password: Yup.string().required('Password is required'),
// });

const Home = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [visible, setVisible] = useState(false);
    const [lngMenu, setLngMenu] = useState(false);
    const [createAuth, {isError: errorAuth, isLoading: loadingAuth, data: auth}] = useCreateAuthMutation();
    const [refreshAuth, {isError: refreshError, isLoading: refreshLoading, data: refreshData}] = useRefreshAuthMutation();
    const [getMe, {isError: errorGetMe, isLoading: loadingGetMe, data: me}] = useLazyGetInfoUserQuery();

    useEffect(() => {
        let lsData: any = '';
        try {
            lsData = JSON.parse(localStorage.getItem('getMe') || '');
        } catch (e) {}
        if (lsData?.refresh) {
            refreshAuth({refresh: lsData.refresh}).then((res: any) => {
                if (res?.data?.access) lsData.access = res.data.access;
                localStorage.setItem('getMe', JSON.stringify(lsData));
                getMe();
            });
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            detail: ''
        },
        // validationSchema: validationSchema,
        onSubmit: (values, {setSubmitting}) => {
            const data = {
                password: values.password,
                username: values.username
            };
            const formData = new FormData();
            // @ts-ignore
            Object.keys(data).forEach(key => formData.append(key, data[key]));

            createAuth(formData).then((res: any) => {
                if (res.data?.access) {
                    localStorage.setItem('getMe', JSON.stringify(res.data));
                    getMe().then(() => {
                        navigate({
                            pathname: '/es-feed'
                        });
                    });
                }
                if(res?.error) {
                    if(res?.error?.data?.username) formik.errors.username = res?.error?.data?.username[0];
                    if(res?.error?.data?.password) formik.errors.password = res?.error?.data?.password[0];
                    if(res?.error?.data?.detail) formik.errors.detail = res?.error?.data?.detail;
                }

                setSubmitting(false);
            }).catch(() => {
                setSubmitting(false);
            });
        }
    });

    const loginHandler = () => {
        if (me?.id && !errorGetMe) {
            navigate('/es-feed');
        } else {
            setVisible(true);
        }
    };

    const setLanguageHandler = (lang: any) => {
        if (lang !== i18n.language) i18n.changeLanguage(lang);
        setLngMenu(false);
    };

    return (
        <>
            <div className='home-icons'>
                <CHeaderNav className="">
                    <CHeaderNav className="">
                        <i className="fa fa-info-circle" aria-hidden="true"> </i>
                    </CHeaderNav>

                    <CHeaderNav className="ms-4" style={{position: 'relative'}}>
                        {lngMenu && <div className="language-select-overlay" onClick={() => {
                            setLngMenu(false)
                        }}/>}
                        <div className={`language-select-wrapper${lngMenu ? ' language-select-wrapper__active' : ''}`}>
                            <div
                                className={`language-select-item${i18n.language === 'en' ? ' language-select-item__active' : ''}`}
                                onClick={() => setLanguageHandler('en')}
                            >
                                <CIcon icon={cifUs} size="xxl"/>
                                <span>English</span>
                            </div>
                            <div
                                className={`language-select-item${i18n.language === 'ky' ? ' language-select-item__active' : ''}`}
                                onClick={() => setLanguageHandler('ky')}
                            >
                                <CIcon icon={cifKg} size="xxl"/>
                                <span>Кыргыз тили</span>
                            </div>
                            <div
                                className={`language-select-item${i18n.language === 'ru' ? ' language-select-item__active' : ''}`}
                                onClick={() => setLanguageHandler('ru')}
                            >
                                <CIcon icon={cifRu} size="xxl"/>
                                <span>Русский</span>
                            </div>
                        </div>
                        <i className="fa fa-language" aria-hidden="true" onClick={() => {
                            setLngMenu(prev => !prev)
                        }}> </i>
                    </CHeaderNav>

                    <i className="fa fa-sign-in ms-4" aria-hidden="true" onClick={() => loginHandler()}></i>
                </CHeaderNav>
            </div>
            <div className='main-wrapper'>
                <CContainer fluid className="d-flex align-items-center justify-content-between container-home">
                    <div className="home-title__wrapper" style={{backgroundImage: `url(${herbKr}), url(${herbMes})`}}>
                        <div className="home-title__item">{t('home.title1')}</div>
                        <div className="home-title__item">{t('home.title2')}</div>
                        <div className="home-title__item">{t('home.title3')}</div>
                    </div>
                </CContainer>

                <div className="home-logo__wrapper">
                    <div className="home-logo__background">
                        <div/>
                    </div>
                    <div className="home-logo__main" style={{backgroundImage: `url(${herbCmc})`}}/>
                </div>

                <CContainer>
                    <div className="home-desc__wrapper">
                        <div className="home-desc__item">
                            <strong>{t('home.desc1')}</strong>
                        </div>
                        <div className="home-desc__item">
                            <strong>{t('home.desc2')}</strong>
                        </div>
                        <div className="home-desc__item">
                            <strong>{t('home.desc3')}</strong>
                        </div>
                        <div className="home-desc__item">
                            <strong>{t('home.desc4')}</strong>
                        </div>
                        <div className="home-desc__item">
                            <strong>{t('home.desc5')}</strong>{t('home.desc6')}
                        </div>
                        <div className="home-desc__item">
                            {t('home.desc7')}
                        </div>
                    </div>
                </CContainer>
            </div>

            <CModal
                alignment="center"
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby="VerticallyCenteredExample"
                className="registration-modal"
            >
                <CModalBody className="registration-modal-body">
                    <CModalTitle className="main-modal-title" id="VerticallyCenteredExample">Войти</CModalTitle>
                    <p className="main-modal-subtitle">Войдите в свой аккуант</p>

                    <CForm className="main-modal-form" onSubmit={formik.handleSubmit}>
                        <div className="mb-3 main-modal-block-input">
                            <div className="modal-form-svg-box">
                                <div className="modal-form-svg">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21"
                                         viewBox="0 0 19 21" fill="none">
                                        <path
                                            d="M9.46875 10.8711C12.2305 10.8711 14.4688 8.63281 14.4688 5.87109C14.4688 3.10938 12.2305 0.871094 9.46875 0.871094C6.70703 0.871094 4.46875 3.10938 4.46875 5.87109C4.46875 8.63281 6.70703 10.8711 9.46875 10.8711ZM12.9688 12.1211H12.1211C11.2305 12.4961 10.1797 12.7461 9.11719 12.7461C8.06641 12.7461 7.00391 12.4961 6.12109 12.1211H5.27344C3.31641 12.1211 1.77344 13.6641 1.77344 15.6211V17.3711C1.77344 18.3184 2.57812 19.1211 3.52344 19.1211H14.7188C15.6641 19.1211 16.4688 18.3184 16.4688 17.3711V15.6211C16.4688 13.6641 14.9258 12.1211 12.9688 12.1211Z"
                                            fill="#717783"/>
                                    </svg>
                                </div>
                            </div>

                            <CFormInput
                                className={`main-input-main ${formik.touched.username && formik.errors.username ? 'is-invalid' : ''}`}
                                type="text"
                                placeholder='Логин'
                                name="username"
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.username && formik.errors.username && (
                                <div
                                    className={`invalid-feedback`}>{formik.errors.username}</div>
                            )}
                        </div>
                        <div className="mb-3 main-modal-block-input">
                            <div className="modal-form-svg-box">
                                <div className="modal-form-svg">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21"
                                         fill="none">
                                        <path
                                            d="M16.5078 9.57275H15.5703V6.76025C15.5703 3.48682 12.9062 0.822754 9.63281 0.822754C6.35938 0.822754 3.69531 3.48682 3.69531 6.76025V9.57275H2.75781C1.72266 9.57275 0.882812 10.4126 0.882812 11.4478V18.9478C0.882812 19.9829 1.72266 20.8228 2.75781 20.8228H16.5078C17.543 20.8228 18.3828 19.9829 18.3828 18.9478V11.4478C18.3828 10.4126 17.543 9.57275 16.5078 9.57275ZM12.4453 9.57275H6.82031V6.76025C6.82031 5.20947 8.08203 3.94775 9.63281 3.94775C11.1836 3.94775 12.4453 5.20947 12.4453 6.76025V9.57275Z"
                                            fill="#717783"/>
                                    </svg>
                                </div>
                            </div>

                            <CFormInput
                                className={`main-input-main ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                                type="password"
                                placeholder='Пароль'
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className="invalid-feedback">{formik.errors.password}</div>
                            )}
                        </div>

                        {formik.errors.detail && (
                            <div
                                className={`full-error`}>{formik.errors.detail}</div>
                        )}
                        <div className="main-modal-link-block">
                            <CButton type="submit" className="main-modal-btn">
                                Войти
                            </CButton>
                            <CLink
                                className="text-black px-2 main-modal-link"
                                href="https://coreui.io"
                                target="_blank"
                            >
                                Сбросить пароль
                            </CLink>
                        </div>

                    </CForm>

                </CModalBody>

            </CModal>
        </>
    );
};

export default Home;


// import React, {useEffect, useState} from 'react'
// import {
//     CButton,
//     CContainer, CForm, CFormInput,
//     CHeaderNav, CLink,
//     CModal,
//     CModalBody,
//     CModalTitle
// } from "@coreui/react"
//
// import herbKr from '../../assets/images/herb-kr.png'
// import herbMes from '../../assets/images/herb-mes.png'
// import herbCmc from '../../assets/images/herb-cmc.png'
// import {useTranslation} from "react-i18next";
// import i18n from "i18next";
// import CIcon from "@coreui/icons-react";
// import {cifKg, cifRu, cifUs} from "@coreui/icons";
// import {useCreateAuthMutation, useRefreshAuthMutation} from "../../store/auth/auth.api";
// import {useGetReadCurrentUserQuery, useLazyGetReadCurrentUserQuery} from "../../store/account/account.api";
// import {useNavigate} from "react-router-dom";
//
// const Home = () => {
//     const {t} = useTranslation();
//     const navigate = useNavigate();
//
//     const [visible, setVisible] = useState(false)
//     const [lngMenu, setLngMenu] = useState(false);
//     const [createAuth, {isError: errorAuth,  isLoading: loadingAuth,data: auth}] = useCreateAuthMutation();
//     const [getMe, {isError: errorGetMe,  isLoading: loadingGetMe, data: me}] = useLazyGetReadCurrentUserQuery();
//     // const {data: meData} = useGetReadCurrentUserQuery();
//     useEffect(() => {
//         getMe();
//     }, [])
//     const [state, setState] = useState({
//         username: '',
//         password: '',
//     });
//
//     const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         setState((prevState: any) => ({
//             ...prevState,
//             [event.target.name]: event.target.value
//         }));
//     }
//
//     const getFormData = (object: {
//         [x: string]: string | Blob; full_name?: any; phone_number?: any; // @ts-ignore
//         email?: string; content?: any;
//     }) => {
//         const formData = new FormData();
//         Object.keys(object).forEach(key => formData.append(key, object[key]));
//         return formData;
//     }
//
//     const handleSubmit =  (event: { preventDefault: () => void; }) => {
//         event.preventDefault();
//
//         const data = {
//             password: state.password,
//             username: state.username
//         }
//         const formData = getFormData(data);
//         createAuth(formData).then(res => {
//             // @ts-ignore
//             if(res.data?.access) {
//                 // @ts-ignore
//                 localStorage.setItem('getMe', res?.data?.access);
//
//                 getMe().then(() => {
//                     navigate({
//                         pathname: '/es-feed'
//                     })
//                 });
//             }
//         })
//     }
//
//     const loginHandler = () => {
//       if(me?.id && !errorGetMe) {
//           navigate('/es-feed');
//       } else {
//           setVisible(true);
//       }
//     }
//
//     const setLanguageHandler = (lang: any) => {
//         if (lang !== i18n.language) i18n.changeLanguage(lang);
//         setLngMenu(false);
//     };
//
//     return (
//         <>
//             <div className='home-icons'>
//                 <CHeaderNav className="">
//                     <CHeaderNav className="">
//                         <i className="fa fa-info-circle" aria-hidden="true"> </i>
//                     </CHeaderNav>
//
//                     <CHeaderNav className="ms-4" style={{position: 'relative'}}>
//                         {lngMenu && <div className="language-select-overlay" onClick={() => {
//                             setLngMenu(false)
//                         }}/>}
//                         <div className={`language-select-wrapper${lngMenu ? ' language-select-wrapper__active' : ''}`}>
//                             <div
//                                 className={`language-select-item${i18n.language === 'en' ? ' language-select-item__active' : ''}`}
//                                 onClick={() => setLanguageHandler('en')}
//                             >
//                                 <CIcon icon={cifUs} size="xxl"/>
//                                 <span>English</span>
//                             </div>
//                             <div
//                                 className={`language-select-item${i18n.language === 'ky' ? ' language-select-item__active' : ''}`}
//                                 onClick={() => setLanguageHandler('ky')}
//                             >
//                                 <CIcon icon={cifKg} size="xxl"/>
//                                 <span>Кыргыз тили</span>
//                             </div>
//                             <div
//                                 className={`language-select-item${i18n.language === 'ru' ? ' language-select-item__active' : ''}`}
//                                 onClick={() => setLanguageHandler('ru')}
//                             >
//                                 <CIcon icon={cifRu} size="xxl"/>
//                                 <span>Русский</span>
//                             </div>
//                         </div>
//                         <i className="fa fa-language" aria-hidden="true" onClick={() => {
//                             setLngMenu(prev => !prev)
//                         }}> </i>
//                     </CHeaderNav>
//
//                     {/*<i className="fa fa-sign-out ms-4" aria-hidden="true" onClick={() => setVisible(!visible)}></i>*/}
//                     <i className="fa fa-sign-in ms-4" aria-hidden="true" onClick={() => loginHandler()}></i>
//                 </CHeaderNav>
//             </div>
//             <div className='main-wrapper'>
//                 <CContainer fluid className="d-flex align-items-center justify-content-between container-home">
//                     <div className="home-title__wrapper" style={{backgroundImage: `url(${herbKr}), url(${herbMes})`}}>
//                         <div className="home-title__item">{t('home.title1')}</div>
//                         <div className="home-title__item">{t('home.title2')}</div>
//                         <div className="home-title__item">{t('home.title3')}</div>
//                     </div>
//                 </CContainer>
//
//                 <div className="home-logo__wrapper">
//                     <div className="home-logo__background">
//                         <div/>
//                     </div>
//                     <div className="home-logo__main" style={{backgroundImage: `url(${herbCmc})`}}/>
//                 </div>
//
//                 <CContainer>
//                     <div className="home-desc__wrapper">
//                         <div className="home-desc__item">
//                             <strong>{t('home.desc1')}</strong>
//                         </div>
//                         <div className="home-desc__item">
//                             <strong>{t('home.desc2')}</strong>
//                         </div>
//                         <div className="home-desc__item">
//                             <strong>{t('home.desc3')}</strong>
//                         </div>
//                         <div className="home-desc__item">
//                             <strong>{t('home.desc4')}</strong>
//                         </div>
//                         <div className="home-desc__item">
//                             <strong>{t('home.desc5')}</strong>{t('home.desc6')}
//                         </div>
//                         <div className="home-desc__item">
//                             {t('home.desc7')}
//                         </div>
//                     </div>
//                 </CContainer>
//             </div>
//
//             <CModal
//                 alignment="center"
//                 visible={visible}
//                 onClose={() => setVisible(false)}
//                 aria-labelledby="VerticallyCenteredExample"
//                 className="registration-modal"
//             >
//                 <CModalBody className="registration-modal-body">
//                     <CModalTitle className="main-modal-title" id="VerticallyCenteredExample">Войти</CModalTitle>
//                     <p className="main-modal-subtitle">Войдите в свой аккуант</p>
//
//                     <CForm className="main-modal-form" onSubmit={ e => handleSubmit(e)}>
//                         <div className="mb-3 main-modal-block-input">
//                             <div className="modal-form-svg-box">
//                                 <div className="modal-form-svg">
//                                     <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
//                                         <path d="M9.46875 10.8711C12.2305 10.8711 14.4688 8.63281 14.4688 5.87109C14.4688 3.10938 12.2305 0.871094 9.46875 0.871094C6.70703 0.871094 4.46875 3.10938 4.46875 5.87109C4.46875 8.63281 6.70703 10.8711 9.46875 10.8711ZM12.9688 12.1211H12.3164C11.4492 12.5195 10.4844 12.7461 9.46875 12.7461C8.45312 12.7461 7.49219 12.5195 6.62109 12.1211H5.96875C3.07031 12.1211 0.71875 14.4727 0.71875 17.3711V18.9961C0.71875 20.0312 1.55859 20.8711 2.59375 20.8711H16.3438C17.3789 20.8711 18.2188 20.0312 18.2188 18.9961V17.3711C18.2188 14.4727 15.8672 12.1211 12.9688 12.1211Z" fill="#717783"/>
//                                     </svg>
//                                 </div>
//                             </div>
//
//                             <CFormInput className="main-input-main"
//                                         type="text"
//                                         placeholder='Имя пользователя'
//                                         name="username"
//                                         value={state.username}
//                                         onChange={handleChange}
//                             >
//
//                             </CFormInput>
//                         </div>
//                         <div className="mb-3 main-modal-block-input">
//                             <div className="modal-form-svg-box">
//                                 <div className="modal-form-svg">
//                                     <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
//                                         <path d="M16.5078 9.57275H15.5703V6.76025C15.5703 3.48682 12.9062 0.822754 9.63281 0.822754C6.35938 0.822754 3.69531 3.48682 3.69531 6.76025V9.57275H2.75781C1.72266 9.57275 0.882812 10.4126 0.882812 11.4478V18.9478C0.882812 19.9829 1.72266 20.8228 2.75781 20.8228H16.5078C17.543 20.8228 18.3828 19.9829 18.3828 18.9478V11.4478C18.3828 10.4126 17.543 9.57275 16.5078 9.57275ZM12.4453 9.57275H6.82031V6.76025C6.82031 5.20947 8.08203 3.94775 9.63281 3.94775C11.1836 3.94775 12.4453 5.20947 12.4453 6.76025V9.57275Z" fill="#717783"/>
//                                     </svg>
//                                 </div>
//                             </div>
//
//                             <CFormInput
//                                 className="main-input-main"
//                                 type="password"
//                                 placeholder='Пароль'
//                                 name="password"
//                                 value={state.password}
//                                 onChange={handleChange}
//                             />
//                         </div>
//
//                         <div className="main-modal-link-block">
//                             <CButton type="submit" className="main-modal-btn">Войти</CButton>
//                             <CLink
//                                 className="text-black px-2 main-modal-link"
//                                 href="https://coreui.io"
//                                 target="_blank"
//                             >
//                                 Сбросить пароль
//                             </CLink>
//                         </div>
//                     </CForm>
//
//                 </CModalBody>
//
//             </CModal>
//         </>
//     )
// }
//
// export default Home

import React, {useEffect, useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'

import {CBadge} from '@coreui/react'

interface IAppSidebarNav {
    items: any;
}

export const AppSidebarNav: React.FC<IAppSidebarNav> = ({items}) => {
    const location = useLocation();

    const [state, setState] = useState();
    useEffect(() => {

    }, []);
    const navLink = (name: string, icon: any, badge: any) => {
        return (
            <>
                {icon && icon}
                {name && name}
                {badge && (
                    <CBadge color={badge.color} className="ms-auto">
                        {badge.text}
                    </CBadge>
                )}
            </>
        )
    }

    const navItem = (item: any, index: number) => {
        const {component, name, badge, icon, ...rest} = item
        const Component = component

        if (item.to === location.pathname) {
        // if (location.pathname.includes(item.to)) {
            return (
                <div style={{background: '#EB7D3C', margin: '0 0.375rem 0 0', borderRadius: '0 6px 6px 0'}} key={index}
                >
                    <Component
                        {...(rest.to &&
                            !rest.items && {
                                component: NavLink,
                            })}
                        key={index}
                        {...rest}
                    >
                        {navLink(name, icon, badge)}
                    </Component>
                </div>
            )
        } else {
            return (
                <Component
                    {...(rest.to &&
                        !rest.items && {
                            component: NavLink,
                        })}
                    key={index}
                    {...rest}
                >
                    {navLink(name, icon, badge)}
                </Component>
            )
        }

    }
    const navGroup = (item: any, index: number) => {
        const {component, name, icon, to, ...rest} = item
        const Component = component

        return (
            <Component
                idx={String(index)}
                key={index}
                // @ts-ignore
                toggler={navLink(name, icon)}
                visible={location.pathname.startsWith(to)}
                {...rest}
            >
                <div></div>
                {item.items?.map((item: any, index: number) =>
                    item.items ? navGroup(item, index) : navItem(item, index),
                )}
            </Component>
        )
    }

    return (
        <React.Fragment>
            {items &&
                items.map((item: any, index: number) => (item.items ? navGroup(item, index) : navItem(item, index)))}
        </React.Fragment>
    )
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config";
import { headerConfig } from "../../functions/headerConfig";

export const esFormApi = createApi({
    reducerPath: 'esForm/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['EsForm'],
    endpoints: build => ({
        getData: build.query<any, string>({
            query: (query: string) => {
                return {
                    url: `/generation/docs/em/${query}`
                }
            },
            providesTags: ['EsForm'],
        }),
    })
})

export const { useLazyGetDataQuery } = esFormApi;
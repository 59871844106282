import React from 'react';

const NewDictionary = () => {
  return (
    <div>
      New dictionary
    </div>
  );
};

export default NewDictionary;

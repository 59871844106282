import React, {FC} from 'react';
import {CFormCheck, CTooltip} from "@coreui/react";

import {IEmergencyType} from "../../../interfaces/IEmergency";

interface IEmergencyTypesList {
  data: IEmergencyType[]
  state: number[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void
}

const EmergencyTypesList: FC<IEmergencyTypesList> = ({data, state, onChange}) => {

  let emergencyTypesList;

  if (data.length) {
    let arrayForSort = [...data];
    emergencyTypesList = arrayForSort.sort((a: any, b: any) => {
      if (a.name > b.name) {
        return 1
      } else return -1
    }).map(el => {
      return (
        <div className="es-feed-es-type" key={el.id} title={el.name}>
          <div>
            <CFormCheck id={`emergency-type_${el.id}`}
                        style={{marginRight: '10px'}}
                        checked={state.includes(el.id)}
                        name={`emergency-type_${el.id}`}
                        onChange={(e) => onChange(e, el.id)}
            />
          </div>
          {/*<CTooltip content={el.name} placement="right" offset={[-1, 10]}>*/}
            <label className="es-feed-es-type__label" htmlFor={`emergency-type_${el.id}`}>
              {/*@ts-ignore*/}
              <img alt={''} src={el.geo_icon.icon} width={32} height={37}/>
              <span style={{fontSize: '14px', paddingLeft: '10px'}}>{el.name}</span>
            </label>
          {/*</CTooltip>*/}
        </div>
      )
    })
  }

  return (
    <>
      {emergencyTypesList}
    </>
  );
};

export default EmergencyTypesList;
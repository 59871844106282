import React, {ReactNode} from 'react';
import {CCol, CFormLabel, CRow} from "@coreui/react";

interface FormGroupProps {
    htmlFor?: string;
    label?: string;
    children: ReactNode;
    className?: string;
}
const FormGroup: React.FC<FormGroupProps> = ({htmlFor, label, children, className}) => {
  return (
    <CRow className={className || "mb-3"}>
      {(label || label === '') ?
      <CCol sm={12}>
        <CFormLabel htmlFor={htmlFor} className="col-form-label">
          {label}
        </CFormLabel>
      </CCol>
      : null}
      <CCol sm={12}>
        {children}
      </CCol>
    </CRow>
  );
};

export default FormGroup;

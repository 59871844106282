import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {IDocument, IDocuments} from "../../interfaces/IDocuments";

export const documentsApi = createApi({
    reducerPath: 'documents/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['Documents'],
    endpoints: build => ({
        getDocuments: build.query<IDocuments, any>({
            query: ({id, query}) => {
                return {
                    url: `/list/docs/on/type/${id}/${query || ''}`
                }
            },
            providesTags: ['Documents'],
        }),
        getDocument: build.query<IDocument, any>({
            query: (id) => ({
                url: `/detail/docs/${id}/`
            })
        }),
        createDocument: build.mutation<any, any>({
            query: (body) => ({
                url: '/create/district/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Documents'],
        }),
        updateDocument: build.mutation<any, Partial<any> & Pick<any, 'id'>>({
            query: ({id, body, query}) => {
                return {
                    // url: `/update/district/${id}/${query}/`,
                    url: `/update/district/${id}/`,
                    method: 'PUT',
                    body
                }
            },
            invalidatesTags: ['Documents'],
        }),
        removeDocument: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/delete/district/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Documents'],
        })
    })
})

export const {
    useLazyGetDocumentsQuery,
    useLazyGetDocumentQuery,
    useCreateDocumentMutation,
    useUpdateDocumentMutation,
    useRemoveDocumentMutation
} = documentsApi;